import { createContext, ReactElement, useCallback } from 'react';

import {
  ListContextInitialValues,
  ListContextValues,
  ListProvider,
  useListContext,
} from '../../../providers/ListProvider';

import RequestError from '../../../network/RequestError';
import ObjectType from '../../../utils/ObjectType';
import useListState from '../../../hooks/useListState';

import useInboxMessages from '../hooks/messages/useInboxMessages';
import useOutboxMessages from '../hooks/messages/useOutboxMessages';
import useSendMessage from '../hooks/messages/useSendMessage';
import { FilteredMessagesModel } from '../network/models';
import { SendMessageParams } from '../network';

export type MessagesProviderProps = {
  children: ReactElement;
};

export interface MessagesContextValues extends ListContextValues {
  inboxMessages: FilteredMessagesModel | null;
  isInboxMessagesLoading: boolean;
  inboxMessagesError: null | RequestError;
  loadInboxMessages: () => void;

  outboxMessages: FilteredMessagesModel | null;
  isOutboxMessagesLoading: boolean;
  outboxMessagesError: null | RequestError;
  loadOutboxMessages: () => void;

  sentMessage: ObjectType | null;
  isSendMessageLoading: boolean;
  sendMessageError: null | RequestError;
  sendMessage: (params: SendMessageParams) => void;
}

const MessagesContextInitialValues = {
  ...ListContextInitialValues,

  inboxMessages: null,
  isInboxMessagesLoading: false,
  inboxMessagesError: null,
  loadInboxMessages: () => {},

  outboxMessages: null,
  isOutboxMessagesLoading: false,
  outboxMessagesError: null,
  loadOutboxMessages: () => {},

  sentMessage: null,
  isSendMessageLoading: false,
  sendMessageError: null,
  sendMessage: () => {},
};

export const MessagesContext = createContext<MessagesContextValues>(MessagesContextInitialValues);

export const MessagesProvider = (props: MessagesProviderProps) => {
  const { page, pageSize, setPage, setPageSize } = useListState();

  const [inboxMessages, isInboxMessagesLoading, inboxMessagesError, loadInboxMessages] = useInboxMessages();
  const [outboxMessages, isOutboxMessagesLoading, outboxMessagesError, loadOutboxMessages] = useOutboxMessages();

  const [sentMessage, isSendMessageLoading, sendMessageError, sendMessage] = useSendMessage();

  const data = {
    inbox: inboxMessages,
    outbox: outboxMessages,
  };

  const error = inboxMessagesError || outboxMessagesError;

  const isLoading = isInboxMessagesLoading || isOutboxMessagesLoading;

  const loadData = useCallback(() => {
    loadInboxMessages();
    loadOutboxMessages();
  }, [loadInboxMessages, loadOutboxMessages]);


  return (
    <ListProvider
      ListContext={MessagesContext}

      data={data}
      error={error}
      loading={isLoading}
      loadData={loadData}

      inboxMessages={inboxMessages}
      isInboxMessagesLoading={isInboxMessagesLoading}
      inboxMessagesError={inboxMessagesError}
      loadInboxMessages={loadInboxMessages}

      outboxMessages={outboxMessages}
      isOutboxMessagesLoading={isOutboxMessagesLoading}
      outboxMessagesError={outboxMessagesError}
      loadOutboxMessages={loadOutboxMessages}

      sentMessage={sentMessage}
      isSendMessageLoading={isSendMessageLoading}
      sendMessageError={sendMessageError}
      sendMessage={sendMessage}

      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
    >
      {props.children}
    </ListProvider>
  );
};

export const useMessagesContext = () => useListContext(MessagesContext) as MessagesContextValues;
