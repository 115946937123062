import { createContext, ReactElement, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import ObjectType from '../../utils/ObjectType';
import { saveAccountToLS } from '../../utils/accountsLS';

export type CurrentAccountProps = null | {
  account_type: string;
  id: number;
  name: string;
  status: string;
};

export type CabinetContextProps = {
  currentUser: null | ObjectType;
  currentAccount: CurrentAccountProps;
  setCurrentAccount: (currentAccount: any) => void;
};

export const CabinetContext = createContext<CabinetContextProps>({
  currentUser: null,
  currentAccount: null,

  setCurrentAccount: (currentAccount: any) => {},
});

let currentCabinetContextValue: CabinetContextProps | null = null;

export const getCurrentCabinetContextValue = (): Promise<CabinetContextProps | null> => {
  return new Promise((resolve) => {
    if (!currentCabinetContextValue) {
      setTimeout(() => {
        resolve(currentCabinetContextValue);
      }, 500);

      return;
    }

    resolve(currentCabinetContextValue);
  });
};

export const CabinetContextProvider = ({ children, currentUser }: { children: ReactElement | ReactElement[]; currentUser: any }) => {
  const [currentAccount, setCurrentAccount] = useState(null);

  const handleSetCurrentAccount = useCallback((currentAccount: any) => {
    saveAccountToLS(currentAccount);
    setCurrentAccount(currentAccount);
  }, []);

  const value = useMemo(() => ({
    currentUser,
    currentAccount,
    setCurrentAccount: handleSetCurrentAccount,
  }), [currentUser, currentAccount, handleSetCurrentAccount]);

  useEffect(() => {
    currentCabinetContextValue = value;

    return () => {
      currentCabinetContextValue = null
    };
  }, [value]);

  return (
    <CabinetContext.Provider
      value={value}
    >
      {children}
    </CabinetContext.Provider>
  );
};

export const useCabinetContext = () => {
  const context = useContext(CabinetContext);
  return context;
};
