import request from './request';
import { VerificationDataModel } from './models/verification';

export type GetVerificationDataProps = {
  token: string;
};

export const getVerificationData = ({ token }: GetVerificationDataProps): Promise<VerificationDataModel | null> => request({
  url: '/api/v1/sumsub/verification/',
  method: 'POST',
  body: {
    token,
  },
  contentType: 'json',
}).then((result) => result.data);
