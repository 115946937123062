import React, { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AllItemsFlattenedModel } from '../../utils/companySecretary';

import { useCompanySecretaryContext } from '../../providers/CompanySecretaryProvider';
import AccountLayout from '../../components/AccountLayout';
import CompanySecretaryTable from './components/CompanySecretaryTable';
import CompanySecretaryBreadcrumbs from './components/CompanySecretaryBreadcrumbs';
import SearchBar from './components/SearchBar';
import SearchResultsPage from './components/SearchResultsPage';

const CompanySecretaryScreen = () => {
  const { t } = useTranslation();
  const { path } = useRouteMatch();

  const { allItemsFlattened, loading, error } = useCompanySecretaryContext();

  const rootFolders = useMemo(() =>
    (allItemsFlattened || []).filter(item => item.parentId === 0),
    [allItemsFlattened]
  );

  return (
    <AccountLayout
      title={t('account.companySecretary.title')}
      loading={loading}
      error={error}
    >
      <>
        <Switch>
          <Route path={`${path}`} exact>
            <>
              <CompanySecretaryBreadcrumbs />
              <SearchBar />
              <CompanySecretaryTable data={rootFolders} />
            </>
          </Route>
          <Route path={`${path}/search`}>
            <SearchResultsPage />
          </Route>
          {(allItemsFlattened || []).map((item: AllItemsFlattenedModel) => (
            <Route key={`${item.id}_${item.type}`} path={`/${item.path}`} exact>
              <>
                <CompanySecretaryBreadcrumbs />
                <SearchBar />
                <CompanySecretaryTable data={item.children} />
              </>
            </Route>
          ))}
        </Switch>
      </>
    </AccountLayout>
  );
};

export default CompanySecretaryScreen;
