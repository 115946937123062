import React from 'react';

import MenuItem from '../../../../../../../../components/ui/MenuItem';
import FormSelect from '../../../../../../../../components/ui/FormSelect';
import { useTranslation } from 'react-i18next';

type GenderProps = {
  formPrefix: string;
  tPrefix: string;
  validate?: (value: any) => string | undefined;
};

export const Gender = (props: GenderProps) => {
  const { validate, formPrefix, tPrefix } = props;

  const { t } = useTranslation();

  return (
    <FormSelect
      name={`${formPrefix}_gender`}
      label={`${t(`${tPrefix}.peopleForm.individual.howToHandle`)} *`}
      validate={validate}
      initialValue=""
      variant="outlined"
    >
      {[
        t(`${tPrefix}.peopleForm.individual.mr`),
        t(`${tPrefix}.peopleForm.individual.ms`),
      ].map((option, index) => {
        return (
          <MenuItem key={index} value={option}>
            {option}
          </MenuItem>
        );
      })}
    </FormSelect>
  );
};
