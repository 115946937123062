import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as KeyboardArrowRight } from '../../../../../../assets/img/messages/reply-arrow.svg';

import CircularProgress from '../../../../../../components/ui/CircularProgress';
import IconButton from '../../../../../../components/ui/IconButton';
import { getFromEmailName, getHistoryMessages, removeReplyTextRegExp } from '../../../../utils/messages';
import useDateLib from '../../../../../../hooks/useDateLib';
import { useMessagesContext } from '../../../../providers/MessagesProvider';
import { FilteredMessageModel } from '../../../../network/models';

import History from '../History';
import { MessageProps } from '../MessagesList';

import {
  MessageWrapper,
  ContentWrapper,
  MessageContent,
  MessageSubject,
  MessageSubtitle,
  MessageFrom,
  MessageDate,
  MessageBody,
  Reply,
  ReplyInput,
} from './styled';

const Message = ({ message }: MessageProps) => {

  const [currentMessage, setCurrentMessage] = useState<FilteredMessageModel>(message);

  const { body_text, type, subject, date: created } = currentMessage;

  const [replyValue, setReplyValue] = useState('');
  const { sendMessage, isSendMessageLoading, loadInboxMessages, loadOutboxMessages, data } = useMessagesContext();
  const { getCreatedDate } = useDateLib();
  const { t } = useTranslation();

  const body = (body_text || '').replace(removeReplyTextRegExp, '');
  const date = getCreatedDate(created);
  const from = getFromEmailName(type);

  const historyMessages = getHistoryMessages({ data, messageSubject: subject });

  const handleChangeCurrentMessage = useCallback((message: FilteredMessageModel) => () => {
    setCurrentMessage(message);
  }, []);

  const handleChangeReply = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setReplyValue(event.target.value);
  }, []);

  const handleSubmit = useCallback(async () => {
    if (replyValue) {
      await sendMessage({
        subject,
        body_text: replyValue,
      });
      setReplyValue('');
      await loadInboxMessages();
      await loadOutboxMessages();
    }
  }, [loadInboxMessages, loadOutboxMessages, replyValue, sendMessage, subject]);

  const handleKeyDown = useCallback(async (event: React.KeyboardEvent<HTMLInputElement>) => {
    if(event.key === 'Enter') {
      await handleSubmit();
    }
  }, [handleSubmit]);

  return (
    <MessageWrapper>
      <ContentWrapper>
        <MessageContent width={{ xs: '100%', md: '50%' }}>
          <MessageSubject>{subject}</MessageSubject>
          <MessageSubtitle>
            <MessageFrom>{from}</MessageFrom>, <MessageDate>{date}</MessageDate>
          </MessageSubtitle>
          <MessageBody>{body}</MessageBody>
        </MessageContent>

        <History
          history={historyMessages}
          handleChangeCurrentMessage={handleChangeCurrentMessage}
          currentMessage={currentMessage}
        />
      </ContentWrapper>

      <Reply>
        <ReplyInput
          fullWidth
          disabled={isSendMessageLoading}
          name="reply"
          variant="outlined"
          label={t('account.messages.message.reply')}
          InputProps={{
            endAdornment: (
              <IconButton
                color="inherit"
                aria-label="send message"
                onClick={handleSubmit}
                disabled={isSendMessageLoading || !replyValue}
              >
                {isSendMessageLoading ? <CircularProgress size={20} /> : <KeyboardArrowRight />}
              </IconButton>
            ),
          }}
          value={replyValue}
          onChange={handleChangeReply}
          onKeyDown={handleKeyDown}
        />
      </Reply>
    </MessageWrapper>
  );
};

export default Message;
