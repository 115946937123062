import styled from 'styled-components';

import FormControl from '../FormControl';
import InputLabel from '../InputLabel';

export const StyledFormControl = styled(FormControl)`
  width: 100%;
  & .MuiInputBase-root {
    border-radius: 10px;
  }
  & .MuiSelect-root {
    border-radius: 10px;
    background: #FFFFFF;
  }
  & .MuiFormLabel-root {
     z-index: 1;
   }
`;

export const StyledInputLabel = styled(InputLabel)`
  &.MuiInputLabel-formControl {
    transform: translate(14px, 21px) scale(1);
    z-index: 1;

    &.Mui-focused {
      color: #232121;
    }
  }

  &.MuiInputLabel-shrink {
    transform: translate(14px, -6px) scale(0.75);
  }
`;
