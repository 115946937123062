import { useCallback, useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

import Hidden from '../../components/ui/Hidden';

import {
  StyledButtonGroup,
  StyledButton,
} from './styled';

import { CurrentDisplayItem, CurrentMenuItem, Divider, StyledMenu, StyledMenuItem } from '../AsideMenu/styled';

export type TabsMenuItemProps = {
  id: number;
  path: string;
  title: string;
};

export type TabsMenuProps = {
  items: TabsMenuItemProps[];
};

const TabsMenu = ({ items }: TabsMenuProps) => {
  const { pathname } = useLocation();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = useCallback(
    () => setAnchorEl(null),
    [setAnchorEl],
  );
  const handleMenu = useCallback(
    (event: any) => setAnchorEl(event.currentTarget as Element),
    [setAnchorEl],
  );

  const currentTitle = useMemo(
    () => items.filter((x) => pathname.includes(x.path))[0]?.title || '',
    [items, pathname],
  );
  return (
    <>
      <Hidden smDown>
        <StyledButtonGroup>
          {items.map(({ id, path, title }: any) => (
            <StyledButton
              to={path}
              component={NavLink}
              key={id}
            >
              {title}
            </StyledButton>
          ))}
        </StyledButtonGroup>
      </Hidden>

      <Hidden mdUp>
        <CurrentDisplayItem onClick={handleMenu}>
          {currentTitle}
          <KeyboardArrowDownIcon color="inherit" />
        </CurrentDisplayItem>
        <StyledMenu
          id="menu-appbar"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          open={open}
          onClose={handleClose}
          TransitionProps={{ timeout: { exit: 200 } }}
        >
          <CurrentMenuItem onClick={handleClose}>
            {currentTitle}
          </CurrentMenuItem>

          <Divider />

          {items.map((link) => (
            <StyledMenuItem
              key={link.path}
              component={Link}
              to={link.path}
              onClick={handleClose}
            >
              {link.title}
            </StyledMenuItem>
          ))}
        </StyledMenu>
      </Hidden>
    </>
  );
};

export default TabsMenu;
