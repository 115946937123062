import styled from 'styled-components';

import TableCell from '../../../../../../components/ui/TableCell';

export const ColumnTitle = styled(TableCell)`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 1.5px;
  text-transform: capitalize;

  padding-left: 0;
`;
