import React from 'react';
import { useTranslation } from 'react-i18next';

import LoaderProgress from '../LoaderProgress';
import Typography from '../ui/Typography';
import Box from '../ui/Box';

import {
  ContentWrapper,
  EmptyWrapper,
  StyledSubTitle,
} from './styled';

const AccountListLayout = (props: any) => {
  const {
    title,
    actions = null,
    loading,
    children,
    empty = false,
    footer = null,
    subTitle = '',
  } = props;

  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={{ xs: 3, sm: 4.5 }}
      >
        {title && (
          <Box>
            <Typography variant="h4" component="h1">
              {title}
            </Typography>
            {subTitle && (
              <StyledSubTitle>
                {subTitle}
              </StyledSubTitle>
            )}
          </Box>
        )}
        {actions}
      </Box>
      {!loading && children}
      {(empty && !loading) && (
        <EmptyWrapper>
          <Typography variant="body2" color="textSecondary">
            {t('account.noData')}
          </Typography>
        </EmptyWrapper>
      )}
      {loading ? <LoaderProgress /> : null}
      {footer}
    </ContentWrapper>
  );
};

export default AccountListLayout;
