import { useTranslation } from 'react-i18next';

import {
  STATUS_DONE,
  STATUS_DRAFT,
  STATUS_IN_PROCESSING,
  STATUS_IN_PROGRESS,
  STATUS_REJECTED,
} from '../../utils/constants';

import { ProcessResultModel } from '../../network/models';

import {
  ProcessStatusArchive,
  ProcessStatusDone,
  ProcessStatusDraft,
  ProcessStatusInProgress,
  ProcessStatusRejected,
} from './styled';

export type ProcessStatusProps = Partial<ProcessResultModel> & {
  withBackground?: boolean;
  withMarker?: boolean;
};

const ProcessStatus = (props: ProcessStatusProps) => {
  const { status, is_archived, withBackground = true, withMarker = true } = props;
  const { t } = useTranslation();

  if (is_archived) {
    return (
      <ProcessStatusArchive withBackground={withBackground} withMarker={withMarker}>
        {t('account.services.statuses.archive')}
      </ProcessStatusArchive>
    );
  }

  switch (status) {
    case STATUS_DRAFT:
      return (
        <ProcessStatusDraft withBackground={withBackground} withMarker={withMarker}>
          {t('account.services.statuses.draft')}
        </ProcessStatusDraft>
      );
    case STATUS_DONE:
      return (
        <ProcessStatusDone withBackground={withBackground} withMarker={withMarker}>
          {t('account.services.statuses.done')}
        </ProcessStatusDone>
      );
    case STATUS_IN_PROCESSING:
    case STATUS_IN_PROGRESS:
      return (
        <ProcessStatusInProgress withBackground={withBackground} withMarker={withMarker}>
          {t('account.services.statuses.inProgress')}
        </ProcessStatusInProgress>
      );
    case STATUS_REJECTED:
      return (
        <ProcessStatusRejected withBackground={withBackground} withMarker={withMarker}>
          {t('account.services.statuses.rejected')}
        </ProcessStatusRejected>
      );
    default:
      return (
        <ProcessStatusInProgress withBackground={withBackground} withMarker={withMarker}>
          {t('account.services.statuses.inProgress')}
        </ProcessStatusInProgress>
      );
  }
};

export default ProcessStatus;
