import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import { getPaymentClientSecret, PaymentParams } from '../../network';
import { ProcessPaymentModel } from '../../network/models';

type ResultData = ProcessPaymentModel | null;

const useProcessPaymentClientSecret = (params: PaymentParams): [ResultData, boolean, Error | null, () => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const { taskId, processId, processName } = params;

  const loadData = useDebouncedCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getPaymentClientSecret(params);
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  useEffect(() => {
    loadData();
  }, [taskId, processId, processName, loadData]);

  return [data, isLoading, error, loadData];
};

export default useProcessPaymentClientSecret;
