import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 32px;
  position: relative;
  width: 100%;
  text-align: center;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #232121;

  margin-bottom: 4px;
  margin-top: 32px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: rgba(35, 33, 33, 0.6);

  margin-bottom: 34px;
`;

export const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const Image = styled.img`
  max-width: 420px;
  width: 100%;
`;
