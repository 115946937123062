import { useEffect } from 'react';

export type UseSmoothScrollToTopProps = {
  conditions: boolean;
  scrollElementContainer: any;
};

const useSmoothScrollToTop = (props: UseSmoothScrollToTopProps) => {
  const { conditions, scrollElementContainer } = props;

  useEffect(() => {
    if (conditions && scrollElementContainer) {
      scrollElementContainer.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [conditions, scrollElementContainer]);
};

export default useSmoothScrollToTop;
