import { useEffect, useState, useCallback } from 'react';
import mainClient from '../network/mainClient';
import RequestError from '../network/RequestError';

type ResultData = any | null;

const useCurrentUser = (): [ResultData, boolean, RequestError | null, () => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const request = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await mainClient.user.getCurrentUser();
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, []);

  useEffect(() => {
    request();
  }, [request]);

  return [data, isLoading, error, request];
};

export default useCurrentUser;
