import React from 'react';
import { Field } from 'react-final-form';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import Select, { Props } from '../Select';
import FormHelperText from '../FormHelperText';
import { StyledFormControl, StyledInputLabel } from './styled';

export type FormSelectProps = Props & {
  name: string;
  label?: string;
  initialValue?: string;
  validate?: (value: any) => any;
};

const FormSelect = (props: FormSelectProps) => {
  const { children, name, label, initialValue, validate, ...restProps } = props;
  const inputLabelId = `${label}_input_label_id`;

  return (
    <Field
      name={name}
      initialValue={initialValue}
      validate={validate}
      render={({ input, meta }) => (
        <StyledFormControl>
          <StyledInputLabel id={inputLabelId}>{label}</StyledInputLabel>
          <Select
            {...input}
            labelId={inputLabelId}
            label={label}
            fullWidth
            error={!!((meta.active || meta.touched) && meta.error)}
            variant="outlined"
            IconComponent={KeyboardArrowDown}
            MenuProps={{
              style: {
                zIndex: 99999,
              }
            }}
            {...restProps}
          >
            {children}
          </Select>
          {meta.error && (meta.active || meta.touched) && <FormHelperText>{meta.error}</FormHelperText>}
        </StyledFormControl>
      )}
    />
  );
};

export default FormSelect;
