import { Link } from 'react-router-dom';

const NotFoundScreen = () => {
  return (
    <div>
      <h1>Not Found</h1>
      <Link to="/">Go to home</Link>
    </div>
  )
};

export default NotFoundScreen;
