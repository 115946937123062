import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../network/RequestError';
import ObjectType from '../../../utils/ObjectType';
import { getServiceList } from '../network';

type ResultData = ObjectType | null;

const useServicesList = (): [ResultData, boolean, Error | null, () => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useDebouncedCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getServiceList({});

      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 1500, { leading: true });

  useEffect(() => {
    loadData();
  }, [loadData]);

  return [data, isLoading, error, loadData];
};

export default useServicesList;
