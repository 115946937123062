import { useCallback, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import RefreshIcon from '@material-ui/icons/Refresh';

import AccountLayout from '../../components/AccountLayout';
import Box from '../../../../components/ui/Box';
import Hidden from '../../../../components/ui/Hidden';
import { TabsMenuItemProps } from '../../../../components/TabsMenu';
import { useMessagesContext } from '../../providers/MessagesProvider';
import { sortMessagesByDate } from '../../utils/messages';
import { getUrlWithoutSlash } from '../../../../utils/helpers';

import MessagesList from './components/MessagesList';

import { StyledOutlinedButton } from './styled';

const MessagesScreen = () => {
  const { t } = useTranslation();
  const { path, url } = useRouteMatch();

  const {
    inboxMessages,
    isInboxMessagesLoading,
    outboxMessages,
    isOutboxMessagesLoading,
    loadInboxMessages,
    loadOutboxMessages,
  } = useMessagesContext();

  const inbox = useMemo(() => (
    (inboxMessages && inboxMessages.results.length)
      ? inboxMessages.results.map((message) => ({ ...message, type: 'inbox' }))
      : []
  ), [inboxMessages]);

  const outbox = useMemo(() => (
    (outboxMessages && outboxMessages.results.length)
      ? outboxMessages.results.map((message) => ({ ...message, type: 'outbox'}))
      : []
  ), [outboxMessages]);

  const all = useMemo(() => ((inbox.length || outbox.length) ? [
    ...inbox,
    ...outbox
  ] : []), [inbox, outbox]);

  const sortedAllMessages = useMemo(() => all.sort(sortMessagesByDate), [all]);
  const sortedInboxMessages = useMemo(() => inbox.sort(sortMessagesByDate), [inbox]);
  const sortedOutboxMessages = useMemo(() => outbox.sort(sortMessagesByDate), [outbox]);

  const isMessagesLoading = isInboxMessagesLoading || isOutboxMessagesLoading;

  const rightUrl = getUrlWithoutSlash(url);

  const tabsMenuItems: TabsMenuItemProps[] = useMemo(() => [
    {
      id: 1,
      title: t('account.messages.menu.all'),
      path: `${rightUrl}/all`,
    },
    {
      id: 2,
      title: t('account.messages.menu.inbox'),
      path: `${rightUrl}/inbox`,
    },
    {
      id: 3,
      title: t('account.messages.menu.outbox'),
      path: `${rightUrl}/outbox`,
    },
  ], [rightUrl, t]);

  const refreshMessages = useCallback(() => {
    loadInboxMessages();
    loadOutboxMessages();
  }, [loadInboxMessages, loadOutboxMessages]);

  const headActions = (
    <StyledOutlinedButton
      startIcon={<RefreshIcon />}
      onClick={refreshMessages}
    >
      <Hidden smDown>{t('account.messages.refresh')}</Hidden>
    </StyledOutlinedButton>
  );

  useEffect(() => {
    const num = setInterval(refreshMessages, 30000);

    return () => {
      clearInterval(num);
    };
  }, [refreshMessages]);

  return (
    <AccountLayout
      title={t('account.messages.title')}
      titleCount={all.length}
      loading={isMessagesLoading}
      tabsMenuItems={tabsMenuItems}
      headActions={headActions}
    >
      <Box display="flex" flex={1} flexDirection={{ xs: 'column', md: 'row' }}>
        <Switch>
          <Redirect from={path} exact to={`${rightUrl}/all`} />

          <Route path={`${path}/all`}><MessagesList messages={sortedAllMessages} /></Route>
          <Route path={`${path}/inbox`}><MessagesList messages={sortedInboxMessages} /></Route>
          <Route path={`${path}/outbox`}><MessagesList messages={sortedOutboxMessages} /></Route>
        </Switch>
      </Box>
    </AccountLayout>
  );
}

export default MessagesScreen
