import React, { useCallback, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckMarkIcon } from '../../../../assets/img/services/check.svg';
import { ReactComponent as SignIcon } from '../../../../assets/img/services/sign.svg';

import { ReactComponent as DownloadIcon } from '../../../../assets/img/services/download.svg';
import Grid from '../../../../components/ui/Grid';
import Hidden from '../../../../components/ui/Hidden';
import StyledButton, { PrimaryButton, SecondaryButton } from '../../../../components/ui/Button';
import CircularProgress from '../../../../components/ui/CircularProgress';
import Box from '../../../../components/ui/Box';
import ErrorMessage from '../../../../components/ui/ErrorMessage';
import CheckoutCard from '../../components/CheckoutCard';
import { openProcessModal } from '../../../process/components/ProcessModal';
import { useCheckoutCardItems } from '../../utils/useCheckoutCardItems';
import useQueryString from '../../../../hooks/useQueryString';
import useSmoothScrollToTop from '../../../../hooks/useSmoothScrollToTop';
import { useProcessesContext } from '../../../process/providers/ProcessesProvider';
import { ProcessResultModel } from '../../../process/network/models';
import { ServiceChildModel, ServiceResultModel } from '../../network/models';

import {
  CheckoutCardWrapper,
  DownloadExampleWrapper,
  ServicesChild,
  ServicesChildrenWrapper,
  ServicesDescription,
  ServicesTitle,
  ServicesWrapper,
  SignWrapper,
  StyledGrid,
  Divider,
  ComingSoonTitle,
} from './styled';

export type ActiveServicesScreenProps = {
  service: ServiceResultModel;
  readOnly?: boolean;
  isScrolled?: boolean;
}

const ActiveServicesScreen = (props: ActiveServicesScreenProps) => {
  const { service, readOnly = false, isScrolled } = props;

  const {
    id,
    children,
    time_estimation_days,
    description,
    example_documents,
    price,
    required_documents,
    title,
  } = service;

  const { t } = useTranslation();
  const {
    data,
    loading,
    createdProcess,
    isCreateProcessLoading,
    isDeleteProcessLoading,
    createProcessError,
    createProcess,
    loadData,
  } = useProcessesContext();

  const history = useHistory();
  const query = useQueryString();
  const processId = query.get('process');

  const titleRef = useRef<HTMLDivElement>(null);

  const existentProcess = data?.results?.find((result: ProcessResultModel) => result.service?.id === id);

  useSmoothScrollToTop({
    conditions: Boolean(createProcessError),
    scrollElementContainer: window,
  });

  const checkoutCardItems = useCheckoutCardItems({ time_estimation_days, price, required_documents });

  const startProcess = useCallback(async () => {
    const process = await createProcess({ serviceId: id });
    await loadData();

    if (process && process.id) {
      history.push({
        search: `process=${process.id}`,
      });
    }
  }, [createProcess, history, id, loadData]);

  const openRequest = useCallback(() => {
    if (!processId && existentProcess) {
      history.push({
        search: `process=${existentProcess.id}`,
      });
    }
  }, [existentProcess, history, processId]);

  const handleCheckout = useCallback(async () => {
    await startProcess();
  }, [startProcess]);

  useEffect(() => {
    if (loading) {
      return;
    }

    if (
      // if there is no process and not valid process id
      (processId && !createdProcess && !existentProcess)
      // or there is an existent process and it is not the same as the process id
      || (processId && existentProcess && existentProcess.id !== Number(processId))
    ) {
      query.delete('process');

      history.replace({
        search: query.toString(),
      });

      void loadData();
    }
  }, [createdProcess, existentProcess, history, loadData, loading, processId, query]);

  useEffect(() => {
    if (processId && existentProcess && existentProcess.id === Number(processId)) {
      openProcessModal({
        processId: Number(processId),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processId]);

  useEffect(() => {
    if (isScrolled) {
      titleRef?.current?.classList.add('scrolled');
    } else {
      titleRef?.current?.classList.remove('scrolled');
    }
  }, [isScrolled]);

  // @TODO change after new coming services
  const isCompanyIncorporationService = title.includes('Company incorporation');

  const CheckoutCardActions = () => {
    if (isDeleteProcessLoading || isCreateProcessLoading || loading) {
      return (
        <Box display="flex" justifyContent="center" alignItems="center" mb={3} width="100%">
          <CircularProgress size={25} />
        </Box>
      );
    }

    return existentProcess
      ? (
        <SecondaryButton onClick={openRequest}>
          <CheckMarkIcon/> {t('account.services.checkout.card.buttons.continue')}
        </SecondaryButton>
      ) : (
        <>
          <PrimaryButton onClick={handleCheckout}>{t('account.services.checkout.card.buttons.start')}</PrimaryButton>
        </>
      );
  }

  const ComingSoon = () => (
    <>
      <Divider />
      <ComingSoonTitle>{t('account.services.comingSoon')}</ComingSoonTitle>
    </>
  );

  const actions = !isCompanyIncorporationService
    ? <ComingSoon />
    : (!readOnly ? <CheckoutCardActions /> : null);

  const ServiceCard = () => (
    <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
      <CheckoutCardWrapper>
        <CheckoutCard
          items={checkoutCardItems}
          actions={actions}
        />

        <SignWrapper>
          <SignIcon/>
        </SignWrapper>
      </CheckoutCardWrapper>
    </Grid>
  );

  return (
    <Box display="flex" flexDirection="column" flex={1} height="100%">
      {createProcessError && (
        <Box mb={2}>
          <ErrorMessage error={createProcessError} />
        </Box>
      )}
      <StyledGrid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <ServicesWrapper>
            <ServicesTitle ref={titleRef}>{title}</ServicesTitle>
            <ServicesDescription dangerouslySetInnerHTML={{ __html: description }} />

            <Hidden mdUp>
              <ServiceCard />
            </Hidden>

            {children.length ? (
              <ServicesChildrenWrapper>
                {children.map((child: ServiceChildModel) => (
                  <ServicesChild key={child.id}>
                    <CheckMarkIcon/>
                    <p>{child.title}</p>
                  </ServicesChild>
                ))}
              </ServicesChildrenWrapper>
            ) : null}

            {example_documents.length ? example_documents.map(({ file }) => (
              <DownloadExampleWrapper key={file}>
                <StyledButton
                  target="_blank"
                  href={file}
                >
                  <DownloadIcon/>
                  <p>{t('account.services.downloadExample')}</p>
                </StyledButton>
              </DownloadExampleWrapper>
            )) : null}

          </ServicesWrapper>
        </Grid>
        <Hidden smDown>
          <ServiceCard />
        </Hidden>
      </StyledGrid>
    </Box>
  )
};

export default ActiveServicesScreen;
