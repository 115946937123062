import styled from 'styled-components';

export const TabPanels = styled.div`
  padding: 0;
`;

export const TabsWrapper = styled.div`
  margin-top: 8px;
`;

export const FormSubheader = styled.div`
  color: #232121;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 24px;
  margin-bottom: -8px;
`;
