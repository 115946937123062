import React, { useEffect, useMemo, useRef } from 'react';

import useDateLib from '../../../../hooks/useDateLib';
import { ProcessResultModel } from '../../network/models';
import {
  FLOW_TASK_PAYMENT,
  FLOW_TASK_PAYMENT_PENDING,
  FLOW_TASK_KYC_ONBOARDING,
  FLOW_TASK_PROVIDE_FORM,
  FLOW_TASK_DOCUMENTS_SUBMIT,
  TASK_STATUS_STARTED, STATUS_DONE,
} from '../../utils/constants';

import Grid from '../../../../components/ui/Grid';
import Box from '../../../../components/ui/Box';
import Hidden from '../../../../components/ui/Hidden';

import ProcessForm from '../ProcessForm';
import ProcessPayment from '../ProcessPayment';
import ProcessPaymentPending from '../ProcessPaymentPending';
import ProcessDocumentsSubmit from '../ProcessDocumentsSubmit';
import ProcessKYCOnboarding from '../ProcessKYCOnboarding';
import ProcessStatus from '../ProcessStatus';
import Steps from '../Steps';

import { ProcessWrapper, ProcessCreatedDate, ProcessStatusWrapper, ProcessTitle } from './styled';

export type ProcessPageProps = {
  process: ProcessResultModel;
  closeModal: () => void;
  loadProcess: (loader?: boolean) => void;
  isScrolled?: boolean;
  isKYCShareholdersDone?: boolean;
};

const ProcessPage = (props: ProcessPageProps) => {
  const { process, closeModal, loadProcess, isScrolled, isKYCShareholdersDone } = props;
  const { tasks, create_datetime, service, is_archived, pandadocs, status } = process;

  const titleRef = useRef<HTMLDivElement>(null);
  const { getCreatedDate } = useDateLib();
  const date = getCreatedDate(create_datetime);

  const firstStartedTaskIndex = tasks.findIndex(task => task.status === TASK_STATUS_STARTED);
  const currentTask = tasks[firstStartedTaskIndex] || tasks[tasks.length - 1];
  const allDocumentsCompleted = pandadocs?.length > 0 && pandadocs.every(doc => doc.status === 'document.completed');

  if (firstStartedTaskIndex !== -1 && (tasks.length - 1) !== firstStartedTaskIndex) {
    tasks.splice(firstStartedTaskIndex + 1, tasks.length - 1);
  }

  const renderContent = useMemo(() => () => {
    switch(currentTask?.flow_task) {
      case FLOW_TASK_PAYMENT:
        return (
          <ProcessPayment
            loadProcess={loadProcess}
            processId={process.id}
            processName={service.process_name}
            taskId={currentTask?.id}
            price={service.price}
          />
        );
      case FLOW_TASK_PAYMENT_PENDING:
        return (
          <ProcessPaymentPending
            loadProcess={loadProcess}
            processId={process.id}
            processName={service.process_name}
            taskId={currentTask?.id}
            isSuccess={Boolean(process.bank_transfer?.is_success)}
          />
        );
      case FLOW_TASK_KYC_ONBOARDING:
        return (
          <ProcessKYCOnboarding
            task={currentTask}
            closeModal={closeModal}
            loadProcess={loadProcess}
            process={process}
          />
        );
      case FLOW_TASK_PROVIDE_FORM:
        return (
          <ProcessForm
            task={currentTask}
            closeModal={closeModal}
            loadProcess={loadProcess}
            process={process}
          />
        );
      case FLOW_TASK_DOCUMENTS_SUBMIT:
        return (
          <ProcessDocumentsSubmit
            taskId={currentTask?.id}
            loadProcess={loadProcess}
            process={process}
            documents={pandadocs}
            isKYCShareholdersDone={isKYCShareholdersDone}
            allDocumentsCompleted={allDocumentsCompleted}
          />
        );
      default:
        return null;
    }
  }, [allDocumentsCompleted, closeModal, currentTask, isKYCShareholdersDone, loadProcess, pandadocs, process, service.price, service.process_name]);

  useEffect(() => {
    if (isScrolled) {
      titleRef?.current?.classList.add('scrolled');
    } else {
      titleRef?.current?.classList.remove('scrolled');
    }
  }, [isScrolled]);

  const processStatus = currentTask?.flow_task === 'documents_submit' && allDocumentsCompleted && isKYCShareholdersDone
    ? STATUS_DONE : status;

  return (
    <ProcessWrapper>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={7} lg={8} xl={9}>
          <ProcessCreatedDate>{date}</ProcessCreatedDate>
          <ProcessTitle ref={titleRef}>{service.title}</ProcessTitle>
          <ProcessStatusWrapper>
            <ProcessStatus status={processStatus} is_archived={Boolean(is_archived)} />
          </ProcessStatusWrapper>

          <Hidden mdUp>
            <Steps tasks={tasks} view="mobile" isKYCShareholdersDone={isKYCShareholdersDone} allDocumentsCompleted={allDocumentsCompleted} />
          </Hidden>

          {renderContent?.()}
        </Grid>

        <Hidden smDown>
          <Grid item xs={12} sm={12} md={5} lg={4} xl={3}>
            <Box mt={3} display="flex" justifyContent="flex-end">
              <Steps tasks={tasks} isKYCShareholdersDone={isKYCShareholdersDone} allDocumentsCompleted={allDocumentsCompleted} />
            </Box>
          </Grid>
        </Hidden>
      </Grid>
    </ProcessWrapper>
  );
};

export default ProcessPage;
