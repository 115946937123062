import styled, { css } from 'styled-components';

export const Divider = styled.div`
  background: #DEDEDE;

  width: 100%;
  height: 1px;
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const Name = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #232121;

  display: flex;
  align-items: center;
`;

export const Status = styled.div<{ status?: string }>`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.5px;

  ${(p) => p.status === 'In progress' && css`
    color: rgba(35, 33, 33, 0.6);
  `}
  ${(p) => p.status === 'Signed' && css`
    color: #27C297;
  `}
  ${(p) => p.status === 'Rejected' && css`
    color: #D95050;
  `}
`;

export const ShareholderIcon = styled.div`
  margin-right: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
