import { useTranslation } from 'react-i18next'
import AddIcon from '@material-ui/icons/Add';

import CardContent from '../../../../../../components/ui/CardContent';
import Box from '../../../../../../components/ui/Box';
import Typography from '../../../../../../components/ui/Typography';
import Button, { DashedLongButton } from '../../../../../../components/ui/Button';

import { StyledCard } from './styled';

function PaymentSettings() {
  const { t } = useTranslation();

  return (
    <div>
      <Box mb={2}>
        <Typography variant="h5" component="h3">{t('account.settings.cards')}</Typography>
      </Box>

      <Box mb={2}>
        <StyledCard>
          <CardContent style={{ paddingRight: 16 }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <div>Visa</div>
              <Box
                flexBasis={{ xs: '100%', sm: 'auto' }}
                order={{ xs: 1, sm: 0 }}
                mr={{ xs: 0, sm: 2 }}
                ml={{ xs: 0, sm: 3 }}
                mt={{ xs: 2, sm: 0 }}
                flexGrow={1}
              >
                **** **** **** *123
              </Box>
              <Button color="secondary">{t('actions.edit')}</Button>
            </Box>
          </CardContent>
        </StyledCard>
      </Box>

      <Box mb={5}>
        <DashedLongButton
          startIcon={<AddIcon />}
        >{t('actions.addNew')}</DashedLongButton>
      </Box>

      <Box mb={2}>
        <Typography variant="h5" component="h3">{t('account.settings.bankAccounts')}</Typography>
      </Box>

      <Box>
        <DashedLongButton
          startIcon={<AddIcon />}
        >{t('actions.addNew')}</DashedLongButton>
      </Box>
    </div>
  );
}

export default PaymentSettings;
