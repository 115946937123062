import React, { ReactElement } from 'react';

import {
  CheckoutCardWrapper,
  CheckoutCardActions,
  CheckoutCardItem,
  CheckoutCardLabel,
  CheckoutCardText,
} from './styled';

export type CheckoutCardItemProps = {
  label: string;
  text: any;
};

export type CheckoutCardProps = {
  items: CheckoutCardItemProps[];
  actions?: string | ReactElement | null;
  withBackground?: boolean;
};

const CheckoutCard = (props: CheckoutCardProps) => {
  const { items, actions, withBackground = true } = props;

  return (
    <CheckoutCardWrapper withBackground={withBackground}>
      {items.map(({ label, text }) => (
        <CheckoutCardItem key={label}>
          <CheckoutCardLabel>{label}</CheckoutCardLabel>
          <CheckoutCardText>{text}</CheckoutCardText>
        </CheckoutCardItem>
      ))}
      {actions && (
        <CheckoutCardActions>
          {actions}
        </CheckoutCardActions>
      )}
    </CheckoutCardWrapper>
  )
};

export default CheckoutCard;
