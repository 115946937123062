import { ProcessesListProps } from '../ProcessesList';
import ProcessesListItemMobile from '../ProcessesListItemMobile';

import Pagination from '../../../../../../components/ui/Pagination';
import Box from '../../../../../../components/ui/Box';
import { useProcessesContext } from '../../../../providers/ProcessesProvider';

import { RequestsListMobile } from './styled';

const ProcessesMobileView = (props: ProcessesListProps) => {
  const { processes } = props;

  const { page, pageSize, setPage, setPageSize } = useProcessesContext();

  return (
    <RequestsListMobile>
      {processes.map(process => (
        <ProcessesListItemMobile
          key={process.id}
          process={process}
        />
      ))}
      <Box mt={2} alignSelf="center">
        <Pagination
          page={page}
          pageSize={pageSize}
          showRowsPerPage={false}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          count={processes && processes.length}
        />
      </Box>
    </RequestsListMobile>
  );
};

export default ProcessesMobileView;
