import styled, { css }from 'styled-components';
import Box from '../../ui/Box';
import Button, { Props as ButtonProps } from '../../ui/Button';

export const RootOverlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2000;

  padding: 24px;

  background: rgba(35, 33, 33, 0.12);
`;

export const Container = styled.div`
  width: 100%;
  max-width: 368px;
  padding: 20px 24px;

  background-color: #fff;
  box-shadow: 0 3px 13px rgba(35, 33, 33, 0.039), 0 10.5px 36px rgba(35, 33, 33, 0.19);
  border-radius: 10px;
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #232121;
  margin-bottom: 12px;
`;

export const Content = styled(Box)`
  letter-spacing: 0.25px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: rgba(35, 33, 33, 0.6);
`;

export const StyledSubmitButton = styled(Button)<ButtonProps & { mode?: string }>`
  padding: 6px 15px;
  ${(p) => p.mode === 'remove' && css`
    background: #D95050;
    color: #FFFFFF;

    &:hover {
      background: #D95050;
      color: #FFFFFF;
      opacity: .9;
    }
  `}
  ${(p) => p.mode === 'confirm' && css`
    background: #00C853;
  `}
`;
