import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import MobileMenu from '../../../../../../components/MobileMenu';
import ProcessStatus from '../../../../components/ProcessStatus';
import getShowProcessesActions from '../../../../utils/getShowProcessesActions';
import { ProcessesListItemProps } from '../ProcessesList';
import ProcessesListItemAction from '../ProcessesListItemAction';

import {
  ProcessesListItem,
  ProcessMobile,
  ProcessTitleMobile,
  ProcessMenuMobile,
  ProcessTimeMobile,
  ProcessStatusMobile,
} from './styled';
import useDateLib from '../../../../../../hooks/useDateLib';

const ProcessesListItemMobile = (props: ProcessesListItemProps) => {
  const { process } = props;
  const history = useHistory();

  const { getCreatedDate } = useDateLib();
  const date = getCreatedDate(process.create_datetime);

  const openRequest = useCallback(() => {
      history.push({
        search: `process=${process.id}`,
      });
    },
    [history, process.id],
  );

  const showActions = getShowProcessesActions(process);

  return (
    <ProcessesListItem>
      <ProcessMobile onClick={openRequest}>
        <ProcessStatusMobile>
          <ProcessStatus {...process} />
        </ProcessStatusMobile>
        <ProcessTitleMobile>{process.service.title}</ProcessTitleMobile>
        <ProcessTimeMobile>{date}</ProcessTimeMobile>
      </ProcessMobile>
      <ProcessMenuMobile>
        {showActions && (
          <MobileMenu>
            <ProcessesListItemAction
              process={process}
            />
          </MobileMenu>
        )}
      </ProcessMenuMobile>
    </ProcessesListItem>
  );
};

export default ProcessesListItemMobile;
