import styled, { css } from 'styled-components';

import Box from '../ui/Box';

export const Root = styled(Box)<{ color?: string }>`
  ${(p) => p.color === 'primary' ? css`
    background: #232121;
    color: #fff;

    ${Divider} {
      background: #FFFFFF;
      opacity: 0.5;
    }
  ` : css`
    background: #FFFFFF;

    ${Divider} {
      background: #232121;
      opacity: 0.12;
    }
  `}
  border-radius: 12px;
  box-shadow: 0 27px 80px rgba(35, 33, 33, 0.05),
    0 16px 40px rgba(35, 33, 33, 0.03),
    0 2px 16px rgba(35, 33, 33, 0.03);
`;

export const Divider = styled.div`
  height: 1px;
`;

export const SettingsTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #232121;
`;

export const Status = styled(Box)`
  color: #27C297;

  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
`;
