import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';


import { ProcessResultModel } from '../../../../network/models';
import Hidden from '../../../../../../components/ui/Hidden';
import useQueryString from '../../../../../../hooks/useQueryString';
import { openProcessModal } from '../../../../components/ProcessModal';

import ProcessesDesktopView from '../ProcessesDesktopView';
import ProcessesMobileView from '../ProcessesMobileView';

import { EmptyText, EmptyWrapper } from './styled';

export type ProcessesListItemProps = {
  process: ProcessResultModel;
};

export type ProcessesListProps = {
  processes: ProcessResultModel[];
  status?: string;
};

const ProcessesList = (props: ProcessesListProps) => {
  const {
    processes,
    status,
  } = props;

  const { t } = useTranslation();

  const query = useQueryString();
  const processId = query.get('process');

  const hasProcesses = !!processes?.length;

  const baseProps = useMemo(() => ({
    processes,
  }), [processes]);

  useEffect(() => {
    if (processId) {
      openProcessModal({ processId: Number(processId) });
    }
  }, [processId]);

  return hasProcesses ? (
    <>
      <Hidden smDown>
        <ProcessesDesktopView {...baseProps} />
      </Hidden>

      <Hidden mdUp>
        <ProcessesMobileView {...baseProps} />
      </Hidden>
    </>
  ) : (
    <EmptyWrapper>
      {status && (<EmptyText>{t('account.requests.emptyText', { status })}</EmptyText>)}
    </EmptyWrapper>
  );
};

export default ProcessesList;
