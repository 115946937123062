import styled from 'styled-components';

import IconButton from '../../../../../../components/ui/IconButton';
import TableCell from '../../../../../../components/ui/TableCell';

export const BillingDate = styled.span`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
`;
export const BillingItem = styled(TableCell)`
  color: #232121;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;

  padding-left: 0;
  width: fit-content;
`;

export const DownloadButton = styled(IconButton)`
  float: right;
`;
