import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Form } from 'react-final-form';
import { createForm, FormApi } from 'final-form';
import { useTranslation } from 'react-i18next';
import { saveAs } from 'file-saver';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { ReactComponent as SuccessIcon } from '../../../../../../assets/img/processes/success-task.svg';

import { useValidators } from '../../../../../service/utils/validation';
import useConstant from '../../../../../../utils/hooks';
import { COUNTRIES, EU_LUXEMBOURG, EU_STATES } from '../../../../utils/constants';
import RequestError from '../../../../../../network/RequestError';
import { CheckPaymentParams, PaymentParams } from '../../../../network';
import useVerifyVAT from '../../../../hooks/payments/useVerifyVAT';
import useProcessPaymentInvoice from '../../../../hooks/payments/useProcessPaymentInvoice';

import { PrimaryButton, OutlinedButton } from '../../../../../../components/ui/Button';
import CircularProgress from '../../../../../../components/ui/CircularProgress';
import FormControlLabel from '../../../../../../components/ui/FormControlLabel';
import FormTextInput from '../../../../../../components/ui/FormTextInput';
import TabContext from '../../../../../../components/ui/TabContext';
import FormSelect from '../../../../../../components/ui/FormSelect';
import MenuItem from '../../../../../../components/ui/MenuItem';
import Checkbox from '../../../../../../components/ui/Checkbox';
import Grid from '../../../../../../components/ui/Grid';
import Box from '../../../../../../components/ui/Box';

import { InvisibleTab, StyledTab, StyledTabList, StyledTabPanel, Title } from '../../styled';

import {
  FormSubheader,
  StyledSelect,
  StyledMenuItem,
  VatWrapper,
  StyledTextField,
  StyledSubmitButton,
  ActionsWrapper,
  VerifyingSubmitWrapper,
  NotValid,
  NotValidMessage,
  InvoiceCreated,
} from './styled';

type OnlinePaymentProps = PaymentParams &{
  checkPayment: (params: CheckPaymentParams) => void;
  isCheckPaymentLoading: boolean;
  checkPaymentError: RequestError | null;
  loadProcess: () => void;
};

const BankTransfer = (props: OnlinePaymentProps) => {
  const { loadProcess, checkPayment, isCheckPaymentLoading, checkPaymentError, processId, processName, taskId } = props;
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState('0');
  const [hasVAT, setHasVAT] = useState(false);
  const [vatValid, setVatValid] = useState<null | boolean>(null);
  const [vatNumber, setVatNumber] = useState('');
  const [selectedState, setSelectedState] = useState(EU_STATES[0].code);
  const [invoiceDownloaded, setInvoiceDownloaded] = useState(false);

  const [verifiedVAT, isVerifyVATLoading,, verifyVAT, reset] = useVerifyVAT();
  const [, isInvoiceLoading,, createInvoice] = useProcessPaymentInvoice({ processId, processName, taskId });

  const form = useConstant(() => createForm({
    initialValues: {
      name: '',
      city: '',
      postal_code: '',
      country: '',
      address: '',
    },
    mutators: {
      setValue: ([field, value], state, { changeValue }) => {
        changeValue(state, field, () => value);
      },
    },
    onSubmit: () => {},
    validate: (values) => {
      const errors: any = {};

      if (!values.name) {
        errors.name = t('account.services.checkout.form.validation.required');
      }
      if (!values.city) {
        errors.city = t('account.services.checkout.form.validation.required');
      }
      if (!values.postal_code) {
        errors.postal_code = t('account.services.checkout.form.validation.required');
      }
      if (!values.country) {
        errors.country = t('account.services.checkout.form.validation.required');
      }
      if (!values.address) {
        errors.address = t('account.services.checkout.form.validation.required');
      }
      return errors;
    },
  })) as unknown as FormApi<any>;

  const validators = useValidators();

  const bankTPrefix = 'account.processes.payment.tabs.bankTransfer';
  const inEUText = t(`${bankTPrefix}.location.inEU.title`);
  const outsideEUText = t(`${bankTPrefix}.location.outsideEU.title`);
  const inLUText = t(`${bankTPrefix}.location.inLU.title`);
  const notValidText = t(`${bankTPrefix}.notValid`);
  const notValidMessage = t(`${bankTPrefix}.notValidMessage`);
  const vatText = t(`${bankTPrefix}.location.inEU.vat`);
  const submitButtonText = t(`${bankTPrefix}.buttons.submit`);
  const paidButtonText = t(`${bankTPrefix}.buttons.paid`);
  const downloadButtonText = t(`${bankTPrefix}.buttons.download`);
  const invoiceCreatedText = t(`${bankTPrefix}.invoiceCreated`);

  const isInEU = activeTab === '1';
  const isOutsideEU = activeTab === '2';
  const isInLU = activeTab === '3';

  const formDisabled = useMemo(() =>
      isInvoiceLoading || isVerifyVATLoading || isCheckPaymentLoading,
    [isInvoiceLoading, isVerifyVATLoading, isCheckPaymentLoading]);

  const resetValidation = useCallback(() => {
    setVatValid(null);
    reset();
    setInvoiceDownloaded(false);
  }, [reset]);

  const handleChangeTab = useCallback((event: ChangeEvent<{}>, newValue: string) => {
    setActiveTab(newValue);
    setHasVAT(false);

    if (newValue === '1') {
      form.mutators.setValue('country', selectedState || '');
    }
    if (newValue === '2') {
      form.mutators.setValue('country', '');
    }
    if (newValue === '3') {
      form.mutators.setValue('country', EU_LUXEMBOURG[0].code);
    }
    resetValidation();
  }, [form.mutators, resetValidation, selectedState]);

  const handleSetHasVAT = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setHasVAT(event.target.checked);
    resetValidation();
  }, [resetValidation]);

  const handleChangeVAT = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    setVatNumber(event.target.value.toUpperCase());
    resetValidation();
  }, [resetValidation]);

  const onStateSelect = useCallback((event: ChangeEvent<{ name?: string, value: unknown }>) => {
    if (isInEU) {
      setSelectedState(event.target.value as string);
    }

    form.mutators.setValue('country', event.target.value);
    resetValidation();
  }, [form.mutators, isInEU, resetValidation]);

  const handleVerifyVATSubmit = useCallback(() => {
    verifyVAT({
      stateCode: selectedState,
      vatNumber,
    });
  }, [selectedState, vatNumber, verifyVAT]);

  const handleDownload = useCallback(async () => {
    // if inEU and hasVAT and VAT is valid make tax 0% otherwise make it 17%
    // if outsideEU make tax 0%
    // if inLU make tax 17%
    const tax: boolean = isInEU
      ? (!(hasVAT && vatValid))
      : isOutsideEU
        ? false
        : isInLU;

    try {
      const formState = form.getState();
      const values = formState ? formState.values : {};

      const countryField = isInEU
        ? EU_STATES.find(({ code }) => code === values.country)
        : isOutsideEU
          ? COUNTRIES.find(({ code }) => code === values.country)
          : EU_LUXEMBOURG[0];

      const data = await createInvoice({
        tax,
        ...values,
        country: countryField ? countryField.name : values.country,
      });

      saveAs(data, 'EZ_START_invoice.pdf');

      setInvoiceDownloaded(true);
    } catch (error) {
      console.error(error);
    }
  }, [createInvoice, form, hasVAT, isInEU, isInLU, isOutsideEU, vatValid]);

  const handlePaid = useCallback(async () => {
    try {
      const params: CheckPaymentParams = {
        paymentType: 'bank_transfer',
        invoiceType: (isInEU && 'eu') || (isOutsideEU && 'non_eu') || (isInLU && 'lu') || '',
      };

      if (hasVAT && vatValid) {
        params.vat = {
          stateCode: selectedState,
          number: vatNumber,
        };
      }

      await checkPayment(params);

      !checkPaymentError && loadProcess();
    } catch (error) {
      console.error(error);
    }
  }, [checkPayment, checkPaymentError, hasVAT, isInEU, isInLU, isOutsideEU, loadProcess, selectedState, vatNumber, vatValid]);

  const getCountries = useCallback(() => {
    if (isInEU) {
      return EU_STATES;
    }

    if (isInLU) {
      return EU_LUXEMBOURG;
    }

    return COUNTRIES.filter(country => country.code !== 'LU' && !EU_STATES.some(state => state.code === country.code));
  }, [isInEU, isInLU]);

  const VerifyingSubmit = () => {
    if (vatValid === null) {
      return (
        <StyledSubmitButton
          onClick={handleVerifyVATSubmit}
          disabled={!selectedState || !vatNumber || !hasVAT || formDisabled}
        >
          {isVerifyVATLoading ? (
            <Box mr={1} display="flex" alignItems="center">
              <CircularProgress size={15}/>
            </Box>
          ) : <>{submitButtonText}</>}
        </StyledSubmitButton>
      );
    }

    return (
      <VerifyingSubmitWrapper>
        {vatValid
          ? <SuccessIcon />
          : <NotValid>{notValidText}</NotValid>
        }
      </VerifyingSubmitWrapper>
    );
  }

  useEffect(() => {
    if (verifiedVAT !== null && !isVerifyVATLoading) {
      setVatValid(verifiedVAT);
    }
  }, [isVerifyVATLoading, vatValid, verifiedVAT]);

  const isInvoiceCreated = activeTab === '1'
    ? ((hasVAT && vatValid === null) || isVerifyVATLoading)
    : false;

  return (
    <Form
      form={form}
      onSubmit={() => {}}
      render={({ valid }) => {
        const tFormPrefix = `${bankTPrefix}.form`;

        return (
          <>
            <Title>{t(`${bankTPrefix}.location.title`)}</Title>

            <Box mt={2} minHeight="300px">
              <TabContext value={activeTab}>
                <StyledTabList onChange={handleChangeTab} aria-label="payment tabs">
                  <StyledTab label={inEUText} value="1" color="primary" disabled={formDisabled} />
                  <StyledTab label={outsideEUText} value="2" color="primary" disabled={formDisabled} />
                  <StyledTab label={inLUText} value="3" color="primary" disabled={formDisabled} />
                  <InvisibleTab value="0" />
                </StyledTabList>
                <StyledTabPanel value="1">
                  <FormControlLabel
                    disabled={formDisabled}
                    control={
                      <Checkbox
                        checked={hasVAT}
                        onChange={handleSetHasVAT}
                        name="hasVAT"
                        color="default"
                      />
                    }
                    label={vatText}
                  />
                  {hasVAT && (
                    <>
                      <VatWrapper>
                        <StyledSelect
                          disabled={formDisabled}
                          value={selectedState}
                          variant="outlined"
                          onChange={onStateSelect}
                          IconComponent={KeyboardArrowDown}
                          MenuProps={{ style: { zIndex: 99999 } }}
                          renderValue={(selected: unknown) => <>{selected}</>}
                        >
                          {EU_STATES.map(state => (
                            <StyledMenuItem key={state.code} value={state.code}>
                              {state.code} - {state.name}
                            </StyledMenuItem>
                          ))}
                        </StyledSelect>
                        <StyledTextField
                          value={vatNumber}
                          disabled={formDisabled}
                          onChange={handleChangeVAT}
                          placeholder={vatText}
                          inputProps={{ 'aria-label': vatText }}
                        />
                        <VerifyingSubmit />
                      </VatWrapper>
                      {hasVAT && vatValid === false ? (
                        <NotValidMessage>{notValidMessage}</NotValidMessage>
                      ) : null}
                    </>
                  )}

                </StyledTabPanel>
                <StyledTabPanel value="2" />
                <StyledTabPanel value="3" />
                <StyledTabPanel value="0" />
              </TabContext>

              {activeTab && activeTab !== '0' ? (
                <>
                  <Box mt={3} mb={4}>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormSubheader>{t(`${tFormPrefix}.billTo`)}</FormSubheader>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                        <FormTextInput
                          name="name"
                          label={`${t(`${tFormPrefix}.name`)} *`}
                          placeholder={t(`${tFormPrefix}.namePlaceholder`)}
                          validate={validators.is_required}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormSubheader>{t(`${tFormPrefix}.registeredAddress`)}</FormSubheader>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <FormTextInput
                          name="city"
                          label={`${t(`${tFormPrefix}.city`)} *`}
                          validate={validators.is_required}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <FormTextInput
                          name="postal_code"
                          label={`${t(`${tFormPrefix}.postalCode`)} *`}
                          validate={validators.is_required}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <FormSelect
                          name="country"
                          disabled={isInLU}
                          label={`${t(`${tFormPrefix}.country`)} *`}
                          variant="outlined"
                          onChange={onStateSelect}
                          initialValue=""
                        >
                          {getCountries().map((country) => {
                            return (
                              <MenuItem key={country.code} value={country.code}>
                                {country.name}
                              </MenuItem>
                            );
                          })}
                        </FormSelect>
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormTextInput
                          name="address"
                          label={`${t(`${tFormPrefix}.address`)} *`}
                          validate={validators.is_required}
                          initialValue=""
                        />
                      </Grid>
                    </Grid>
                  </Box>

                  {!isInvoiceCreated && valid && <InvoiceCreated>{invoiceCreatedText}</InvoiceCreated>}

                  <ActionsWrapper>
                    <OutlinedButton
                      endIcon={<ArrowForwardIcon />}
                      disabled={!invoiceDownloaded || isCheckPaymentLoading}
                      onClick={handlePaid}
                    >
                      {isCheckPaymentLoading ? (
                        <Box mr={1} display="flex" alignItems="center">
                          <CircularProgress size={15}/>
                        </Box>
                      ) : null}

                      {paidButtonText}
                    </OutlinedButton>

                    <PrimaryButton
                      onClick={handleDownload}
                      disabled={isInvoiceCreated || isInvoiceLoading || !valid}
                    >
                      {isInvoiceLoading ? (
                        <Box mr={1} display="flex" alignItems="center">
                          <CircularProgress size={15}/>
                        </Box>
                      ) : null}

                      {downloadButtonText}
                    </PrimaryButton>
                  </ActionsWrapper>
                </>
              ) : null}
            </Box>
          </>
        );
      }}
    />
  );
};

export default BankTransfer;
