import { useTranslation } from 'react-i18next';

import AccountLayout from '../../components/AccountLayout';
import Box from '../../../../components/ui/Box';
import BillingsList from './components/BillingsList';
import { useBillingsContext } from '../../providers/BillingsProvider';
import LoaderProgress from '../../../../components/LoaderProgress';

const BillingsScreen = () => {
  const { t } = useTranslation();

  const { data, loading } = useBillingsContext();

  if (data === null) {
    return <LoaderProgress />;
  }

  return (
    <AccountLayout
      title={t('account.invoices.title')}
      titleCount={data.count}
      loading={loading}
    >
      <Box display="flex" flex={1} flexDirection={{ xs: 'column', md: 'row' }}>
        <BillingsList data={data} />
      </Box>
    </AccountLayout>
  )
}

export default BillingsScreen
