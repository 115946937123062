import styled from 'styled-components';

import Typography from '../../../../../../components/ui/Typography';
import ButtonBase from '../../../../../../components/ui/ButtonBase';

export const StyledListItem = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledListItemIcon = styled.div`
  display: flex;
  align-items: center;
  line-height: 24px;
  margin-right: 10px;
`;

export const ActionTitle = styled(Typography)`
  text-transform: uppercase;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
`;

export const ActionButton = styled(ButtonBase)`
  padding: 8px 16px;
  border-radius: 12px;
`;
