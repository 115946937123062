import React from 'react';

import Box from '../../../../../../components/ui/Box';

import {
  Root,
  Divider,
  SettingsTitle,
  TitleWrapper,
  ContentWrapper,
} from './styled';

export interface OverviewCardProps {
  title: string | React.ReactNode;
  color?: 'empty' | string;
  headAction?: React.ReactNode;
  children?: React.ReactNode;
}

function OverviewCard(props: OverviewCardProps) {
  const {
    title = '',
    color = '',
    headAction = null,
    children,
  } = props;

  return (
    <Root color={color}>
      <TitleWrapper>
        <SettingsTitle>{title}</SettingsTitle>
        <Box mr={-1.5}>
          {headAction && headAction}
        </Box>
      </TitleWrapper>
      {children && (
        <>
          <Divider />
          <ContentWrapper>{children}</ContentWrapper>
        </>
      )}
    </Root>
  )
}

export default OverviewCard;
