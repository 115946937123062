import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BankTransferIcon } from '../../../../assets/img/processes/bank-transfer-icon.svg';
import { ReactComponent as CardIcon } from '../../../../assets/img/processes/card-icon.svg';

import { useCabinetContext } from '../../../../containers/CabinetContainer/context';
import VerificationInfoPanel from '../../../verification/components/VerificationInfoPanel';
import useProcessPaymentClientSecret from '../../hooks/payments/useProcessPaymentClientSecret';
import useProcessCheckPayment from '../../hooks/payments/useProcessCheckPayment';
import RequestError from '../../../../network/RequestError';
import { CheckPaymentParams, PaymentParams } from '../../network';

import LoaderProgress from '../../../../components/LoaderProgress';
import ErrorMessage from '../../../../components/ui/ErrorMessage';
import TabContext from '../../../../components/ui/TabContext';
import Box from '../../../../components/ui/Box';

import BankTransfer from './components/BankTransfer';
import OnlinePayment from './components/OnlinePayment';

import {
  Title,
  StyledTabList,
  StyledTab,
  IconWrapper,
  StyledTabPanel,
  Wrapper,
} from './styled';

type ProcessPaymentProps = ProcessPaymentControllerProps & {
  loadProcess: (loader?: boolean) => void;
  price: string;
  processPayment: any;
  isProcessPaymentLoading: boolean;
  checkPaymentResult: any;
  checkPaymentError: RequestError | null;
  isCheckPaymentLoading: boolean;
  checkPayment: (params: CheckPaymentParams) => void;
};

const ProcessPayment = (props: ProcessPaymentProps) => {
  const {
    processName,
    processId,
    taskId,
    loadProcess,
    price,
    processPayment,
    isProcessPaymentLoading,
    checkPaymentResult,
    checkPaymentError,
    isCheckPaymentLoading,
    checkPayment,
  } = props;

  const { t } = useTranslation();
  const { currentAccount } = useCabinetContext();

  const [isCardPaymentSuccess, setIsCardPaymentSuccess] = useState<boolean>(false);
  const [value, setValue] = useState<string>('1');

  const payOnlineText = t('account.processes.payment.tabs.byCard.title');
  const bankTransferText = t('account.processes.payment.tabs.bankTransfer.title');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  const showVerificationPanel = currentAccount?.status === 'new';

  return (
    <Wrapper>
      {showVerificationPanel && <VerificationInfoPanel type="standard" />}

      {checkPaymentError && <ErrorMessage error={checkPaymentError} />}

      {!isCardPaymentSuccess ? <Title>{t('account.processes.payment.title')}</Title> : null}

      {isProcessPaymentLoading
        ? <LoaderProgress/>
        : (
          <Box mt={2} minHeight="300px">
            <TabContext value={value}>
              {!isCardPaymentSuccess ? (<StyledTabList onChange={handleChange} aria-label="payment tabs">
                  <StyledTab
                    label={bankTransferText}
                    icon={<IconWrapper><BankTransferIcon/></IconWrapper>}
                    value="1"
                    color="primary"
                  />
                  <StyledTab
                    label={payOnlineText}
                    icon={<IconWrapper><CardIcon/></IconWrapper>}
                    value="2"
                    color="primary"
                  />
                </StyledTabList>
              ) : null}
              <StyledTabPanel value="1">
                <BankTransfer
                  loadProcess={loadProcess}
                  processId={processId}
                  processName={processName}
                  taskId={taskId}
                  checkPayment={checkPayment}
                  isCheckPaymentLoading={isCheckPaymentLoading}
                  checkPaymentError={checkPaymentError}
                />
              </StyledTabPanel>
              <StyledTabPanel value="2">
                {processPayment?.clientSecret ? (
                  <OnlinePayment
                    loadProcess={loadProcess}
                    checkPayment={checkPayment}
                    checkPaymentResult={checkPaymentResult}
                    checkPaymentError={checkPaymentError}
                    clientSecret={processPayment?.clientSecret}
                    price={price}
                    setIsCardPaymentSuccess={setIsCardPaymentSuccess}
                    isCardPaymentSuccess={isCardPaymentSuccess}
                  />
                ) : null}
              </StyledTabPanel>
            </TabContext>
          </Box>
        )
      }
    </Wrapper>
  )
};

type ProcessPaymentControllerProps = PaymentParams & {
  loadProcess: (loader?: boolean) => void;
  price: string;
};

const ProcessPaymentController = (props: ProcessPaymentControllerProps) => {
  const { processName, processId, taskId } = props;
  const [processPayment, isProcessPaymentLoading] = useProcessPaymentClientSecret({ taskId, processId, processName });
  const [checkPaymentResult, isCheckPaymentLoading, checkPaymentError, checkPayment] = useProcessCheckPayment({ taskId, processId, processName })

  return (
    <ProcessPayment
      {...props}
      processPayment={processPayment}
      isProcessPaymentLoading={isProcessPaymentLoading}
      checkPaymentResult={checkPaymentResult}
      isCheckPaymentLoading={isCheckPaymentLoading}
      checkPaymentError={checkPaymentError}
      checkPayment={checkPayment}
    />
  );
};

export default ProcessPaymentController;
