import { useTranslation } from 'react-i18next';

import { ServiceRequiredDocumentsModel } from '../network/models';
import { CheckoutCardItemProps } from '../components/CheckoutCard';
import { DocumentProcessModel } from '../../process/network/models';

export type GetCheckoutCardItemsProps = {
  time_estimation_days: number,
  price: string,
  required_documents: Array<ServiceRequiredDocumentsModel>,
  requestId?: number,
  documents?: DocumentProcessModel[],
};

export const useCheckoutCardItems = ({ time_estimation_days, price, required_documents, documents }: GetCheckoutCardItemsProps): CheckoutCardItemProps[] => {
  const { t } = useTranslation();

  const items = [
    {
      label: t('account.services.checkout.card.items.estimatedTime.label'),
      text: t('account.services.checkout.card.items.estimatedTime.daysCount.dayWithCount', { count: time_estimation_days }),
    },
    {
      label: t('account.services.checkout.card.items.cost.label'),
      text: `${t('account.services.checkout.card.items.cost.currencySign')}${price}`,
    },
  ];

  if (documents && documents[0]) {
    items.push({
      label: t('account.services.checkout.card.items.documents.label'),
      text: `${documents.map(d => (<span key={d.id}>{d.name}</span>))}`,
    });
  }

  if (required_documents[0]) {
    items.push({
      label: t('account.services.checkout.card.items.requiredDocuments.label'),
      text: required_documents.map(d => d.title).join(', '),
    });
  }

  return items;
}
