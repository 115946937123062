import styled, { css } from 'styled-components';

import TabList from '../../../../components/ui/TabList';
import Tab from '../../../../components/ui/Tab';
import TabPanel from '../../../../components/ui/TabPanel';

export const Wrapper = styled.div`
  margin-top: 32px;
  position: relative;
  width: 100%;
`;

export const Title = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #232121;
`;

export const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #232121;
`;

export const StyledTabList = styled(TabList)`
  & .MuiTabs-flexContainer {
    display: flex;

    ${(p) => p.theme.breakpoints.down('md')} {
      flex-direction: column;
      align-items: center;
    }
  }

  & .MuiTabs-indicator {
    display: none;
  }
`;

export const InvisibleTab = styled(Tab)`
  display: none;
`;

export const StyledTab = styled(Tab)`
  &.MuiTab-root {
    flex-grow: 0;
    width: calc(33% - 13.5px);
    max-width: 33%;
    height: 80px;

    background: #F5F5F5;
    border: 1px solid rgba(35, 33, 33, 0.12);
    border-radius: 12px;
    text-transform: none;

    margin-right: 24px;

    ${(p) => p.theme.breakpoints.down('md')} {
      width: 100%;
      max-width: 100%;
      margin-right: 0;
      margin-top: 16px;

      &:first-child {
        margin-top: 0;
      }
    }

    &:last-child {
      margin-right: 0;
    }

    ${({ selected, theme }) => selected && css`
      background: #FFFFFF;
      border: 1px solid #232121;
      box-shadow:
        0 27px 138px rgba(67, 67, 67, 0.07),
        0 5.45819px 30.8241px rgba(67, 67, 67, 0.0417275),
        0 1.65985px 9.17714px rgba(67, 67, 67, 0.0282725);

      ${theme.breakpoints.down('md')} {
        box-shadow:
          0 7px 40px rgba(67, 67, 67, 0.04),
          0 2px 10px rgba(67, 67, 67, 0.02),
          0 1px 5px rgba(67, 67, 67, 0.02);
      }

      & ${IconWrapper} {
        svg {
          fill: #232121;
        }
      }
    `}
  }

  & .MuiTab-wrapper {
    flex-direction: row;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: #232121;

    & > *:first-child {
      margin-bottom: 0;
    }
  }

  &.MuiTab-labelIcon {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const IconWrapper = styled.div`
  margin-bottom: 0;
  margin-right: 20px;
  height: 20px;
  display: flex;
  align-items: center;

  svg {
    fill: rgba(35, 33, 33, 0.6);
  }
`;

export const StyledTabPanel = styled(TabPanel)`
  padding: 32px 0 0;

  ${(p) => p.theme.breakpoints.down('md')} {
    padding-bottom: 0;
  }
`;
