import request from '../../../network/mainClient/request';

export const getInboxMessages = () => request({
  url: '/api/v1/messenger/inbox/',
}).then((result) => result.data);

export const getOutboxMessages = () => request({
  url: '/api/v1/messenger/outbox/',
}).then((result) => result.data);

export type SendMessageParams = {
  body_text: string;
  subject: string;
};

export const sendMessage = ({ body_text, subject }: SendMessageParams) => request({
  url: '/api/v1/messenger/',
  method: 'POST',
  body: {
    body_text,
    subject,
  },
  contentType: 'json',
}).then((result) => result.data);

export type ReadMessageParams = {
  messageId: string;
};

export const readMessage = ({ messageId }: ReadMessageParams) => request({
  url: `/api/v1/messenger/${messageId}/read/`,
  method: 'POST',
}).then((result) => result.data);

export const getMessagesNotifications = () => request({
  url: '/api/v1/messenger/unseen/',
}).then((result) => result.data);
