import React, { useCallback } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import { ModalItemBaseProps, openModal } from '../../../../containers/ModalsContainer';
import { OutlinedButton, PrimaryButton } from '../../../../components/ui/Button';
import Typography from '../../../../components/ui/Typography';
import Box from '../../../../components/ui/Box';
import Grid from '../../../../components/ui/Grid';
import BaseModal from '../../../../components/Modal/BaseModal';
import useAccountCreate from '../../hooks/accounts/actions/useAccountCreate';
import FormTextInput from '../../../../components/ui/FormTextInput';
// import FormCheckbox from '../../../../components/ui/FormCheckbox';

import {
  BackgroundImg,
  StyledForm,
  FormGrid,
  // FormHeader,
  // FormSubtitle,
} from './styled';
import { useValidators } from '../../../service/utils/validation';

type IConfirmDialogProps = {
  title?: string;
  subTitle?: string | React.ReactElement;
  okButtonText?: string;
  cancelButtonText?: string;
  callback?: () => void;
};

const CreateAccountModal = (props: ModalItemBaseProps & IConfirmDialogProps) => {
  const {
    callback,
    modalLevel,
    modalIsOpen,
    modalRequestClose,
  } = props;

  const { t } = useTranslation();
  const validators = useValidators();
  const required = validators.is_required;
  const [data,,, loadData] = useAccountCreate();

  const title = t('account.create.title');
  const nameField = t('account.create.fields.name');
  const cancelButton = t('account.create.buttons.cancel');
  const addCompanyButton = t('account.create.buttons.addCompany');
  // const mainSubtitle = t('account.create.labels.main');
  // const actualAddressSubtitle = t('account.create.labels.actualAddress');
  // const representativeSubtitle = t('account.create.labels.representative');
  // const descriptionField = t('account.create.fields.description');
  // const registrationNumberField = t('account.create.fields.registrationNumber');
  // const companyTaxNumberField = t('account.create.fields.companyTaxNumber');
  // const legalAddressField = t('account.create.fields.legalAddress');
  // const coincidesWithLegalField = t('account.create.fields.coincidesWithLegal');
  // const positionField = t('account.create.fields.position');

  const handleSubmit = useCallback(
    async (values) => {
      try {
        const newValues = { ...values };

        if (newValues.coincides_with_legal) {
          delete newValues.actual_address;
        }

        await loadData({
          ...newValues,
          account_type: 'company',
        });
        modalRequestClose();
        if (callback) {
          callback();
        }
      } catch (e) {}
    },
    [callback, loadData, modalRequestClose]
  );

  return (
    <BaseModal
      open={modalIsOpen}
      level={modalLevel}
      onRequestClose={modalRequestClose}
      content={
        <Form
          onSubmit={handleSubmit}
          initialValues={data}
          render={({ handleSubmit, valid }) => (
            <StyledForm onSubmit={handleSubmit}>
              <Box mb={4}>
                <Typography variant="h4" component="h4">{title}</Typography>
              </Box>
              <FormGrid container spacing={4}>
                <Grid item xs={12} md={8}>
                  <Box mb={5}>
                    <Box mb={4}>
                      <Grid container spacing={4}>
                        <Grid item xs={12} sm={6}>
                          <FormTextInput name="name" label={nameField} autoFocus validate={required} />
                        </Grid>
                      </Grid>
                    </Box>

                    {/*<FormHeader>{mainSubtitle}</FormHeader>*/}

                    {/*<Box mb={4}>*/}
                    {/*  <Grid container spacing={4}>*/}
                    {/*    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>*/}
                    {/*      <FormTextInput name="description" label={descriptionField} validate={required} />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>*/}
                    {/*      <FormTextInput name="registration_number" label={registrationNumberField} validate={required} />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>*/}
                    {/*      <FormTextInput name="company_tax_number" label={companyTaxNumberField} validate={required} />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>*/}
                    {/*      <FormTextInput name="legal_address" label={legalAddressField} validate={required} />*/}
                    {/*    </Grid>*/}

                    {/*    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>*/}
                    {/*      <FormSubtitle>{actualAddressSubtitle}</FormSubtitle>*/}

                    {/*      <FormCheckbox*/}
                    {/*        name="coincides_with_legal"*/}
                    {/*        label={coincidesWithLegalField}*/}
                    {/*        initialValue={false}*/}
                    {/*      />*/}
                    {/*      {values.coincides_with_legal === false ? (*/}
                    {/*        <Box mt={1}>*/}
                    {/*          <FormTextInput name="actual_address" label={actualAddressSubtitle} validate={required} />*/}
                    {/*        </Box>*/}
                    {/*      ) : null}*/}
                    {/*    </Grid>*/}

                    {/*  </Grid>*/}
                    {/*</Box>*/}

                    {/*<FormHeader>{representativeSubtitle}</FormHeader>*/}

                    {/*<Box mb={3}>*/}
                    {/*  <Grid container spacing={4}>*/}
                    {/*    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>*/}
                    {/*      <FormTextInput name="representative_name" label={nameField} validate={required} />*/}
                    {/*    </Grid>*/}
                    {/*    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>*/}
                    {/*      <FormTextInput name="representative_position" label={positionField} validate={required} />*/}
                    {/*    </Grid>*/}
                    {/*  </Grid>*/}
                    {/*</Box>*/}
                  </Box>

                  <Box display="flex" justifyContent={{ xs: 'center', md: 'flex-end' }}>
                    <div>
                      <Grid
                        container
                        spacing={2}
                      >
                        <Grid item>
                          <OutlinedButton onClick={modalRequestClose}>{cancelButton}</OutlinedButton>
                        </Grid>
                        <Grid item>
                          <PrimaryButton disabled={!valid} onClick={handleSubmit}>{addCompanyButton}</PrimaryButton>
                        </Grid>
                      </Grid>
                    </div>
                  </Box>
                </Grid>

                <Grid
                  component={Box}
                  display={{ xs: 'none', md: 'flex' }}
                  item
                  xs={12}
                  md={4}
                >
                  <Grid item xs={12} container alignItems="flex-end">
                    <BackgroundImg />
                  </Grid>
                </Grid>
              </FormGrid>
            </StyledForm>
          )}
        />
      }
    />
  );
}

export const openCreateAccountModal = (
  props?: IConfirmDialogProps
): ReturnType<typeof openModal> => {
  return openModal(CreateAccountModal, props, 'cabinet');
};

export default CreateAccountModal;
