import React, { useEffect } from 'react';
import { useRouteMatch, Switch, Redirect, Route, useLocation } from 'react-router-dom';

import NotFoundScreen from '../../../../screens/NotFoundScreen';
import AccountLayoutProvider from '../../providers/AccountProvider';
import AccountWorkspaceScreen from '../AccountWorkspaceScreen';
import ServicesCatalogScreen from '../../../service/screens/ServicesCatalogScreen';
import SettingsScreen from '../SettingsScreen';
import { useCabinetContext } from '../../../../containers/CabinetContainer/context';
import { getUrlWithoutSlash } from '../../../../utils/helpers';
import useAccountItem from '../../hooks/accounts/useAccountItem';
import ErrorMessage from '../../../../components/ui/ErrorMessage';
import { ModalsContainer } from '../../../../containers/ModalsContainer';
import { ServicesProvider } from '../../../service/providers/ServicesProvider';
import { ProcessesProvider } from '../../../process/providers/ProcessesProvider';
import { BillingsProvider } from '../../providers/BillingsProvider';
import { CompanySecretaryProvider } from '../../providers/CompanySecretaryProvider';
import SidebarMenu from '../../components/SidebarMenu';
import LoaderProgress from '../../../../components/LoaderProgress';
import Hidden from '../../../../components/ui/Hidden';

import { Wrapper } from './styled';

const AccountScreen = () => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const { setCurrentAccount, currentAccount } = useCabinetContext();
  const [data, isLoading, error] = useAccountItem();

  const rightUrl = getUrlWithoutSlash(url);

  useEffect(() => {
    if (data) {
      setCurrentAccount(data);
    }

    return () => setCurrentAccount(null);
  }, [data, setCurrentAccount]);

  if (error) {
    return (
      <>
        <ErrorMessage error={error}/>
        <NotFoundScreen/>
      </>
    );
  }

  if (isLoading || !currentAccount?.id) {
    return <LoaderProgress/>;
  }

  const isServicesPage = pathname.includes('/services');

  return (
    <AccountLayoutProvider>
      <ProcessesProvider>
        <BillingsProvider>
          <CompanySecretaryProvider>
            <>
              {!isServicesPage ? <Hidden smDown><SidebarMenu/></Hidden> : null}

              <Wrapper isServicesPage={isServicesPage}>
                <Switch>
                  <Redirect from={path} exact to={`${rightUrl}/workspace`}/>

                  <Route path={`${path}/workspace`} component={AccountWorkspaceScreen}/>

                  <Route path={`${path}/settings`} component={SettingsScreen}/>

                  <Route path={`${path}/services`}>
                    <ServicesProvider>
                      <ServicesCatalogScreen/>
                    </ServicesProvider>
                  </Route>

                  <Route path="*" component={NotFoundScreen}/>
                </Switch>
              </Wrapper>
              <ModalsContainer name="account"/>
            </>
          </CompanySecretaryProvider>
        </BillingsProvider>
      </ProcessesProvider>
    </AccountLayoutProvider>
  );
};

export default AccountScreen;
