import { Theme } from '@material-ui/core';
import mui from './mui';

declare module 'styled-components' {
  export interface DefaultTheme extends Theme {}
}

const mainTheme = {
  mui,
};

export default mainTheme;
