import React from 'react';
import { useRouteMatch, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '../../../../../../components/ui/Box';
import Hidden from '../../../../../../components/ui/Hidden';
import Table from '../../../../../../components/ui/Table';
import TableBody from '../../../../../../components/ui/TableBody';
import { getUrlWithoutSlash } from '../../../../../../utils/helpers';
import { getFormattedTotalAmount } from '../../../../utils/billings';
import { useBillingsContext } from '../../../../providers/BillingsProvider';
import { BillingModel } from '../../../../network/models';
import useDateLib from '../../../../../../hooks/useDateLib';

import LoaderProgress from '../../../../../../components/LoaderProgress';
import BillingStatus from '../../../BillingsScreen/components/BillingStatus';
import { EmptyText } from '../../styled';

import {
  Root,
  Divider,
  Title,
  Transaction,
  TransactionColumn,
  TransactionNumber,
  TransactionDate,
  StyledButton,
  TitleWrapper,
  ContentWrapper,
} from './styled';

function Transactions() {
  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const { getCreatedDate } = useDateLib();
  const { data, loading } = useBillingsContext();

  const rightUrl = getUrlWithoutSlash(url).replace('/overview', '');

  const transactions: BillingModel[] = (data?.results || []).slice(0, 3);
  const hasTransactions = Boolean(transactions.length > 0);

  return (
    <Root color={!hasTransactions || loading ? 'empty' : ''}>
      {loading ? <LoaderProgress /> : (
        <>
          <TitleWrapper>
            <Title>{t('account.overview.cards.invoices')}</Title>
            <Box mr={-1.5}>
              {hasTransactions ? (
                <StyledButton
                  component={Link}
                  to={`${rightUrl}/billings`}
                >{t('account.overview.buttons.viewAll')}</StyledButton>
              ) : null}
            </Box>
          </TitleWrapper>

          <Divider />

          {hasTransactions ? (
            <ContentWrapper>
              <Table>
                <TableBody>
                  {transactions.map((({ id, number, created, status, amount }) => {
                    const date = getCreatedDate(created);
                    const total = getFormattedTotalAmount(amount);

                    return (
                      <Transaction key={id}>
                        <Hidden smDown>
                          <TransactionColumn>
                            <TransactionNumber>{number}</TransactionNumber>
                            <TransactionDate>{date}</TransactionDate>
                          </TransactionColumn>
                          <TransactionColumn>€{total}</TransactionColumn>
                          <TransactionColumn>
                            <Box display="flex" justifyContent="flex-end">
                              <BillingStatus status={status} />
                            </Box>
                          </TransactionColumn>
                        </Hidden>

                        <Hidden mdUp>
                          <TransactionColumn>
                            <Box pb={0.5}>
                              <BillingStatus status={status} />
                            </Box>
                            <TransactionNumber>no. {number}</TransactionNumber>
                            <TransactionDate>{date}</TransactionDate>
                          </TransactionColumn>
                          <TransactionColumn align="right">€{total}</TransactionColumn>
                        </Hidden>
                      </Transaction>
                    );
                  }))}
                </TableBody>
              </Table>
            </ContentWrapper>
          ) : (
            <Box p={3}>
              <EmptyText>{t('account.overview.empty.invoices')}</EmptyText>
            </Box>
          )}
        </>
      )}
    </Root>
  )
}

export default Transactions;
