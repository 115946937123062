import React, { useCallback, useState } from 'react';

import { TaskModel } from '../../network/models';
import { STEPS, TASK_STATUS_DONE, TASK_STATUS_STARTED } from '../../utils/constants';

import Step, { StepParams } from '../Step';

import { StepsWrapper, Dots, Dot, StyledSwipeableViews, MobileStepsWrapper, MobileStepWrapper } from './styled';

export type StepsProps = {
  tasks: TaskModel[];
  view?: 'desktop' | 'mobile';
  isKYCShareholdersDone?: boolean;
  allDocumentsCompleted: boolean;
};

const Steps = (props: StepsProps) => {
  const { tasks, view, allDocumentsCompleted, isKYCShareholdersDone } = props;

  const startedTaskIndex = tasks.findIndex(task => task.status === TASK_STATUS_STARTED);
  const defaultActiveStep = startedTaskIndex !== -1 ? startedTaskIndex : tasks.length - 1;

  const [activeStep, setActiveStep] = useState(defaultActiveStep);

  const futureSteps = Object.keys(STEPS)
    .filter((key: string) => !tasks?.find(({ flow_task }) => flow_task === key))
    .filter((key: string) => !Boolean(STEPS[key].optional))
    .map((key: string, index) => ({
      id: index,
      positionId: STEPS[key].positionId,
      title: STEPS[key].title,
      description: STEPS[key].description,
    }));

  const steps: Partial<StepParams>[] = [
    ...futureSteps,
    ...tasks.map(task => ({
      ...STEPS[task.flow_task],
      ...task,
      status: task.flow_task === 'kyc_onboarding'
        ? isKYCShareholdersDone
          ? TASK_STATUS_DONE
          : TASK_STATUS_STARTED
        : task.flow_task === 'documents_submit'
          ? allDocumentsCompleted
            ? TASK_STATUS_DONE
            : TASK_STATUS_STARTED
          : task.status,
    })),
  ].sort((a, b) => a.positionId - b.positionId);

  const handleOnChangeIndex = useCallback((index: any) => {
    setActiveStep(index);
  }, []);

  if (view === 'mobile') {
    return (
      <MobileStepsWrapper>
        <StyledSwipeableViews enableMouseEvents index={activeStep} resistance onChangeIndex={handleOnChangeIndex}>
          {steps.map((step, index) => (
            <MobileStepWrapper key={step.id} >
              <Step step={step} index={index} />
            </MobileStepWrapper>
          ))}
        </StyledSwipeableViews>
        <Dots>
          {steps.map((step, index) => (
            <Dot key={step.id} isActive={activeStep === index} onClick={() => handleOnChangeIndex(index)} />
          ))}
        </Dots>
      </MobileStepsWrapper>
    );
  }

  return (
    <StepsWrapper>
      {steps.map((step, index) => (
        <Step key={step.id} step={step} index={index} />
      ))}
    </StepsWrapper>
  )
};

export default Steps;
