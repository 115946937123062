import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useCabinetContext } from '../../../../containers/CabinetContainer/context';
import { openSumSubModal } from '../SumSubModal';

import { Text, VerifyButton, Wrapper } from './styled';

type VerificationInfoPanelProps = {
  type: 'standard' | 'next' | 'current';
};

const VerificationInfoPanel = (props: VerificationInfoPanelProps) => {
  const { type } = props;
  const { t } = useTranslation();
  const { currentUser } = useCabinetContext()

  const handleClick = useCallback(() => {
    openSumSubModal({ currentUser });
  }, [currentUser]);

  switch (type) {
    case 'next':
      return (
        <Wrapper type="next">
          <Text>{t('account.verification.infoPanel.next.text')}</Text>
          <VerifyButton onClick={handleClick}>{t('account.verification.infoPanel.next.button')}</VerifyButton>
        </Wrapper>
      );
    case 'current':
      return (
        <Wrapper type="current">
          <Text>
            ❗{t('account.verification.infoPanel.current.text')}{' '}
            <VerifyButton onClick={handleClick}>{t('account.verification.infoPanel.current.button')}</VerifyButton>
          </Text>
        </Wrapper>
      );
    case 'standard':
    default:
      return (
        <Wrapper type="standard">
          <Text>{t('account.verification.infoPanel.standard.text')}</Text>
          <VerifyButton onClick={handleClick}>{t('account.verification.infoPanel.standard.button')}</VerifyButton>
        </Wrapper>
      );
  }
};

export default VerificationInfoPanel;
