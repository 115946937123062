import React, { useCallback, useRef } from 'react';

import { ModalItemBaseProps, openModal } from '../../../../containers/ModalsContainer';
import BaseModal from '../../../../components/Modal/BaseModal';
import useDocumentSession from '../../hooks/documents/useDocumentSession';
import { DocumentSessionModel } from '../../network/models';
import RequestError from '../../../../network/RequestError';
import Box from '../../../../components/ui/Box';
import LoaderProgress from '../../../../components/LoaderProgress';

type ServiceCheckoutDocumentModalProps = {
  documentSession?: DocumentSessionModel | null;
  isDocumentSessionLoading: boolean;
  documentSessionError: null | RequestError;
  loadDocumentSession: () => void;
  loadProcess: () => void;
};

const ProcessDocumentSessionModal = (props: ModalItemBaseProps & ServiceCheckoutDocumentModalProps) => {
  const {
    modalLevel,
    modalIsOpen,
    modalRequestClose,
    documentSession,
    isDocumentSessionLoading,
    loadProcess,
  } = props;

  const rootRef = useRef<HTMLDivElement | null>(null);

  const renderDocument = () => {
    if (!documentSession && isDocumentSessionLoading) {
      return <LoaderProgress />;
    }

    if (documentSession) {
      return (
        <Box height="100vh">
          <iframe height="100%" width="100%" title="User Document" src={`https://app.pandadoc.com/s/${documentSession.session_id}`} frameBorder="0" />
        </Box>
      );
    }

    return null;
  };

  const handleClose = useCallback(async () => {
    modalRequestClose();
    loadProcess();
  }, [loadProcess, modalRequestClose]);

  return (
    <>
      <BaseModal
        noBg
        ref={rootRef}
        open={modalIsOpen}
        level={modalLevel}
        onRequestClose={handleClose}
        content={renderDocument()}
      />
    </>
  );
};

type ProcessDocumentSessionModalControllerProps = {
  documentId: number;
  loadProcess: () => void;
};

const ProcessDocumentSessionModalController = (props: ModalItemBaseProps & ProcessDocumentSessionModalControllerProps) => {
  const {
    documentId,
    loadProcess,
    ...rest
  } = props;

  const [documentSession, isDocumentSessionLoading, documentSessionError, loadDocumentSession] = useDocumentSession({ documentId });

  return (
    <ProcessDocumentSessionModal
      {...rest}
      documentSession={documentSession}
      isDocumentSessionLoading={isDocumentSessionLoading}
      documentSessionError={documentSessionError}
      loadDocumentSession={loadDocumentSession}

      loadProcess={loadProcess}
    />
  );
};

export const openProcessDocumentSessionModal = (
  props?: ProcessDocumentSessionModalControllerProps,
): ReturnType<typeof openModal> => {
  return openModal(ProcessDocumentSessionModalController, props, 'account');
};

export default ProcessDocumentSessionModalController;
