import styled, { css } from 'styled-components';

export const ServiceStatusBase = styled.div<{ withBackground: boolean, withMarker: boolean }>`
  border-radius: 8px;

  align-items: center;
  display: flex;
  font-weight: 600;
  justify-content: center;
  height: 32px;
  padding-right: 12px;
  width: fit-content;
  position: relative;
  white-space: nowrap;
  text-transform: uppercase;

  ${(p) => p.withBackground ? css`
    padding-left: 30px;
  ` : css`
    height: fit-content;
    padding-left: 26px;
  `}

  ${(p) => p.withMarker ? css`
    &::before {
      content: '•';
      display: block;
      position: absolute;
      color: inherit;
      font-size: 36px;
      line-height: 24px;
      top: 3px;
      ${p.withBackground ? css`
        left: 8px;
      ` : css`
        left: -4px;
      `}
    }
  ` : css`
    padding-left: 0;
  `}
`;

export const ProcessStatusDraft = styled(ServiceStatusBase)`
  ${(p) => p.withBackground && css`
    background: #F5F5F5;
  `}
  color: #232121;
`;

export const ProcessStatusDone = styled(ServiceStatusBase)`
  ${(p) => p.withBackground ? css`
    background: #27C297;
    color: white;
  ` : css`
    color: #27C297;
  `}
`;

export const ProcessStatusInProgress = styled(ServiceStatusBase)`
  ${(p) => p.withBackground ? css`
    background: #FFB800;
    color: white;
  ` : css`
    color: #FFB800;
  `}
`;

export const ProcessStatusRejected = styled(ServiceStatusBase)`
  ${(p) => p.withBackground ? css`
    background: #D95050;
    color: white;
  ` : css`
    color: #D95050;
  `}
`;

export const ProcessStatusArchive = styled(ServiceStatusBase)`
  ${(p) => p.withBackground ? css`
    background: rgba(35, 33, 33, 0.12);
    color: rgba(35, 33, 33, 0.6);
  ` : css`
    color: rgba(35, 33, 33, 0.6);
  `}
`;
