import React, { ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import ArchiveIcon from '@material-ui/icons/Archive';
import UnarchiveIcon from '@material-ui/icons/Unarchive';

import { ReactComponent as DeleteForeverIcon } from '../../../../../../assets/img/services/delete-icon.svg';

import Box from '../../../../../../components/ui/Box';
import { OutlinedButton } from '../../../../../../components/ui/Button';

import { ProcessResultModel } from '../../../../network/models';
import { useProcessesContext } from '../../../../providers/ProcessesProvider';
import CircularProgress from '../../../../../../components/ui/CircularProgress';

import { ActionButton, ActionTitle, StyledListItem, StyledListItemIcon } from './styled';

type ActionButtonWrapperProps = {
  children: ReactElement;
  outlined?: boolean;
  disabled: boolean;
  onClick: () => void;
};

const ActionButtonWrapper = (props: ActionButtonWrapperProps) => {
  const { children, outlined, ...rest } = props;

  return outlined ? (
    <OutlinedButton {...rest}>
      {children}
    </OutlinedButton>
  ) : (
    <ActionButton {...rest}>
      {children}
    </ActionButton>
  );
};

type BaseButtonProps = {
  disabled: boolean;
  icon: ReactElement;
  onClick: () => void;
  outlined?: boolean;
  showLoading: boolean;
  title: string;
};

const BaseActionButton = (props: BaseButtonProps) => {
  const { disabled, icon, onClick, outlined, showLoading, title } = props;

  return (
    <ActionButtonWrapper disabled={disabled} onClick={onClick} outlined={outlined}>
      <StyledListItem>
        {showLoading ? (
          <Box mr={1} display="flex" alignItems="center">
            <CircularProgress size={20} />
          </Box>
        ) : null}
        <StyledListItemIcon>
          {icon}
        </StyledListItemIcon>
        <ActionTitle>{title}</ActionTitle>
      </StyledListItem>
    </ActionButtonWrapper>
  );
};

export type ProcessesListItemActionProps = {
  isUpdating?: boolean;
  onDelete?: () => void;
  onUpdate?: () => void;
  outlined?: boolean;
  process: ProcessResultModel;
};

const ProcessesListItemAction = (props: ProcessesListItemActionProps) => {
  const { isUpdating, onDelete, onUpdate, outlined, process } = props;
  const { id, status, is_archived } = process;

  const {
    loadData,
    loading,
    archiveProcess,
    deleteProcess,
    restoreProcess,
    isArchiveProcessLoading,
    isDeleteProcessLoading,
    isRestoreProcessLoading,
  } = useProcessesContext();

  const { t } = useTranslation();

  const disabled = Boolean(loading || isArchiveProcessLoading || isDeleteProcessLoading || isRestoreProcessLoading || isUpdating);

  const handleArchive = useCallback(async () => {
    await archiveProcess({ processId: id });
    await loadData();
    onUpdate && onUpdate();
  }, [archiveProcess, id, loadData, onUpdate]);

  const handleDelete = useCallback(async () => {
    await deleteProcess({ processId: id });
    await loadData();
    onDelete && onDelete();
  }, [deleteProcess, id, loadData, onDelete]);

  const handleRestore = useCallback(async () => {
    await restoreProcess({ processId: id });
    await loadData();
    onUpdate && onUpdate();
  }, [id, loadData, onUpdate, restoreProcess]);

  const showLoadingBase = loading || isUpdating;

  if (is_archived) {
    return (
      <BaseActionButton
        onClick={handleRestore}
        showLoading={showLoadingBase || isRestoreProcessLoading}
        title={t('account.requests.actions.restore')}
        icon={<UnarchiveIcon />}
        outlined={outlined}
        disabled={disabled}
      />
    );
  }

  switch (status) {
    case 'draft': {
      return (
        <BaseActionButton
          onClick={handleDelete}
          showLoading={showLoadingBase || isDeleteProcessLoading}
          title={t('account.requests.actions.delete')}
          icon={<DeleteForeverIcon />}
          outlined={outlined}
          disabled={disabled}
        />
      );
    }
    case 'done':
    case 'rejected': {
      return (
        <BaseActionButton
          onClick={handleArchive}
          showLoading={showLoadingBase || isArchiveProcessLoading}
          title={t('account.requests.actions.archive')}
          icon={<ArchiveIcon />}
          outlined={outlined}
          disabled={disabled}
        />
      );
    }
    case 'in_progress':
    case 'in_processing':
    default: {
      return null;
    }
  }
};

export type ProcessesActionButtonProps = {
  isUpdating?: boolean;
  onDelete?: () => void;
  onUpdate?: () => void;
  outlined?: boolean;
  process: ProcessResultModel;
};

export const ProcessesActionButton = (props: ProcessesActionButtonProps) => {
  const { isUpdating, onDelete, onUpdate, outlined = false, process } = props;
  const { status } = process;

  if (status === 'in_progress' || status === 'in_processing') {
    return null;
  }

  return (
    <ProcessesListItemAction
      process={process}
      onDelete={onDelete}
      onUpdate={onUpdate}
      isUpdating={isUpdating}
      outlined={outlined}
    />
  );
};

export default ProcessesListItemAction;
