import React, { FormEvent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';

import { CheckPaymentParams } from '../../../../network';
import RequestError from '../../../../../../network/RequestError';

import { PrimaryButton } from '../../../../../../components/ui/Button';
import SuccessPage from '../../../SuccessPage';

import { StyledForm } from './styled';

export type PaymentFormProps = {
  loadProcess: () => void;
  checkPayment: (params: CheckPaymentParams) => void;
  checkPaymentResult: any;
  checkPaymentError: RequestError | null;
  setIsCardPaymentSuccess: (isCardPaymentSuccess: boolean) => void;
  isCardPaymentSuccess: boolean;
};

const PaymentForm = (props: PaymentFormProps) => {
  const {
    isCardPaymentSuccess,
    loadProcess,
    checkPaymentResult,
    checkPaymentError,
    checkPayment,
    setIsCardPaymentSuccess,
  } = props;

  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isRendering, setIsRendering] = useState(true);
  const [paymentIntentId, setPaymentIntentId] = useState<string | null>(null);

  const payNowText = t('account.processes.payment.tabs.byCard.payNow');

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: '',
      },
      redirect: 'if_required'
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error?.type === 'card_error' || error?.type === 'validation_error') {
      setMessage(error?.message || null);
    }

    if (paymentIntent?.id) {
      setPaymentIntentId(paymentIntent.id);
      setIsCardPaymentSuccess(true);
    }

    setIsLoading(false);
  };

  const handleSuccessPayment = useCallback(async () => {
    if (paymentIntentId && !checkPaymentError && isCardPaymentSuccess) {
      await checkPayment({
        paymentId: paymentIntentId,
        paymentType: 'credit_card',
        invoiceType: 'online',
      });
      await loadProcess();
    }
  }, [checkPayment, checkPaymentError, isCardPaymentSuccess, loadProcess, paymentIntentId]);

  useEffect(() => {
    if (checkPaymentResult?.non_field_errors) {
      setMessage(checkPaymentResult.non_field_errors);
    }
  }, [checkPaymentResult, loadProcess]);

  return paymentIntentId && isCardPaymentSuccess ? (
    <SuccessPage
      title={t('account.processes.payment.success.byCard.title')}
      description={t('account.processes.payment.success.text')}
      actions={
        <PrimaryButton onClick={handleSuccessPayment}>
          {t('account.processes.payment.success.button')}
        </PrimaryButton>
      }
    />
  ) : (
    <StyledForm id="payment-form" onSubmit={handleSubmit}>
      {!message && (
        <>
          <PaymentElement id="payment-element" onReady={() => setIsRendering(false)} />
          {isRendering ? (
            <div className="spinner" id="spinner" />
          ) : (
            <button disabled={isLoading || !stripe || !elements} id="submit">
              <span id="button-text">
                {isLoading ? <div className="spinner" id="spinner"/> : payNowText}
              </span>
            </button>
          )}
        </>
      )}

      {message && <div id="payment-message">{message}</div>}
    </StyledForm>
  );
};

export default PaymentForm;
