import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SettingsIcon from '@material-ui/icons/Settings';

import IndividualAvatar from '../../../../assets/img/individual-avatar.svg';
import CompanyAvatar from '../../../../assets/img/company-avatar.svg';
import useAvatarLetter from '../../../../hooks/useAvatarLetter';
import { getLogoutUrl, getProfileUrl } from '../../../../auth';
import { CurrentAccountProps, useCabinetContext } from '../../../../containers/CabinetContainer/context';
import { openSumSubModal } from '../../../../modules/verification/components/SumSubModal';
import { openCreateAccountModal } from '../../../../modules/account/components/CreateAccountModal';
import useAccountList from '../../../../modules/account/hooks/accounts/useAccountList';

import Badge from '../../../ui/Badge';
import Box from '../../../ui/Box';
import IconButton from '../../../ui/IconButton';
import ListItemText from '../../../ui/ListItemText';
import Tooltip from '../../../ui/Tooltip';
import Hidden from '../../../ui/Hidden';

import UserMenuAccountsListItem from '../UserMenuAccountsListItem';

import {
  AccountName,
  AvatarListItemIcon,
  ListItemSeparator,
  StyledAvatar,
  StyledMenuItem,
  StyledListItemIcon,
  StyledListItem,
  StyledListSubheader,
  StyledMenu,
  UserName,
  UserStatus,
  useStyles,
} from './styled';

export const getAccountAvatarIconSrc =(account: CurrentAccountProps) => {
  return account?.account_type === 'company' ? CompanyAvatar : IndividualAvatar;
};

const UserMenu = () => {
  const { currentUser, currentAccount } = useCabinetContext();
  const classes = useStyles();
  const [accountList,,,loadData] = useAccountList();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { t } = useTranslation();

  const open = Boolean(anchorEl);

  const showVerification: boolean = currentAccount?.status === 'new';

  const handleMenu = useCallback(
    (event: any) =>
      setAnchorEl(event.currentTarget as Element),
    [setAnchorEl],
  );

  const handleClose = useCallback(
    () => setAnchorEl(null),
    [setAnchorEl],
  );

  const createAccount = useCallback(
    () => {
      handleClose();
      openCreateAccountModal({ callback: loadData });
    },
    [handleClose, loadData],
  );

  const avatarLetter = useAvatarLetter(
    currentUser?.first_name
      ? [currentUser?.first_name, currentUser?.last_name]
      : [currentUser?.email],
  );

  const handleOpenVerificationModal = useCallback(
    () => {
      handleClose();
      openSumSubModal({ currentUser });
    },
    [currentUser, handleClose],
  );

  const menuLinks = [
    {
      title: t('user.menu.services'),
      link: `/cabinet/account/${currentAccount?.id}/services`,
      onClick: handleClose,
    },
    {
      title: t('user.menu.workspace'),
      link: `/cabinet/account/${currentAccount?.id}/workspace`,
      onClick: handleClose,
    },
  ];

  return (
    <div>
      <Tooltip arrow title="User menu">
        <StyledListItem
          aria-label="User menu"
          aria-owns={open ? 'menu-appbar' : undefined}
          aria-haspopup="true"
          button
          component="button"
          onClick={handleMenu}
        >
          <AvatarListItemIcon>
            <Badge
              color="error"
              badgeContent={showVerification ? <span style={{ fontWeight: 700 }}>!</span> : null}
              overlap="circular"
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <StyledAvatar alt={currentUser?.email} src={getAccountAvatarIconSrc(currentAccount)}>
                {avatarLetter}
              </StyledAvatar>
            </Badge>
            <AccountName>{currentAccount?.name}</AccountName>
          </AvatarListItemIcon>

          <KeyboardArrowDownIcon />
        </StyledListItem>
      </Tooltip>
      <StyledMenu
        id="menu-appbar"
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClose={handleClose}
        TransitionProps={{ timeout: { exit: 200 } }}
        PopoverClasses={{
          root: classes.popoverRoot,
        }}
      >
        <Box display={{ xs: 'static', sm: 'none' }} p={0} pb={0}>
          <IconButton color="inherit" aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        {currentAccount?.id ? (
          <StyledMenuItem
            component={'a'}
            display="flex"
            onClick={handleClose}
            alignItems="center"
            href={getProfileUrl()}
          >
            <StyledAvatar alt={currentUser?.first_name} src={getAccountAvatarIconSrc(currentAccount)}>
              {avatarLetter}
            </StyledAvatar>
            <Box ml={1.5}>
              <Box mb={0.5}>
                <UserName>
                  {currentUser?.first_name} {currentUser?.last_name}
                </UserName>
              </Box>
              <UserStatus>{currentAccount?.account_type === 'individual' ? t('user.menu.individual') : t('user.menu.company')}</UserStatus>
            </Box>
          </StyledMenuItem>
        ) : null}

        <Hidden smUp>
          {currentAccount?.id ? menuLinks.map(({ title, link, onClick }) => (
            <StyledMenuItem
              key={title}
              component={Link}
              aria-label={title}
              onClick={onClick}
              to={link}
            >
              <ListItemText>{title}</ListItemText>
            </StyledMenuItem>
          )) : null}
        </Hidden>

        {showVerification ? (
          <StyledMenuItem
            component={'div'}
            onClick={handleOpenVerificationModal}
          >
            <ListItemText primaryTypographyProps={{ color: 'error' }}>
              {t('user.menu.verification')}
              <StyledListItemIcon>
                <ErrorIcon color="error" />
              </StyledListItemIcon>
            </ListItemText>
          </StyledMenuItem>
        ) : null}

        {currentAccount?.id ? <ListItemSeparator /> : null}

        <StyledListSubheader>
          {t('user.menu.subheaders.accounts')}
          <IconButton
            size="small"
            color="inherit"
            aria-label="Accounts"
            onClick={handleClose}
            component={Link}
            to={'/cabinet/account'}
          >
            <SettingsIcon />
          </IconButton>
        </StyledListSubheader>

        {accountList?.results ? accountList.results.map(
          (account: any) => <UserMenuAccountsListItem key={account.id} account={account} handleClose={handleClose} />
        ) : null}

        <StyledMenuItem onClick={createAccount}>
          <StyledListItemIcon>
            <AddIcon />
          </StyledListItemIcon>
          <ListItemText>{t('user.menu.addCompany')}</ListItemText>
        </StyledMenuItem>

        <ListItemSeparator />

        <StyledListSubheader>{t('user.menu.subheaders.global')}</StyledListSubheader>

        <StyledMenuItem
          component={'a'}
          onClick={handleClose}
          href={getLogoutUrl()}
        >
          <ListItemText>{t('user.menu.logout')}</ListItemText>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
};

export default UserMenu;
