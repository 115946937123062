import React from 'react';
import { Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import FormControlLabel from '../../../../../../../../components/ui/FormControlLabel';
import Radio from '../../../../../../../../components/ui/Radio';
import RadioGroup from '../../../../../../../../components/ui/RadioGroup';

import { FormInputLabel } from '../PeopleForm/styled';

type LegalTypeProps = {
  formPrefix: string;
  tPrefix: string;
};

export const LegalType = (props: LegalTypeProps) => {
  const { formPrefix, tPrefix } = props;

  const { t } = useTranslation();

  return (
    <RadioGroup row>
      <Field
        name={`${formPrefix}_legal_type`}
        value={t(`${tPrefix}.peopleForm.individual.label`)}
        type="radio"
        initialValue={t(`${tPrefix}.peopleForm.individual.label`)}
      >
        {({ input }) => (
          <FormControlLabel
            control={<Radio color="default" {...input}  />}
            label={<FormInputLabel>{t(`${tPrefix}.peopleForm.individual.label`)}</FormInputLabel>}
          />
        )}
      </Field>
      <Field
        name={`${formPrefix}_legal_type`}
        value={t(`${tPrefix}.peopleForm.entity.label`)}
        type="radio"
      >
        {({ input }) => (
          <FormControlLabel
            control={<Radio color="default" {...input}  />}
            label={<FormInputLabel>{t(`${tPrefix}.peopleForm.entity.label`)}</FormInputLabel>}
          />
        )}
      </Field>
    </RadioGroup>
  );
};
