import { createContext, ReactElement } from 'react';

import {
  ListContextInitialValues,
  ListContextValues,
  ListProvider,
  useListContext,
} from '../../../providers/ListProvider';

import useListState from '../../../hooks/useListState';
import useBillingsList from '../hooks/billings/useBillingsList';
import { BillingsModel } from '../network/models';

export type BillingsProviderProps = {
  children: ReactElement;
};

export const BillingsContext = createContext<ListContextValues>(ListContextInitialValues);

export const BillingsProvider = (props: BillingsProviderProps) => {
  const { page, pageSize, setPage, setPageSize } = useListState();

  const [billingsListRaw, isBillingsListLoading, billingsListError, loadBillingsList] = useBillingsList();

  let billingsList: BillingsModel | null = billingsListRaw;

  if (billingsList !== null) {
    billingsList = {
      ...billingsList,
      results: billingsList.results
        .sort((a, b) => {
          return new Date(b.created).valueOf() - new Date(a.created).valueOf();
        }).slice((page - 1) * pageSize, page * pageSize),
    };
  }

  return (
    <ListProvider
      ListContext={BillingsContext}

      data={billingsList}
      loading={isBillingsListLoading}
      error={billingsListError}
      loadData={loadBillingsList}

      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
    >
      {props.children}
    </ListProvider>
  );
};

export const useBillingsContext = () => useListContext(BillingsContext) as ListContextValues;
