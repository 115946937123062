import { Context, Dispatch, ReactElement, SetStateAction, useContext, useMemo } from 'react';

import ObjectType from '../../utils/ObjectType';
import RequestError from '../../network/RequestError';

export interface ListContextValues {
  data: null | any;
  loading: boolean;
  error: null | RequestError;
  page: number;
  pageSize: number;
  filters?: null | ObjectType;
  sort?: string;
  setPage: Dispatch<SetStateAction<number>>;
  setPageSize: Dispatch<SetStateAction<number>>;
  setFilters?: Dispatch<SetStateAction<null | ObjectType>>;
  setSort?: Dispatch<SetStateAction<string>>;

  loadData: () => void;
}

export const ListContextInitialValues = {
  data: null,
  loading: false,
  error: null,
  page: 1,
  pageSize: 10,
  filters: null,
  sort: 'ASC',

  setPage: () => {},
  setPageSize: () => {},
  setFilters: () => {},
  setSort: () => {},
  loadData: () => {},
};

export type ListProviderProps = ListContextValues & {
  ListContext: Context<ListContextValues | any>;
  children: ReactElement;
  [key: string]: any;
};

export const ListProvider = (props: ListProviderProps) => {
  const {
    ListContext,
    children,
    data,
    loading,
    error,
    page,
    pageSize,
    filters,
    sort,
    setPage,
    setPageSize,
    setFilters,
    setSort,
    loadData,
    ...rest
  } = props;

  const value = useMemo(() => ({
    data,
    loading,
    error,
    page,
    pageSize,
    filters,
    sort,
    setPage,
    setPageSize,
    setFilters,
    setSort,
    loadData,
    ...rest,
  }), [data, loading, error, page, pageSize, filters, sort, setPage, setPageSize, setFilters, setSort, loadData, rest]);

  return (
    <ListContext.Provider
      value={value}
    >
      {children}
    </ListContext.Provider>
  );
};

export const useListContext = (ListContext: Context<ListContextValues | any>) => useContext(ListContext);
