import React, { useEffect, useMemo, useState } from 'react';
import { useRouteMatch, Switch, Redirect, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getUrlWithoutSlash } from '../../../../utils/helpers';
import AccountLayoutProvider from '../../providers/AccountProvider';
import ProcessesScreen from '../../../process/screens/ProcessesScreen';
import BillingsScreen from '../BillingsScreen';
import OverviewScreen from '../OverviewScreen';
import MessagesScreen from '../MessagesScreen';
import CompanySecretaryScreen from '../CompanySecretaryScreen';
import { MessagesProvider } from '../../providers/MessagesProvider';
import { ModalsContainer } from '../../../../containers/ModalsContainer';

const AccountWorkspaceScreen = () => {
  const { path, url } = useRouteMatch();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  const [currentTab, setCurrentTab] = useState(0);

  const rightUrl = getUrlWithoutSlash(url);

  const menuList = useMemo(() => [
    {
      to: `${rightUrl}/requests`,
      label: t('account.menu.requests'),
    },
    {
      to: `${rightUrl}/documents`,
      label: t('account.menu.documents'),
    },
    {
      to: `${rightUrl}/invoices`,
      label: t('account.menu.invoices'),
    },
  ], [t, rightUrl]);

  useEffect(() => {
    menuList.forEach((link, index) => {
      if (pathname.indexOf(link.to) !== -1 && currentTab !== index) {
        setCurrentTab(index);
      }
    });
  }, [currentTab, menuList, pathname]);

  return (
    <AccountLayoutProvider>
      <Switch>
        <Redirect from={path} exact to={`${rightUrl}/overview`} />

        <Route path={`${path}/overview`} component={OverviewScreen} />
        <Route path={`${path}/company-secretary`} component={CompanySecretaryScreen} />
        <Route path={`${path}/requests`} component={ProcessesScreen} />
        <Route path={`${path}/invoices`} component={BillingsScreen} />
        <Route path={`${path}/messages`}>
          <MessagesProvider>
            <>
              <MessagesScreen />
              <ModalsContainer name="messages" />
            </>
          </MessagesProvider>
        </Route>
      </Switch>
    </AccountLayoutProvider>
  );
};

export default AccountWorkspaceScreen;
