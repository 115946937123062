import { ReactElement, useState } from 'react';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';

import { Heading, Title, Description, MuiAccordion, StyledSummary, StyledDetails, Hint } from './styled';
import { InfoBubble } from '../ui/InfoBubble';

export type StyledAccordionProps = {
  children: ReactElement | ReactElement[];
  title: ReactElement | string;
  hint?: string;
  description?: string;
  initialExpanded?: boolean;
  disabled?: boolean;
};

const StyledAccordion = ({
  children,
  title,
  hint,
  description,
  initialExpanded = true,
  disabled = false,
}: StyledAccordionProps) => {
  const [expanded, setExpanded] = useState(initialExpanded);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  return (
    <MuiAccordion expanded={expanded}>
      <StyledSummary
        expandIcon={<KeyboardArrowDown />}
        aria-controls="panel1c-content"
        id="panel1c-header"
        onClick={!disabled ? handleChange : () => {}}
      >
        {title && (
          <Heading >
            <Title disabled={disabled}>{title}</Title> {hint && <Hint><InfoBubble text={hint} /></Hint>}
          </Heading>
        )}
        {description && (
          <Description disabled={disabled} dangerouslySetInnerHTML={{ __html: description }} />
        )}
      </StyledSummary>
      <StyledDetails disabled={disabled}>{children}</StyledDetails>
    </MuiAccordion>
  );
};

export default StyledAccordion;
