// import { useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getUrlWithoutSlash } from '../../../../utils/helpers';
import Box from '../../../../components/ui/Box';
import AccountLayout from '../../components/AccountLayout';
import PaymentSettings from './components/Payment';
import NotifySettings from './components/Notify';

const SettingsScreen = () => {
  const { path, url } = useRouteMatch();
  const { t } = useTranslation();

  const rightUrl = getUrlWithoutSlash(url);

  // const menuItems = useMemo(() => [
  //   {
  //     to: `${rightUrl}/payment`,
  //     title: t('account.settings.menu.payment'),
  //   },
  //   {
  //     to: `${rightUrl}/notify`,
  //     title: t('account.settings.menu.notify'),
  //   },
  // ], [t, rightUrl]);

  return (
    <AccountLayout title={t('account.settings.title')}>
      <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }}>
        <Switch>
          <Redirect from={path} exact to={`${rightUrl}/notify`} />

          <Route path={`${path}/payment`} exact component={PaymentSettings} />
          <Route path={`${path}/notify`} exact component={NotifySettings} />
        </Switch>
      </Box>
    </AccountLayout>
  )
}

export default SettingsScreen;
