import { memo, useRef } from 'react';

import { ReactComponent as LogoText } from '../../assets/img/logo.svg';
import { getLandingUrl } from '../../auth';

import {
  LogoIconFrame,
  LogoIconSquare,
  LogoIconWrapper,
  Title,
  LogoTextWrapper,
} from './styled';

const Logo = memo(() => {
  const square = useRef<HTMLDivElement>(null);
  const frame = useRef<HTMLDivElement>(null);

  const onMouseEnter = () => {
    square.current!.style!.transform = 'rotate(-12deg)';
    frame.current!.style!.transform = 'rotate(-25deg)';
  };

  const onMouseLeave = () => {
    square.current!.style!.transform = 'rotate(-10deg)';
    frame.current!.style!.transform = 'rotate(-39deg)';
  };

  return (
    <Title
      href={getLandingUrl()}
      target={'_blank'}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <LogoIconWrapper>
        <LogoIconSquare ref={square} />
        <LogoIconFrame ref={frame} />
      </LogoIconWrapper>
      <LogoTextWrapper>
        <LogoText />
      </LogoTextWrapper>
    </Title>
  );
});

export default Logo;
