import { useCallback, useState } from 'react';

import RequestError from '../../../../../network/RequestError';
import ObjectType from '../../../../../utils/ObjectType';
import { createProcess, CreateProcessParams } from '../../../network';

type ResultData = ObjectType | null;

const useProcessCreate = (): [ResultData, boolean, Error | null, ({ serviceId }: CreateProcessParams) => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useCallback(async ({ serviceId }: CreateProcessParams) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await createProcess({ serviceId });

      setData(response);
      setIsLoading(false);

      return response;
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, [setIsLoading, setError]);

  return [data, isLoading, error, loadData];
};

export default useProcessCreate;
