import { useEffect, useState } from 'react';
import useCurrentUser from './useCurrentUser';

export enum AuthState {
  Loading = 'Loading',
  Authorized = 'Authorized',
  Unauthorized = 'Unauthorized',
  Error = 'Error',
}

const useAuth = (): [AuthState, any] => {
  const [currentUser,, loadError] = useCurrentUser();
  const [state, setState] = useState<AuthState>(AuthState.Loading);

  useEffect(() => {
    if (currentUser !== null) {
      setState(AuthState.Authorized);
    } else if (loadError !== null) {
      if (loadError.isUnauthorized) {
        setState(AuthState.Unauthorized);
      } else {
        setState(AuthState.Error);
      }
    }
  }, [currentUser, setState, loadError]);

  return [state, currentUser];
};

export default useAuth;
