import { useTranslation } from 'react-i18next';

import {
  BILLING_STATUS_PROCESSING,
  BILLING_STATUS_PAID,
  BILLING_STATUS_CANCEL,
} from '../../../../utils/constants';

import { BillingStatusInProgress, BillingStatusCanceled, BillingStatusSucceeded } from './styled';

type BillingStatusProps = {
  status: 'processing' | 'paid' | 'cancel';
};

const BillingStatus = ({ status }: BillingStatusProps) => {
  const { t } = useTranslation();

  switch (status) {
    case BILLING_STATUS_CANCEL: {
      return (
        <BillingStatusCanceled>{t('account.invoices.statuses.canceled')}</BillingStatusCanceled>
      );
    }
    case BILLING_STATUS_PAID: {
      return (
        <BillingStatusSucceeded>{t('account.invoices.statuses.succeeded')}</BillingStatusSucceeded>
      );
    }
    case BILLING_STATUS_PROCESSING:
    default: {
      return (
        <BillingStatusInProgress>{t('account.invoices.statuses.inProgress')}</BillingStatusInProgress>
      );
    }
  }
};

export default BillingStatus;
