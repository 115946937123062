import { AlertTitle } from '@material-ui/lab';
import { StyledAlert } from './styled';
import RequestError from '../../../network/RequestError';

export default ({ error }: { error: RequestError | null }) => {
  return (
    <StyledAlert severity="error">
      <AlertTitle>Error</AlertTitle>
      {error?.toString()}
      {error?.body?.detail ? (
        <div>Details: {error.body.detail}</div>
      ) : null}
    </StyledAlert>
  );
}
