import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import { checkPayment, CheckPaymentParams, PaymentParams } from '../../network';
import { PaymentModel } from '../../network/models';

type ResultData = { payment: PaymentModel } | null;

const useProcessCheckPayment = (initialParams: PaymentParams): [ResultData, boolean, Error | null, (params: CheckPaymentParams) => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useDebouncedCallback(async (params: CheckPaymentParams) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await checkPayment({ ...initialParams, ...params });
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  return [data, isLoading, error, loadData];
};

export default useProcessCheckPayment;
