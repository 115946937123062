import styled, { css } from 'styled-components';

import TableCell from '../../../../../../components/ui/TableCell';
import TableRow from '../../../../../../components/ui/TableRow';

export const StyledTableRow = styled(TableRow)<{ seen: boolean }>`
  cursor: pointer;

  ${(p) => !p.seen && css`
    ${MessageEmail}, ${MessageDate} {
      font-weight: bold;
      color: #232121;
      opacity: 1;
    }
    background-color: #f5f5f5;
  `}

  &:hover {
    background-color: #f5f5f5;
  }
`;

export const MessageItem = styled(TableCell)`
  color: #232121;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;

  padding-left: 16px;
  width: fit-content;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding-left: 0;
  }
`;

export const MessageEmail = styled.div`
  color: #232121;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const MessageSubject = styled.span`
  color: #232121;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const MessageText = styled(MessageSubject)`
  opacity: 0.6;
`;

export const MessageDate = styled.span`
  color: rgba(35, 33, 33, 0.6);
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  opacity: 0.6;
`;
