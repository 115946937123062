import styled from 'styled-components';
import { StyledTableRow } from '../CompanySecretaryTable/styled';

export const TableTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(35, 33, 33, 0.6);

  margin-left: 56px;
  margin-top: 24px;
  margin-bottom: 8px;
`;

export const StyledRow = styled(StyledTableRow)`
  padding-left: 8px;
`;
