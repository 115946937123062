import { useCallback, useState } from 'react';

import RequestError from '../../../../../network/RequestError';
import { SubmitProcessParams } from '../../../network';
import { submitProcess } from '../../../network';
import { ProcessResultModel } from '../../../network/models';

type ResultData = ProcessResultModel | null;

const useProcessSubmit = (): [
  ResultData,
  boolean,
    Error | null,
  (params: SubmitProcessParams) => void,
] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useCallback(async (params: SubmitProcessParams) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await submitProcess(params);

      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, [setIsLoading, setError]);

  return [data, isLoading, error, loadData];
};

export default useProcessSubmit;
