import React from 'react';

import { ProcessResultModel, TaskModel } from '../../network/models';

import { SERVICE_COMPANY_INCORPORATION_SLUG } from '../../utils/services';
import CompanyIncorporation from './forms/CompanyIncorporation';

export type ProcessFormProps = {
  process: ProcessResultModel;
  task: TaskModel;
  closeModal: () => void;
  loadProcess: (loader?: boolean) => void;
}

const ProcessForm = (props: ProcessFormProps) => {
  const { process: { service: { slug } } } = props;

  if (slug === SERVICE_COMPANY_INCORPORATION_SLUG) {
    return <CompanyIncorporation {...props} />;
  }

  return null;
};

export default ProcessForm;
