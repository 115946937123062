import styled from 'styled-components';

export const ProcessWrapper = styled.div`
`;

export const ProcessCreatedDate = styled.div`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  margin-bottom: 8px;
`;

export const ProcessTitle = styled.div`
  color: #232121;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 24px;
    line-height: 32px;
  }

  &.scrolled {
    ${(p) => p.theme.breakpoints.down('sm')} {
      display: none;
    }
  }
`;

export const ProcessStatusWrapper = styled.div`
  margin-top: 12px;
  margin-bottom: 32px;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  position: relative;
`;
