import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ModalItemBaseProps, openModal } from '../../../../containers/ModalsContainer';
import BaseModal from '../../../../components/Modal/BaseModal';
// import Hidden from '../../../../components/ui/Hidden';
// import { ProcessesActionButton } from '../../screens/ProcessesScreen/components/ProcessesListItemAction';
import { ProcessResultModel } from '../../network/models';
import { useProcessesContext } from '../../providers/ProcessesProvider';
import ProcessPage from '../ProcessPage';
import useProcess from '../../hooks/processes/useProcess';
import usePrevious from '../../../../hooks/usePrevious';
import useSmoothScrollToTop from '../../../../hooks/useSmoothScrollToTop';
import useQueryString from '../../../../hooks/useQueryString';
import RequestError from '../../../../network/RequestError';
import useKYCShareholders from '../../hooks/tasks/useKYCShareholders';

// import MobileMenu from '../../../../components/MobileMenu';
// import ProcessDocuments from '../ProcessDocuments';

type ProcessModalProps = {
  process: ProcessResultModel;
  isProcessLoading: boolean;
  processError: null | RequestError;
  loadProcess: (loader?: boolean) => void;
};

const ProcessModal = (props: ModalItemBaseProps & ProcessModalProps) => {
  const {
    modalLevel,
    modalIsOpen,
    modalRequestClose,
    process,
    loadProcess,
    isProcessLoading,
  } = props;

  const rootRef = useRef<HTMLDivElement | null>(null);
  const history = useHistory();
  const {
    // isDeleteProcessLoading,
    // isSubmitProcessLoading,
    deleteProcessError,
    submitProcessError,
  } = useProcessesContext();

  const [KYCShareholders] = useKYCShareholders({
    processId: process.id,
    processName: process.service.process_name,
  });

  const [isKYCShareholdersDone, setIsKYCShareholdersDone] = useState(false);

  useEffect(() => {
    if (KYCShareholders) {
      const isDone = KYCShareholders.length !== 0 && KYCShareholders.every(({ status }) => status === 'verified');
      setIsKYCShareholdersDone(isDone);
    }
  }, [KYCShareholders])

  const query = useQueryString();
  const processIdQS = query.get('process');
  const previousProcessIdQS = usePrevious(processIdQS);

  const [isScrolled, setIsScrolled] = useState(false);

  useSmoothScrollToTop({
    conditions: Boolean(deleteProcessError || submitProcessError),
    scrollElementContainer: rootRef.current,
  });

  const removeProcessQueryString = useCallback(() => {
    query.delete('process');

    const newQuery = query.toString();

    history.push({
      search: newQuery,
    });
  }, [history, query]);

  const handleCloseModal = useCallback(() => {
    modalRequestClose();
    removeProcessQueryString();
  }, [modalRequestClose, removeProcessQueryString]);

  // const handleOnDeleteProcess = useCallback(
  //   async () => {
  //     try {
  //       if (!deleteProcessError && !isDeleteProcessLoading) {
  //         handleCloseModal();
  //       }
  //     } catch (e) {}
  //   },
  //   [deleteProcessError, isDeleteProcessLoading, handleCloseModal],
  // );

  // const RightHeadActions = () => {
  //   return (
  //     <>
  //       <Hidden smDown>
  //         <ProcessesActionButton process={process} onDelete={handleOnDeleteProcess} onUpdate={loadProcess} isUpdating={isProcessLoading} outlined />
  //       </Hidden>
  //       <Hidden mdUp>
  //         {!done && (
  //           <MobileMenu>
  //             <ProcessesActionButton process={process} onDelete={handleOnDeleteProcess} onUpdate={loadProcess} isUpdating={isProcessLoading} />
  //           </MobileMenu>
  //         )}
  //       </Hidden>
  //     </>
  //   );
  // };

  // useEffect(() => {
  //   if (isSubmitProcessLoading || submitProcessError) {
  //     setDone(false);
  //   }
  // }, [setDone, isSubmitProcessLoading, submitProcessError]);

  useEffect(() => {
    if (previousProcessIdQS && !processIdQS) {
      modalRequestClose();
    }
  }, [modalRequestClose, previousProcessIdQS, processIdQS]);

  return (
    <BaseModal
      noBg
      ref={rootRef}
      open={modalIsOpen}
      level={modalLevel}
      routerModal
      onRequestClose={handleCloseModal}
      closeOnOutsideClick={false}
      loading={isProcessLoading}
      titleToHead={process.service.title}
      isScrolled={isScrolled}
      setIsScrolled={setIsScrolled}
      // rightHeadActions={<RightHeadActions />}
      content={
        <ProcessPage
          isScrolled={isScrolled}
          process={process}
          closeModal={handleCloseModal}
          loadProcess={loadProcess}
          isKYCShareholdersDone={isKYCShareholdersDone}
        />
      }
    />
  );
};

type ProcessModalControllerProps = {
  processId: number;
};

const ProcessModalController = (props: ModalItemBaseProps & ProcessModalControllerProps) => {
  const {
    processId,
    ...rest
  } = props;

  const query = useQueryString();
  const history = useHistory();

  const [process, isProcessLoading, processError, loadProcess] = useProcess({ processId });

  if (processError) {
    query.delete('process');

    const newQuery = query.toString();

    history.replace({
      search: newQuery,
    });
  }

  if (!process) {
    return null;
  }

  return (
    <ProcessModal
      {...rest}
      process={process}
      isProcessLoading={isProcessLoading}
      processError={processError}
      loadProcess={loadProcess}
    />
  );
};

export const openProcessModal = (
  props?: ProcessModalControllerProps,
): ReturnType<typeof openModal> => {
  return openModal(ProcessModalController, props, 'account');
};

export default ProcessModalController;
