import styled, { css } from 'styled-components';

import Box from '../../../../../../components/ui/Box';
import Button from '../../../../../../components/ui/Button';
import TableCell from '../../../../../../components/ui/TableCell';
import TableRow from '../../../../../../components/ui/TableRow';

export const Divider = styled.div`
  height: 1px;
`;

export const Root = styled(Box)<{ color?: string }>`
  border-radius: 12px;
  color: #232121;

  position: relative;
  min-height: 150px;

  ${(p) => p.color === 'empty' ? css`
    background: #F5F5F5;
    border: 1px dashed #DEDEDE;
    box-shadow: none;
  ` : css`
    background: #FFFFFF;
    border: none;
    box-shadow: 0 27px 80px rgba(35, 33, 33, 0.05),
      0 16px 40px rgba(35, 33, 33, 0.03),
      0 2px 16px rgba(35, 33, 33, 0.03);
  `}

  ${Divider} {
    background: #232121;
    opacity: 0.12;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 8px 16px;
  }
`;

export const ContentWrapper = styled(Box)`
  padding: 16px;
  overflow: auto;
  width: 100%;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 8px;
  }
`;

export const Title = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #232121;

  ${(p) => p.theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 24px;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Transaction = styled(TableRow)`
  padding-bottom: 16px;

  &:last-child {
    padding-bottom: 0;
  }
`;

export const TransactionColumn = styled(TableCell)`
  padding: 8px;
  border: none;
`;

export const TransactionNumber = styled(Box)`
  color: #232121;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const TransactionDate = styled(Box)`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
`;

export const StyledButton = styled(Button)`
  color: #232121;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
`;
