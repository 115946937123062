import React, { useCallback } from 'react';
import CloseIcon from '@material-ui/icons/Close';

import SidebarMenu from '../../../modules/account/components/SidebarMenu';
import { ModalItemBaseProps, openModal } from '../../../containers/ModalsContainer';
import { useCabinetContext } from '../../../containers/CabinetContainer/context';
import IconButton from '../../ui/IconButton';
import Box from '../../ui/Box';
import Grid from '../../ui/Grid';
import Button, { PrimaryButton } from '../../ui/Button';

import { PaperRoot, StyledPopover } from './styled';

const MobileMainMenuModal = (props: ModalItemBaseProps) => {
  const { modalRequestClose } = props;

  const { currentUser } = useCabinetContext();

  const onContainerClick = useCallback(
    (e) => e.stopPropagation(),
    []
  );

  return (
    <StyledPopover onClick={modalRequestClose}>
      <PaperRoot onClick={onContainerClick}>
        <Box ml={0.5} mt={1} mb={2}>
          <IconButton
            color="inherit"
            aria-label="close"
            onClick={modalRequestClose}
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Box flex={1} ml={3} position="relative">
          <SidebarMenu handleItemClick={modalRequestClose} />
        </Box>

        {!currentUser ? (
          <Box mt={3} mb={5.75}>
            <Grid container spacing={2} justifyContent="center">
              <Grid item>
                <Button>Log in</Button>
              </Grid>
              <Grid item>
                <PrimaryButton>Sign up</PrimaryButton>
              </Grid>
            </Grid>
          </Box>
        ) : null}
      </PaperRoot>
    </StyledPopover>
  );
}

export const openMobileMainMenuModal = (): ReturnType<typeof openModal> => {
  return openModal(MobileMainMenuModal, null, 'account');
};

export default MobileMainMenuModal;
