import styled from 'styled-components';

import TableCell from '../../../../../../components/ui/TableCell';
import TableRow from '../../../../../../components/ui/TableRow';

export const ProcessRow = styled(TableRow)`
  &:hover {
    box-shadow: 0 3px 10px -8px #232121;
  }
`;

export const ProcessItem = styled(TableCell)`
  padding: 28px 28px 28px 0;
  cursor: pointer;
`;

export const ProcessTitle = styled.div`
  color: #232121;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;

  width: 100%;
`;

export const ProcessDescription = styled.div`
  margin-top: 4px;

  color: #4A5351;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  opacity: 0.6;
  max-width: 500px;

  p {
    margin: 0;
  }
`;

export const ProcessStatusWrapper = styled(TableCell)`
  font-weight: 600;

  position: relative;
  padding-left: 0;
  width: 20%;
`;

export const ProcessTime = styled(TableCell)`
  color: rgba(35, 33, 33, 0.6);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.45px;

  margin-bottom: 4px;
  padding-left: 0;
  width: 20%;
`;
