import React, { useCallback, useEffect, useState } from 'react';
import { FormSpy, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/img/services/close-icon.svg';
import { NUMBERING } from '../../../../../../utils/constants';
import {
  composeValidators,
  useValidators,
  UseValidatorsReturnProps,
} from '../../../../../../../service/utils/validation';

import Box from '../../../../../../../../components/ui/Box';
import Grid from '../../../../../../../../components/ui/Grid';
import FormCheckbox from '../../../../../../../../components/ui/FormCheckbox';
import FormTextInput from '../../../../../../../../components/ui/FormTextInput';

import LegalRepresentatives from '../LegalRepresentatives';
import Citizenship from '../Citizenship';

import { generateTab, addNewTab, removeExistentTab } from '../../utils/tabs';
import { Birthday, Country, Gender, LegalType } from '../fields';

import {
  TabsWrapper,
  StyledTabs,
  ChipsWrapper,
  ChipWrapper,
  StyledChip,
  StyledButton,
  TabPanels,
  FormSubheader,
} from './styled';

type FormTabState = {
  id: string;
  name: string;
};

type PeopleFormProps = {
  emailRequired?: boolean;
  idSeparatorNumber: number;
  formPrefix: string; // shareholders | management | directorship
  numbering: 'digits' | 'alphabet';
  minTabs?: number;
  maxTabs?: number;
  tFormPrefix?: string;
  tPrefix: string;
};

function PeopleForm(props: PeopleFormProps) {
  const {
    emailRequired = false,
    idSeparatorNumber,
    formPrefix,
    numbering = 'digits',
    minTabs = 1,
    maxTabs = 50,
    tFormPrefix,
    tPrefix,
  } = props;

  const { t } = useTranslation();
  const validators: UseValidatorsReturnProps = useValidators();

  const formState = useFormState();
  const tabName = t(`${tPrefix}.${tFormPrefix || formPrefix}.one`);

  const existentTabsState: FormTabState[] = [];

  Object.keys(formState.values)
    .forEach(key => {
      if (key.includes(`${formPrefix}_`)) {
        const id = key.split('_')[idSeparatorNumber];
        const legalType = formState.values[`${formPrefix}_${id}_legal_type`];
        const isEntityLegalType = legalType === t(`${tPrefix}.peopleForm.entity.label`);
        const exist = existentTabsState.find(tab => tab.id === id);

        if (!exist) {
          const individualName = formState.values[`${formPrefix}_${id}_name`];
          const individualSurname = formState.values[`${formPrefix}_${id}_surname`];
          const entityName = formState.values[`${formPrefix}_${id}_entity_name`];

          const newTabName = isEntityLegalType
            ? entityName || null
            : (!individualName || !individualSurname) ? null : `${individualName} ${individualSurname}`;

          // Make class management with A + B + B etc.
          const numberString = numbering === 'alphabet' && existentTabsState.length > 1
            ? NUMBERING[numbering][1]
            : NUMBERING[numbering][existentTabsState.length];

          existentTabsState.push({
            id,
            name: newTabName || `${tabName} ${numberString}`,
          });
        }
      }
    });

  const defaultTabs = existentTabsState.length
    ? existentTabsState
    : [generateTab({ tabName, index: 0, isDefault: true, numbering })];

  const [tabs, setTabs] = useState<FormTabState[]>(defaultTabs);
  const [activeTab, setActiveTab] = useState(defaultTabs[0]);

  const showAddNewButton = !!tabs.length && (!maxTabs || (maxTabs && (maxTabs > tabs.length)));

  const addTab = addNewTab({ tabs, setActiveTab, setTabs, maxTabs, tabName, numbering });

  const removeTab = removeExistentTab({ tabs, setActiveTab, setTabs });

  const handleSetActiveTab = useCallback((tab: FormTabState) => () => {
    setActiveTab(tab);
  }, []);

  const renderTab = (tab: FormTabState) => {
    const tabId = tab.id;
    const isActive = tabId === activeTab.id;

    const removeProps = tabs.length > minTabs ? { onDelete: removeTab(tabId) } : {};

    return (
      <ChipWrapper isActive={isActive} key={tab.id}>
        <StyledChip
          id={tab.id}
          onClick={handleSetActiveTab(tab)}
          label={tab.name}
          deleteIcon={<DeleteIcon/>}
          {...removeProps}
        />
      </ChipWrapper>
    );
  };

  useEffect(() => {
    tabs.forEach((tab, index) => {
      const tabIndividualNameInputId = `${formPrefix}_${tab.id}_name`;
      const tabIndividualSurnameInputId = `${formPrefix}_${tab.id}_surname`;
      const tabEntityNameInputId = `${formPrefix}_${tab.id}_entity_name`;
      const tabLegalType = `${formPrefix}_${tab.id}_legal_type`;

      const individualName = formState.values[tabIndividualNameInputId];
      const individualSurname = formState.values[tabIndividualSurnameInputId];
      const entityName = formState.values[tabEntityNameInputId];
      const legalType = formState.values[tabLegalType];

      // if the (name and surname) or entity name are empty use default tab name
      const newTabName = legalType === t(`${tPrefix}.peopleForm.individual.label`)
        ? (!individualName || !individualSurname) ? null : `${individualName ? `${individualName} ` : ''}${individualSurname || ''}`
        : entityName || null;

      const numberString = numbering === 'alphabet' && index > 1
        ? NUMBERING[numbering][1]
        : NUMBERING[numbering][index];

      tab.name = newTabName
        || `${tabName} ${numberString}`;
    });
  }, [formPrefix, formState.values, numbering, t, tPrefix, tabName, tabs]);

  useEffect(() => {
    if (tabs.length < minTabs) {
      const restTabs = minTabs - tabs.length;

      for (let i = 0; i < restTabs; i++) {
        addTab();
      }
    }
  }, [addTab, maxTabs, minTabs, numbering, tabName, tabs]);

  useEffect(() => {
    Object.keys(formState.values).forEach((key) => {
      if (key.includes(`${formPrefix}_`)) {
        const foundTab = tabs.find((tab) => key.includes(`${tab.id}`));

        if (!foundTab) {
          delete formState.values[key];
        }
      }
    });
  }, [formPrefix, formState.values, tabs, tabs.length]);

  return (
    <TabsWrapper>
      <StyledTabs aria-label={`tabs of ${tabName}s`}>
        <ChipsWrapper>
          {tabs.map(renderTab)}
        </ChipsWrapper>
        {showAddNewButton ? (
          <StyledButton
            onClick={addTab}
            startIcon={<AddIcon/>}
            size="small"
          >{t(`${tPrefix}.peopleForm.addButton`)}</StyledButton>
        ) : null}
      </StyledTabs>

      <TabPanels>
        {tabs.map((tab) => {
          const tabId = tab.id;
          const isActive = tabId === activeTab.id;

          return (
            <Box key={tabId} display={isActive ? 'flex' : 'none'} flexDirection="column">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box ml={1}>
                    <LegalType
                      formPrefix={`${formPrefix}_${tabId}`}
                      tPrefix={tPrefix}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormSpy>
                    {props => {
                      const isEntityLegalType = props.values[`${formPrefix}_${tabId}_legal_type`] === t(`${tPrefix}.peopleForm.entity.label`);
                      const isIndividualLegalType = props.values[`${formPrefix}_${tabId}_legal_type`] === t(`${tPrefix}.peopleForm.individual.label`);
                      const isSameAddressChecked = props.values[`${formPrefix}_${tabId}_current_same`];

                      return (
                        <>
                          {isIndividualLegalType ? (
                            <>
                              <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <Box mt={1}>
                                    <Gender
                                      formPrefix={`${formPrefix}_${tabId}`}
                                      tPrefix={tPrefix}
                                      validate={validators.is_required}
                                    />
                                  </Box>
                                </Grid>

                                <Grid item xs={false} sm={false} md={6} lg={6} xl={6}/>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <FormTextInput
                                    name={`${formPrefix}_${tabId}_name`}
                                    label={`${t(`${tPrefix}.peopleForm.individual.name`)} *`}
                                    validate={validators.is_required}
                                    initialValue=""
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <FormTextInput
                                    name={`${formPrefix}_${tabId}_surname`}
                                    label={`${t(`${tPrefix}.peopleForm.individual.surname`)} *`}
                                    validate={validators.is_required}
                                    initialValue=""
                                  />
                                </Grid>


                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <Birthday
                                    formPrefix={`${formPrefix}_${tabId}`}
                                    tPrefix={tPrefix}
                                  />
                                </Grid>

                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                  <FormTextInput
                                    name={`${formPrefix}_${tabId}_birthplace`}
                                    label={`${t(`${tPrefix}.peopleForm.individual.birthplace`)} *`}
                                    validate={validators.is_required}
                                    initialValue=""
                                  />
                                </Grid>
                              </Grid>

                              {formPrefix === 'shareholders' ? (
                                <Citizenship
                                  tPrefix={tPrefix}
                                  parentId={`${formPrefix}_${tabId}`}
                                  idSeparatorNumber={idSeparatorNumber ? idSeparatorNumber + 2 : 4}
                                  maxTabs={formPrefix === 'shareholders' ? 5 : 1}
                                  showTabs={formPrefix === 'shareholders'}
                                />
                              ) : null}
                            </>
                          ) : (
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormTextInput
                                  name={`${formPrefix}_${tabId}_entity_name`}
                                  label={`${t(`${tPrefix}.peopleForm.entity.name`)} *`}
                                  validate={validators.is_required}
                                  initialValue=""
                                />
                              </Grid>

                              <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <FormTextInput
                                  name={`${formPrefix}_${tabId}_organization_number`}
                                  label={`${t(`${tPrefix}.peopleForm.entity.organizationNumber`)} *`}
                                  validate={validators.is_required}
                                  initialValue=""
                                />
                              </Grid>
                            </Grid>
                          )}

                          <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <FormSubheader>{t(`${tPrefix}.peopleForm.registeredAddress.label`)}</FormSubheader>
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                              <FormTextInput
                                name={`${formPrefix}_${tabId}_city`}
                                label={`${t(`${tPrefix}.peopleForm.registeredAddress.city`)} *`}
                                validate={validators.is_required}
                                initialValue=""
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                              <FormTextInput
                                name={`${formPrefix}_${tabId}_postal_code`}
                                label={`${t(`${tPrefix}.peopleForm.registeredAddress.postalCode`)} *`}
                                validate={validators.is_required}
                                initialValue=""
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                              <Country
                                name={`${formPrefix}_${tabId}_country`}
                                tPrefix={tPrefix}
                                validate={validators.is_required}
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                              <FormTextInput
                                name={`${formPrefix}_${tabId}_address`}
                                label={`${t(`${tPrefix}.peopleForm.registeredAddress.address`)} *`}
                                validate={validators.is_required}
                                initialValue=""
                              />
                            </Grid>
                          </Grid>

                          {(formPrefix === 'shareholders' && isIndividualLegalType) ? (
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSubheader>{t(`${tPrefix}.peopleForm.individual.currentAddress.label`)}</FormSubheader>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <Box mt={-1}>
                                  <FormCheckbox
                                    name={`${formPrefix}_${tabId}_current_same`}
                                    label={t(`${tPrefix}.peopleForm.individual.currentAddress.sameAddress`)}
                                  />
                                </Box>
                              </Grid>
                              {!isSameAddressChecked ? (
                                <>
                                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <FormTextInput
                                      name={`${formPrefix}_${tabId}_current_city`}
                                      label={`${t(`${tPrefix}.peopleForm.individual.currentAddress.city`)} *`}
                                      validate={validators.is_required}
                                      initialValue=""
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <FormTextInput
                                      name={`${formPrefix}_${tabId}_current_postal_code`}
                                      label={`${t(`${tPrefix}.peopleForm.individual.currentAddress.postalCode`)} *`}
                                      validate={validators.is_required}
                                      initialValue=""
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <Country
                                      name={`${formPrefix}_${tabId}_current_country`}
                                      tPrefix={tPrefix}
                                      validate={validators.is_required}
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormTextInput
                                      name={`${formPrefix}_${tabId}_current_address`}
                                      label={`${t(`${tPrefix}.peopleForm.individual.currentAddress.address`)} *`}
                                      validate={validators.is_required}
                                      initialValue=""
                                    />
                                  </Grid>
                                </>
                              ) : null}
                            </Grid>
                          ) : null}

                          {isEntityLegalType ? (
                            <LegalRepresentatives
                              tPrefix={tPrefix}
                              parentId={`${formPrefix}_${tabId}`}
                              idSeparatorNumber={idSeparatorNumber ? idSeparatorNumber + 3 : 4}
                            />
                          ) : null}

                          {emailRequired ? (
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormSubheader>{t(`${tPrefix}.${formPrefix}.contacts.label`)}</FormSubheader>
                              </Grid>

                              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <FormTextInput
                                  name={`${formPrefix}_${tabId}_email`}
                                  label={`${t(`${tPrefix}.${formPrefix}.contacts.email`)} *`}
                                  validate={composeValidators(validators.email, validators.is_required)}
                                  type="email"
                                  initialValue=""
                                />
                              </Grid>
                            </Grid>
                          ) : null}
                        </>
                      );
                    }}
                  </FormSpy>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </TabPanels>
    </TabsWrapper>
  );
}

export default PeopleForm;
