import { useCallback } from 'react';
import TablePagination from '@material-ui/core/TablePagination';

type PaginationProps = {
  page: number;
  pageSize: number;
  showRowsPerPage?: boolean;
  count?: number | null;
  hasNext?: boolean;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
};

const Pagination = (props: PaginationProps) => {
  const { page, pageSize, showRowsPerPage = true, count = null, hasNext = null, onChangePage, onChangePageSize } = props;

  const handleChangePage = useCallback((event, newPage) => {
    onChangePage(newPage + 1);
  }, [onChangePage]);

  const handleChangeRowsPerPage = useCallback((event) => {
    onChangePageSize(parseInt(event.target.value, 10));
    onChangePage(1);
  }, [onChangePage, onChangePageSize]);

  const nextIconButtonProps: { [key: string]: any } = {};
  if (count === null && hasNext !== null) {
    nextIconButtonProps.disabled = !hasNext;
  }

  let labelDisplayedRows = undefined;
  if (count === null) {
    labelDisplayedRows = ({ from, to }: { from: number; to: number }) => `${from}-${to}`;
  }

  // hide pagination if there is only one page and count less than page size
  if (page === 1 && count !== null && count <= pageSize) {
    return null;
  }

  return (
    <TablePagination
      component="div"
      count={count === null ? -1 : count}
      page={page - 1}
      rowsPerPage={pageSize}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      nextIconButtonProps={nextIconButtonProps}
      labelDisplayedRows={labelDisplayedRows}
      rowsPerPageOptions={showRowsPerPage ? [5, 10, 25, 50] : []}
    />
  );
};

export default Pagination;
