import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import { getInvoice, PaymentParams } from '../../network';

type ResultData = any;

export type InvoiceData = {
  tax: boolean;
  name: string;
  city: string;
  postal_code: string;
  country: string;
  address: string;
};

const useProcessPaymentInvoice = (params: PaymentParams): [ResultData, boolean, Error | null, (data: InvoiceData) => any] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useDebouncedCallback(async (data: InvoiceData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getInvoice({ ...params, data });

      setData(response);
      setIsLoading(false);

      return response;
    } catch (e) {
      setIsLoading(false);
      setError(e);
    }
  }, 500, { leading: true });

  return [data, isLoading, error, loadData];
};

export default useProcessPaymentInvoice;
