import React from 'react';
import { useTranslation } from 'react-i18next';

import { OutlinedButton, PrimaryButton } from '../../../../components/ui/Button';

import { SuccessActions, SuccessDescription, SuccessImage, SuccessTitle, SuccessWrapper } from './styled';

export type SuccessPageProps = {
  closeModal?: () => void;
  title?: string;
  description?: string;
  actions?: React.ReactNode | React.ReactNode[];
};

const SuccessPage = (props: SuccessPageProps) => {
  const { actions, closeModal, description, title } = props;
  const { t } = useTranslation();

  return (
    <SuccessWrapper>
      <SuccessTitle>{title || t('account.services.checkout.success.title')}</SuccessTitle>
      <SuccessDescription>{description || t('account.services.checkout.success.description')}</SuccessDescription>
      <SuccessActions>
        {actions || (
          <>
            <OutlinedButton onClick={closeModal}>
              {t('account.services.checkout.success.buttons.otherServices')}
            </OutlinedButton>
            <PrimaryButton href={'/cabinet/account'}>
              {t('account.services.checkout.success.buttons.account')}
            </PrimaryButton>
          </>
        )}
      </SuccessActions>
      <SuccessImage />
    </SuccessWrapper>
  );
};

export default SuccessPage;
