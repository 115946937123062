import styled from 'styled-components';

import Table from '../../../../../../components/ui/Table';
import TableCell from '../../../../../../components/ui/TableCell';
import TableRow from '../../../../../../components/ui/TableRow';
import { StyledIcon } from '../ItemLink/styled';

export const TableWrapper = styled.div`
  width: 100%;
`;

export const StyledTable = styled(Table)`
  min-width: 100%;

  border-bottom: 1px solid rgba(35, 33, 33, 0.12);
`;

export const StyledTableRow = styled(TableRow)`
  ${StyledIcon} {
    display: flex;
    width: 22px;
    min-width: 22px;
    justify-content: center;

    svg {
      width: 100%;
      fill: rgba(35, 33, 33, 0.6);
    }
  }
  &:hover {
    ${StyledIcon} {
      svg {
        fill: rgba(35, 33, 33, 1);
      }
    }
  }
`;

export const StyledTableCell = styled(TableCell)`
  &.MuiTableCell-root {
    padding: 24px 16px;
  }
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 56px;
`;

export const EmptyText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.444444px;
  color: rgba(35, 33, 33, 0.6);
`;

export const EmptyBg = styled.img`
  margin-top: 100px;
  max-width: 320px;
  width: auto;
`;
