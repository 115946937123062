import { useTranslation } from 'react-i18next';

export const composeValidators = (...validators: any) => (value: any): any =>
  validators.reduce((error: any, validator: any) => error || validator(value), undefined);

export type UseValidatorsReturnProps = {
  numbers_only: (value: any) => string | undefined;
  letters_only: (value: any) => string | undefined;
  is_required: (value: any) => string | undefined;
  date: (value: any) => string | undefined;
  float: (value: any) => string | undefined;
  email: (value: any) => string | undefined;
  minimumNumber: (min: number, name: string, message?: string) => (value: number | string) => string | undefined;
};

export const useValidators = (): UseValidatorsReturnProps => {
  const { t } = useTranslation();

  return {
    numbers_only: (value: any) => value && isNaN(value)
      ? t('account.services.checkout.form.validation.onlyNumbers')
      : undefined,
    letters_only: (value: any) => value && !value.match(/^[a-zA-Z]+$/g)
      ? t('account.services.checkout.form.validation.onlyLetters')
      : undefined,
    is_required: (value: any) => !value
      ? t('account.services.checkout.form.validation.required')
      : undefined,
    date: (value: any) => value && !isDateValid(value)
      ? t('account.services.checkout.form.validation.date')
      : undefined,
    float: (value: any) => value && isNaN(value)
      ? t('account.services.checkout.form.validation.float')
      : undefined,
    email: (value: any) => value && !value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
      ? t('account.services.checkout.form.validation.email')
      : undefined,
    minimumNumber: (min: number, name: string, message?: string) => (value: number | string) => value && Number(value) < min
      ? message || t('account.services.checkout.form.validation.minimumNumber', { min, name })
      : undefined,
  };
}

function isDateValid(date: string): boolean {
  const [year, month, day] = date.split('-');
  const d = new Date(Number(year), Number(month) - 1, Number(day));
  return d.getFullYear() === Number(year) && d.getMonth() + 1 === Number(month) && d.getDate() === Number(day);
}
