import React from 'react';
import { Link, Redirect } from 'react-router-dom';
// import Button from '../../components/ui/Button';

const HomeScreen = () => {
  return (
    <div>
      <Redirect from={'/'} to={'/cabinet'} exact />
      <h1>Home Screen</h1>
      <Link to="/cabinet">Cabinet</Link>
      {/* <div>
        <Button>Services</Button>
      </div> */}
    </div>
  )
};

export default HomeScreen;
