import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalItemBaseProps, openModal } from '../../../../containers/ModalsContainer';
import Box from '../../../../components/ui/Box';
import BaseModal from '../../../../components/Modal/BaseModal';
import { sumSubElementId } from '../../utils/constants';
import mainClient from '../../../../network/mainClient';
import launchWebSdk from '../../utils/launchWebSdk';
import launchOneboardSdk from '../../utils/launchOneboardSdk';

import { Title } from './styled';

type IConfirmDialogProps = {
  title?: string;
  subTitle?: string | React.ReactElement;
  okButtonText?: string;
  cancelButtonText?: string;
  currentUser?: any;
};

const SumSubModal = (props: ModalItemBaseProps & IConfirmDialogProps) => {
  const {
    modalLevel,
    modalIsOpen,
    modalRequestClose,
    currentUser,
  } = props;

  const { t } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);

  const getNewAccessToken = async () => {
    const { token } = await mainClient.account.generateVerificationData();
    return token ? token.token : null;
  };

  const fetchDataAndLaunch = useCallback(async (): Promise<void> => {
    const verificationData = await mainClient.account.generateVerificationData();

    if (verificationData.type === 'sumsub') {
      launchWebSdk({
        accessToken: verificationData.data.token.token,
        applicantEmail: currentUser?.email,
        getNewAccessToken,
      });
    } else if (verificationData.type === 'oneboard') {
      launchOneboardSdk(
        `#${sumSubElementId}`,
        {
          appPublicKey: verificationData.data.publicKey,
          verificationToken: verificationData.data.verificationToken.id,
        }
      )
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      fetchDataAndLaunch();
    }
  }, [currentUser, fetchDataAndLaunch]);

  useEffect(() => {
    if (isScrolled) {
      titleRef?.current?.classList.add('scrolled');
    } else {
      titleRef?.current?.classList.remove('scrolled');
    }
  }, [isScrolled]);

  return (
    <BaseModal
      noBg
      open={modalIsOpen}
      level={modalLevel}
      onRequestClose={modalRequestClose}
      titleToHead={t('account.verification.title')}
      isScrolled={isScrolled}
      setIsScrolled={setIsScrolled}
      content={
        <>
          <Box mb={3}>
            <Title ref={titleRef}>{t('account.verification.title')}</Title>
          </Box>
          <div id={sumSubElementId}/>
        </>
      }
    />
  );
}

export const openSumSubModal = (
  props?: IConfirmDialogProps,
): ReturnType<typeof openModal> => {
  return openModal(SumSubModal, props, 'cabinet');
};

export default SumSubModal;
