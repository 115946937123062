/* #TODO: fix TS */
export default class Listener {
  _eventHandlers: any = {}

  on(event: any, handler: any) {
    if (!(event in this._eventHandlers)) {
      this._eventHandlers[event] = []
    }

    this._eventHandlers[event].push(handler)
  }

  off(event: any, handler: any) {
    if (!(event in this._eventHandlers)) {
      return
    }

    this._eventHandlers[event] = this._eventHandlers[event].filter((x: any) => x !== handler)
  }

  trigger(event: any, data: any) {
    if (!(event in this._eventHandlers)) {
      return
    }
    this._eventHandlers[event].forEach((handler: any) => handler(data))
  }
}
