import request from '../../../network/mainClient/request';
import { CheckoutProcessDataModel } from './models';

export type GetProcessesListParams = {
  page?: number;
  pageSize?: number;
}

export const getAllProcessesList = () => request({
  url: '/api/v1/processes/',
}).then((result) => result.data);

export const getProcessesList = ({ page = 1, pageSize = 30 }: GetProcessesListParams) => request({
  url: '/api/v1/processes/',
  urlParams: {
    page,
    page_size: pageSize,
  },
}).then((result) => result.data);

export interface BaseProcessParams {
  processId: number;
}

export type CreateProcessParams = {
  serviceId: number;
};

export const createProcess = ({ serviceId }: CreateProcessParams) => request({
  url: '/api/v1/processes/base_process/start/',
  method: 'POST',
  body: {
    service: serviceId,
  },
  contentType: 'json',
  addAccountToBody: true,
}).then((result) => result.data);

export type GetProcessParams = {
  processId: number;
};

export const getProcess = ({ processId }: GetProcessParams) => request({
  url: `/api/v1/processes/${processId}/`,
  method: 'GET',
  contentType: 'json',
}).then((result) => result.data);

export const deleteRequest = ({ processId }: BaseProcessParams) => request({
  url: `/api/v1/processes/${processId}/`,
  method: 'DELETE',
}).then((result) => result.data);

export interface UpdateProcessParams extends BaseProcessParams {
  data: CheckoutProcessDataModel;
}

export const updateProcess = ({ processId, data }: UpdateProcessParams) => request({
  url: `/api/v1/processes/${processId}/`,
  method: 'PATCH',
  body: data,
  contentType: 'json',
}).then((result) => result.data);

export interface SubmitProcessParams extends BaseProcessParams {
  taskId: number;
  taskName: string;
  processName: string;
  value: any;
  form_submitted: boolean;
}

export const submitProcess = ({ processId, taskId, taskName, processName, value, form_submitted }: SubmitProcessParams) => request({
  url: `/api/v1/processes/${processName}/${processId}/${taskName}/${taskId}/`,
  method: 'POST',
  contentType: 'json',
  body: {
    value,
    form_submitted,
  },
}).then((result) => result.data);
