import React from 'react';

import { TaskModel } from '../../network/models';

import {
  StepWrapper,
  StepNumber,
  StepTitle,
  StepDescription,
  StepIcon,
} from './styled';

export type StepProps = {
  step: StepParams;
  index: number;
};

export type StepParams = Partial<TaskModel & {
  title: string;
  description: string;
}>;

const Step = (props: StepProps) => {
  const { step, index } = props;

  const { status, title, description } = step;

  return (
    <StepWrapper status={status}>
      <StepNumber>Step {index + 1}</StepNumber>
      <StepTitle status={status}>{title}</StepTitle>
      <StepDescription>{description}</StepDescription>
      <StepIcon status={status}/>
    </StepWrapper>
  );
};

export default Step;
