import styled from 'styled-components';

export const ProcessesListItem = styled.div`
  display: flex;
  padding: 16px 0;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(35, 33, 33, 0.12);
  }
`;

export const ProcessMobile = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ProcessTimeMobile = styled.div`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  margin-top: 2px;
`;

export const ProcessTitleMobile = styled.div`
  color: #232121;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;

  margin-top: 8px;
`;

export const ProcessStatusMobile = styled.div`
  color: #232121;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
  text-transform: capitalize;

  margin-top: 4px;
`;

export const ProcessMenuMobile = styled.div`
`;
