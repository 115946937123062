import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import langs from './langs';

const DEFAULT_LANG = 'en';

export const getAvailableLanguages = () => Object.keys(langs);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: langs,
    supportedLngs: getAvailableLanguages(),
    fallbackLng: DEFAULT_LANG,

    interpolation: {
      escapeValue: false
    }
  });
