import styled from 'styled-components';

import Select from '../../../../../../components/ui/Select';
import MenuItem from '../../../../../../components/ui/MenuItem';
import InputBase from '../../../../../../components/ui/InputBase';
import Button from '../../../../../../components/ui/Button';

export const VatWrapper = styled.div`
  border: 1px solid #ABABAB;
  border-radius: 12px;
  overflow: hidden;

  margin-top: 16px;
  min-width: 272px;
  width: 100%;
  height: 56px;
  display: flex;
`;

export const FormSubheader = styled.div`
  color: #232121;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 24px;
  margin-bottom: -8px;
`;

export const StyledSelect = styled(Select)`
  &.MuiOutlinedInput-root {
    border: none;
    border-radius: 12px 0 0 12px;
    background: #F5F5F5;

    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.15px;
    color: rgba(35, 33, 33, 0.6);

    display: inline-flex;
    flex-direction: row;
    padding: 0;

    &.Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
      border-color: #DEDEDE;
      border-right: 1px solid #DEDEDE;
    }
  }

  & .MuiSelect-root.MuiSelect-select {
    background: #F5F5F5;
    padding: 16px 40px 16px 16px;

    ${({ theme }) => theme.breakpoints.down('sm')} {
      padding: 16px 32px 16px 16px;
    }

    &:hover, &:focus, &:active {
      border-radius: 12px 0 0 12px;

      & ~ fieldset {
        border-right: 1px solid #DEDEDE;
      }
    }
  }

  & .MuiSelect-icon {
    ${({ theme }) => theme.breakpoints.down('sm')} {
      right: 5px;
    }
  }

  fieldset {
    border: none;
    border-right: 1px solid #DEDEDE;
    border-radius: 12px 0 0 12px;
  }
`;

export const StyledMenuItem = styled(MenuItem)``;

export const StyledTextField = styled(InputBase)`
  border: none;
  border-radius: 12px;
  padding: 11px 24px;
  width: 100%;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #232121;

  &::placeholder {
    opacity: 0.6;
  }

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 11px 16px;
  }
`;

export const StyledSubmitButton = styled(Button)`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(35, 33, 33, 1);
  padding: 16px 24px;
  border-radius: 0 12px 12px 0;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 16px;
    min-width: auto;
  }
`;

export const NotValid = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.5px;
  color: #D95050;
`;

export const NotValidMessage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #232121;

  margin-top: 12px;
`;

export const VerifyingSubmitWrapper = styled.div`
  padding: 16px 24px;
  display: flex;
  align-content: center;
  justify-content: flex-end;
  min-width: fit-content;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 38px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: column-reverse;

    & button {
      margin-top: 24px;

      &:last-child {
        margin-top: 0;
      }
    }
  }
`;

export const InvoiceCreated = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 16px;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    justify-content: center;
  }
`;
