import React from 'react';
import { Field } from 'react-final-form';

import { onlyNumbersRegex } from '../../../utils/constants';

import { Props } from '../TextField';
import FormHelperText from '../FormHelperText';
import { StyledTextField } from './styled';

export type FormTextInputProps = Props & {
  name: string;
  label?: string;
  initialValue?: string;
  validate?: (value: any) => any;
  onlyNumbers?: boolean;
};

const FormTextInput = (props: FormTextInputProps) => {
  const { name, onlyNumbers, validate, initialValue, ...restProps } = props;
  return (
    <Field
      name={name}
      validate={validate}
      initialValue={initialValue}
      render={({ input, meta }) => (
        <>
          <StyledTextField
            {...input}
            onChange={(event) => {
              const value = event.target.value;

              if (onlyNumbers && value && !value.match(onlyNumbersRegex)) {
                return;
              }

              input.onChange(event);
            }}
            fullWidth
            error={!!((meta.active || meta.touched) && meta.error)}
            variant="outlined"
            {...restProps}
          />
          {meta.error && (meta.active || meta.touched) && <FormHelperText>{meta.error}</FormHelperText>}
        </>
      )}
    />
  );
};

export default FormTextInput;
