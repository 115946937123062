import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { makeTextShorter } from '../../../../../../utils/helpers';
import useDateLib from '../../../../../../hooks/useDateLib';
import ProcessStatus from '../../../../../process/components/ProcessStatus';
import getShowProcessesActions from '../../../../utils/getShowProcessesActions';
import { ProcessesListItemProps } from '../ProcessesList';
import { ProcessesActionButton } from '../ProcessesListItemAction';

import {
  ProcessDescription,
  ProcessItem,
  ProcessRow,
  ProcessStatusWrapper,
  ProcessTime,
  ProcessTitle,
} from './styled';

const ProcessesListItemDesktop = (props: ProcessesListItemProps) => {
  const { process } = props;
  const history = useHistory();

  const [openAction, setOpenAction] = useState<boolean>(false);
  const { getCreatedDate } = useDateLib();

  const handleShowOpenAction = useCallback(() => {
    setOpenAction(true);
  }, [setOpenAction]);

  const handleHideOpenAction = useCallback(() => {
    setOpenAction(false);
  }, [setOpenAction]);

  const openRequest = useCallback(() => {
      history.push({
        search: `process=${process.id}`,
      });
    },
    [history, process.id],
  );

  const showActions = getShowProcessesActions(process);
  const date = getCreatedDate(process.create_datetime);

  return (
    <ProcessRow onMouseEnter={handleShowOpenAction} onMouseLeave={handleHideOpenAction}>
      <ProcessItem component="th" scope="row" onClick={openRequest}>
        <ProcessTitle>{process.service.title}</ProcessTitle>
        <ProcessDescription dangerouslySetInnerHTML={{ __html: makeTextShorter(process.service.description) }} />
      </ProcessItem>
      <ProcessStatusWrapper>
        <ProcessStatus {...process} />
      </ProcessStatusWrapper>
      <ProcessTime>
        {
          showActions && openAction
            ? <ProcessesActionButton process={process} />
            : date
        }
      </ProcessTime>
    </ProcessRow>
  );
};

export default ProcessesListItemDesktop;
