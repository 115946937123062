import styled from 'styled-components';
import BgImg from '../../../../assets/img/backgrounds/account-create-bg.png';
import Grid from '../../../../components/ui/Grid';

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;

  flex: 1;
`;

export const FormGrid = styled(Grid)`
  flex: 1;
  margin-bottom: 16px; /* indent fix */
`;

export const FormHeader = styled.div`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #232121;

  margin-bottom: 24px;

  ${({ theme }) => theme.breakpoints.down('xs')} {
    margin-bottom: 16px;
  }
`;

export const FormSubtitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #232121;
`;

export const BackgroundImg = styled.div`
  width: 100%;
  max-width: 400px;
  height: 400px;
  background-image: url(${BgImg});
  background-size: contain;
  background-position: 50% 100%;
  background-repeat: no-repeat;
`;
