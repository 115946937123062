import React from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';

import useDateLib from '../../../../../../hooks/useDateLib';
import { getFormattedTotalAmount } from '../../../../utils/billings';

import TableRow from '../../../../../../components/ui/TableRow';

import { BillingsListItemProps } from '../BillingsList';
import BillingStatus from '../BillingStatus';

import {
  BillingItem,
  BillingDate,
  DownloadButton,
} from './styled';

const BillingsListItemDesktop = (props: BillingsListItemProps) => {
  const { billing: { number, created, description, amount, receipt_url, status } } = props;

  const { getCreatedDate } = useDateLib();
  const date = getCreatedDate(created);
  const total = getFormattedTotalAmount(amount);

  return (
    <TableRow>
      <BillingItem><BillingDate>{date}</BillingDate></BillingItem>
      {description ? <BillingItem>{description}</BillingItem> : null}
      <BillingItem><BillingStatus status={status} /></BillingItem>
      <BillingItem>€{total}</BillingItem>
      <BillingItem>{number}</BillingItem>
      <BillingItem>
        {receipt_url && (
          <DownloadButton
            aria-label="more"
            aria-controls="menu"
            aria-haspopup="true"
            onClick={() => window.open(receipt_url, '_blank')}
          >
            <DownloadIcon />
          </DownloadButton>
        )}
      </BillingItem>
    </TableRow>
  );
};

export default BillingsListItemDesktop;
