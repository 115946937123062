import React, { useState } from 'react';

import { ModalItemBaseProps, openModal } from '../../../../containers/ModalsContainer';
import BaseModal from '../../../../components/Modal/BaseModal';
import ActiveServicesScreen from '../../screens/ActiveServicesScreen';
import { ServiceResultModel } from '../../network/models';

type ActiveServiceModalProps = {
  service: ServiceResultModel;
  readOnly?: boolean;
};

const ActiveServiceModal = (props: ModalItemBaseProps & ActiveServiceModalProps) => {
  const {
    modalLevel,
    modalIsOpen,
    modalRequestClose,
    service,
    readOnly = false,
  } = props;

  const [isScrolled, setIsScrolled] = useState(false);

  return (
    <BaseModal
      noBg
      open={modalIsOpen}
      level={modalLevel}
      onRequestClose={modalRequestClose}
      titleToHead={service.title}
      isScrolled={isScrolled}
      setIsScrolled={setIsScrolled}
      content={<ActiveServicesScreen isScrolled={isScrolled} service={service} readOnly={readOnly} />}
    />
  );
}

export const openActiveServiceModal = (
  props?: ActiveServiceModalProps,
): ReturnType<typeof openModal> => {
  return openModal(ActiveServiceModal, props, 'account');
};

export default ActiveServiceModal;
