import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import { verifyVAT, VerifyVATProps } from '../../network';

type ResultData = boolean | null;

const useVerifyVAT = (): [ResultData, boolean, Error | null, (params: VerifyVATProps) => void, () => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useDebouncedCallback(async (params: VerifyVATProps) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await verifyVAT(params);
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  const reset = () => {
    setData(null);
    setIsLoading(false);
    setError(null);
  };

  return [data, isLoading, error, loadData, reset];
};

export default useVerifyVAT;
