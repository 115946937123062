export const generateUUID = (): string => {
  let d: number = Date.now();
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
    /[xy]/g,
    (c) => {
      const r: number = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16) as string;
    },
  );
};

export const makeTextShorter = (text: string, length = 100): string => text.length >= length
  ? `${text.slice(0, length - 3)}...`
  : text;

export const getUrlWithoutSlash = (url: string): string => url.replace(/\/$/, '');
