import React, { useCallback, useEffect, useState} from 'react';
import { useTranslation } from 'react-i18next';
import saveAs from 'file-saver';
import RefreshIcon from '@material-ui/icons/Refresh';

import { ReactComponent as DocumentIcon } from './../../../../assets/img/processes/document-icon.svg';

import { PandadocProcessModel, ProcessResultModel } from '../../network/models';

import Box from '../../../../components/ui/Box';
import Hidden from '../../../../components/ui/Hidden';
import LoaderProgress from '../../../../components/LoaderProgress';
import StyledAccordion from '../../../../components/StyledAccordion';
import CircularProgress from '../../../../components/ui/CircularProgress';
import { OutlinedButton, PrimaryButton } from '../../../../components/ui/Button';

import useDocumentDownload from '../../hooks/documents/useDocumentDownload';
import useTaskQuestionnaireEdit from '../../hooks/tasks/useTaskQuestionnaireEdit';
import { getDocumentStatus } from '../../utils/documents';
import { openProcessDocumentSessionModal } from '../ProcessDocumentSessionModal';

import ProcessKYCVerificationPanel from '../ProcessKYCVerificationPanel';

import {
  Wrapper,
  Documents,
  Document,
  NameWrapper,
  Name,
  Status,
  IconWrapper,
  Congratulations,
} from './styled';

export type ProcessSubmittingDocumentsProps = {
  taskId: number;
  documents: PandadocProcessModel[];
  loadProcess: (loader?: boolean) => void;
  process: ProcessResultModel;
  isKYCShareholdersDone?: boolean;
  allDocumentsCompleted: boolean;
};

const ProcessSubmittingDocuments = (props: ProcessSubmittingDocumentsProps) => {
  const { documents, loadProcess, taskId, process, allDocumentsCompleted, isKYCShareholdersDone } = props;
  const { t } = useTranslation();

  const processId = process.id;
  const processName = process.service.process_name;

  const [, isDocumentDownloading,, downloadDocument] = useDocumentDownload();
  const [, isFormEditLoading,, editForm] = useTaskQuestionnaireEdit({
    taskId,
    processId,
    processName,
  });

  const [idLoadingDocument, setIdLoadingDocument] = useState<number | null>(null);

  const isDocumentsStillUploading = documents.some(doc => doc.status === 'document.uploaded');

  const handleClickDocument = useCallback(({ id, status, name }: PandadocProcessModel) => async () => {
    if (status === 'completed' && idLoadingDocument !== id) {
      try {
        setIdLoadingDocument(id);
        const data = await downloadDocument(id);
        setIdLoadingDocument(null);
        saveAs(data, `${name}.pdf`);
      } catch (error) {
        console.error(error);
      }

      return;
    }

    openProcessDocumentSessionModal({
      documentId: id,
      loadProcess,
    });
  }, [downloadDocument, idLoadingDocument, loadProcess]);

  const handleEditForm = useCallback(async () => {
    await editForm();
    await loadProcess();
  }, [editForm, loadProcess]);

  const refreshProcess = useCallback(async () => {
    await loadProcess();
  }, [loadProcess]);

  useEffect(() => {
    let timeoutId: any;

    if (isDocumentsStillUploading) {
      timeoutId = setTimeout(() => loadProcess(false), 7000);
    }

    return () => clearTimeout(timeoutId);
  }, [isDocumentsStillUploading, loadProcess, refreshProcess, documents]);

  return (
    <Wrapper>
      <ProcessKYCVerificationPanel processId={processId} processName={processName} />

      <StyledAccordion
        title={t('account.processes.documents.title')}
        description={t('account.processes.documents.description')}
      >
        <Documents>
          {documents
            .map(d => ({ ...d, status: d.status.replace('document.', '') }))
            .map((document) => {
              const { id, name, status } = document;
              const docStatus = getDocumentStatus(status, t);

              const loading = idLoadingDocument === id;
              const docDisabled = loading || isDocumentDownloading || status === 'draft' || status === 'uploaded';

              return (
                <Document key={id} container>
                  <Hidden smDown>
                    <NameWrapper disabled={docDisabled} item sm={12} md={9} lg={9}>
                      <IconWrapper><DocumentIcon /></IconWrapper>
                      <Name onClick={handleClickDocument(document)}>{name}</Name>
                    </NameWrapper>
                    <Status item sm={12} md={2} lg={2} status={status}>{loading ? (
                      <Box display="flex" justifyContent="flex-end">
                        <CircularProgress size={20} />
                      </Box>
                    ) : docStatus}</Status>
                  </Hidden>

                  <Hidden mdUp>
                    {loading && <LoaderProgress />}
                    <IconWrapper><DocumentIcon /></IconWrapper>
                    <NameWrapper disabled={docDisabled} item xs={10} sm={11} md={9} lg={9}>
                      <Name onClick={handleClickDocument(document)}>{name}</Name>
                      <Status status={status}>
                        {loading ? (
                          <Box display="flex" justifyContent="flex-end">
                            <CircularProgress size={20} />
                          </Box>
                        ) : <>{docStatus}</>}
                      </Status>
                    </NameWrapper>
                  </Hidden>
                </Document>
              )
            })}
        </Documents>
      </StyledAccordion>

      {allDocumentsCompleted && isKYCShareholdersDone ? (
        <Congratulations>{t('account.documents.congratulations')}</Congratulations>
      ) : (
        <Box mt={3} display="flex" alignItems="center" justifyContent="space-between">
          <OutlinedButton onClick={handleEditForm} disabled={isFormEditLoading}>
            {isFormEditLoading ? (
              <Box mr={1} display="flex" alignItems="center">
                <CircularProgress size={20} />
              </Box>
            ) : null}
            {t('account.documents.buttons.editForm')}
          </OutlinedButton>

          <Box>
            <PrimaryButton
              disabled={isDocumentsStillUploading}
              startIcon={isDocumentsStillUploading ? <CircularProgress size={20} /> : <RefreshIcon />}
              onClick={refreshProcess}
            >
              {t('account.documents.buttons.refresh')}
            </PrimaryButton>
          </Box>
        </Box>
      )}
    </Wrapper>
  )
};

export default ProcessSubmittingDocuments;
