import { FilesModel, FolderModel, NormalizedCompanySecretaryItemModel } from '../network/models';

export function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export type Order = 'asc' | 'desc';

export function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSortProcess<T>(array: AllItemsFlattenedModel[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [any, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]) as AllItemsFlattenedModel[];
}

export function stableSort<T>(array: AllItemsFlattenedModel[], comparator: (a: T, b: T) => number, orderBy: string) {
  if (orderBy === 'name') {
    const folders = array.filter((el) => el.type === 'folder');
    const files = array.filter((el) => el.type === 'file');

    return [
      ...stableSortProcess(folders, comparator),
      ...stableSortProcess(files, comparator),
    ] as AllItemsFlattenedModel[];
  }

  if (orderBy === 'last_date') {
    return stableSortProcess(array, comparator) as AllItemsFlattenedModel[];
  }

  return array;
}

export function getMostRecentItemDate(row: FolderModel | FilesModel): number {
  let date: number = 0;

  if ('files' in row && row.files.length > 0) {
    // find last created file
    row.files.forEach((file) => {
      if (file.modified) {
        date = Math.max(new Date(file.modified).valueOf() || 0, new Date(date || 0).valueOf());
      }
    });
  }

  if ('folders' in row && row.folders.length > 0) {
    row.folders.map(getMostRecentItemDate).forEach((folderDate) => {
      date = Math.max(new Date(folderDate).valueOf() || 0, new Date(date || 0).valueOf());
    });
  }

  if ('modified' in row && row.modified) {
    return new Date(row.modified).valueOf();
  }

  return date;
}

export function getNormalizedCompanySecretaryItem(results: (FolderModel | FilesModel)[]): NormalizedCompanySecretaryItemModel[] {
  return results.map((item) => {
    return ({
      id: item.id,
      title: item.title,
      type: 'folders' in item ? 'folder' : 'file',
      date: getMostRecentItemDate(item),
      slug: ('slug' in item && item.slug) || undefined,
      file: ('file' in item && item.file) || undefined,
      folders: ('folders' in item && getNormalizedCompanySecretaryItem(item.folders)) || null,
      files: ('files' in item && getNormalizedCompanySecretaryItem(item.files)) || null,
    });
  });
}

export interface AllItemsFlattenedModel {
  id: number;
  title: string;
  parentId: number; // 0 - root
  type: string;
  slug?: string;
  file?: string;
  path?: string; // only for folders; rootUrl/:parentIdSlug/:slug
  last_date?: number;
  children: AllItemsFlattenedModel[]; // only for folders;
}

export type GetNestedRoutesParams = {
  root: NormalizedCompanySecretaryItemModel,
  parentPrefix: string,
  parentId?: number,
};

export const getNestedItems = ({ root, parentPrefix, parentId }: GetNestedRoutesParams): AllItemsFlattenedModel[] => {
  const { id, title, type, slug, file, date } = root;

  const isFolder = type === 'folder';
  const containsFolders = 'folders' in root && root.folders && root.folders.length > 0;
  const containsFiles = 'files' in root && root.files && root.files.length > 0;

  const nestedItems: AllItemsFlattenedModel[] = [];

  const nestedRawItems: NormalizedCompanySecretaryItemModel[] = [
    ...(containsFolders ? root.folders! : []),
    ...(containsFiles ? root.files! : []),
  ];

  nestedRawItems.forEach((item) => {
    nestedItems.push(...getNestedItems({
      root: item,
      parentPrefix: isFolder ? `${parentPrefix}/${slug}` : '',
      parentId: id,
    }));
  });

  return [
    {
      id,
      title,
      parentId: parentId || 0,
      type,
      slug,
      file,
      path: isFolder ? `${parentPrefix}/${slug}` : undefined,
      last_date: date,
      children: [
        ...nestedItems.filter((item) => item.parentId === id),
      ],
    },
    ...nestedItems,
  ];
};
