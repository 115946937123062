import styled from 'styled-components';

export const AsideMenuWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  position: fixed;
  height: calc(100vh - 148px);
  z-index: 5;
  padding-bottom: 48px;
  margin-top: 48px;
  width: 370px;

  &::-webkit-scrollbar {
    display: none;
  }

  margin-bottom: ${(p) => p.theme.spacing(3)}px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    flex-shrink: 1;
    width: 100%;
    position: relative;
    height: auto;
    margin-bottom: 0;
    margin-top: 0;
    padding-bottom: 0;
    overflow-y: auto;
  }
`;
