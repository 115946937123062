import React from 'react';
import { useTranslation } from 'react-i18next';

import RequestError from '../../../../network/RequestError';

import TabsMenu, { TabsMenuItemProps } from '../../../../components/TabsMenu';
import LoaderProgress from '../../../../components/LoaderProgress';
import ErrorMessage from '../../../../components/ui/ErrorMessage';
import Typography from '../../../../components/ui/Typography';
import Box from '../../../../components/ui/Box';

import {
  ContentWrapper,
  EmptyWrapper,
  StyledSubTitle,
  TitleCount,
} from './styled';

interface AccountLayoutProps {
  children: React.ReactChild;
  title: string;
  titleCount?: string | number;
  subTitle?: string;
  loading?: boolean;
  footer?: React.ReactNode;
  headActions?: React.ReactNode;
  tabsMenuItems?: TabsMenuItemProps[];
  empty?: boolean;
  error?: RequestError | null;
}

const AccountLayout = (props: AccountLayoutProps) => {
  const {
    title,
    loading,
    titleCount,
    empty = false,
    error,
    footer = null,
    tabsMenuItems = null,
    subTitle = '',
    headActions,
    children,
  } = props;

  const { t } = useTranslation();

  return (
    <ContentWrapper>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={{ xs: 3, sm: 4.5 }}
      >
        {error && (
          <ErrorMessage error={error} />
        )}
        {title && (
          <Box>
            <Typography variant="h4" component="h1">
              {title}
              {titleCount !== undefined ? <TitleCount>({titleCount})</TitleCount> : null}
            </Typography>
            {subTitle && (
              <Box mt={1}>
                <StyledSubTitle>
                  {subTitle}
                </StyledSubTitle>
              </Box>
            )}
          </Box>
        )}

        {headActions ? headActions : null}
      </Box>
      {tabsMenuItems ? (
        <Box mb={3}>
          <TabsMenu items={tabsMenuItems} />
        </Box>
      ) : null}
      {!loading && children}
      {(empty && !loading) && (
        <EmptyWrapper>
          <Typography variant="body2" color="textSecondary">
            {t('account.noData')}
          </Typography>
        </EmptyWrapper>
      )}
      {loading ? <LoaderProgress /> : null}
      {footer}
    </ContentWrapper>
  );
};

export default AccountLayout;
