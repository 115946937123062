import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #232121;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  & span > b {
    font-weight: 600;
  }

  & span > mark {
    background: rgba(39, 194, 151, 0.3);
  }
`;

export const StyledNativeLink = styled.a`
  display: flex;
  align-items: center;

  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: #232121;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  & span > b {
    font-weight: 600;
  }

  & span > mark {
    background: rgba(39, 194, 151, 0.3);
  }
`;

export const StyledIcon = styled.span`
  margin-right: 16px;
`;
