import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

import eventBroker from '../../core/eventBroker';
import { generateUUID } from '../../utils/helpers';

export { default as ModalsContainer } from './ModalsContainer';

type ModalItem = {
  id: string;
  level: number;
  render: (props: any) => React.ReactNode;
  props: any;
  containerName: string;
};

export type ModalItemBaseProps = {
  modalId: string;
  modalLevel: number;
  modalIsOpen: boolean;
  modalRequestClose: () => void;
};

export type ModalsContextValue = {
  items: ModalItem[];
  closeModal: (id: string) => void;
};

export const ModalsContext = createContext<ModalsContextValue>({
  items: [],
  closeModal: () => {},
});

export const useModalsContext = () => useContext(ModalsContext) as ModalsContextValue;

const ModalsProvider = (props: any) => {
  const [modals, setModals] = useState<ModalItem[]>([]);

  const onEvent = useCallback((event) => {
    setModals((modals) => [
      ...modals,
      {
        id: event.uuid,
        render: (props) => <event.Component {...props} />,
        props: event.props,
        level: modals.length + 1,
        containerName: event.containerName || 'root'
      },
    ]);
  }, []);

  const closeModal = useCallback((id: string) => {
    setModals((modals) => modals.filter((x) => x.id !== id));
  }, []);

  useEffect(() => {
    eventBroker.on('openModalRequest', onEvent);

    return () => {
      eventBroker.off('openModalRequest', onEvent);
    };
  }, [onEvent]);

  return (
    <ModalsContext.Provider value={{
      items: modals,
      closeModal,
    }}>
      {props.children}
    </ModalsContext.Provider>
  );
};

export const  openModal = (Component: any, props: any, containerName?: string) => {
  const uuid = generateUUID();
  eventBroker.trigger('openModalRequest', {
    Component,
    uuid,
    props,
    containerName,
  });

  return {
    modalId: uuid,
  };
};

export default ModalsProvider;
