import OneBoard from '@oneboard/widget';

export type LaunchOneboardSdkOptions = {
  verificationToken: string;
  appPublicKey: string;
};

export default function launchOneboardSdk(container: HTMLElement | string, options: LaunchOneboardSdkOptions) {
  const {
    verificationToken,
    appPublicKey,
  } = options;

  const oneBoardInstance = new OneBoard({
    verificationToken,
    appPublicKey,
  });

  oneBoardInstance.renderOnBoardingView(container);
}
