import React from 'react';

import Box from '../ui/Box';

import {
  Root,
  Divider,
  SettingsTitle,
  Status,
} from './styled';

export interface SettingsCardProps {
  title: string | React.ReactNode;
  color?: 'primary' | '';
  status?: string;
  headAction?: React.ReactNode;
  children?: React.ReactNode;
}

function SettingsCard(props: SettingsCardProps) {
  const {
    title = '',
    color = '',
    status = '',
    headAction = null,
    children,
  } = props;

  return (
    <Root color={color}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={3}
      >
        <div>
          <SettingsTitle>{title}</SettingsTitle>
          {status && (
            <Status mt={0.5}>Confirmed</Status>
          )}
        </div>
        <Box mr={-1.5}>
          {headAction && headAction}
        </Box>
      </Box>
      {children && (
        <>
          <Divider />
          <Box p={3}>{children}</Box>
        </>
      )}
    </Root>
  )
}

export default SettingsCard;
