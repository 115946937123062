import { TFunction } from 'react-i18next';

import ObjectType from '../../../../../../../utils/ObjectType';

import {
  CitizenshipModel,
  FormattedValuesModel,
  LegalRepresentativeModel,
} from '../models/formValues';

import { FORMATTED_FORM_KEYS, INTERNAL_FORM_KEYS } from './constants';

export const getShareholdersIds = (values: any): string[] => {
  const shareholdersIds: string[] = [];

  Object.keys(values).forEach((key: string) => {
    if (key.includes('shareholders_')) {
      const shId = key.split('_')[1];

      // if people already is in the list of people list, skip it
      if (shareholdersIds.find((id: string) => id === shId)) {
        return;
      }

      shareholdersIds.push(shId);
    }
  });

  return shareholdersIds;
};

const transformAllUndefinedToEmptyString = (obj: ObjectType): ObjectType => {
  const newObj = { ...obj };

  Object.keys(newObj).forEach((key) => {
    if (newObj[key] === undefined) {
      newObj[key] = '';
    }
  });

  return newObj;
};

type GetFormattedValuesParams = {
  values: ObjectType;
  t: TFunction;
  tPrefix: string;
};

export const formatValues = (props: GetFormattedValuesParams): FormattedValuesModel | ObjectType => {
  const { t, tPrefix, values } = props;

  const shareCapitalAmount = values[FORMATTED_FORM_KEYS.share_capital_amount];
  const shareCapitalPerShare = values[FORMATTED_FORM_KEYS.share_capital_value_per_share];

  const result: FormattedValuesModel | ObjectType = {
    company_name: values[FORMATTED_FORM_KEYS.company_name],
    legal_form: values[FORMATTED_FORM_KEYS.legal_form],
    spf: values[FORMATTED_FORM_KEYS.spf],
    registered_office: {
      type: values[FORMATTED_FORM_KEYS.registered_office],
    },
  };

  if (result.registered_office.type !== t(`${tPrefix}.registeredOffice.radio1`)) {
    result.registered_office.city = values[INTERNAL_FORM_KEYS.registered_office_city];
    result.registered_office.postal_code = values[INTERNAL_FORM_KEYS.registered_office_postal_code];
    result.registered_office.country = values[INTERNAL_FORM_KEYS.registered_office_country];
    result.registered_office.address = values[INTERNAL_FORM_KEYS.registered_office_address];
  } else {
    result.registered_office.city = 'Luxembourg';
    result.registered_office.postal_code = '1466';
    result.registered_office.country = 'Luxembourg';
    result.registered_office.address = '4 rue Jean Engling';
  }

  result.share_capital_amount = shareCapitalAmount ? Number(shareCapitalAmount) : '';
  result.share_capital_currency = values[FORMATTED_FORM_KEYS.share_capital_currency];
  result.share_capital_value_per_share = shareCapitalPerShare ? Number(shareCapitalPerShare) : '';

  result.shareholders = [];

  type FillPeopleFormParams = {
    emailRequired?: boolean,
    key: string;
    peoplePrefix: string;
    peoplePostfix?: string;
    resultField?: string;
    idSeparatorNumber: number;
  };

  const fillPeopleForm = (props: FillPeopleFormParams) => {
    const { key, peoplePrefix, resultField, peoplePostfix, emailRequired, idSeparatorNumber } = props;

    const isShareholders = peoplePrefix === FORMATTED_FORM_KEYS.shareholders;

    const peopleId = key.split('_')[idSeparatorNumber];
    const postfix = peoplePostfix ? `_${peoplePostfix}` : '';
    const prefix = `${FORMATTED_FORM_KEYS[peoplePrefix]}${postfix}`;

    if (!result[resultField || peoplePrefix]) {
      result[resultField || peoplePrefix] = [];
    }

    // if people already is in the list of people list, skip it
    if (result[resultField || peoplePrefix].find(({ id }: ObjectType) => id === peopleId)) {
      return;
    }

    // otherwise, add all information about shareholder
    const legalType = values[`${prefix}_${peopleId}_legal_type`];
    const email = emailRequired ? values[`${prefix}_${peopleId}_email`] : null;
    const city = values[`${prefix}_${peopleId}_city`];
    const postalCode = values[`${prefix}_${peopleId}_postal_code`];
    const country = values[`${prefix}_${peopleId}_country`];
    const address = values[`${prefix}_${peopleId}_address`];

    const isIndividualLegalType = legalType === t(`${tPrefix}.peopleForm.individual.label`);
    const isEntityLegalType = legalType === t(`${tPrefix}.peopleForm.entity.label`);

    const peopleOne: ObjectType = {
      id: peopleId,
      legal_type: legalType,
      city,
      postal_code: postalCode,
      country,
      address,
    };

    if (emailRequired) {
      peopleOne.email = email;
    }

    if (isIndividualLegalType) {
      peopleOne.gender = values[`${prefix}_${peopleId}_gender`];
      peopleOne.name = values[`${prefix}_${peopleId}_name`];
      peopleOne.surname = values[`${prefix}_${peopleId}_surname`];
      peopleOne.birthday = values[`${prefix}_${peopleId}_birthday`];
      peopleOne.birthplace = values[`${prefix}_${peopleId}_birthplace`];

      if (isShareholders) {
        const currentSameAddress = values[`${prefix}_${peopleId}_current_same`];
        peopleOne.current_same = currentSameAddress;

        Object.keys(values).forEach((k: string) => {
          if (k.includes(`${prefix}_${peopleId}_citizenship_`)) {
            const citizenshipId = k.split('_')[idSeparatorNumber + 2];

            if (!peopleOne.citizenship) {
              peopleOne.citizenship = [];
            }

            if (peopleOne.citizenship.find(({ id }: LegalRepresentativeModel) => id === citizenshipId)) {
              return;
            }

            const citizenship: CitizenshipModel = {
              id: citizenshipId,
              country: values[`${prefix}_${peopleId}_citizenship_${citizenshipId}_country`],
              passport: values[`${prefix}_${peopleId}_citizenship_${citizenshipId}_passport`],
              passport_issued: values[`${prefix}_${peopleId}_citizenship_${citizenshipId}_passport_issued`],
              passport_valid_until: values[`${prefix}_${peopleId}_citizenship_${citizenshipId}_passport_valid_until`],
            };

            peopleOne.citizenship.push(citizenship);
          }
          if (k.includes(`${prefix}_${peopleId}_current`)) {
            if (!currentSameAddress) {
              peopleOne.current_city = values[`${prefix}_${peopleId}_current_city`];
              peopleOne.current_postal_code = values[`${prefix}_${peopleId}_current_postal_code`];
              peopleOne.current_country = values[`${prefix}_${peopleId}_current_country`];
              peopleOne.current_address = values[`${prefix}_${peopleId}_current_address`];
            }
          }
        });
      }
    }

    if (isEntityLegalType) {
      peopleOne.entity_name = values[`${prefix}_${peopleId}_entity_name`];
      peopleOne.organization_number = values[`${prefix}_${peopleId}_organization_number`];

      if (!peopleOne.legal_representatives) {
        peopleOne.legal_representatives = [];
      }

      Object.keys(values).forEach((k: string) => {
        if (k.includes(`${prefix}_${peopleId}_legal_representatives_`)) {
          const representativeId = k.split('_')[idSeparatorNumber + 3];

          if (peopleOne.legal_representatives.find(({ id }: LegalRepresentativeModel) => id === representativeId)) {
            return;
          }

          const representative: LegalRepresentativeModel = {
            id: representativeId,
            name: values[`${prefix}_${peopleId}_legal_representatives_${representativeId}_name`],
            surname: values[`${prefix}_${peopleId}_legal_representatives_${representativeId}_surname`],
          };

          peopleOne.legal_representatives.push(representative);
        }
      });

      peopleOne.legal_representatives = peopleOne.legal_representatives.map(transformAllUndefinedToEmptyString);
    }

    result[resultField || peoplePrefix].push(peopleOne);
  };

  Object.keys(values)
    .filter((key: string) => key.includes(`${FORMATTED_FORM_KEYS.shareholders}_`))
    .forEach((key: string) => {
      fillPeopleForm({
        key,
        peoplePrefix: FORMATTED_FORM_KEYS.shareholders,
        idSeparatorNumber: 1,
        emailRequired: true,
      });
    });

  result.end_of_financial_year = values[FORMATTED_FORM_KEYS.end_of_financial_year];
  result.annual_general_meeting_date = values[FORMATTED_FORM_KEYS.annual_general_meeting_date];

  result.management = [];
  result.leadership_type = '';
  result.management_type = '';

  Object.keys(values).forEach((key: string) => {
    // if management
    if (values[FORMATTED_FORM_KEYS.legal_form] === t(`${tPrefix}.legalForm.radio2`)) {
      const managementType = values[FORMATTED_FORM_KEYS.management_type];
      result[FORMATTED_FORM_KEYS.leadership_type] = FORMATTED_FORM_KEYS.management;
      result[FORMATTED_FORM_KEYS.management_type] = managementType?.split(' ')[0].toLowerCase();

      if (managementType === t(`${tPrefix}.management.radio1`)) {
        if (key.includes(`${FORMATTED_FORM_KEYS.management}_${INTERNAL_FORM_KEYS.sole}`)) {
          fillPeopleForm({
            key,
            peoplePrefix: FORMATTED_FORM_KEYS.management,
            resultField: FORMATTED_FORM_KEYS.management,
            peoplePostfix: INTERNAL_FORM_KEYS.sole,
            idSeparatorNumber: 2,
            emailRequired: false,
          });
        }
      }

      if (managementType === t(`${tPrefix}.management.radio2`)) {
        if (key.includes(`${FORMATTED_FORM_KEYS.management}_${INTERNAL_FORM_KEYS.class}`)) {
          fillPeopleForm({
            key,
            peoplePrefix: FORMATTED_FORM_KEYS.management,
            resultField: FORMATTED_FORM_KEYS.management,
            peoplePostfix: INTERNAL_FORM_KEYS.class,
            idSeparatorNumber: 2,
            emailRequired: false,
          });
        }
      }

      if (managementType === t(`${tPrefix}.management.radio3`)) {
        if (key.includes(`${FORMATTED_FORM_KEYS.management}_${INTERNAL_FORM_KEYS.noclass}`)) {
          fillPeopleForm({
            key,
            peoplePrefix: FORMATTED_FORM_KEYS.management,
            resultField: FORMATTED_FORM_KEYS.management,
            peoplePostfix: INTERNAL_FORM_KEYS.noclass,
            idSeparatorNumber: 2,
            emailRequired: false,
          });
        }
      }
    }

    // if directorship
    if (values[FORMATTED_FORM_KEYS.legal_form] === t(`${tPrefix}.legalForm.radio1`)) {
      const directorshipType = values[FORMATTED_FORM_KEYS.directorship_type];
      result[FORMATTED_FORM_KEYS.leadership_type] = FORMATTED_FORM_KEYS.directorship;
      result[FORMATTED_FORM_KEYS.management_type] = directorshipType?.split(' ')[0].toLowerCase();

      if (directorshipType === t(`${tPrefix}.directorship.radio1`)) {
        if (key.includes(`${FORMATTED_FORM_KEYS.directorship}_${INTERNAL_FORM_KEYS.sole}`)) {
          fillPeopleForm({
            key,
            peoplePrefix: FORMATTED_FORM_KEYS.directorship,
            resultField: FORMATTED_FORM_KEYS.management,
            peoplePostfix: INTERNAL_FORM_KEYS.sole,
            idSeparatorNumber: 2,
            emailRequired: false,
          });
        }
      }

      if (directorshipType === t(`${tPrefix}.directorship.radio2`)) {
        if (key.includes(`${FORMATTED_FORM_KEYS.directorship}_${INTERNAL_FORM_KEYS.class}`)) {
          fillPeopleForm({
            key,
            peoplePrefix: FORMATTED_FORM_KEYS.directorship,
            resultField: FORMATTED_FORM_KEYS.management,
            peoplePostfix: INTERNAL_FORM_KEYS.class,
            idSeparatorNumber: 2,
            emailRequired: false,
          });
        }
      }

      if (
        directorshipType === t(`${tPrefix}.directorship.radio3`)
        || directorshipType === t(`${tPrefix}.directorship.radio3MinimumThreeDirectors`)
      ) {
        result[FORMATTED_FORM_KEYS.management_type] = INTERNAL_FORM_KEYS.multiple;
        if (key.includes(`${FORMATTED_FORM_KEYS.directorship}_${INTERNAL_FORM_KEYS.noclass}`)) {
          fillPeopleForm({
            key,
            peoplePrefix: FORMATTED_FORM_KEYS.directorship,
            resultField: FORMATTED_FORM_KEYS.management,
            peoplePostfix: INTERNAL_FORM_KEYS.noclass,
            idSeparatorNumber: 2,
            emailRequired: false,
          });
        }
      }
    }
  });

  if (values[FORMATTED_FORM_KEYS.spf] || values[FORMATTED_FORM_KEYS.has_purpose] === t(`${tPrefix}.purpose.radio2`)) {
    result[FORMATTED_FORM_KEYS.company_purpose] = null;
  } else {
    result[FORMATTED_FORM_KEYS.company_purpose] = values[FORMATTED_FORM_KEYS.company_purpose];
  }

  result.incorporation_method = values[FORMATTED_FORM_KEYS.incorporation_method];

  result.client = {
    legal_type: values[FORMATTED_FORM_KEYS.client_legal_type],
      phone: values[FORMATTED_FORM_KEYS.client_phone],
      email: values[FORMATTED_FORM_KEYS.client_email],
      city: values[FORMATTED_FORM_KEYS.client_city],
      postal_code: values[FORMATTED_FORM_KEYS.client_postal_code],
      country: values[FORMATTED_FORM_KEYS.client_country],
      address: values[FORMATTED_FORM_KEYS.client_address],
      authorized_person: [],
  };

  const fillClient = () => {
    // if client
    const isIndividualLegalType = values[FORMATTED_FORM_KEYS.client_legal_type] === t(`${tPrefix}.peopleForm.individual.label`);

    if (isIndividualLegalType) {
      result.client.name = values[FORMATTED_FORM_KEYS.client_name];
      result.client.surname = values[FORMATTED_FORM_KEYS.client_surname];
    } else {
      result.client.entity_name = values[FORMATTED_FORM_KEYS.client_entity_name];
      result.client.organization_number = values[FORMATTED_FORM_KEYS.client_organization_number];
    }

    const authorizedPerson: any[] = [];

    Object.keys(values).forEach((key: string) => {
      if (key.includes(`${FORMATTED_FORM_KEYS.client_authorized_person}_`)) {
        const personId = key.split('_')[3];

        if (authorizedPerson.find(({ id }) => id === personId)) {
          return;
        }

        const prefix = `${FORMATTED_FORM_KEYS.client_authorized_person}_${personId}`;

        const person: any = {
          id: personId,
          same: isIndividualLegalType ? Boolean(values[`${prefix}_same`]) : false,
        };

        if (!person.same) {
          person.name = values[`${prefix}_name`];
          person.surname = values[`${prefix}_surname`];
          person.phone = values[`${prefix}_phone`];
          person.email = values[`${prefix}_email`];
          person.city = values[`${prefix}_city`];
          person.postal_code = values[`${prefix}_postal_code`];
          person.country = values[`${prefix}_country`];
          person.address = values[`${prefix}_address`];
        } else {
          person.name = values[FORMATTED_FORM_KEYS.client_name];
          person.surname = values[FORMATTED_FORM_KEYS.client_surname];
          person.phone = values[FORMATTED_FORM_KEYS.client_phone];
          person.email = values[FORMATTED_FORM_KEYS.client_email];
          person.city = values[FORMATTED_FORM_KEYS.client_city];
          person.postal_code = values[FORMATTED_FORM_KEYS.client_postal_code];
          person.country = values[FORMATTED_FORM_KEYS.client_country];
          person.address = values[FORMATTED_FORM_KEYS.client_address];
        }

        authorizedPerson.push(person);
      }
    });

    result.client.authorized_person = authorizedPerson;
  };

  fillClient();

  const fillStatutoryAuditor = () => {
    // if SA not checked
    if (values[FORMATTED_FORM_KEYS.legal_form] !== t(`${tPrefix}.legalForm.radio1`)) {
      return;
    }

    if (!result.statutory_auditor) {
      result.statutory_auditor = {};
    }

    result.statutory_auditor.type = values[FORMATTED_FORM_KEYS.statutory_auditor_type]

    // if statutory auditor from client
    if (values[FORMATTED_FORM_KEYS.statutory_auditor_type] === t(`${tPrefix}.statutoryAuditor.radio2`)) {
      result.statutory_auditor.legal_type = values[FORMATTED_FORM_KEYS.statutory_auditor_legal_type];

      const isIndividualLegalType = values[FORMATTED_FORM_KEYS.statutory_auditor_legal_type] === t(`${tPrefix}.peopleForm.individual.label`);

      if (isIndividualLegalType) {
        result.statutory_auditor.name = values[FORMATTED_FORM_KEYS.statutory_auditor_name];
        result.statutory_auditor.surname = values[FORMATTED_FORM_KEYS.statutory_auditor_surname];
      } else {
        result.statutory_auditor.entity_name = values[FORMATTED_FORM_KEYS.statutory_auditor_entity_name];
        result.statutory_auditor.organization_number = values[FORMATTED_FORM_KEYS.statutory_auditor_organization_number];
      }

      result.statutory_auditor.city = values[FORMATTED_FORM_KEYS.statutory_auditor_city];
      result.statutory_auditor.postal_code = values[FORMATTED_FORM_KEYS.statutory_auditor_postal_code];
      result.statutory_auditor.country = values[FORMATTED_FORM_KEYS.statutory_auditor_country];
      result.statutory_auditor.address = values[FORMATTED_FORM_KEYS.statutory_auditor_address];
    }
  };

  fillStatutoryAuditor();

  result.shareholders = result.shareholders.map(transformAllUndefinedToEmptyString);

  result.management = result.management.map(transformAllUndefinedToEmptyString);

  return transformAllUndefinedToEmptyString(result);
}

type GetFlattenedValuesParams = {
  values: ObjectType;
  t: TFunction;
  tPrefix: string;
};

export const getFlattenedValues = ({ values, t, tPrefix }: GetFlattenedValuesParams): ObjectType => {
  if (!values) {
    return {};
  }

  const result: ObjectType = {
    company_name: values[FORMATTED_FORM_KEYS.company_name],
    legal_form: values[FORMATTED_FORM_KEYS.legal_form],
    spf: values[FORMATTED_FORM_KEYS.spf],

    registered_office: values[FORMATTED_FORM_KEYS.registered_office]?.type,
    registered_office_city: values[FORMATTED_FORM_KEYS.registered_office]?.city,
    registered_office_postal_code: values[FORMATTED_FORM_KEYS.registered_office]?.postal_code,
    registered_office_country: values[FORMATTED_FORM_KEYS.registered_office]?.country,
    registered_office_address: values[FORMATTED_FORM_KEYS.registered_office]?.address,

    share_capital_amount: values[FORMATTED_FORM_KEYS.share_capital_amount],
    share_capital_currency: values[FORMATTED_FORM_KEYS.share_capital_currency],
    share_capital_value_per_share: values[FORMATTED_FORM_KEYS.share_capital_value_per_share],
  };

  type FillPeopleFormParams = {
    emailRequired?: boolean;
    managementPrefix?: string;
    peoplePrefix: string;
    peoplePostfix?: string;
  };

  const fillPeopleForm = (props: FillPeopleFormParams) => {
    const { emailRequired, managementPrefix, peoplePrefix, peoplePostfix } = props;

    const isShareholders = peoplePrefix === FORMATTED_FORM_KEYS.shareholders;

    values[managementPrefix || peoplePrefix]?.forEach((people: ObjectType) => {
      const prefix = `${peoplePrefix}${peoplePostfix ? `_${peoplePostfix}` : ''}_${people.id}`;
      result[`${prefix}_legal_type`] = people.legal_type;

      if (people.legal_type === t(`${tPrefix}.peopleForm.individual.label`)) {
        result[`${prefix}_gender`] = people.gender;
        result[`${prefix}_name`] = people.name;
        result[`${prefix}_surname`] = people.surname;
        result[`${prefix}_birthday`] = people.birthday;
        result[`${prefix}_birthplace`] = people.birthplace;

        if (isShareholders) {
          result[`${prefix}_current_same`] = people.current_same;

          if (!people.current_same) {
            result[`${prefix}_current_city`] = people.current_city;
            result[`${prefix}_current_postal_code`] = people.current_postal_code;
            result[`${prefix}_current_country`] = people.current_country;
            result[`${prefix}_current_address`] = people.current_address;
          }

          people.citizenship?.forEach((citizenship: CitizenshipModel) => {
            const citizenshipPrefix = `${prefix}_citizenship_${citizenship.id}`;
            result[`${citizenshipPrefix}_country`] = citizenship.country;
            result[`${citizenshipPrefix}_passport`] = citizenship.passport;
            result[`${citizenshipPrefix}_passport_issued`] = citizenship.passport_issued;
            result[`${citizenshipPrefix}_passport_valid_until`] = citizenship.passport_valid_until;
          });
        }
      }

      if (people.legal_type === t(`${tPrefix}.peopleForm.entity.label`)) {
        result[`${prefix}_entity_name`] = people.entity_name;
        result[`${prefix}_organization_number`] = people.organization_number;

        people.legal_representatives.forEach((legalRepresentative: LegalRepresentativeModel) => {
          const representativePrefix = `${prefix}_legal_representatives_${legalRepresentative.id}`;
          result[`${representativePrefix}_name`] = legalRepresentative.name;
          result[`${representativePrefix}_surname`] = legalRepresentative.surname;
        });
      }

      result[`${prefix}_city`] = people.city;
      result[`${prefix}_postal_code`] = people.postal_code;
      result[`${prefix}_country`] = people.country;
      result[`${prefix}_address`] = people.address;

      if (emailRequired) {
        result[`${prefix}_email`] = people.email;
      }
    });
  };

  if (values[FORMATTED_FORM_KEYS.shareholders]) {
    fillPeopleForm({
      peoplePrefix: FORMATTED_FORM_KEYS.shareholders,
      emailRequired: true,
    });
  }

  result[FORMATTED_FORM_KEYS.end_of_financial_year] = values[FORMATTED_FORM_KEYS.end_of_financial_year];

  result[FORMATTED_FORM_KEYS.annual_general_meeting_date] = values[FORMATTED_FORM_KEYS.annual_general_meeting_date];

  if (values[FORMATTED_FORM_KEYS.leadership_type] === FORMATTED_FORM_KEYS.management) {
    if (values[FORMATTED_FORM_KEYS.management_type] === INTERNAL_FORM_KEYS.sole) {
      result[FORMATTED_FORM_KEYS.management_type] = t(`${tPrefix}.management.radio1`);
      fillPeopleForm({
        peoplePrefix: FORMATTED_FORM_KEYS.management,
        managementPrefix: FORMATTED_FORM_KEYS.management,
        peoplePostfix: INTERNAL_FORM_KEYS.sole,
      });
    }

    if (values[FORMATTED_FORM_KEYS.management_type] === INTERNAL_FORM_KEYS.class) {
      result[FORMATTED_FORM_KEYS.management_type] = t(`${tPrefix}.management.radio2`);
      fillPeopleForm({
        peoplePrefix: FORMATTED_FORM_KEYS.management,
        managementPrefix: FORMATTED_FORM_KEYS.management,
        peoplePostfix: INTERNAL_FORM_KEYS.class,
      });
    }

    if (values[FORMATTED_FORM_KEYS.management_type] === INTERNAL_FORM_KEYS.multiple) {
      result[FORMATTED_FORM_KEYS.management_type] = t(`${tPrefix}.management.radio3`);
      fillPeopleForm({
        peoplePrefix: FORMATTED_FORM_KEYS.management,
        managementPrefix: FORMATTED_FORM_KEYS.management,
        peoplePostfix: INTERNAL_FORM_KEYS.noclass,
      });
    }
  }

  if (values[FORMATTED_FORM_KEYS.leadership_type] === FORMATTED_FORM_KEYS.directorship) {
    if (values[FORMATTED_FORM_KEYS.management_type] === INTERNAL_FORM_KEYS.sole) {
      result[FORMATTED_FORM_KEYS.directorship_type] = t(`${tPrefix}.directorship.radio1`);
      fillPeopleForm({
        peoplePrefix: FORMATTED_FORM_KEYS.directorship,
        managementPrefix: FORMATTED_FORM_KEYS.management,
        peoplePostfix: INTERNAL_FORM_KEYS.sole,
      });
    }

    if (values[FORMATTED_FORM_KEYS.management_type] === INTERNAL_FORM_KEYS.class) {
      result[FORMATTED_FORM_KEYS.directorship_type] = t(`${tPrefix}.directorship.radio2`);
      fillPeopleForm({
        peoplePrefix: FORMATTED_FORM_KEYS.directorship,
        managementPrefix: FORMATTED_FORM_KEYS.management,
        peoplePostfix: INTERNAL_FORM_KEYS.class,
      });
    }

    if (values.management_type === INTERNAL_FORM_KEYS.multiple) {
      result[FORMATTED_FORM_KEYS.directorship_type] = values.legal_form === t(`${tPrefix}.legalForm.radio1`)
        ? t(`${tPrefix}.directorship.radio3MinimumThreeDirectors`)
        : t(`${tPrefix}.directorship.radio3`);

      fillPeopleForm({
        peoplePrefix: FORMATTED_FORM_KEYS.directorship,
        managementPrefix: FORMATTED_FORM_KEYS.management,
        peoplePostfix: INTERNAL_FORM_KEYS.noclass,
      });
    }
  }

  if (values[FORMATTED_FORM_KEYS.spf] || values[FORMATTED_FORM_KEYS.company_purpose] === null) {
    result[FORMATTED_FORM_KEYS.company_purpose] = null;
    result[FORMATTED_FORM_KEYS.has_purpose] = t(`${tPrefix}.purpose.radio2`);
  } else {
    result[FORMATTED_FORM_KEYS.has_purpose] = t(`${tPrefix}.purpose.radio1`);
    result[FORMATTED_FORM_KEYS.company_purpose] = values[FORMATTED_FORM_KEYS.company_purpose];
  }

  result[FORMATTED_FORM_KEYS.incorporation_method] = values[FORMATTED_FORM_KEYS.incorporation_method];

  const fillClient = () => {
    const isIndividualLegalType = values.client?.legal_type === t(`${tPrefix}.peopleForm.individual.label`);

    result[FORMATTED_FORM_KEYS.client_legal_type] = values.client?.legal_type;

    if (isIndividualLegalType) {
      result[FORMATTED_FORM_KEYS.client_name] = values.client?.name;
      result[FORMATTED_FORM_KEYS.client_surname] = values.client?.surname;
    } else {
      result[FORMATTED_FORM_KEYS.client_entity_name] = values.client?.entity_name;
      result[FORMATTED_FORM_KEYS.client_organization_number] = values.client?.organization_number;
    }

    result[FORMATTED_FORM_KEYS.client_phone] = values.client?.phone;
    result[FORMATTED_FORM_KEYS.client_email] = values.client?.email;
    result[FORMATTED_FORM_KEYS.client_city] = values.client?.city;
    result[FORMATTED_FORM_KEYS.client_postal_code] = values.client?.postal_code;
    result[FORMATTED_FORM_KEYS.client_country] = values.client?.country;
    result[FORMATTED_FORM_KEYS.client_address] = values.client?.address;

    values.client?.authorized_person?.forEach((person: any) => {
      const prefix = `client_authorized_person_${person.id}`;
      result[`${prefix}_same`] = isIndividualLegalType ? Boolean(person.same) : false;
      result[`${prefix}_name`] = person.name;
      result[`${prefix}_surname`] = person.surname;
      result[`${prefix}_phone`] = person.phone;
      result[`${prefix}_email`] = person.email;
      result[`${prefix}_city`] = person.city;
      result[`${prefix}_postal_code`] = person.postal_code;
      result[`${prefix}_country`] = person.country;
      result[`${prefix}_address`] = person.address;
    });
  };

  fillClient();

  const fillStatutoryAuditor = () => {
    // if SA not checked
    if (values[FORMATTED_FORM_KEYS.legal_form] !== t(`${tPrefix}.legalForm.radio1`)) {
      return;
    }

    result[FORMATTED_FORM_KEYS.statutory_auditor_type] = values.statutory_auditor?.type;

    // if statutory auditor from client
    if (values.statutory_auditor?.type === t(`${tPrefix}.statutoryAuditor.radio2`)) {
      result[FORMATTED_FORM_KEYS.statutory_auditor_legal_type] = values.statutory_auditor?.legal_type;

      const isIndividualLegalType = values.statutory_auditor?.legal_type === t(`${tPrefix}.peopleForm.individual.label`);

      if (isIndividualLegalType) {
        result[FORMATTED_FORM_KEYS.statutory_auditor_name] = values.statutory_auditor?.name;
        result[FORMATTED_FORM_KEYS.statutory_auditor_surname] = values.statutory_auditor?.surname;
      } else {
        result[FORMATTED_FORM_KEYS.statutory_auditor_entity_name] = values.statutory_auditor?.entity_name;
        result[FORMATTED_FORM_KEYS.statutory_auditor_organization_number] = values.statutory_auditor?.organization_number;
      }

      result[FORMATTED_FORM_KEYS.statutory_auditor_city] = values.statutory_auditor?.city;
      result[FORMATTED_FORM_KEYS.statutory_auditor_postal_code] = values.statutory_auditor?.postal_code;
      result[FORMATTED_FORM_KEYS.statutory_auditor_country] = values.statutory_auditor?.country;
      result[FORMATTED_FORM_KEYS.statutory_auditor_address] = values.statutory_auditor?.address;
    }
  };

  fillStatutoryAuditor();

  return transformAllUndefinedToEmptyString(result);
};

export const getAllNonFilledFields = (props: GetFlattenedValuesParams): string[] => {
  const nonFilledFields: string[] = [];

  const trimmedValues = getFlattenedValues({ values: formatValues(props), t: props.t, tPrefix: props.tPrefix });

  Object.keys(trimmedValues).forEach((key: string) => {
    if (trimmedValues[key] === '') {
      nonFilledFields.push(key);
    }
  });

  return nonFilledFields;
};

export function formatToDateInput(date: string | Date) {
  return new Date(date).toISOString().split('T')[0];
}
