import React, { useCallback, useMemo, useState, ChangeEvent, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';

import EmptyDocs from '../../../../../../assets/img/backgrounds/empty-docs.png';

import TableCell from '../../../../../../components/ui/TableCell';
import Checkbox from '../../../../../../components/ui/Checkbox';
import Pagination from '../../../../../../components/ui/Pagination';
import TableContainer from '../../../../../../components/ui/TableContainer';
import TableBody from '../../../../../../components/ui/TableBody';

import {
  StyledPagination
} from '../../../../../process/screens/ProcessesScreen/components/ProcessesDesktopView/styled';
import { AllItemsFlattenedModel, getComparator, Order, stableSort } from '../../../../utils/companySecretary';
import useListState from '../../../../../../hooks/useListState';
import useDateLib from '../../../../../../hooks/useDateLib';

import TableToolbar from '../TableToolbar';
import TableHeader from '../TableHeader';
import ItemLink from '../ItemLink';

import {
  TableWrapper,
  StyledTable,
  StyledTableRow,
  StyledTableCell,
  EmptyWrapper,
  EmptyText,
  EmptyBg,
} from './styled';

type CompanySecretaryTableProps = {
  data: AllItemsFlattenedModel[];
  highlight?: string;
  isSearchPage?: boolean;
};

const CompanySecretaryTable = ({ data, highlight, isSearchPage }: CompanySecretaryTableProps) => {
  const { getCreatedDate } = useDateLib();
  const { page, pageSize, setPage, setPageSize } = useListState();
  const { t } = useTranslation();

  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('name');
  const [selectedFiles, setSelectedFiles] = useState<AllItemsFlattenedModel[]>([]);

  const rows = useMemo(() => data || [], [data]);

  const files = useMemo(() => rows ? rows.filter(({ type }: AllItemsFlattenedModel) => type === 'file') : [], [rows]);

  const handleRequestSort = useCallback((event: MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  }, [order, orderBy]);

  const handleSelectAllClick = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSelectedFiles(files)
      return;
    }

    setSelectedFiles([]);
  }, [files]);

  const handleCheck = useCallback((event: ChangeEvent<HTMLInputElement>, rowId: number) => {
    if (event.target.checked) {
      const selectedFile = rows.find(({ id, type }: AllItemsFlattenedModel) => id === rowId && type === 'file');

      if (selectedFile) {
        setSelectedFiles([...selectedFiles, selectedFile]);
      }
      return;
    }

    setSelectedFiles(selectedFiles.filter(({ id }) => id !== rowId));
  }, [rows, selectedFiles]);

  const isSelected = useCallback((rowId: number) =>
    selectedFiles.findIndex(({ id }) => id === rowId) !== -1,
    [selectedFiles]
  );

  if (!rows.length) {
    return (
      <EmptyWrapper>
        <EmptyText>{t('account.companySecretary.emptyText')}</EmptyText>
        <EmptyBg src={EmptyDocs} />
      </EmptyWrapper>
    );
  }

  return (
    <TableWrapper>
      <TableToolbar selected={selectedFiles} />
      <TableContainer>
        <StyledTable
          aria-labelledby="tableTitle"
          size="medium"
          aria-label="company secretary table"
        >
          <TableHeader
            numSelected={selectedFiles.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={files.length}
            showSelectAll={files.length > 0}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy), orderBy)
              .slice((page - 1) * pageSize, (page - 1) * pageSize + pageSize)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id as number);
                const labelId = `company-secretary-table-checkbox-${index}`;
                const date = row.last_date ? getCreatedDate(row.last_date) : '';

                return (
                  <StyledTableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={`${row.id}_${row.type}`}
                    selected={isItemSelected}
                  >
                    {files.length > 0 && (
                      <TableCell padding="checkbox">
                        {row.type === 'file' && (
                          <Checkbox
                            onChange={(event) => handleCheck(event, row.id as number)}
                            checked={isItemSelected}
                            inputProps={{ 'aria-labelledby': labelId }}
                          />
                        )}
                      </TableCell>
                    )}
                    <StyledTableCell component="th" id={labelId} scope="row">
                      <ItemLink row={row} highlight={highlight} isSearchPage={isSearchPage} />
                    </StyledTableCell>
                    <TableCell align="right">{date}</TableCell>
                  </StyledTableRow>
                );
              })}
          </TableBody>
        </StyledTable>
      </TableContainer>
      <StyledPagination>
        <Pagination
          page={page}
          pageSize={pageSize}
          showRowsPerPage={false}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          count={rows.length}
        />
      </StyledPagination>
    </TableWrapper>
  );
}

export default CompanySecretaryTable;
