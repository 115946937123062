import React from 'react';

import FormTextInput from '../../../../../../../../components/ui/FormTextInput';
import { useValidators } from '../../../../../../../service/utils/validation';
import { useTranslation } from 'react-i18next';

type BirthdayProps = {
  formPrefix: string;
  tPrefix: string;
  required?: boolean;
};

export const Birthday = (props: BirthdayProps) => {
  const { formPrefix, tPrefix, required = true } = props;

  const validators = useValidators();
  const { t } = useTranslation();

  return (
    <FormTextInput
      name={`${formPrefix}_birthday`}
      label={`${t(`${tPrefix}.peopleForm.individual.birthday`)} *`}
      validate={required ? validators.is_required : undefined}
      initialValue=""
      type="date"
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={{
        inputProps: {
          max: new Date().toISOString().split('T')[0],
        },
      }}
    />
  );
};
