import { useTranslation } from 'react-i18next';

import TableContainer from '../../../../../../components/ui/TableContainer';
import Table from '../../../../../../components/ui/Table';
import TableHead from '../../../../../../components/ui/TableHead';
import TableRow from '../../../../../../components/ui/TableRow';
import TableBody from '../../../../../../components/ui/TableBody';

import { BillingsListProps } from '../BillingsList';
import BillingsListItemDesktop from '../BillingsListItemDesktop';

import { ColumnTitle } from './styled';
import { getIsBillingsContainDescriptions } from '../../../../utils/messages';

const BillingsDesktopView = (props: BillingsListProps) => {
  const { billings } = props;
  const { t } = useTranslation();

  const isBillingsContainDescriptions = getIsBillingsContainDescriptions(billings);

  return (
    <TableContainer component="div">
      <Table size="medium" aria-label="requests table">
        <TableHead>
          <TableRow>
            <ColumnTitle>{t('account.invoices.list.date')}</ColumnTitle>
            {isBillingsContainDescriptions ? <ColumnTitle>{t('account.invoices.list.description')}</ColumnTitle> : null}
            <ColumnTitle>{t('account.invoices.list.status')}</ColumnTitle>
            <ColumnTitle>{t('account.invoices.list.amount')}</ColumnTitle>
            <ColumnTitle>{t('account.invoices.list.number')}</ColumnTitle>
            <ColumnTitle />
          </TableRow>
        </TableHead>
        <TableBody>
          {billings && billings.map((billing) => (
            <BillingsListItemDesktop key={billing.id} billing={billing} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BillingsDesktopView;
