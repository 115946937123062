import styled from 'styled-components';
import Typography, { Props as TypographyProps } from '../../../../components/ui/Typography';

export const ContentWrapper = styled.div`
  flex: 1;
  display: inline-flex;
  flex-direction: column;
  min-height: 250px;
  position: relative;
`;

export const StyledSubTitle = styled(Typography)<TypographyProps>`
  color: rgba(35, 33, 33, 0.6);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
  }
`;
