import styled from 'styled-components';
import CheckMarkIcon from '../../../../assets/img/services/check.svg';
import { Grid } from '@material-ui/core';

export const StyledGrid = styled(Grid)`
  height: 100%;
`;

export const ServicesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-right: 30px;
`;

export const ServicesTitle = styled.div`
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;

  &.scrolled {
    ${(p) => p.theme.breakpoints.down('sm')} {
      display: none;
    }
  }
`;

export const ServicesDescription = styled.div`
  color: #232121;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 32px;
  margin-bottom: 48px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    margin-bottom: 16px;
  }

  p {
    margin: 0 0 15px;
  }

  & > ul {
    margin: 9px 0 0 0;
    padding: 0;

    & > li {
      background: url(${CheckMarkIcon}) no-repeat left 27px;
      border-bottom: 1px solid #E5E4E4;
      color: #232121;
      font-weight: 600;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.1px;
      padding: 25px 0 25px 48px;
      position: relative;
      display: flex;
      align-items: start;
      flex-direction: column;

      &:last-child {
        border-bottom: none;
      }

      & > ul {
        margin-top: 14px;
        font-weight: 400;

        & > li {
          list-style-type: disc;
        }
      }
    }
  }
`;

export const ServicesChildrenWrapper = styled.div`
  margin-top: 9px;
`;

export const ServicesChild = styled.div`
  svg {
    position: absolute;
    top: 55%;
    left: 5px;
    transform: translate(0, -53%);
  }
`;

export const DownloadExampleWrapper = styled.div`
  justify-self: flex-end;
  margin-top: auto;

  svg {
    margin-right: 12px;
  }

  p {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    margin: 0;
    padding: 0;
  }
`;

export const CheckoutCardWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 48px;
  margin-bottom: 48px;
  height: 100%;
  width: 100%;

  ${(p) => p.theme.breakpoints.down('sm')} {
    align-items: center;
    margin-top: 24px;
  }
`;

export const SignWrapper = styled.div`
  margin-top: 24px;
  margin-bottom: 48px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    display: none;
  }

  svg {
    max-width: 100%;
  }
`;

export const Divider = styled.div`
  background: rgba(35, 33, 33, 0.12);
  margin-bottom: 24px;
  height: 1px;
  width: 100%;
`;

export const ComingSoonTitle = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(35, 33, 33, 0.6);
  margin-bottom: 24px;
`;
