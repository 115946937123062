import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SettingsCard from '../../../../../../components/SettingsCard';
import Box from '../../../../../../components/ui/Box';
import Typography from '../../../../../../components/ui/Typography';
import Switch from '../../../../../../components/ui/Switch';

function NotifySettings() {
  const { t } = useTranslation();

  const [ switchState, setSwitchState ] = useState({
    newMessages: true,
    paymentStatus: false,
    requestStatus: false,
  });

  const switchHandleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSwitchState({ ...switchState, [event.target.name]: event.target.checked });
  };

  return (
    <Box width={{ sm: '100%', md: '50%' }} minWidth="276px">
      <SettingsCard title={t('account.settings.notify.title')}>
        <Box mb={5} display="flex">
          <Box flex={1}>
            <Box mb={0.5}>
              <Typography variant="h6" component="h6">{t('account.settings.notify.requestStatus')}</Typography>
            </Box>
            <Typography variant="subtitle2" color="secondary">
              {t('account.settings.notify.emailText')}
              &nbsp;
              {switchState.requestStatus
                ? t('account.settings.notify.active')
                : t('account.settings.notify.disabled')
              }
            </Typography>
          </Box>
          <Switch
            checked={switchState.requestStatus}
            onChange={switchHandleChange}
            color="primary"
            name="requestStatus"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Box>

        <Box mb={5} display="flex">
          <Box flex={1}>
            <Box mb={0.5}>
              <Typography variant="h6" component="h6">{t('account.settings.notify.paymentStatus')}</Typography>
            </Box>
            <Typography variant="subtitle2" color="secondary">
              {t('account.settings.notify.emailText')}
              &nbsp;
              {switchState.paymentStatus
                ? t('account.settings.notify.active')
                : t('account.settings.notify.disabled')
              }
            </Typography>
          </Box>
          <Switch
            checked={switchState.paymentStatus}
            onChange={switchHandleChange}
            color="primary"
            name="paymentStatus"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Box>

        <Box display="flex">
          <Box flex={1}>
            <Box mb={0.5}>
              <Typography variant="h6" component="h6">{t('account.settings.notify.newMessages')}</Typography>
            </Box>
            <Typography variant="subtitle2" color="secondary">
              {t('account.settings.notify.emailText')}
              &nbsp;
              {switchState.newMessages
                ? t('account.settings.notify.active')
                : t('account.settings.notify.disabled')
              }
            </Typography>
          </Box>
          <Switch
            checked={switchState.newMessages}
            onChange={switchHandleChange}
            color="primary"
            name="newMessages"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </Box>
      </SettingsCard>
    </Box>
  );
}

export default NotifySettings;
