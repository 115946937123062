import { NUMBERING } from '../../../../../utils/constants';

type GenerateTabProps = {
  numbering: 'digits' | 'alphabet';
  tabName: string;
  index: number;
  isDefault?: boolean;
  id?: string;
};

type FormTabState = {
  id: string;
  name: string;
};

export function generateTab({ tabName, index, isDefault, id, numbering }: GenerateTabProps): FormTabState {
  // Make class management with A + B + B etc.
  const numberingString = numbering === 'alphabet' && index > 1
    ? NUMBERING[numbering][1]
    : NUMBERING[numbering][index];

  const tabNameFormatted = `${tabName}${isDefault ? ` ${numberingString}` : ''}`;

  const randomId = `${Math.floor(Math.random() * 10000000) + 1}`;
  const newId = isDefault ? randomId : (id || randomId);

  return { id: newId, name: tabNameFormatted };
}

type AddNewTabProps = {
  tabs: FormTabState[];
  tabName: string;
  numbering: 'digits' | 'alphabet';
  maxTabs: number;
  setTabs: (tabs: FormTabState[]) => void;
  setActiveTab: (tab: FormTabState) => void;
};

export function addNewTab({ tabs, setActiveTab, setTabs, maxTabs, tabName, numbering }: AddNewTabProps) {
  return () => {
    const tabIndex = tabs.length;

    if (maxTabs && (tabIndex >= maxTabs)) {
      return;
    }

    const newTab = generateTab({ tabName, index: tabIndex, numbering });

    setTabs([...tabs, newTab]);
    setActiveTab(newTab);
  };
}

type RemoveExistentTabProps = {
  tabs: FormTabState[];
  setTabs: (tabs: FormTabState[]) => void;
  setActiveTab: (tab: FormTabState) => void;
};

export function removeExistentTab({ tabs, setActiveTab, setTabs }: RemoveExistentTabProps) {
  return (tabId: string) => () => {
    const removingTabIndex = tabs.findIndex(tab => tab.id === tabId);
    const previousTabIndex = removingTabIndex !== 0 ? removingTabIndex - 1 : 0;

    const newTabs = tabs.filter(({ id }) => id !== tabId);
    const newActiveTab = previousTabIndex !== -1 ? newTabs[previousTabIndex] : newTabs[0];

    setTabs(newTabs);
    setActiveTab(newActiveTab);
  };
}
