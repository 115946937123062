export const getDocumentStatus = (status: string, t: any) => {
  switch (status) {
    case 'uploaded':
      return t('account.documents.statuses.uploaded');
    case 'draft':
      return t('account.documents.statuses.draft');
    case 'sent':
      return t('account.documents.statuses.generated');
    case 'completed':
      return t('account.documents.statuses.completed');
    case 'viewed':
      return t('account.documents.statuses.underReview');
    case 'waiting_approval':
      return t('account.documents.statuses.waitingApproval');
    case 'approved':
      return t('account.documents.statuses.approved');
    case 'rejected':
      return t('account.documents.statuses.rejected');
    case 'waiting_pay':
      return t('account.documents.statuses.waitingPay');
    case 'paid':
      return t('account.documents.statuses.paid');
    case 'voided':
      return t('account.documents.statuses.voided');
    case 'declined':
      return t('account.documents.statuses.declined');
    case 'external_review':
      return t('account.documents.statuses.externalReview');
    default:
      return status;
  }
};
