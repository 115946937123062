import request from '../../../network/mainClient/request';

export interface GetDocumentSessionParams {
  documentId: number;
}

export type SubmitDocumentParams = {
  taskId: number;
  processId: number;
  processName: string;
};

export const submitDocuments = ({ processName, processId, taskId }: SubmitDocumentParams) => request({
  url: `/api/v1/processes/${processName}/${processId}/documents_submit/${taskId}/`,
  method: 'POST',
  contentType: 'json',
  body: {
    documents_submitted: true,
  },
}).then((result) => result.data);

export const getDocumentSession = ({ documentId }: GetDocumentSessionParams) => request({
  url: `/api/v1/pandadoc/documents/${documentId}/session-id`,
  method: 'GET',
  contentType: 'json',
}).then((result) => result.data);

export const downloadDocument = ({ documentId }: GetDocumentSessionParams) => request({
  url: `/api/v1/pandadoc/documents/${documentId}/download`,
  method: 'GET',
  contentType: 'pdf',
  responseType: 'blob',
}).then((result) => result.data);
