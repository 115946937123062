import React, { useEffect, useCallback, SyntheticEvent, forwardRef, ForwardedRef, useRef } from 'react';
import { BrowserRouter, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import ArrowBack from '@material-ui/icons/ArrowBack';

import LoaderProgress from '../../LoaderProgress';
import Button from '../../ui/Button';
import Hidden from '../../ui/Hidden';

import {
  Root,
  Container,
  TopCloseWrapper,
  CloseButton,
  CloseIcon,
  CloseText,
  ContentWrapper,
  HeadBox,
  BackText,
  HeadTitle,
  HeadActions,
  HiddenBackground,
} from './styled';

const bodyNoScrollClassName = '_no-scroll';

interface ModalProps {
  open: boolean;
  onRequestClose?: () => void;
  closeOnOutsideClick?: boolean;
  customBack?: () => void;
  content: React.ReactNode;
  level?: number;
  loading?: boolean;
  rightHeadActions?: React.ReactNode | null;
  noBg?: boolean;
  shiftToRight?: boolean;
  routerModal?: boolean;
  hideMobileOverflow?: boolean;

  isScrolled?: boolean;
  setIsScrolled?: (isScrolled: boolean) => void
  titleToHead?: string;
}

const BaseModal = forwardRef((props: ModalProps, rootRef: ForwardedRef<HTMLDivElement>, ) => {
  const {
    content,
    level,
    loading = false,
    onRequestClose,
    closeOnOutsideClick = true,
    hideMobileOverflow = false,
    customBack,
    rightHeadActions,
    noBg,
    shiftToRight,
    routerModal,
    isScrolled,
    setIsScrolled,
    titleToHead,
  } = props;

  const history = useHistory();
  const { t } = useTranslation();
  const headBoxRef = useRef<HTMLDivElement>(null);

  const onContainerClick = useCallback(
    (e: SyntheticEvent) => e.stopPropagation(),
    []
  );

  const onRequestCloseHandler = useCallback(() => {
    if (onRequestClose) {
      onRequestClose();
      return;
    }
    if (routerModal) {
      history.goBack();
    }
  }, [routerModal, history, onRequestClose]);

  const handleScroll = useDebouncedCallback((e: SyntheticEvent) => {
    const { scrollTop } = e.target as HTMLDivElement;

    if (scrollTop > 0) {
      headBoxRef?.current?.classList?.add('with-shadow');
      document.getElementById(`root-modal-container-${level}`)?.classList?.add('scrolled');
      setIsScrolled && setIsScrolled(true);
    } else {
      headBoxRef?.current?.classList?.remove('with-shadow');
      document.getElementById(`root-modal-container-${level}`)?.classList?.remove('scrolled');
      setIsScrolled && setIsScrolled(false);
    }
  }, 50, { leading: true });

  useEffect(() => {
    const keyHandler = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        onRequestCloseHandler();
      }
    };

    if (!document.body.classList.contains(bodyNoScrollClassName)) {
      document.body.classList.add(bodyNoScrollClassName);
    }

    window.addEventListener('keydown', keyHandler);

    if (closeOnOutsideClick) {
      setTimeout(() => document.addEventListener('click', onRequestCloseHandler), 0);
    }

    return () => {
      if (document.body.classList.contains(bodyNoScrollClassName)) {
        document.body.classList.remove(bodyNoScrollClassName);
      }

      window.removeEventListener('keydown', keyHandler);
      document.removeEventListener('click', onRequestCloseHandler);
    };
  }, [closeOnOutsideClick, onRequestCloseHandler]);

  return (
    <BrowserRouter>
      <Root noBg={noBg} onClick={onContainerClick}>
        <HiddenBackground />
        <TopCloseWrapper>
          <CloseButton onClick={onRequestCloseHandler}>
            <CloseIcon />
            <CloseText>{t('modal.close')}</CloseText>
          </CloseButton>
        </TopCloseWrapper>
        <Container id={`root-modal-container-${level}`} shiftToRight={shiftToRight} ref={rootRef || null} onScroll={handleScroll}>
          <HeadBox ref={headBoxRef}>
            <div>
              <Button
                onClick={customBack || onRequestCloseHandler}
                startIcon={<ArrowBack />}
              >
                <BackText>{t('actions.back')}</BackText>
              </Button>
            </div>

            {titleToHead && isScrolled && <Hidden mdUp><HeadTitle>{titleToHead}</HeadTitle></Hidden>}

            {rightHeadActions && (
              <HeadActions>
                {rightHeadActions}
              </HeadActions>
            )}
          </HeadBox>
          <ContentWrapper hideMobileOverflow={hideMobileOverflow}>
            {loading ? <LoaderProgress /> : (content && content)}
          </ContentWrapper>
        </Container>
      </Root>
    </BrowserRouter>
  );
});

export default BaseModal;
