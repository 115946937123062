import React, { useRef } from 'react';

import { ModalItemBaseProps, openModal } from '../../../../../../containers/ModalsContainer';
import { FilteredMessageModel } from '../../../../network/models';
import BaseModal from '../../../../../../components/Modal/BaseModal';

import Message from '../Message';

type MessageModalProps = {
  message: FilteredMessageModel;
};

const MessageModal = (props: ModalItemBaseProps & MessageModalProps) => {
  const {
    modalLevel,
    modalIsOpen,
    modalRequestClose,
    message,
  } = props;

  const rootRef = useRef<HTMLDivElement | null>(null);

  return (
    <BaseModal
      noBg
      ref={rootRef}
      open={modalIsOpen}
      level={modalLevel}
      onRequestClose={modalRequestClose}
      closeOnOutsideClick={false}
      hideMobileOverflow
      content={<Message message={message} />}
    />
  );
};

export const openMessageModal = (
  props?: MessageModalProps,
): ReturnType<typeof openModal> => {
  return openModal(MessageModal, props, 'messages');
};

export default MessageModal;
