import React, { useCallback, useEffect, useState } from 'react';
import { Field, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

import { ReactComponent as DeleteIcon } from '../../../../../../assets/img/services/close-icon.svg';
import { NUMBERING } from '../../../../utils/constants';
import {
  composeValidators,
  useValidators,
  UseValidatorsReturnProps,
} from '../../../../../service/utils/validation';

import Box from '../../../../../../components/ui/Box';
import Grid from '../../../../../../components/ui/Grid';
import Radio from '../../../../../../components/ui/Radio';
import RadioGroup from '../../../../../../components/ui/RadioGroup';
import FormTextInput from '../../../../../../components/ui/FormTextInput';
import FormControlLabel from '../../../../../../components/ui/FormControlLabel';

import { addNewTab, generateTab } from '../../../ProcessForm/forms/CompanyIncorporation/utils/tabs';

import {
  StyledTabs,
  TabPanels,
  FormInputLabel,
  TabsWrapper,
  StyledButton,
  ChipsWrapper,
  ChipWrapper,
  StyledChip,
} from '../../../ProcessForm/forms/CompanyIncorporation/components/PeopleForm/styled';

export type FormTabState = {
  id: string;
  name: string;
};

type ShareholdersFormProps = {
  tPrefix: string;
};

function ShareholdersForm(props: ShareholdersFormProps) {
  const { tPrefix } = props;

  const { t } = useTranslation();
  const validators: UseValidatorsReturnProps = useValidators();

  const formState = useFormState();

  const numbering = 'digits';
  const minTabs = 1;
  const maxTabs = 50;
  const formPrefix = 'shareholders';
  const tabName = t(`${tPrefix}.${formPrefix}.one`);

  const defaultTabs = [generateTab({ tabName, index: 0, isDefault: true, numbering })];

  const [tabs, setTabs] = useState<FormTabState[]>(defaultTabs);
  const [activeTab, setActiveTab] = useState(defaultTabs[0]);

  const showAddNewButton = !!tabs.length && (!maxTabs || (maxTabs && (maxTabs > tabs.length)));

  const addTab = addNewTab({ setTabs, setActiveTab, numbering, tabName, tabs, maxTabs });

  const removeTab = useCallback((tabId: string) => () => {
    const removingTabIndex = tabs.findIndex(tab => tab.id === tabId);
    const previousTabIndex = removingTabIndex !== 0 ? removingTabIndex - 1 : 0;

    const newTabs = tabs.filter(({ id }) => id !== tabId);
    const newActiveTab = previousTabIndex !== -1 ? newTabs[previousTabIndex] : newTabs[0];

    setTabs(newTabs);
    setActiveTab(newActiveTab);
  }, [tabs]);

  const handleSetActiveTab = useCallback((tab: FormTabState) => () => {
    setActiveTab(tab);
  }, []);

  const renderTab = (tab: FormTabState) => {
    const tabId = tab.id;
    const isActive = tabId === activeTab.id;

    const removeProps = tabs.length > minTabs ? { onDelete: removeTab(tabId) } : {};

    return (
      <ChipWrapper isActive={isActive} key={tab.id}>
        <StyledChip
          onClick={handleSetActiveTab(tab)}
          label={tab.name}
          deleteIcon={<DeleteIcon/>}
          {...removeProps}
        />
      </ChipWrapper>
    );
  };

  useEffect(() => {
    tabs.forEach((tab, index) => {
      const tabIndividualNameInputId = `${formPrefix}_${tab.id}_name`;

      const individualName = formState.values[tabIndividualNameInputId];

      tab.name = individualName
        || `${tabName} ${NUMBERING[numbering][index]}`;
    });
  }, [formPrefix, formState, minTabs, numbering, setTabs, t, tPrefix, tabName, tabs]);

  useEffect(() => {
    Object.keys(formState.values).forEach((key) => {
      if (key.includes(`${formPrefix}_`)) {
        const foundTab = tabs.find((tab) => key.includes(`${tab.id}`));

        if (!foundTab) {
          delete formState.values[key];
        }
      }
    });
  }, [formPrefix, formState.values, tabs, tabs.length]);

  return (
    <TabsWrapper>
      <StyledTabs aria-label={`tabs of ${tabName}s`}>
        <ChipsWrapper>
          {tabs.map(renderTab)}
        </ChipsWrapper>

        {showAddNewButton ? (
          <StyledButton
            onClick={addTab}
            startIcon={<AddIcon/>}
            size="small"
          >{t(`${tPrefix}.peopleForm.addButton`)}</StyledButton>
        ) : null}
      </StyledTabs>

      <TabPanels>
        {tabs.map((tab) => {
          const tabId = tab.id;
          const isActive = tabId === activeTab.id;

          if (!isActive) {
            return null;
          }

          return (
            <Box key={tabId} display="flex" flexDirection="column">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Box ml={1} mb={2}>
                    <RadioGroup row>
                      <Field
                        name={`${formPrefix}_${tabId}_account_type`}
                        value={t(`${tPrefix}.peopleForm.individual.label`)}
                        type="radio"
                        initialValue={t(`${tPrefix}.peopleForm.individual.label`)}
                      >
                        {({ input }) => (
                          <FormControlLabel
                            control={<Radio color="default" {...input}  />}
                            label={<FormInputLabel>{t(`${tPrefix}.peopleForm.individual.label`)}</FormInputLabel>}
                          />
                        )}
                      </Field>
                      <Field
                        name={`${formPrefix}_${tabId}_account_type`}
                        value={t(`${tPrefix}.peopleForm.entity.label`)}
                        type="radio"
                      >
                        {({ input }) => (
                          <FormControlLabel
                            control={<Radio color="default" {...input}  />}
                            label={<FormInputLabel>{t(`${tPrefix}.peopleForm.entity.label`)}</FormInputLabel>}
                          />
                        )}
                      </Field>
                    </RadioGroup>
                  </Box>

                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormTextInput
                        name={`${formPrefix}_${tabId}_name`}
                        label={`${t(`${tPrefix}.peopleForm.individual.name`)} *`}
                        validate={validators.is_required}
                        initialValue=""
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <FormTextInput
                        name={`${formPrefix}_${tabId}_email`}
                        label={`${t(`${tPrefix}.${formPrefix}.contacts.email`)} *`}
                        validate={composeValidators(validators.email, validators.is_required)}
                        type="email"
                        initialValue=""
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </TabPanels>
    </TabsWrapper>
  );
}

export default ShareholdersForm;
