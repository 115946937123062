import { useCallback } from 'react';
import { Link } from 'react-router-dom';

import useAvatarLetter from '../../../../hooks/useAvatarLetter';
import { CurrentAccountProps, useCabinetContext } from '../../../../containers/CabinetContainer/context';
import Box from '../../../ui/Box';

import { StyledAvatar, StyledMenuItem, UserName, UserStatus } from '../UserMenu/styled';
import { getAccountAvatarIconSrc } from '../UserMenu';

type UserMenuAccountsListItemProps = {
  account: CurrentAccountProps;
  handleClose: () => void;
};

const UserMenuAccountsListItem = (props: UserMenuAccountsListItemProps) => {
  const { account, handleClose } = props;
  const { setCurrentAccount } = useCabinetContext();
  const avatarLetter = useAvatarLetter([account?.name || '']);

  const handleChooseAccount = useCallback(
    () => {
      if (account) {
        handleClose();
        setCurrentAccount(account);
      }
    },
    [account, handleClose, setCurrentAccount],
  );

  return (
    <StyledMenuItem
      component={Link}
      display="flex"
      onClick={handleChooseAccount}
      alignItems="center"
      to={`/cabinet/account/${account?.id}`}
    >
      <StyledAvatar alt={account?.name} src={getAccountAvatarIconSrc(account)}>
        {avatarLetter}
      </StyledAvatar>
      <Box ml={1.5}>
        <Box mb={0.5}>
          <UserName>
            {account?.name}
          </UserName>
        </Box>
        <UserStatus>{account?.account_type}</UserStatus>
      </Box>
    </StyledMenuItem>
  );
};

export default UserMenuAccountsListItem;
