import styled from 'styled-components';
import { ButtonGroup as MuiButtonGroup } from '@material-ui/core';


export const ButtonGroup = styled((props) =>
  <MuiButtonGroup
    color="primary"
    aria-label="outlined primary button group"
    variant="outlined"
    {...props}
  />
)`
  border-radius: 10px;

  & .MuiButton-containedPrimary {
    border: 1px solid transparent;
  }

  & .MuiButton-root {
    padding: 6px 16px;

    background: #F5F5F5;
    border-radius: 10px;
    border-color: #fff;
    border-top-width: 0;
    border-bottom-width: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 1.5px;
  }

  & .MuiButton-containedPrimary {
    background-color: #fff;
    color: #232121;
  }

  .MuiButtonGroup-groupedHorizontal{
    &:not(:first-child) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &:not(:last-child) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

export type { ButtonGroupProps as Props } from '@material-ui/core';
export default ButtonGroup;
