import { MESSAGES_NAME_EZSTART, MESSAGES_NAME_ME } from './constants';
import { MessageModel, FilteredMessageModel, FilteredMessagesModel, BillingModel } from '../network/models';

export const getFromEmailName = (type: string): string => {
  switch (type) {
    case 'inbox':
      return MESSAGES_NAME_EZSTART;
    case 'outbox':
      return `${MESSAGES_NAME_EZSTART}, ${MESSAGES_NAME_ME}`;
    default:
      return '';
  }
};

export const removeReplyTextRegExp = /\d{2,}\.\d{2,}.\d{4,},\s+\d{2,}:\d{2,},\s+".+"\s+<.+>.+/g;

export const getIsBillingsContainDescriptions = (billings: BillingModel[]): boolean =>
  billings.every(b => b.description && b.description.length > 0);

export const addInboxTypeForMessages = (message: MessageModel) => ({ ...message, type: 'inbox' });
export const addOutboxTypeForMessages = (message: MessageModel) => ({ ...message, type: 'outbox' });

type EmailBodyParams = {
  body_text: string | null;
};

export const getTrimmedEmailBodyString = ({ body_text }: EmailBodyParams): string => {
  const bodyString = (body_text || '')
    .replace(removeReplyTextRegExp, '')
    .replace(/<\/?[^>]+(>|$)/g, '')
    .slice(0, 60);

  return `${bodyString}${bodyString.length >= 60 ? '...' : ''}`;
}

export const sortMessagesByDate = (a: FilteredMessageModel, b: FilteredMessageModel) =>
  (b.date ? new Date(b.date).valueOf() : 0) - (a.date ? new Date(a.date).valueOf() : 0);

export type GetHistoryMessagesParams = {
  data: {
    [key: string]: FilteredMessagesModel,
  } | null,
  messageSubject: string;
};

export const getHistoryMessages = ({ data, messageSubject }: GetHistoryMessagesParams): FilteredMessageModel[] => {
  const results: FilteredMessageModel[] = [];

  if (data !== null) {
    Object.keys(data).forEach((key: string) => {
      data[key]?.results.forEach((message: FilteredMessageModel) => {
        const { subject } = message;
        if (messageSubject === subject) {
          results.push(message);
        }
      });
    });
  }

  return results.sort(sortMessagesByDate);
};
