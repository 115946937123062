import React, { useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';

import useAvatarLetter from '../../../../hooks/useAvatarLetter';
import { CurrentAccountProps, useCabinetContext } from '../../../../containers/CabinetContainer/context';
import { getAccountAvatarIconSrc } from '../../../../components/CabinetLayout/components/UserMenu';
import Button from '../../../../components/ui/Button';
import Box from '../../../../components/ui/Box';

import { StyledAvatar, StyledCard, StyledAccountName } from './styled';
import { openConfirmDialogModal } from '../../../../components/Modal/ConfirmModal';
import useAccountRemove from '../../hooks/accounts/actions/useAccountRemove';
import LoaderProgress from '../../../../components/LoaderProgress';

export type AccountListItemProps = {
  item: CurrentAccountProps;
  loadData: () => void;
};

const AccountListItem = ({ item, loadData }: AccountListItemProps) => {
  const { setCurrentAccount } = useCabinetContext();
  const { t } = useTranslation();

  const [, isRemoveLoading,, removeAccount] = useAccountRemove();

  const avatarLetter = useAvatarLetter([item?.name || '']);
  const removeButton = t('account.list.buttons.remove');
  const removeDialogTitle = t('account.list.remove.title');
  const removeDialogDescription = t('account.list.remove.description');

  const handleChooseAccount = useCallback(() => {
    if (item) {
      setCurrentAccount(item);
    }
  }, [setCurrentAccount, item]);

  // const onEditClick = (e: React.MouseEvent) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   e.nativeEvent.stopImmediatePropagation();
  // }

  const removeCompany = async () => {
    if (item) {
      await removeAccount(`${item.id}`);
      await loadData();
    }
  };

  const onRemoveClick = (e: React.MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();

    openConfirmDialogModal({
      title: removeDialogTitle,
      content: removeDialogDescription,
      action: removeCompany,
      submitLabel: removeButton,
      buttonMode: 'remove',
    });

    // remove active focus from the remove button to prevent activating after enter or space key press
    // @ts-ignore
    document.activeElement.blur();
  }

  return (
    <Box mb={2} position="relative">
      {isRemoveLoading && <LoaderProgress />}
      <StyledCard>
        <CardActionArea
          disableTouchRipple // for disable nested ripple
          component={Link}
          to={`/cabinet/account/${item?.id}`}
          onClick={handleChooseAccount}
        >
          <CardContent style={{ paddingRight: 16 }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              flexWrap="wrap"
            >
              <StyledAvatar alt={item?.name} src={getAccountAvatarIconSrc(item)}>
                {avatarLetter}
              </StyledAvatar>
              <Box
                flexBasis={{ xs: '100%', sm: 'auto' }}
                order={{ xs: 1, sm: 0 }}
                mr={{ xs: 0, sm: 2 }}
                ml={{ xs: 0, sm: 3 }}
                mt={{ xs: 2, sm: 0 }}
                flexGrow={1}
              >
                <Box mb={0.5}>
                  <StyledAccountName>
                    {item?.name}
                  </StyledAccountName>
                </Box>
                <Typography variant="body2" color="textSecondary" component="p">
                  {item?.account_type} ({item?.status})
                </Typography>
              </Box>
              <Button onClick={onRemoveClick}>{removeButton}</Button>
            </Box>
          </CardContent>
        </CardActionArea>
      </StyledCard>
    </Box>
  );
}

export default AccountListItem;
