import React from 'react';
import styled, { css } from 'styled-components';
import { Button, ButtonProps } from '@material-ui/core';

const StyledButton = styled(<C extends React.ElementType>(
  props: ButtonProps<C, { component?: C }>
) => <Button {...props} />)`
  border-radius: 10px;
`;

const StyledPrimaryButton = styled((props) => <StyledButton variant="contained" color="default" {...props} />)`
  color: #fff;
  background-color: #232121;
  box-shadow: 0 0.25px 1px rgba(35, 33, 33, 0.039), 0 0.85px 3px rgba(35, 33, 33, 0.19);

  &:hover {
    background-color: #585656;
  }
`;

export const SecondaryButton = styled((props) => <StyledButton {...props} />)`
  padding-left: 16px;
  padding-right: 16px;
  color: #fff;
  background-color: ${(p) => p.theme.palette.secondary.light};
  box-shadow: 0 0.25px 1px rgba(35, 33, 33, 0.039), 0 0.85px 3px rgba(35, 33, 33, 0.19);

  &:hover {
    background-color: ${(p) => p.theme.palette.secondary.light};
  }
`;

export const ErrorButton = styled((props) => <StyledButton {...props} />)`
  ${(p) => p.variant === 'contained' ? css`
    color: #fff;
    background-color: ${(p) => p.theme.palette.error.main};
    box-shadow: 0 0.25px 1px rgba(35, 33, 33, 0.039), 0 0.85px 3px rgba(35, 33, 33, 0.19);

    &:hover {
      background-color: ${(p) => p.theme.palette.error.main};
    }
  ` : css`
    color: ${(p) => p.theme.palette.error.main};
  `}
`;

const StyledDashedLongButton = styled((props) => <StyledButton {...props} />)`
  width: 100%;
  height: 80px;
  border-radius: 12px;
  background-color: #F5F5F5;
  border: 1px dashed rgba(35, 33, 33, 0.6);
`;

export const DashedLongButton = StyledDashedLongButton;

export const OutlinedButton = styled((props) => (<StyledButton variant="outlined" {...props} />))``;

export const PrimaryButton = StyledPrimaryButton;

export default StyledButton;

export type { ButtonProps as Props } from '@material-ui/core/Button';
