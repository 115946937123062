import React from 'react';

import { ReactComponent as FolderIcon } from '../../../../../../assets/img/companySecretary/folder-icon.svg';
import { ReactComponent as FileIcon } from '../../../../../../assets/img/companySecretary/file-icon.svg';

import { AllItemsFlattenedModel } from '../../../../utils/companySecretary';
import Highlighted from '../../../../../../components/Highlighted';

import {
  StyledLink,
  StyledNativeLink,
  StyledIcon,
} from './styled';

type CompanySecretaryTableProps = {
  row: AllItemsFlattenedModel;
  highlight?: string;
  isSearchPage?: boolean;
};

const ItemLink = ({ row, highlight, isSearchPage }: CompanySecretaryTableProps) => {
  const folderLink = `/${row.path}`;

  const name = highlight ? <Highlighted text={row.title} highlight={highlight} isSearchPage={isSearchPage} /> : row.title;

  const linkContent = (
    <>
      <StyledIcon>
        {row.type === 'folder' ? <FolderIcon /> : <FileIcon />}
      </StyledIcon>
      {name}
    </>
  );

  return row.type === 'folder'
    ? (
      <StyledLink to={folderLink}>
        {linkContent}
      </StyledLink>
    )
    : (
      <StyledNativeLink href={('file' in row && row.file) || ''} target="__blank">
        {linkContent}
      </StyledNativeLink>
    );
}

export default ItemLink;
