import styled from 'styled-components';

import Box from '../../../../components/ui/Box';
import BgImg from '../../../../assets/img/backgrounds/requests-bg.png';

export const ProcessesTitle = styled.div`
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;
  color: #232121;
`;

export const ProcessesTotal = styled.span`
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: rgba(35, 33, 33, 0.6);
  margin-left: 12px;
`;

export const AsideBgImg = styled.div`
  width: 100%;
  padding-bottom: 80%;
  margin-bottom: 20px;
  background-image: url(${BgImg});
  background-position: 50% 100%;
  background-repeat: no-repeat;
  background-size: contain;

  ${(p) => p.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const EmptyContent = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
`;

export const EmptyScreenText = styled.div`
  color: rgba(35, 33, 33, 0.6);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;
`;

export const EmptyBg = styled.img`
  max-width: 610px;
  width: 100%;
  margin-top: auto;
`;
