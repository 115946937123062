import { useCallback, useState } from 'react';

import RequestError from '../../../../../network/RequestError';
import { UpdateProcessParams } from '../../../network';
import { updateProcess } from '../../../network';
import { ProcessResultModel } from '../../../network/models';

type ResultData = ProcessResultModel | null;

const useProcessUpdate = (): [
  ResultData,
  boolean,
    Error | null,
  (params: UpdateProcessParams) => void,
] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useCallback(async (params: UpdateProcessParams) => {
    setIsLoading(true);

    setError(null);

    try {
      const response = await updateProcess(params);

      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, [setIsLoading, setError]);

  return [data, isLoading, error, loadData];
};

export default useProcessUpdate;
