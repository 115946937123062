import styled from 'styled-components';
import Button from '../../../../../../../../components/ui/Button';
import Chip from '../../../../../../../../components/ui/Chip';

export const ChipWrapper = styled.div<{ isActive: boolean }>`
  border-radius: 8px;
  margin-right: 8px;
  margin-bottom: 8px;

  background-color: ${p => p.isActive ? '#232121' : '#DEDEDE'};
  color: ${p => p.isActive ? '#FFFFFF' : '#232121'};

  & .MuiChip-deleteIcon {
    display: ${p => p.isActive ? 'block' : 'none'};
    color: ${p => p.isActive ? '#FFFFFF' : '#232121'};
    width: 15px;
    height: 15px;
  }
`;

export const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-bottom: 8px;
  margin-left: auto;
  padding: 8px;
`;

export const StyledChip = styled(Chip)`
  border-radius: 8px;
  padding: 4px;

  background-color: inherit;
  color: inherit;

  transition:
    background-color 200ms linear,
    box-shadow 200ms linear,
    color 200ms linear;

  &:hover, &:focus, &:active {
    background:  #232121;
    color: #FFFFFF;

    .MuiChip-deleteIcon {
      color: #FFFFFF;
    }
  }
`;

export const StyledTabs = styled.div`
  padding: 0;
  margin-bottom: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const TabPanels = styled.div`
  padding: 0;
`;

export const TabsWrapper = styled.div`
  margin-top: 8px;
`;

export const FormSubheader = styled.div`
  color: #232121;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 24px;
  margin-bottom: -8px;
`;
