import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useCompanySecretaryContext } from '../../../../providers/CompanySecretaryProvider';
import useQueryString from '../../../../../../hooks/useQueryString';

import CompanySecretaryTable from '../CompanySecretaryTable';
import SearchBar from '../SearchBar';

import { FoundText } from './styled';

const SearchResultsPage = () => {
  const { t } = useTranslation();
  const { allItemsFlattened, rootUrl } = useCompanySecretaryContext();
  const query = useQueryString();
  const history = useHistory();

  const search = useMemo(() => query.get('search') || '', [query]);

  const changeSearch = (newQuery: string) => {
    query.set('search', newQuery);
    history.push({
      search: `search=${newQuery}`
    });
  };

  const resetSearch = () => {
    query.delete('search');
    history.push(`/${rootUrl}`);
  };

  const foundItems = (allItemsFlattened || []).filter(item => item.title.toLowerCase().includes(search.toLowerCase()));

  const foundText = t('account.companySecretary.search.found');
  const itemsText = t('account.companySecretary.search.itemsCount.itemWithCount', { count: foundItems.length });

  return (
    <>
      <SearchBar isSearchPage defaultSearch={search} changeSearchQuery={changeSearch} resetSearchQuery={resetSearch} />
      <FoundText>{foundText} {itemsText}</FoundText>
      <CompanySecretaryTable data={foundItems} isSearchPage highlight={search} />
    </>
  );
};

export default SearchResultsPage;
