import styled, { keyframes, css } from 'styled-components';
import MuiCloseIcon from '@material-ui/icons/Close';

const openModal = keyframes`
  from {
    transform: translateY(64px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Root = styled.div<{ noBg?: boolean, hideMobileOverflow?: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 20000;
  background: ${(p) => p.noBg ? 'rgba(35, 33, 33, 0.12)' : '#F5F5F5'};
  padding: 0;

  pointer-events: none;

  ${(p) => p.theme.breakpoints.down('xs')} {
    z-index: 3000;
  }
`;

export const HiddenBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4000;
  background: transparent;

  pointer-events: none;

  ${(p) => p.theme.breakpoints.down('xs')} {
    z-index: 3000;
  }
`;

export const Container = styled.div<{ shiftToRight?: boolean }>`
  display: flex;
  flex-direction: column;
  z-index: 5000;

  position: absolute;
  top: 0;
  left: ${(p) => !p.shiftToRight ? '40px' : 'calc(30% - 40px)'};
  bottom: 0;
  right: 40px;

  padding: 0;
  margin-top: 70px;

  animation: ${openModal} .3s cubic-bezier(0.42, 0, 0.26, 1);
  transition: all .3s cubic-bezier(0.42, 0, 0.26, 1);
  background-color: #fff;
  box-shadow: 0 27px 80px rgba(35, 33, 33, 0.05), 0 16px 40px rgba(35, 33, 33, 0.0298179), 0 2px 16px rgba(35, 33, 33, 0.03);
  border-radius: 24px 24px 0 0;

  overflow: auto;
  -webkit-overflow-scrolling: touch;
  pointer-events: all;

  &.scrolled {
    ${(p) => p.theme.breakpoints.down('xs')} {
      margin-top: 0;
      border-radius: 0;
    }
  }

  ${(p) => p.shiftToRight && css`
    ${(p) => p.theme.breakpoints.down('sm')} {
      left: 40px;
    }
  `}

  ${(p) => p.theme.breakpoints.down('xs')} {
    top: 0;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 3000;
    margin-top: 50px;
  }
`;

export const BackText = styled.span``;

export const TopCloseWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 12px 12px 0 0;

  width: 104px;
  height: 36px;
  cursor: pointer;
  pointer-events: all;

  position: absolute;
  top: 34px;
  left: calc(50% - 52px);

  animation: ${openModal} .3s cubic-bezier(0.42, 0, 0.26, 1);
  transition: all .3s cubic-bezier(0.42, 0, 0.26, 1);

  ${(p) => p.theme.breakpoints.down('sm')} {
    width: 48px;
    left: calc(50% - 24px);
  }

  ${(p) => p.theme.breakpoints.down('xs')} {
    top: 14px;
  }
`;

export const CloseButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 100%;
`;

export const CloseIcon = styled(MuiCloseIcon)`
  height: 18px;
  width: 18px;
  color: rgba(35, 33, 33, 0.6);
`;

export const CloseText = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(35, 33, 33, 0.6);

  margin-left: 6px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    display: none;
  }
`;

export const HeadBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color: #fff;
  padding: 22px 32px;
  transition: box-shadow 0.2s ease-in-out;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 11px 22px;
  }

  &.with-shadow {
    box-shadow: 0 0.25px 1px rgba(35, 33, 33, 0.039), 0 0.85px 3px rgba(35, 33, 33, 0.19);

    & .MuiButton-root {
      min-width: 36px;
      padding: 7px 0;
    }

    & ${BackText} {
      display: none;
    }
  }
`;

export const HeadTitle = styled.div`
  color: #232121;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  padding-left: 16px;
  padding-right: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeadActions = styled.div`
  display: flex;
  align-items: center;

  & > *:not(:last-child) {
    margin-right: ${(p) => p.theme.spacing(2)}px;
  }
`;

export const ContentWrapper = styled.div<{ hideMobileOverflow?: boolean }>`
  position: relative;
  padding: 24px 60px;
  height: 100%;

  ${(p) => p.theme.breakpoints.down('sm')} {
    ${(p) => p.hideMobileOverflow && css`
      overflow: hidden;
    `}
    padding: 24px;
  }
`;
