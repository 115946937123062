import React, { useCallback, useEffect, useState } from 'react';
import { FormSpy, useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/img/services/close-icon.svg';
import { NUMBERING } from '../../../../../../utils/constants';
import {
  composeValidators,
  useValidators,
  UseValidatorsReturnProps,
} from '../../../../../../../service/utils/validation';

import Box from '../../../../../../../../components/ui/Box';
import Grid from '../../../../../../../../components/ui/Grid';
import FormTextInput from '../../../../../../../../components/ui/FormTextInput';
import FormCheckbox from '../../../../../../../../components/ui/FormCheckbox';

import { Country } from '../fields';

import {
  ChipWrapper,
  StyledButton,
  StyledChip,
  StyledTabs,
  TabPanels,
  TabsWrapper,
  FormSubheader,
} from './styled';

type FormTabState = {
  id: number;
  name: string;
};

type GenerateTabProps = {
  tabName: string;
  index: number;
  isDefault?: boolean;
};

function generateTab({ tabName, index, isDefault }: GenerateTabProps): FormTabState {
  const numberingString = NUMBERING.digits[index];
  const tabNameFormatted = `${tabName}${isDefault ? ` ${numberingString}` : ''}`;

  return { id: index, name: tabNameFormatted };
}

type AuthorizedPersonProps = {
  idSeparatorNumber: number;
  parentId: string;
  tPrefix: string;
  isEntityLegalType: boolean;
};

function AuthorizedPerson({ idSeparatorNumber, parentId, tPrefix, isEntityLegalType }: AuthorizedPersonProps) {
  const { t } = useTranslation();
  const validators: UseValidatorsReturnProps = useValidators();

  const formState = useFormState();
  const tabName = t(`${tPrefix}.clientAndAuthorizedPerson.person.one`);

  const existentTabsState = Object.keys(formState.values)
    .filter((key: string) => key.includes(`${parentId}_authorized_person_`) && key.includes('_name'))
    .map((key: string) => generateTab({
      tabName: `${formState.values[key]} ${formState.values[`${key.slice(0, -5)}_surname`]}`,
      index: Number(key.split('_')[idSeparatorNumber]),
    }));

  const defaultTabs = existentTabsState.length
    ? existentTabsState
    : [generateTab({ tabName, index: 0, isDefault: true })];

  const [tabs, setTabs] = useState<FormTabState[]>(defaultTabs);
  const [activeTab, setActiveTab] = useState(defaultTabs[0]);

  const showAddNewButton = tabs.length;

  const addNewTab = useCallback(() => {
    let tabIndex = tabs.length;

    if (tabIndex >= 3) {
      return;
    }

    const tabsIds = tabs.map(tab => tab.id);

    while (tabsIds.includes(tabIndex)) {
      tabIndex++;
    }

    const newTab = generateTab({ tabName, index: tabIndex, isDefault: false });

    setTabs([...tabs, newTab]);
    setActiveTab(newTab);
  }, [tabName, tabs]);

  const removeTab = useCallback((tabId: number) => () => {
    const removingTabIndex = tabs.findIndex(tab => tab.id === tabId);
    const nextTabIndex = removingTabIndex !== tabs.length - 1 ? removingTabIndex : removingTabIndex - 1;

    const newTabs = tabs
      .filter(({ id }) => id !== tabId)
      .map((tab, index) => {
        const newTab = { ...tab };

        if (tab.name.includes(tabName)) {
          newTab.name = `${tabName} ${index + 1}`;
        }

        return newTab;
      });
    const newActiveTab = nextTabIndex !== -1 ? newTabs[nextTabIndex] : newTabs[0];

    delete formState.values[`${parentId}_authorized_person_${tabId}_same`];
    delete formState.values[`${parentId}_authorized_person_${tabId}_name`];
    delete formState.values[`${parentId}_authorized_person_${tabId}_surname`];
    delete formState.values[`${parentId}_authorized_person_${tabId}_phone`];
    delete formState.values[`${parentId}_authorized_person_${tabId}_email`];

    delete formState.values[`${parentId}_authorized_person_${tabId}_city`];
    delete formState.values[`${parentId}_authorized_person_${tabId}_postal_code`];
    delete formState.values[`${parentId}_authorized_person_${tabId}_country`];
    delete formState.values[`${parentId}_authorized_person_${tabId}_address`];

    setTabs(newTabs);
    setActiveTab(newActiveTab);
  }, [formState.values, parentId, tabName, tabs]);

  const handleSetActiveTab = useCallback((tab: FormTabState) => () => {
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    tabs.forEach((tab) => {
      const tabIndividualNameInputId = `${parentId}_authorized_person_${tab.id}_name`;
      const tabIndividualSurnameInputId = `${parentId}_authorized_person_${tab.id}_surname`;

      const newTabName = `${formState.values[tabIndividualNameInputId] || ''} ${formState.values[tabIndividualSurnameInputId] || ''}`

      tab.name = (newTabName !== ' ' ? newTabName : '')
        || `${tabName} ${NUMBERING.digits[tabs.findIndex(t => t.id === tab.id)]}`;
    });
  },[activeTab, formState, parentId, t, tPrefix, tabName, tabs]);

  return (
    <TabsWrapper>
      <Box mt={4} mb={3}>
        <FormSubheader>{t(`${tPrefix}.clientAndAuthorizedPerson.person.one`)}</FormSubheader>
      </Box>

      <StyledTabs aria-label={`tabs of ${tabName}s`}>
        {tabs.map((tab) => {
          const tabId = tab.id;
          const isActive = tabId === activeTab.id;

          const removeProps = tabs.length !== 1 ? { onDelete: removeTab(tabId) } : {};

          return (
            <ChipWrapper isActive={isActive} key={tabId}>
              <StyledChip
                id={`person-${tabId}`}
                onClick={handleSetActiveTab(tab)}
                label={tab.name}
                deleteIcon={<DeleteIcon />}
                {...removeProps}
              />
            </ChipWrapper>
          );
        })}

        {showAddNewButton && (tabs.length < 3) ? (
          <StyledButton
            onClick={addNewTab}
            startIcon={<AddIcon />}
            size="small"
          >{t(`${tPrefix}.peopleForm.addButton`)}</StyledButton>
        ) : null}
      </StyledTabs>

      <TabPanels>
        {tabs.map((tab) => {
          const tabId = tab.id;
          const isActive = tabId === activeTab.id;

          if (!isActive) {
            return null;
          }

          const sameAlreadyCheckedInAnotherTab = !tabs
            .filter(t => t.id !== tab.id)
            .every(t => !formState.values[`${parentId}_authorized_person_${t.id}_same`]);

          return (
            <Box key={tabId} display="flex" flexDirection="column">
              {!isEntityLegalType && !sameAlreadyCheckedInAnotherTab ? (
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box mt={-2} mb={3}>
                      <FormCheckbox
                        name={`${parentId}_authorized_person_${tabId}_same`}
                        label={t(`${tPrefix}.clientAndAuthorizedPerson.person.sameAsClient`)}
                      />
                    </Box>
                  </Grid>
                </Grid>
              ) : null}

              <FormSpy>
                {({ values }) => {
                  if (values[`${parentId}_authorized_person_${tabId}_same`] && ! isEntityLegalType) {
                    return null;
                  }

                  return (
                    <Grid container spacing={3}>
                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormTextInput
                          name={`${parentId}_authorized_person_${tabId}_name`}
                          label={`${t(`${tPrefix}.peopleForm.entity.representatives.name`)} *`}
                          validate={validators.is_required}
                          initialValue=""
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormTextInput
                          name={`${parentId}_authorized_person_${tabId}_surname`}
                          label={`${t(`${tPrefix}.peopleForm.entity.representatives.surname`)} *`}
                          validate={validators.is_required}
                          initialValue=""
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormTextInput
                          name={`${parentId}_authorized_person_${tabId}_phone`}
                          label={`${t(`${tPrefix}.peopleForm.individual.phone`)} *`}
                          validate={validators.is_required}
                          initialValue=""
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                        <FormTextInput
                          name={`${parentId}_authorized_person_${tabId}_email`}
                          label={`${t(`${tPrefix}.peopleForm.individual.email`)} *`}
                          validate={composeValidators(validators.email, validators.is_required)}
                          type="email"
                          initialValue=""
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormSubheader>{t(`${tPrefix}.peopleForm.registeredAddress.label`)}</FormSubheader>
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <FormTextInput
                          name={`${parentId}_authorized_person_${tabId}_city`}
                          label={`${t(`${tPrefix}.peopleForm.registeredAddress.city`)} *`}
                          validate={validators.is_required}
                          initialValue=""
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                        <FormTextInput
                          name={`${parentId}_authorized_person_${tabId}_postal_code`}
                          label={`${t(`${tPrefix}.peopleForm.registeredAddress.postalCode`)} *`}
                          validate={validators.is_required}
                          initialValue=""
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                        <Country
                          name={`${parentId}_authorized_person_${tabId}_country`}
                          tPrefix={tPrefix}
                          validate={validators.is_required}
                        />
                      </Grid>

                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <FormTextInput
                          name={`${parentId}_authorized_person_${tabId}_address`}
                          label={`${t(`${tPrefix}.peopleForm.registeredAddress.address`)} *`}
                          validate={validators.is_required}
                          initialValue=""
                        />
                      </Grid>
                    </Grid>
                  );
                }}
              </FormSpy>
            </Box>
          );
        })}
      </TabPanels>
    </TabsWrapper>
  );
}

export default AuthorizedPerson;
