// import { useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Box from '../../../../components/ui/Box';
import ProcessesLayout from '../../components/ProcessesLayout';
import { useProcessesContext } from '../../providers/ProcessesProvider';
import { ProcessResultModel } from '../../network/models';
import ProcessesList from './components/ProcessesList';
import {
  STATUS_DONE,
  STATUS_DRAFT,
  STATUS_IN_PROCESSING,
  STATUS_IN_PROGRESS,
  STATUS_REJECTED,
} from '../../utils/constants';

import {
  ProcessesTitle,
  ProcessesTotal,
} from './styled';
import { getUrlWithoutSlash } from '../../../../utils/helpers';

const ProcessesScreen = () => {
  const { data, loading } = useProcessesContext();
  const { url, path } = useRouteMatch();
  const { t } = useTranslation();

  const rightUrl = getUrlWithoutSlash(url);
  const processesList: ProcessResultModel[] = data?.results?.map(process => ({
    ...process,
    // @TODO fix from backend
    status: process.status || STATUS_DRAFT,
  })) || [];

  const hasProcesses = !!processesList?.length;

  // const menuItems = useMemo(() => [
  //   {
  //     to: `${rightUrl}/all`,
  //     title: t('account.requests.menu.all'),
  //   },
  //   {
  //     to: `${rightUrl}/draft`,
  //     title: t('account.requests.menu.draft'),
  //   },
  //   {
  //     to: `${rightUrl}/done`,
  //     title: t('account.requests.menu.done'),
  //   },
  //   {
  //     to: `${rightUrl}/in-progress`,
  //     title: t('account.requests.menu.inProgress'),
  //   },
  //   {
  //     to: `${rightUrl}/rejected`,
  //     title: t('account.requests.menu.rejected'),
  //   },
  //   {
  //     to: `${rightUrl}/archive`,
  //     title: t('account.requests.menu.archive'),
  //   },
  // ], [t, rightUrl]);

  // @TODO fix from backend
  const draftProcesses = processesList?.filter(({ status }) => !status || status === STATUS_DRAFT);
  const doneProcesses = processesList?.filter(({ status, is_archived }) => status === STATUS_DONE && !is_archived);
  const inProgressProcesses = processesList?.filter(({ status }) => status === STATUS_IN_PROGRESS || status === STATUS_IN_PROCESSING);
  const rejectedProcesses = processesList?.filter(({ status, is_archived }) => status === STATUS_REJECTED && !is_archived);
  const archiveProcesses = processesList?.filter(({ is_archived }) => is_archived);

  const ProcessesTotalTitle = () => (
    <>
      <ProcessesTitle>
        {t('account.requests.title')}
        <ProcessesTotal>({processesList?.length})</ProcessesTotal>
      </ProcessesTitle>
    </>
  );

  return (
    <ProcessesLayout
      title={<ProcessesTotalTitle />}
      empty={!hasProcesses}
      loading={loading}
    >
      <Box display="flex" flex={1} flexDirection={{ xs: 'column', md: 'row' }} position="relative">
        <Switch>
          <Redirect from={path} exact to={`${rightUrl}/all`} />

          <Route path={`${path}/all`} exact>
            <ProcessesList processes={processesList} />
          </Route>
          <Route path={`${path}/draft`} exact>
            <ProcessesList processes={draftProcesses} status="Draft" />
          </Route>
          <Route path={`${path}/done`} exact>
            <ProcessesList processes={doneProcesses} status="Done" />
          </Route>
          <Route path={`${path}/in-progress`} exact>
            <ProcessesList processes={inProgressProcesses} status="Progress" />
          </Route>
          <Route path={`${path}/rejected`} exact>
            <ProcessesList processes={rejectedProcesses} status="Rejected" />
          </Route>
          <Route path={`${path}/archive`} exact>
            <ProcessesList processes={archiveProcesses} status="Archive" />
          </Route>
        </Switch>
      </Box>
    </ProcessesLayout>
  );
};

export default ProcessesScreen;
