import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-bottom: 16px;
  font-weight: 400;
  color: #232121;
`;

export const Item = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;

  margin-bottom: 8px;
`;

export const Total = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
`;

export const Title = styled.div`
  flex: 0;
  white-space: nowrap;
`;

export const Divider = styled.div`
  flex: 1;
  height: 1px;
  background: rgba(35, 33, 33, 0.12);
  margin: 0 10px 5px;
`;

export const Value = styled.div`
  flex: 0;
`;
