import styled, { css, StyledProps } from 'styled-components';

import ButtonBase from '../ui/ButtonBase';
import Menu from '../ui/Menu';
import MenuItem from '../ui/MenuItem';
import { MenuItemProps } from '../../modules/service/components/AsideServicesMenu';

export const Root = styled.div`
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AsideLink = styled(({ isActive, ...props }) => <ButtonBase {...props} />)<{ isActive?: boolean }>`
  display: block;
  width: 100%;
  padding: 12px 24px;
  text-align: left;
  border-radius: 12px;
  white-space: nowrap;

  ${(p) => p.isActive && css`
    background: rgba(35, 33, 33, 0.12);
  `}
`;

export const StyledMenu = styled(Menu)`
  max-width: calc(100% - 48px);

  & .MuiMenu-paper {
    max-width: 100%;
    width: 100%;
    box-shadow:
      0 0.5px 5px rgba(35, 33, 33, 0.039),
      0 3.75px 11px rgba(35, 33, 33, 0.19);
    border-radius: 12px;
  }
`;

export const CurrentDisplayItem = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 56px;
  padding: 0 ${(p) => p.theme.spacing(2)}px;

  background: rgba(35, 33, 33, 0.12);
  border-radius: 12px;
  color: #232121;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const CurrentMenuItem = styled.div`
  padding: 12px 24px;

  background: rgba(35, 33, 33, 0.12);
  color: #4A5351;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const StyledMenuItem: MenuItemProps & StyledProps<any> = styled(MenuItem)`
  padding: 12px 24px;

  color: #4A5351;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const Divider = styled.div`
  height: 1px;
  margin: 8px 0;
  background: #4A5351;
  opacity: 0.12;
`;
