import React, { useCallback, useEffect, SyntheticEvent } from 'react';

import { ModalItemBaseProps, openModal } from '../../../containers/ModalsContainer';
import Box from '../../ui/Box';
import Button from '../../ui/Button';
import { Container, RootOverlay, Content, StyledSubmitButton, Title } from './styled';

type IConfirmDialogProps = {
  buttonMode?: string;
  submitLabel?: string;
  cancelLabel?: string;
  content?: string | React.ReactNode;
  title?: string | React.ReactNode;
  action?: Function;
};

const ConfirmDialogModal = (props: ModalItemBaseProps & IConfirmDialogProps) => {
  const {
    content,
    modalRequestClose,
    buttonMode,
    submitLabel = 'Ok',
    cancelLabel = 'Cancel',
    title,
    action,
  } = props;

  const handleSubmit = useCallback(async () => {
    modalRequestClose();

    try {
      if (action) {
        await action();
      }
    } catch (e) {
      console.error('error', e);
    }
  }, [action, modalRequestClose]);

  const keyHandler = useCallback(async (e: KeyboardEvent) => {
    if (e.code === 'Escape') {
      modalRequestClose();
    }

    if (e.code === 'Enter' || e.code === 'Space') {
      modalRequestClose();
      await handleSubmit();
    }
  }, [handleSubmit, modalRequestClose]);

  useEffect(() => {
    window.addEventListener('keydown', keyHandler);

    return () => {
      window.removeEventListener('keydown', keyHandler);
    };
  }, [keyHandler]);

  const onOverlayClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation();
      modalRequestClose();
    },
    [modalRequestClose]
  );

  const onContainerClick = useCallback(
    (e: SyntheticEvent) => {
      e.stopPropagation()
    },
    []
  );

  return (
    <RootOverlay onClick={onOverlayClick}>
      <Container onClick={onContainerClick}>
        {title && (
          <Title>{title}</Title>
        )}

        {content && (
          <Content mb={3.25}>{content}</Content>
        )}

        <Box display="flex" justifyContent="space-between">
          <Button onClick={modalRequestClose}>{cancelLabel}</Button>
          <StyledSubmitButton mode={buttonMode} onClick={handleSubmit}>{submitLabel}</StyledSubmitButton>
        </Box>
      </Container>
    </RootOverlay>
  );
}

export const openConfirmDialogModal = (
  props?: IConfirmDialogProps
): ReturnType<typeof openModal> => {
  return openModal(ConfirmDialogModal, props);
};

export default ConfirmDialogModal;
