import React from 'react';
import DownloadIcon from '@material-ui/icons/GetApp';

import useDateLib from '../../../../../../hooks/useDateLib';
import IconButton from '../../../../../../components/ui/IconButton';
import { BillingsListItemProps } from '../BillingsList';
import { getFormattedTotalAmount } from '../../../../utils/billings';
import BillingStatus from '../BillingStatus';

import {
  BillingsListItem,
  BillingLineMobile,
  BillingMobile,
  BillingNumberMobile,
  BillingTimeMobile,
  BillingTitleMobile,
  BillingAmountMobile,
} from './styled';

const BillingsListItemMobile = (props: BillingsListItemProps) => {
  const { billing: { number, created, description, amount, receipt_url, status } } = props;

  const { getCreatedDate } = useDateLib();
  const date = getCreatedDate(created);
  const total = getFormattedTotalAmount(amount);

  return (
    <BillingsListItem>
      <BillingMobile>
        <BillingLineMobile>
          <BillingStatus status={status} />
          <BillingTimeMobile>{date}</BillingTimeMobile>
        </BillingLineMobile>
        <BillingLineMobile>
          <BillingTitleMobile>{description}</BillingTitleMobile>
          <BillingAmountMobile>€{total}</BillingAmountMobile>
        </BillingLineMobile>
        {receipt_url && (
          <BillingLineMobile>
            <BillingNumberMobile>no. {number}</BillingNumberMobile>
            <IconButton
              aria-label="more"
              aria-controls="menu"
              aria-haspopup="true"
              onClick={() => window.open(receipt_url, '_blank')}
            >
              <DownloadIcon />
            </IconButton>
          </BillingLineMobile>
        )}
      </BillingMobile>
    </BillingsListItem>
  );
};

export default BillingsListItemMobile;
