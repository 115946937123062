import styled from 'styled-components';

import Box from '../../../../../../components/ui/Box';

import { ReactComponent as EnterIcon } from '../../../../../../assets/img/messages/enter-arrow.svg';

export const HistoryWrapper = styled(Box)`
  padding: 16px;

  display: flex;
  flex-direction: column;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 0;
    margin-top: 40px;
  }
`;

export const HistoryTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #232121;
  padding-left: 16px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 20px;
    padding-left: 0;
  }
`;

export const EmptyHistory = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  color: rgba(35, 33, 33, 0.6);

  margin-top: 18px;
  padding-left: 16px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 20px;
    padding-left: 0;
  }
`;

export const HistoryItem = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 16px;
  width: 100%;

  border-bottom: 1px solid rgba(35, 33, 33, 0.12);
  cursor: pointer;

  &:hover {
    background-color: rgba(35, 33, 33, 0.04);
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 16px 0;
    margin-top: 8px;
  }
`;

export const HistoryText = styled.div`
`;

export const HistoryCheckingIcon = styled(EnterIcon)`
  margin-right: 16px;
  min-width: 21px;
  width: 21px;
  max-width: 21px;
  margin-left: 4px;
`;

export const HistoryItemTitle = styled.div`
  color: #232121;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const HistoryItemDate = styled(HistoryItemTitle)`
  color: rgba(35, 33, 33, 0.6);
`;
