import React from 'react';

import { AllItemsFlattenedModel } from '../../../../utils/companySecretary';
import useDateLib from '../../../../../../hooks/useDateLib';

import TableBody from '../../../../../../components/ui/TableBody';
import TableCell from '../../../../../../components/ui/TableCell';
import TableContainer from '../../../../../../components/ui/TableContainer';

import ItemLink from '../ItemLink';

import { StyledTable, StyledTableCell } from '../CompanySecretaryTable/styled';

import { StyledRow, TableTitle } from './styled';

type SearchTableProps = {
  rows: AllItemsFlattenedModel[];
  title: string;
  search: string;
  handleClose: () => void;
};

const SearchResultsTable = ({ rows, search, title, handleClose }: SearchTableProps) => {
  const { getCreatedDate } = useDateLib();

  return (
    <TableContainer>
      <TableTitle>{title}</TableTitle>
      <StyledTable
        aria-labelledby="search table"
        size="medium"
        aria-label="company secretary search table"
      >
        <TableBody>
          {rows && rows.slice(0, 2)
            .map((row) => {
              const date = row.last_date ? getCreatedDate(row.last_date) : '';

              return (
                <StyledRow
                  hover
                  tabIndex={-1}
                  key={row.id}
                >
                  <StyledTableCell component="th" scope="row" onClick={handleClose}>
                    <ItemLink highlight={search} row={row} />
                  </StyledTableCell>

                  <TableCell align="right">{date}</TableCell>
                </StyledRow>
              );
            })}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default SearchResultsTable;
