import React from 'react';
import { Field } from 'react-final-form';

import Box from '../Box';
import { InfoBubble } from '../InfoBubble';
import Checkbox, { Props } from '../Checkbox';
import FormHelperText from '../FormHelperText';
import FormControlLabel from '../FormControlLabel';

export type FormCheckboxProps = Props & {
  name: string;
  label?: string;
  hint?: string;
  initialValue?: string | boolean;
  validate?: (value: any) => any;
  afterChangeCallback?: (value: any) => void;
};

const FormCheckbox = (props: FormCheckboxProps) => {
  const { name, validate, hint, label, initialValue = false, afterChangeCallback, ...restProps } = props;
  return (
    <Field
      name={name}
      validate={validate}
      initialValue={initialValue}
      type="checkbox"
      render={({ input, meta }) => (
        <>
          <FormControlLabel
            control={
              <Checkbox
                color="default"
                {...input}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  input.onChange(event);
                  afterChangeCallback?.(event.target.checked);
                }}
                {...restProps}
              />
            }
            label={(
              <Box display="flex" alignItems="center">
                <Box mr={1}>{label}</Box>
                {hint ? <InfoBubble text={hint} /> : null}
              </Box>
            )}
          />
          {meta.error && meta.touched && <FormHelperText>{meta.error}</FormHelperText>}
        </>
      )}
    />
  );
};

export default FormCheckbox;
