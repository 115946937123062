import React, { useCallback } from 'react';

import Hidden from '../../../../../../components/ui/Hidden';
import useDateLib from '../../../../../../hooks/useDateLib';
import { getFromEmailName, getTrimmedEmailBodyString } from '../../../../utils/messages';

import { MessageProps } from '../MessagesList';
import { openMessageModal } from '../MessageModal';

import { MessageItem, MessageDate, MessageEmail, MessageSubject, MessageText, StyledTableRow } from './styled';
import useReadMessage from '../../../../hooks/messages/useReadMessage';
import { useMessagesContext } from '../../../../providers/MessagesProvider';

const MessagesListItem = (props: MessageProps) => {
  const { message } = props;
  const { date: created, subject, body_text, type } = message;

  const { getCreatedDate } = useDateLib();
  const [,,, readMessage] = useReadMessage();
  const { loadData } = useMessagesContext();

  const body = getTrimmedEmailBodyString({ body_text });
  const date = getCreatedDate(created);
  const from = getFromEmailName(type);
  const seen = message.is_seen;

  const MessageTextWrapper = () => (
    <>
      <MessageSubject>{subject}</MessageSubject> - <MessageText>{body}</MessageText>
    </>
  );

  const handleOpenMessageModal = useCallback(() => {
    readMessage({ messageId: message.id });
    openMessageModal({ message });
    loadData();
  }, [loadData, message, readMessage]);

  return (
    <StyledTableRow onClick={handleOpenMessageModal} seen={seen}>
      <MessageItem>
        <MessageEmail>{from}</MessageEmail>

        <Hidden mdUp>
          <MessageTextWrapper />
        </Hidden>
      </MessageItem>
      <Hidden smDown><MessageItem><MessageTextWrapper /></MessageItem></Hidden>
      <MessageItem><MessageDate>{date}</MessageDate></MessageItem>
    </StyledTableRow>
  );
};

export default MessagesListItem;
