const getBodyFromFetch = async (response: Response) => {
  const contentType = response.headers.get('content-type');
  if (contentType === null) {
    return null;
  }
  if (contentType.includes('application/json')) {
    return await response.json();
  }
  const text = await response.text();
  return {
    text
  };
}

export default getBodyFromFetch;
