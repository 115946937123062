import { useCallback, useState } from 'react';

import RequestError from '../../../../../network/RequestError';
import { BaseProcessParams, deleteRequest } from '../../../network';
import ObjectType from '../../../../../utils/ObjectType';

type ResultData = ObjectType | null;

const useProcessDelete = (): [
  ResultData,
  boolean,
    Error | null,
  (params: BaseProcessParams) => Promise<void>,
] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useCallback(async ({ processId }: BaseProcessParams) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await deleteRequest({
        processId,
      });

      setData(response);
    } catch (e) {
      setError(e);
      throw new Error(e);
    }
    setIsLoading(false);
  }, [setData, setError, setIsLoading]);

  return [data, isLoading, error, loadData];
};

export default useProcessDelete;
