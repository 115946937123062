import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

import { StyledBreadcrumbs, BreadcrumbsLink, BreadcrumbsText } from './styled';
import { AllItemsFlattenedModel } from '../../../../utils/companySecretary';
import { useCompanySecretaryContext } from '../../../../providers/CompanySecretaryProvider';

const CompanySecretaryBreadcrumbs = () => {
  const { allItemsFlattened, rootUrl } = useCompanySecretaryContext();

  const folders = (allItemsFlattened || []).filter(
    (folder: AllItemsFlattenedModel) => folder.type === 'folder',
  );

  const { pathname } = useLocation();
  const { t } = useTranslation();

  const splitPathname = pathname
    .replace(/^\//, '')
    .split('/')
    .filter(Boolean);

  const breadcrumbs = splitPathname
    // filter paths before company-secretary
    .filter((path, index, array) => index > array.findIndex(i => i === 'company-secretary'))
    .map((path) => {
      const folder = folders.find(route => {
        const foundIndex = splitPathname.findIndex(p => p === path);
        const pathnameToUse = splitPathname.slice(0, foundIndex + 1).join('/');
        return route.path === pathnameToUse;
      });

      return {
        title: folder?.title || 'Secretary',
        path: `${folder?.path}` || `${rootUrl}/company-secretary`,
      };
    });

  return (
    <StyledBreadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
      <BreadcrumbsLink to={`/${rootUrl}`}>
        {t('account.companySecretary.breadcrumbs.root')}
      </BreadcrumbsLink>
      {
        breadcrumbs.map((item, index) => index === breadcrumbs.length - 1
          ? (
            <BreadcrumbsText key={item.path}>
              {item.title}
            </BreadcrumbsText>
          ) : (
            <BreadcrumbsLink key={item.path} to={`/${item.path}`}>
              {item.title}
            </BreadcrumbsLink>
          ))
      }
    </StyledBreadcrumbs>
  );
};

export default CompanySecretaryBreadcrumbs;
