import styled from 'styled-components';
import TableCell from '../../../../../../components/ui/TableCell';

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const EmptyText = styled.div`
  color: rgba(35, 33, 33, 0.6);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 50px;
`;

export const EmptyBg = styled.img`
  margin-top: 50px;
  max-width: 450px;
  width: 100%;
`;

export const ColumnTitle = styled(TableCell)`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 1.5px;
  text-transform: capitalize;

  ${({ theme }) => theme.breakpoints.down('sm')} {
    padding-left: 0;
  }
`;
