import styled from 'styled-components';

import Accordion from '../ui/Accordion';
import AccordionSummary from '../ui/AccordionSummary';
import AccordionDetails, { Props as AccordionDetailsProps } from '../ui/AccordionDetails';

export const MuiAccordion = styled(Accordion)`
  :before {
    display: none;
  }
`;

export const StyledSummary = styled(AccordionSummary)`
  padding: 0;
  margin: 0;

  & > .MuiAccordionSummary-content {
    flex-direction: column;
  }
`;

export const StyledDetails = styled(AccordionDetails)<AccordionDetailsProps & { disabled?: boolean }>`
  ${(p) => p.disabled && `
    pointer-events: none;
    opacity: .5;
  `}

  padding: 0;
  display: block;
`;

export const Heading = styled.div`
  display: flex;
  align-items: center;

  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #232121;
`;

export const Title = styled.div<{ disabled?: boolean }>`
  ${(p) => p.disabled && `
    pointer-events: none;
    opacity: .5;
  `}

  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #232121;
`;

export const Hint = styled.div`
  margin-left: 8px;
`;

export const Description = styled.div<{ disabled?: boolean }>`
  ${(p) => p.disabled && `
    pointer-events: none;
    opacity: .5;
  `}

  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;

  color: #232121;

  margin-top: 8px;
  opacity: 0.6;

  span {
    color: #29C297;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
`;
