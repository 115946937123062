import styled, { css } from 'styled-components';
import ButtonBase from '../../../../components/ui/ButtonBase';

export const Root = styled.div`
  flex-shrink: 0;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 2;
`;

export const AsideServicesLink = styled(({ isActive, ...props }) => <ButtonBase {...props} />)<{ isActive?: boolean }>`
  display: block;
  width: 100%;
  max-width: 274px;
  padding: 24px 0;
  text-align: left;
  border-radius: 12px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: rgba(35, 33, 33, 0.6);
  margin-bottom: 8px;

  transition: all 0.3s ease-in;

  &:last-child {
    margin-bottom: 0;
  }

  ${(p) => p.theme.breakpoints.down('md')} {
    flex-shrink: 1;
    width: 240px;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 16px 0;
    max-width: 80%;
    width: 80%;
  }

  ${(p) => p.isActive ? css`
    background: rgba(35, 35, 35, 1);
    color: #FFFFFF;
    position: relative;
    margin-left: 40px;
    max-width: 290px;
    padding: 24px;

    ${(p) => p.theme.breakpoints.down('sm')} {
      padding: 16px;
      left: 0;
      max-width: 80%;
      margin-left: 0;
    }
  ` : css`
    &:hover {
      background: #DEDEDE;
      color: #232121;
      position: relative;
      margin-left: 40px;
      max-width: 290px;
      padding: 24px;

      ${(p) => p.theme.breakpoints.down('sm')} {
        padding: 16px;
        left: 0;
        max-width: 80%;
        margin-left: 0;
      }
    }
  `}
`;
