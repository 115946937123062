import React from 'react';
import { useLocation } from 'react-router-dom';

import CabinetBody from './components/CabinetBody';
import CabinetHeader from './components/CabinetHeader';

const CabinetLayout = ({ children }: { children: NonNullable<React.ReactNode> }) => {
  const { pathname } = useLocation();

  const isServicesPage = pathname.includes('/services');

  return (
    <>
      <CabinetHeader isServicesPage={isServicesPage} />
      <CabinetBody isServicesPage={isServicesPage}>
        {children}
      </CabinetBody>
    </>
  )
};

export default CabinetLayout;
