import React, { useMemo } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, loadStripe, StripeElementsOptions } from '@stripe/stripe-js';

import PaymentForm, { PaymentFormProps } from './PaymentForm';
import TotalPrice from '../TotalPrice';

const stripePromise = loadStripe('pk_test_51JZEtcFcbDiPfHo9qrfJIRAxuWJ5L3wgGtlK7mqLwAAFKEgv6dPaerkwv4whg7voa2Ubyxt71fxZnVX4UUW0YcoO00G2TzVVyG');

export type OnlinePaymentProps = PaymentFormProps & {
  clientSecret: string;
  price: string;
};

const OnlinePayment = ({ clientSecret, price, ...rest }: OnlinePaymentProps) => {
  const isSuccessPayment = rest.isCardPaymentSuccess;

  const options: StripeElementsOptions = useMemo(() => ({
    appearance: {
      theme: 'stripe',
    } as Appearance,
    clientSecret: clientSecret,
  }), [clientSecret]);

  return (
    <Elements options={options} stripe={stripePromise}>
      {!isSuccessPayment ? <TotalPrice price={price} /> : null}
      <PaymentForm {...rest} />
    </Elements>
  );
};

export default OnlinePayment;
