import styled from 'styled-components';

export const Title = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 44px;
  color: inherit;
  text-decoration: none;
`;

export const LogoIconWrapper = styled.div`
  width: 30px;
  height: 34px;

  position: relative;

  ${(p) => p.theme.breakpoints.down('sm')} {
    width: 24px;
    height: 24px;
  }
`;

export const LogoIconBase = styled.div`
  border-radius: 5%;

  transform-origin: bottom left;
  transition: transform .5s;

  position: absolute;
  top: 7px;
  left: 5px;
  right: 0;
  bottom: 0;
`;

export const LogoIconSquare = styled(LogoIconBase)`
  width: 18px;
  height: 24px;

  background: rgb(39, 194, 151);

  transform: rotate(-10deg);

  ${(p) => p.theme.breakpoints.down('sm')} {
    width: 12px;
    height: 15px;
  }
`;

export const LogoIconFrame = styled(LogoIconBase)`
  width: 20px;
  height: 25px;

  border: 2px solid rgb(35, 33, 33);

  transform: rotate(-39deg);

  ${(p) => p.theme.breakpoints.down('sm')} {
    width: 12px;
    height: 18px;
  }
`;

export const LogoTextWrapper = styled.div`
  width: 93px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    width: 60px;
  }
`;
