import styled, { css }from 'styled-components';
import Avatar from '../../../../components/ui/Avatar';
import Card, { Props as CardProps } from '../../../../components/ui/Card';

export const StyledCard = styled(Card)<CardProps & { disabled?: boolean }>`
  border-radius: 12px;
  box-shadow:
    0 27px 80px rgba(35, 33, 33, 0.05),
    0 16px 40px rgba(35, 33, 33, 0.0298179),
    0 2px 16px rgba(35, 33, 33, 0.03);

  ${(p) => p.disabled && css`
    pointer-events: none;
    opacity: .6;
  `}
`;

export const StyledAvatar = styled(Avatar)`
  width: 56px;
  height: 56px;
`;

export const StyledAccountName = styled.div`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #232121;
`;
