import styled from 'styled-components';

export const FoundText = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;
  color: #232121;

  margin-left: 16px;
  margin-top: 16px;
  margin-bottom: 24px;
`;
