import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import { NormalizedCompanySecretaryItemModel } from '../../network/models';
import { getNormalizedCompanySecretaryItem } from '../../utils/companySecretary';
import { getCompanySecretary } from '../../network';

type ResultData = {
  results: NormalizedCompanySecretaryItemModel[] | null
} | null;

const useBillingsList = () : [ResultData, boolean, Error | null, () => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useDebouncedCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getCompanySecretary();

      setData({ results: getNormalizedCompanySecretaryItem(response?.results) });
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  useEffect(() => {
    loadData();
  }, [loadData]);

  return [data, isLoading, error, loadData];
};

export default useBillingsList;
