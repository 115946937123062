import ObjectType from '../../../../../../../utils/ObjectType';

export const FORMATTED_FORM_KEYS: ObjectType = {
  company_name: 'company_name',
  legal_form: 'legal_form',
  spf: 'spf',
  registered_office: 'registered_office',
  share_capital_amount: 'share_capital_amount',
  share_capital_currency: 'share_capital_currency',
  share_capital_value_per_share: 'share_capital_value_per_share',
  shareholders: 'shareholders',
  end_of_financial_year: 'end_of_financial_year',
  annual_general_meeting_date: 'annual_general_meeting_date',
  leadership_type: 'leadership_type',
  directorship: 'directorship',
  directorship_type: 'directorship_type',
  management: 'management',
  management_type: 'management_type',
  has_purpose: 'has_purpose',
  company_purpose: 'company_purpose',
  incorporation_method: 'incorporation_method',

  client: 'client',
  client_legal_type: 'client_legal_type',
  client_name: 'client_name',
  client_surname: 'client_surname',
  client_phone: 'client_phone',
  client_email: 'client_email',
  client_city: 'client_city',
  client_postal_code: 'client_postal_code',
  client_country: 'client_country',
  client_address: 'client_address',

  client_entity_name: 'client_entity_name',
  client_organization_number: 'client_organization_number',

  client_authorized_person: 'client_authorized_person',

  statutory_auditor_type: 'statutory_auditor_type',

  statutory_auditor_legal_type: 'statutory_auditor_legal_type',
  statutory_auditor_name: 'statutory_auditor_name',
  statutory_auditor_surname: 'statutory_auditor_surname',
  statutory_auditor_city: 'statutory_auditor_city',
  statutory_auditor_postal_code: 'statutory_auditor_postal_code',
  statutory_auditor_country: 'statutory_auditor_country',
  statutory_auditor_address: 'statutory_auditor_address',

  statutory_auditor_entity_name: 'statutory_auditor_entity_name',
  statutory_auditor_organization_number: 'statutory_auditor_organization_number',
};

export const INTERNAL_FORM_KEYS: ObjectType = {
  registered_office_city: 'registered_office_city',
  registered_office_postal_code: 'registered_office_postal_code',
  registered_office_country: 'registered_office_country',
  registered_office_address: 'registered_office_address',
  sole: 'sole',
  class: 'class',
  noclass: 'noclass',
  multiple: 'multiple',
};

