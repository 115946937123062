import styled, { css } from 'styled-components';

import Box from '../../../../../../components/ui/Box';

export const Divider = styled.div`
  height: 1px;
`;

export const Root = styled(Box)<{ color?: string }>`
  border-radius: 12px;
  color: #232121;

  ${(p) => p.color === 'empty' ? css`
    background: #F5F5F5;
    border: 1px dashed #DEDEDE;
    box-shadow: none;
  ` : css`
    background: #FFFFFF;
    border: none;
    box-shadow: 0 27px 80px rgba(35, 33, 33, 0.05),
      0 16px 40px rgba(35, 33, 33, 0.03),
      0 2px 16px rgba(35, 33, 33, 0.03);
  `}

  ${Divider} {
    background: #232121;
    opacity: 0.12;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  overflow: auto;

  ${(p) => p.theme.breakpoints.down('sm')} {
    padding: 16px;
  }
`;

export const SettingsTitle = styled.div`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #232121;

  ${(p) => p.theme.breakpoints.down('md')} {
    font-size: 20px;
    line-height: 24px;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 14px;
    line-height: 20px;
  }
`;

export const Status = styled(Box)`
  color: #27C297;

  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  text-transform: uppercase;
`;
