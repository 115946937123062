import React, { useCallback, useEffect } from 'react';
import { Switch, Route, useRouteMatch, useHistory } from 'react-router-dom';

import { getLoginUrl } from '../../auth';
import useAuth, { AuthState } from '../../auth/useAuth';
import AccountListScreen from '../../modules/account/screens/AccountListScreen';
import CabinetLayout from '../../components/CabinetLayout';
import NotFoundScreen from '../../screens/NotFoundScreen';
import AccountScreen from '../../modules/account/screens/AccountScreen';

import { ModalsContainer } from '../ModalsContainer';
import ServicesCatalogScreen from '../../modules/service/screens/ServicesCatalogScreen';
import useAccountList from '../../modules/account/hooks/accounts/useAccountList';
import { getSavedAccountInLS } from '../../utils/accountsLS';
import { CabinetContextProvider, useCabinetContext } from './context';

const CabinetContainer = () => {
  const [authState, currentUser] = useAuth();
  const { path } = useRouteMatch();
  const history = useHistory();
  const { setCurrentAccount, currentAccount } = useCabinetContext();

  const [accountList, isAccountListLoading,, loadAccountList] = useAccountList();

  const handleChooseAccount = useCallback((account: any) => {
    if (!currentAccount && account) {
      setCurrentAccount(account);

      if (!window.location.pathname.includes('/account')) {
        const redirectUrl = `${window.location.pathname}`.replace('/cabinet', '');

        history.push(`/cabinet/account/${account.id}${redirectUrl}`);
      }
    }
  }, [currentAccount, history, setCurrentAccount]);

  useEffect(() => {
    if (authState === AuthState.Unauthorized) {
      window.location.href = getLoginUrl();
    }
  }, [authState]);

  useEffect(() => {
    if (!accountList && !isAccountListLoading) {
      loadAccountList();
    }

    if (!currentAccount && accountList && accountList?.results.length > 0) {
      const savedAccount = getSavedAccountInLS();
      const firstAccount = accountList?.results[0];

      const account = savedAccount && savedAccount.id && accountList?.results?.find((acc: any) => acc.id === savedAccount.id)
        ? savedAccount
        : firstAccount;

      handleChooseAccount(account);
    }
  }, [accountList, currentAccount, handleChooseAccount, isAccountListLoading, loadAccountList]);

  if (authState === AuthState.Loading) {
    return (
      <h1>Loading</h1>
    );
  }

  if (authState === AuthState.Error) {
    return (
      <h1>Check Auth Failed</h1>
    );
  }

  if (authState === AuthState.Unauthorized) {
    return null;
  }

  return (
    <CabinetContextProvider currentUser={currentUser}>
      <CabinetLayout>
        <Switch>
          <Route path={`${path}/account`} exact component={AccountListScreen} />
          <Route path={`${path}/account/:accountId`} component={AccountScreen} />
          <Route path={`${path}/services`} component={ServicesCatalogScreen} />

          <Route path="*" component={NotFoundScreen} />
        </Switch>
        <ModalsContainer name="cabinet" />
      </CabinetLayout>
    </CabinetContextProvider>
  )
};

export default CabinetContainer;
