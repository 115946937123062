import styled from 'styled-components';
import BgImg from '../../../../assets/img/backgrounds/account-list-bg.png';

export const ListRoot = styled.div`
  min-height: 500px;
`;

export const AsideBgImg = styled.div`
  max-width: 500px;
  width: 100%;
  height: 100%;
  background-image: url(${BgImg});
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: contain;

  @media (max-width: 959px) {
    display: none;
  }
`;

export const ListWrap = styled.div`
  max-width: 70%;

  ${(p) => p.theme.breakpoints.down('sm')} {
    max-width: 100%;
  }
`;
