import { CheckPaymentBodyModel } from '../network/models';
import { CheckPaymentParams } from '../network';

export const getCheckPaymentBody = ({ paymentId, paymentType, vat, invoiceType }: CheckPaymentParams) => {
  const body: CheckPaymentBodyModel = {
    payment_type: paymentType,
  };

  if (paymentId) {
    body.payment_id = paymentId;
  }

  if (vat) {
    body.vat_number = {
      state_code: vat.stateCode,
      number: vat.number,
    };
  }

  if (invoiceType) {
    body.invoice_type = invoiceType;
  }

  return body;
};
