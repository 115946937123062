import { createContext, ReactElement } from 'react';

import {
  ListContextInitialValues,
  ListContextValues,
  ListProvider,
  useListContext,
} from '../../../providers/ListProvider';
import RequestError from '../../../network/RequestError';
import { STATUS_DONE, STATUS_IN_PROGRESS } from '../utils/constants';
import ObjectType from '../../../utils/ObjectType';
import useListState from '../../../hooks/useListState';
import useProcessDelete from '../hooks/processes/actions/useProcessDelete';
import useProcessArchive from '../hooks/processes/actions/useProcessArchive';
import useProcessRestore from '../hooks/processes/actions/useProcessRestore';
import useProcessCreate from '../hooks/processes/actions/useProcessCreate';
import useProcessUpdate from '../hooks/processes/actions/useProcessUpdate';
import useProcessSubmit from '../hooks/processes/actions/useProcessSubmit';
import useProcessesList from '../hooks/processes/useProcessesList';
import { ProcessesWrapperModel } from '../network/models';
import { BaseProcessParams, CreateProcessParams, SubmitProcessParams, UpdateProcessParams } from '../network';
import useProcessSave from '../hooks/processes/actions/useProcessSave';

export type ProcessesProviderProps = {
  children: ReactElement;
};

export interface ProcessesContextValues extends ListContextValues {
  data: ProcessesWrapperModel | null;

  archivedProcess: null | ObjectType;
  createdProcess: null | ObjectType;
  deletedProcess: null | ObjectType;
  restoredProcess: null | ObjectType;
  updatedProcess: null | ObjectType;
  savedProcess: null | ObjectType;
  submittedProcess: null | ObjectType;

  isArchiveProcessLoading: boolean;
  isCreateProcessLoading: boolean;
  isDeleteProcessLoading: boolean;
  isRestoreProcessLoading: boolean;
  isUpdateProcessLoading: boolean;
  isSaveProcessLoading: boolean;
  isSubmitProcessLoading: boolean;

  archiveProcessError: null | RequestError;
  createProcessError: null | RequestError;
  deleteProcessError: null | RequestError;
  restoreProcessError: null | RequestError;
  updateProcessError: null | RequestError;
  saveProcessError: null | RequestError;
  submitProcessError: null | RequestError;

  archiveProcess: (props: BaseProcessParams) => void;
  createProcess: (props: CreateProcessParams) => any;
  deleteProcess: (props: BaseProcessParams) => void;
  restoreProcess: (props: BaseProcessParams) => void;
  updateProcess: (props: UpdateProcessParams) => void;
  saveProcess: (props: SubmitProcessParams) => void;
  submitProcess: (props: SubmitProcessParams) => void;
}

const ProcessesContextInitialValues = {
  ...ListContextInitialValues,

  archivedProcess: null,
  createdProcess: null,
  deletedProcess: null,
  restoredProcess: null,
  updatedProcess: null,
  savedProcess: null,
  submittedProcess: null,

  isArchiveProcessLoading: false,
  isCreateProcessLoading: false,
  isDeleteProcessLoading: false,
  isRestoreProcessLoading: false,
  isUpdateProcessLoading: false,
  isSaveProcessLoading: false,
  isSubmitProcessLoading: false,

  archiveProcessError: null,
  createProcessError: null,
  deleteProcessError: null,
  restoreProcessError: null,
  updateProcessError: null,
  saveProcessError: null,
  submitProcessError: null,

  archiveProcess: () => {},
  createProcess: () => {},
  deleteProcess: () => {},
  restoreProcess: () => {},
  updateProcess: () => {},
  saveProcess: () => {},
  submitProcess: () => {},
};

export const ProcessesContext = createContext<ProcessesContextValues>(ProcessesContextInitialValues)

export const ProcessesProvider = (props: ProcessesProviderProps) => {
  const { page, pageSize, setPage, setPageSize } = useListState();
  const [data, isLoading, error, loadData] = useProcessesList({ page, pageSize });

  const [createdProcess, isCreateProcessLoading, createProcessError, createProcess] = useProcessCreate();
  const [archivedProcess, isArchiveProcessLoading, archiveProcessError, archiveProcess] = useProcessArchive();
  const [deletedProcess, isDeleteProcessLoading, deleteProcessError, deleteProcess] = useProcessDelete();
  const [restoredProcess, isRestoreProcessLoading, restoreProcessError, restoreProcess] = useProcessRestore();
  const [updatedProcess, isUpdateProcessLoading, updateProcessError, updateProcess] = useProcessUpdate();
  const [savedProcess, isSaveProcessLoading, saveProcessError, saveProcess] = useProcessSave();
  const [submittedProcess, isSubmitProcessLoading, submitProcessError, submitProcess] = useProcessSubmit();

  const processes = data ? {
    ...data,
    results: data?.results
      ?.filter(process => process.status !== 'CANCELED')
      .map(({ pandadocs, ...rest }) => {
        let status = STATUS_IN_PROGRESS;

        if (pandadocs.length && pandadocs.every(doc => doc.status === 'document.completed')) {
          status = STATUS_DONE;
        }

        return ({
          ...rest,
          pandadocs,
          status,
        });
      }),
  } : data;

  return (
    <ListProvider
      ListContext={ProcessesContext}
      data={processes}
      loading={isLoading}
      error={error}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
      loadData={loadData}

      archivedProcess={archivedProcess}
      createdProcess={createdProcess}
      deletedProcess={deletedProcess}
      restoredProcess={restoredProcess}
      updatedProcess={updatedProcess}
      savedProcess={savedProcess}
      submittedProcess={submittedProcess}

      isArchiveProcessLoading={isArchiveProcessLoading}
      isCreateProcessLoading={isCreateProcessLoading}
      isDeleteProcessLoading={isDeleteProcessLoading}
      isRestoreProcessLoading={isRestoreProcessLoading}
      isUpdateProcessLoading={isUpdateProcessLoading}
      isSaveProcessLoading={isSaveProcessLoading}
      isSubmitProcessLoading={isSubmitProcessLoading}

      archiveProcessError={archiveProcessError}
      createProcessError={createProcessError}
      deleteProcessError={deleteProcessError}
      restoreProcessError={restoreProcessError}
      updateProcessError={updateProcessError}
      saveProcessError={saveProcessError}
      submitProcessError={submitProcessError}

      archiveProcess={archiveProcess}
      createProcess={createProcess}
      deleteProcess={deleteProcess}
      restoreProcess={restoreProcess}
      updateProcess={updateProcess}
      saveProcess={saveProcess}
      submitProcess={submitProcess}
    >
      {props.children}
    </ListProvider>
  );
};

export const useProcessesContext = () => useListContext(ProcessesContext) as ProcessesContextValues;
