import { useCallback, useEffect, useMemo } from 'react';
import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@material-ui/icons/Settings';
import HelpIcon from '@material-ui/icons/Help';

import Box from '../../../../components/ui/Box';

// import { ReactComponent as AccountingIcon } from '../../../../assets/img/sidebar/accounting-icon.svg';
import { ReactComponent as BillingsIcon } from '../../../../assets/img/sidebar/billings-icon.svg';
// import { ReactComponent as CompanyInfoIcon } from '../../../../assets/img/sidebar/company-info-icon.svg';
// import { ReactComponent as CompanySecretaryIcon } from '../../../../assets/img/sidebar/company-secretary-icon.svg';
import { ReactComponent as MessagesIcon } from '../../../../assets/img/sidebar/messages-icon.svg';
import { ReactComponent as RequestsIcon } from '../../../../assets/img/sidebar/requests-icon.svg';
import useGetMessagesNotifications from '../../hooks/messages/useGetMessagesNotifications';
import { useCabinetContext } from '../../../../containers/CabinetContainer/context';
import { getUrlWithoutSlash } from '../../../../utils/helpers';

import {
  AdditionalMenuTitle,
  Root,
  SidebarLink,
  LinkIcon,
  LinkTitle,
  CountIcon,
  CompanyName,
  CompanyID,
} from './styled';

export type SidebarMenuItemProps = {
  to: string;
  title: string;
  subtitle?: string;
  icon?: JSX.Element;
  count?: number;
};

type SidebarMenuProps = {
  handleItemClick?: () => void;
};

function SidebarMenu(props: SidebarMenuProps) {
  const { handleItemClick } = props;
  const { pathname } = useLocation();
  const { url } = useRouteMatch();
  const { t } = useTranslation();

  const { currentAccount } = useCabinetContext();
  const [unseen,,, loadUnseen] = useGetMessagesNotifications();
  const rightUrl = getUrlWithoutSlash(url);

  const items = useMemo(() => [
    {
      to: `${rightUrl}/workspace/overview`,
      title: currentAccount?.name,
      subtitle: `ID ${currentAccount?.id}`,
    },
    // {
    //   to: `${rightUrl}/workspace/company-info`,
    //   title: t('account.sidebar.companyInfo'),
    //   icon: <CompanyInfoIcon />,
    // },
    {
      to: `${rightUrl}/workspace/requests`,
      title: t('account.sidebar.requests'),
      icon: <RequestsIcon/>,
    },
    {
      to: `${rightUrl}/workspace/messages`,
      title: t('account.sidebar.messages'),
      icon: <MessagesIcon />,
      count: unseen,
    },
    // {
    //   to: `${rightUrl}/workspace/company-secretary`,
    //   title: t('account.sidebar.companySecretary'),
    //   icon: <CompanySecretaryIcon />,
    // },
    // {
    //   to: `${rightUrl}/workspace/accounting`,
    //   title: t('account.sidebar.accounting'),
    //   icon: <AccountingIcon />,
    // },
    {
      to: `${rightUrl}/workspace/invoices`,
      title: t('account.sidebar.invoices'),
      icon: <BillingsIcon/>,
    },
  ], [rightUrl, currentAccount?.name, currentAccount?.id, t, unseen]) as SidebarMenuItemProps[];

  const handleClick = useCallback(() => {
      handleItemClick && handleItemClick();
      loadUnseen();
  }, [handleItemClick, loadUnseen]);

  useEffect(() => {
    const num = setInterval(loadUnseen, 60000);

    return () => {
      clearInterval(num);
    };
  }, [loadUnseen]);

  return (
    <Root>
      {items.map(({ to, title, subtitle, icon, count }) => {
        const isActive = pathname.includes(to);

        return (
          <SidebarLink
            key={to}
            isActive={isActive}
            component={Link}
            to={to}
            onClick={handleClick}
          >
            {subtitle ? (
              <Box>
                <CompanyName>{title}</CompanyName>
                <CompanyID>{subtitle}</CompanyID>
              </Box>
            ) : (
              <>
                <LinkIcon>{icon}</LinkIcon>
                <LinkTitle>{title}</LinkTitle>
                {count ? <CountIcon>{count}</CountIcon> : null}
              </>
            )}
          </SidebarLink>
        );
      })}

      <Box mt="auto">
        <AdditionalMenuTitle>Help&Preferences</AdditionalMenuTitle>
        <SidebarLink
          isActive={pathname.includes('/settings')}
          component={Link}
          to={`/cabinet/account/${currentAccount?.id}/settings`}
          onClick={handleClick}
        >
          <LinkIcon><SettingsIcon/></LinkIcon>
          <LinkTitle>{t('user.menu.settings')}</LinkTitle>
        </SidebarLink>
        <SidebarLink
          isActive={pathname.includes('/help')}
          component={Link}
          to={'#'}
          onClick={handleClick}
        >
          <LinkIcon><HelpIcon/></LinkIcon>
          <LinkTitle>Help</LinkTitle>
        </SidebarLink>
      </Box>
    </Root>
  );
}

export default SidebarMenu;
