import { useCallback } from 'react';
import i18n from 'i18next';
import dayjs, { Dayjs } from 'dayjs';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';

import 'dayjs/locale/en';

dayjs.extend(isToday);
dayjs.extend(relativeTime);
dayjs.extend(updateLocale);

dayjs.updateLocale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s: 'a moment',
    m: 'a minute',
    mm: '%d minutes',
    h: 'an hour',
    hh: '%d hours',
    d: 'a day',
    dd: '%d days',
    M: 'a month',
    MM: '%d months',
    y: 'a year',
    yy: '%d years'
  },
});

type DateProp = string | number | Date | Dayjs | null | undefined;

type UseDateLibParams = {
  dateLib: ((date?: dayjs.ConfigType) => dayjs.Dayjs)
    | ((date?: dayjs.ConfigType, format?: dayjs.OptionType, strict?: boolean) => dayjs.Dayjs)
    | ((date?: dayjs.ConfigType, format?: dayjs.OptionType, locale?: string, strict?: boolean) => dayjs.Dayjs);
  fromNow: (date: DateProp) => string;
  getCreatedDate: (date: DateProp) => string;
  nowTime: () => string;
  nowDate: () => string;
};

const useDateLib = (): UseDateLibParams => {
  const fromNow = (date: DateProp) => dayjs(date).locale(i18n.language).fromNow();

  const getCreatedDate = useCallback((date: DateProp) => {
    const currentDate = dayjs(date);

    const result = currentDate.isToday()
      ? `Today ${currentDate.format('HH:mm')}`
      : currentDate.format('MMM DD, YYYY HH:mm');

    return result !== 'Invalid Date' ? result : '';
  }, []);

  const nowTime = useCallback(() => dayjs().format('HH:mm'), []);
  const nowDate = useCallback(() => dayjs().format('MMM DD, YYYY'), []);

  return {
    dateLib: dayjs,
    fromNow,
    getCreatedDate,
    nowTime,
    nowDate,
  };
};

export default useDateLib;
