import styled from 'styled-components';

export const EmptyText = styled.div`
  color: rgba(35, 33, 33, 0.6);
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 50px;
`;

export const EmptyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const EmptyBg = styled.img`
  margin-top: 100px;
  max-width: 610px;
  width: auto;
`;
