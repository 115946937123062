import * as user from './user';
import * as account from './account';
import * as verification from './verification';

const mainClient = {
  user,
  account,
  verification,
};

export default mainClient;
