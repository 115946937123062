import { Fragment, useCallback } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { ServiceResultModel } from '../../network/models';
import Hidden from '../../../../components/ui/Hidden';
import { openActiveServiceModal } from '../ActiveServiceModal';

import {
  Root,
  AsideServicesLink,
} from './styled';

export type MenuItemProps = ServiceResultModel & {
  to: string;
}

type AsideServicesMenuProps = {
  items: MenuItemProps[];
}

function AsideServicesMenu(props: AsideServicesMenuProps) {
  const { pathname } = useLocation();
  const { items } = props;

  const handleOpen = useCallback((service) => () => openActiveServiceModal({ service }), []);

  return (
    <Root>
      {items.map(({ to, ...service }) => (
        <Fragment key={service.title}>
          <Hidden smDown>
            <AsideServicesLink
              isActive={pathname.indexOf(to) !== -1}
              component={Link}
              to={to}
            >{service.title}</AsideServicesLink>
          </Hidden>

          <Hidden mdUp>
            <AsideServicesLink onClick={handleOpen(service)}>{service.title}</AsideServicesLink>
          </Hidden>
        </Fragment>
      ))}
    </Root>
  )
}

export default AsideServicesMenu
