import styled, { css } from 'styled-components';

export const CheckoutCardWrapper = styled.div<{ withBackground: boolean }>`
  align-items: flex-start;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  max-width: 400px;
  width: 100%;

  ${(p) => p.withBackground && css`
    background-color: #F5F5F5;
  `}

  ${(p) => p.theme.breakpoints.down('md')} {
    width: 100%;
  }
`;

export const CheckoutCardItem = styled.div`
  margin-bottom: 24px;
`;

export const CheckoutCardLabel = styled.div`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

export const CheckoutCardText = styled.div`
  color: #101012;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
`;

export const CheckoutCardActions = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  button {
    margin-right: 16px;
    flex-shrink: 0;
    height: fit-content;
    margin-bottom: 24px;

    svg {
      margin-right: 10px;

      path {
        fill: white;
      }
    }
  }
`;
