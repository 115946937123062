import styled, { css } from 'styled-components';

import Button from '../../../../../../components/ui/Button';
import OutlinedInput from '../../../../../../components/ui/OutlinedInput';

export const Wrapper = styled.div<{ fullWidth: boolean }>`
  position: absolute;
  top: 0;
  right: 0;
  transition: width .5s ease-in-out;
  width: 320px;
  z-index: 100;
  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
`;

export const ClickContainer = styled.div<{ focused: boolean }>`
  display: ${(p) => (p.focused ? 'block' : 'none')};
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 30000;
`;

export const StyledPaper = styled.div<{ focused: boolean, isSearchPage?: boolean }>`
  position: relative;
  background: inherit;
  border-radius: 12px;
  z-index: 30001;
  ${(p) => (p.focused || p.isSearchPage)
    ? css`
      ${p.focused
        ? 'box-shadow: 0 0.5px 5px rgba(35, 33, 33, 0.039), 0 2px 6px rgba(35, 33, 33, 0.19);'
        : ''
      }
      background: #FFFFFF;
    ` : css`
      box-shadow: none;
    `
  }
`;

export const StyledInput = styled(({ focused, ...rest }) => <OutlinedInput {...rest} />)<{ focused?: boolean }>`
  &.MuiOutlinedInput-root {
    min-width: 320px;
    border-radius: 12px;
    background: #F5F5F5;

    ${(p) => p.focused && css`
      background: #FFFFFF;
      input::placeholder {
        color: rgba(35, 33, 33, 0.2);
        opacity: 1;
      }
    `}
  }

  & .MuiOutlinedInput-notchedOutline {
    border: none;
  }

  & .MuiInputAdornment-positionStart {
    opacity: 0.6;
    margin: 0 12px 0 6px;
  }

  & .MuiInputAdornment-positionEnd {
    cursor: pointer;
    svg {
      width: 20px;
    }
    opacity: 0.6;
  }
`;

export const StyledButton = styled(Button)`
  padding: 24px 0;
`;

export const ResultsWrapper = styled.div<{ focused: boolean }>`
  display: flex;
  flex-direction: column;

  z-index: 100;
  position: relative;

  ${(p) => p.focused
    ? css`
      border-top: 1px solid rgba(35, 33, 33, 0.12);
    ` : css`
      border: none;
      display: none;
    `
  }
`;

export const NoResults = styled.div`
  border-top: 1px solid rgba(35, 33, 33, 0.12);
  padding: 24px;
  text-align: center;
`;
