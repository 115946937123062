import styled from 'styled-components';

export const Title = styled.div`
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;

  &.scrolled {
    ${(p) => p.theme.breakpoints.down('sm')} {
      display: none;
    }
  }
`;
