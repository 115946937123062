import styled from 'styled-components';

import Box from '../../../../../../components/ui/Box';
import TextField from '../../../../../../components/ui/TextField';

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${(p) => p.theme.breakpoints.down('sm')} {
    overflow: hidden;
    padding-bottom: 105px;
  }
`;

export const ContentWrapper = styled(Box)`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;

  ${(p) => p.theme.breakpoints.down('sm')} {
    flex-direction: column;
    overflow: scroll;
  }
`;

export const MessageSubject = styled.div`
  color: #232121;
  font-weight: 600;
  font-size: 34px;
  line-height: 40px;
  letter-spacing: 0.25px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const MessageSubtitle = styled.div`
  margin-top: 12px;
`;

export const MessageFrom = styled.span`
  color: #232121;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
`;

export const MessageDate = styled(MessageFrom)`
  opacity: 0.6;
`;

export const MessageBody = styled(Box)`
  color: #232121;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;

  margin-top: 32px;
`;

export const MessageContent = styled(Box)`
  padding: 0;
`;

export const Reply = styled(Box)`
  background-color: #FFFFFF;
  bottom: 0;
  position: sticky;
  padding-bottom: 24px;
  padding-top: 24px;
  width: 50%;

  ${(p) => p.theme.breakpoints.down('sm')} {
    border-top: 1px solid #E6E6E6;
    margin: 0 -24px;
    padding: 24px;
    position: fixed;
    width: calc(100% - 80px);
  }

  ${(p) => p.theme.breakpoints.down('xs')} {
    width: 100%;
  }
`;

export const ReplyInput = styled(TextField)`
  .MuiFormLabel-root.Mui-focused {
    color: #232121;
  }

  &>.MuiOutlinedInput-root{
    border-radius: 10px;

    &.Mui-focused{
      border-color: #232121;
    }

    &.MuiOutlinedInput-adornedEnd {
      padding-right: 4px;
    }
  }
`;
