import React, { MouseEvent, ReactElement, useCallback, useState } from 'react';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import IconButton from '../ui/IconButton';
import Menu from '../ui/Menu';
import MenuItem from '../ui/MenuItem';

type MobileMenuProps = {
  children: ReactElement | ReactElement[];
}

const Index = (props: MobileMenuProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = useCallback(
    (event: MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget),
    [setAnchorEl],
  );

  const handleClose = useCallback(
    () => setAnchorEl(null),
    [setAnchorEl],
  );

  return (
    <>
      <IconButton
        aria-label="more"
        aria-controls="menu"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <MoreVertIcon/>
      </IconButton>
      <Menu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          elevation: 4,
          style: {
            borderRadius: '12px',
            minWidth: '200px',
          },
        }}
      >
        <MenuItem onClick={handleClose}>
          {props.children}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Index;
