import { BillingsListProps } from '../BillingsList';
import BillingsListItemMobile from '../BillingsListItemMobile';

import { BillingsListMobile } from './styled';

const BillingsMobileView = (props: BillingsListProps) => {
  const { billings } = props;

  return (
    <BillingsListMobile>
      {billings && billings.map((billing) => (
        <BillingsListItemMobile key={billing.id} billing={billing} />
      ))}
    </BillingsListMobile>
  );
};

export default BillingsMobileView;
