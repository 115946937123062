import React, { useCallback, useEffect, useState } from 'react';

import { sumSubElementId } from '../../utils/constants';
import mainClient from '../../../../network/mainClient';
import launchWebSdk from '../../utils/launchWebSdk';
import useQueryString from '../../../../hooks/useQueryString';
import NotFoundScreen from '../../../../screens/NotFoundScreen';

const VerificationLanding = () => {
  const query: URLSearchParams & { token?: string } = useQueryString();
  const token = query.get('token');

  const [notFound, setNotFound] = useState(false);

  const getNewAccessToken =  useCallback(async () => {
    if (!token) {
      return;
    }

    const data = await mainClient.verification.getVerificationData({ token });
    return data?.token ? data.token.token : null;
  }, [token]);

  const fetchDataAndLaunch = useCallback(async (): Promise<void> => {
    if (!token) {
      return;
    }

    try {
      const verificationData = await mainClient.verification.getVerificationData({ token });

      launchWebSdk({
        accessToken: verificationData!.token.token,
        getNewAccessToken,
      });
    } catch (e) {
      console.error(e);
      setNotFound(true);
    }
  }, [getNewAccessToken, token]);

  useEffect(() => {
    if (token) {
      setNotFound(false);
      void fetchDataAndLaunch();
    }
  }, [fetchDataAndLaunch, token]);

  if (!token || notFound) {
    return <NotFoundScreen />;
  }

  return (
    <div id={sumSubElementId}/>
  );
}

export default VerificationLanding;
