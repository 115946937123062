import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import { getBillingsList } from '../../network';
import { BillingsModel } from '../../network/models';

type ResultData = BillingsModel | null;

const useBillingsList = () : [ResultData, boolean, Error | null, () => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useDebouncedCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getBillingsList();
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  useEffect(() => {
    loadData();
  }, [loadData]);

  return [data, isLoading, error, loadData];
};

export default useBillingsList;
