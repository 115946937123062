import React from 'react';
import { useTranslation } from 'react-i18next';

import { Divider, Item, Title, Total, Value, Wrapper } from './styled';

export type TotalPriceProps = {
  price: string;
};

const TotalPrice = ({ price }: TotalPriceProps) => {
  const { t } = useTranslation();
  const priceLabel = t('account.processes.payment.tabs.byCard.price.label');
  const euro = t('account.processes.payment.tabs.byCard.price.euro');
  // const vat = t('account.processes.payment.tabs.byCard.price.vat');
  const totalAmount = t('account.processes.payment.tabs.byCard.price.totalAmount');

  return (
    <Wrapper>
      <Item>
        <Title>{priceLabel}</Title>
        <Divider />
        <Value>{euro}{price}</Value>
      </Item>
      {/* <Item> */}
      {/*  <Title>{vat}</Title> */}
      {/*  <Divider /> */}
      {/*  <Value>12%</Value> */}
      {/* </Item> */}
      <Total>
        <Title>{totalAmount}</Title>
        <Divider />
        <Value>{euro}{price}</Value>
      </Total>
    </Wrapper>
  );
};

export default TotalPrice;
