import { useTranslation } from 'react-i18next';

import NoMessagesBgImg from '../../../../../../assets/img/backgrounds/no-messages-bg.png';

import { FilteredMessageModel } from '../../../../network/models';
import Hidden from '../../../../../../components/ui/Hidden';
import Table from '../../../../../../components/ui/Table';
import TableHead from '../../../../../../components/ui/TableHead';
import TableRow from '../../../../../../components/ui/TableRow';
import TableBody from '../../../../../../components/ui/TableBody';
import TableContainer from '../../../../../../components/ui/TableContainer';

import MessagesListItem from '../MessagesListItem';

import { EmptyBg, EmptyText, EmptyWrapper, ColumnTitle } from './styled';

export type MessageProps = {
  message: FilteredMessageModel;
};

export type MessagesListProps = {
  messages: FilteredMessageModel[];
};

const MessagesList = (props: MessagesListProps) => {
  const { messages } = props;

  const { t } = useTranslation();

  const hasMessages = !!messages?.length;

  return hasMessages ? (
    <TableContainer component="div">
      <Table size="medium" aria-label="requests table">
        <TableHead>
          <TableRow>
            <ColumnTitle>{t('account.messages.list.message')}</ColumnTitle>
            <Hidden smDown><ColumnTitle /></Hidden>
            <ColumnTitle>{t('account.messages.list.date')}</ColumnTitle>
          </TableRow>
        </TableHead>
        <TableBody>
          {messages.map((message, index) => (
            <MessagesListItem key={`${message.date}_${index}`} message={message} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <EmptyWrapper>
      <EmptyText>{t('account.messages.emptyText')}</EmptyText>
      <EmptyBg src={NoMessagesBgImg} />
    </EmptyWrapper>
  );
};

export default MessagesList;
