import styled from 'styled-components';
import SwipeableViews from 'react-swipeable-views';

export const StepsWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding: 24px 24px 0 24px;
  max-width: 440px;
  width: 100%;
`;

export const StyledSwipeableViews = styled(SwipeableViews)`
  & {
    margin-top: 8px;
    overflow-x: visible !important;
  }

  & .react-swipeable-view-container {
    width: 100% !important;
  }

  &>div>div {
    display: flex;
    padding: 0 8px;
  }
`;

export const MobileStepsWrapper = styled.div`
  overflow: hidden;
  margin: 0 -24px 32px;
  padding: 0 16px;
`;

export const MobileStepWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 24px;
  width: 100%;
`;

export const Dots = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 8px;
  width: 100%;
`;

export const Dot = styled.div<{ isActive?: boolean }>`
  width: 8px;
  height: 8px;

  background: ${p => p.isActive ? '#232121' : '#DEDEDE'};
  border-radius: 50%;
  margin: 0 8px 0 0;
`;
