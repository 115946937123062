import styled, { css } from 'styled-components';

import ButtonBase from '../../../../components/ui/ButtonBase';

export const Root = styled.div`
  flex-shrink: 0;
  width: 304px;

  display: inline-flex;
  flex-direction: column;
  height: calc(100vh - 128px);
  padding-right: 20px;
  position: fixed;
  overflow: scroll;

  &::-webkit-scrollbar {
    display: none;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    width: 90%;
    max-width: 304px;
    position: absolute;
    height: calc(100vh - 72px);
  }
`;

export const CompanyName = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
`;

export const CompanyID = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
  opacity: 0.6;
  margin-top: 4px;
`;

export const SidebarLink = styled(({ isActive, ...props }) => <ButtonBase {...props} />)<{ isActive?: boolean }>`
  display: inline-flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 16px 0;
  color: #232121;

  width: 100%;
  text-align: left;
  border-radius: 12px;
  white-space: nowrap;
  margin-bottom: 8px;

  transition: all 0.3s ease;

  svg {
    transition: all 0.3s ease;
    opacity: 0.6;
  }

  ${(p) => p.isActive ? css`
    background: #232121;
    padding: 16px 24px 16px 40px;

    color: #FFFFFF;

    svg {
      fill: #FFFFFF;
      opacity: 1;
    }
  ` : css`
    &:hover {
      background: #DEDEDE;
      padding: 16px 24px 16px 40px;

      svg {
        fill: #232121;
        opacity: 1;
      }
    }
  `}
`;

export const LinkIcon = styled.div`
  margin-right: ${(p) => p.theme.spacing(2)}px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LinkTitle = styled.div`
`;

export const CountIcon = styled.div`
  position: absolute;
  right: 16px;
  padding: 3px 8px;
  background: #D95050;
  border-radius: 12px;
  color: #FFFFFF;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.5px;
`;

export const AdditionalMenuTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #232121;
  opacity: 0.6;
  margin-bottom: 12px;
  margin-top: 48px;
`;
