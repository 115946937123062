import { makeStyles } from '@material-ui/core';
import styled, { StyledProps } from 'styled-components';

import { MenuItemProps } from '../../../../modules/service/components/AsideServicesMenu';
import Avatar from '../../../ui/Avatar';
import ListItem, { Props as ListItemProps } from '../../../ui/ListItem';
import ListItemIcon from '../../../ui/ListItemIcon';
import ListSubheader from '../../../ui/ListSubheader';
import Menu, { Props as MenuProps } from '../../../ui/Menu';
import MenuItem from '../../../ui/MenuItem';
import Typography from '../../../ui/Typography';

export const AccountName = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #232121;
  margin-left: 12px;
  margin-right: 9px;

  ${(p) => p.theme.breakpoints.down('xs')} {
    display: none;
  }
`;

export const StyledListItem: ListItemProps & StyledProps<any> = styled(ListItem)`
  border-radius: 12px;
  padding-right: 0;

  ${(p) => p.theme.breakpoints.down('xs')} {
    right: 0;
  }
`;

export const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 44px;
  margin-left: 10px;
`;

export const AvatarListItemIcon = styled(ListItemIcon)`
  min-width: 40px;
  display: flex;
  align-items: center;
`;

export const StyledListSubheader = styled(ListSubheader)`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 24px;
`;

export const ListItemSeparator = styled.div`
  height: 1px;
  margin: 8px 24px;
  background: #4A5351;
  opacity: 0.12;
`;

export const StyledAvatar = styled(Avatar)`
  width: 32px;
  height: 32px;
`;

export const StyledMenu: MenuProps & StyledProps<any> = styled(Menu)`
  & .MuiMenu-paper {
    border-radius: 12px;
    min-width: 288px;
    box-shadow: 0 0.5px 5px rgba(35, 33, 33, 0.039), 0 3.75px 11px rgba(35, 33, 33, 0.19);

    ${(p) => p.theme.breakpoints.down('xs')} {
      top: 56px;
      left: 24px;
      right: 24px;
    }
  }

  & .MuiMenu-popover {
    ${(p) => p.theme.breakpoints.down('xs')} {
      background-color: rgba(35, 33, 33, 0.12);
    }
  }

  & .MuiPopover-root {
    z-index: 21000 !important;
  }
`;

export const StyledMenuItem: MenuItemProps & StyledProps<any> = styled(MenuItem)`
  padding: 8px 24px;
  div > span {
    display: flex;
    align-items: center;
  }
`;

export const UserName = styled(Typography)`
  color: #232121;
  font-weight: 600;
  font-size: 14px;
  letter-spacing: 0.1px;
  line-height: 20px;
`;

export const UserStatus = styled(Typography)`
  color: #232121;
  font-size: 12px;
  letter-spacing: 0.5px;
  line-height: 16px;
  opacity: 0.6;
`;

export const useStyles = makeStyles((theme) => ({
  popoverRoot: {
    backgroundColor: theme.breakpoints.down('xs') ? 'rgba(35, 33, 33, 0.12)' : '',
  },
}));
