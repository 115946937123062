import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import { DocumentSessionModel } from '../../network/models';
import { getDocumentSession } from '../../network';

type ResultData = DocumentSessionModel | null;

type UseDocumentSessionParams = {
  documentId: number;
};

const useDocumentSession = (params: UseDocumentSessionParams): [ResultData, boolean, Error | null, () => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const { documentId } = params;

  const loadData = useDebouncedCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await getDocumentSession(params);

      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  useEffect(() => {
    loadData();
  }, [documentId, loadData]);

  return [data, isLoading, error, loadData];
};

export default useDocumentSession;
