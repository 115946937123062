import styled from 'styled-components';

import Card from '../../../../../../components/ui/Card';

export const StyledCard = styled(Card)`
  border-radius: 12px;
  box-shadow:
    0 27px 80px rgba(35, 33, 33, 0.05),
    0 16px 40px rgba(35, 33, 33, 0.03),
    0 2px 16px rgba(35, 33, 33, 0.03);
`;
