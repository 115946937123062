import { createContext, ReactElement, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import {
  ListContextInitialValues,
  ListContextValues,
  ListProvider,
  useListContext,
} from '../../../providers/ListProvider';

import useListState from '../../../hooks/useListState';
import useCompanySecretary from '../hooks/companySecretary/useCompanySecretary';
import { AllItemsFlattenedModel, getNestedItems } from '../utils/companySecretary';

export type CompanySecretaryProviderProps = {
  children: ReactElement;
};

export interface CompanySecretaryContextValues extends ListContextValues {
  allItemsFlattened: AllItemsFlattenedModel[] | null;
  rootUrl: string | null;
}

const CompanySecretaryContextInitialValues = {
  ...ListContextInitialValues,

  allItemsFlattened: null,
  rootUrl: null,
};

export const CompanySecretaryContext = createContext<CompanySecretaryContextValues>(CompanySecretaryContextInitialValues);

export const CompanySecretaryProvider = (props: CompanySecretaryProviderProps) => {
  const { pathname } = useLocation();
  const { page, pageSize, setPage, setPageSize } = useListState();

  const [companySecretaryList, isCompanySecretaryLoading, companySecretaryError, loadCompanySecretary] = useCompanySecretary();

  const splitPathname = pathname
    .replace(/^\//, '')
    .split('/')
    .filter(Boolean);

  const rootUrl = splitPathname
    .filter((_, index, array) => {
      const foundRootIndex = array.findIndex(i => i === 'company-secretary');
      return index <= foundRootIndex;
    }).toString().replace(/,/g, '/');

  const allItemsFlattened: AllItemsFlattenedModel[] = useMemo(() =>
      companySecretaryList?.results ? companySecretaryList.results.map((item) => getNestedItems({
        root: item,
        parentPrefix: rootUrl,
        parentId: 0,
      })).flat() : [],
    [companySecretaryList?.results, rootUrl],
  );

  return (
    <ListProvider
      ListContext={CompanySecretaryContext}

      data={companySecretaryList}
      loading={isCompanySecretaryLoading}
      error={companySecretaryError}
      loadData={loadCompanySecretary}

      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}

      allItemsFlattened={allItemsFlattened}
      rootUrl={rootUrl}
    >
      {props.children}
    </ListProvider>
  );
};

export const useCompanySecretaryContext = () => useListContext(CompanySecretaryContext) as CompanySecretaryContextValues;
