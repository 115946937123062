import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
  }
});

const mui = createTheme({
  palette: {
    primary: {
      main: '#27C297',
    },
    secondary: {
      main: 'rgba(35, 33, 33, 0.6)',
      light: '#27C297',
    },
    background: {
      default: '#F5F5F5',
    },
    text: {
      primary: '#232121',
    },
    error: {
      main: '#D95050',
    }
  },
  overrides: {
    MuiToolbar: {
      gutters: {
        [defaultTheme.breakpoints.up('sm')]: {
          paddingLeft: 40,
          paddingRight: 40,
          paddingTop: 20,
          paddingBottom: 20,
        },
      },
    },
    MuiCardContent: {
      root: {
        padding: 24,
      }
    },
    MuiButtonBase: {
      root: {
        fontFamily: defaultTheme.typography.fontFamily,
      },
    },
    MuiCheckbox: {
      root: {
        color: '#232121',
      },
    },
    MuiRadio: {
      root: {
        color: '#232121',
      },
    },
    MuiOutlinedInput: {
      root: {
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#232121',
          },
        },
      }
    },
    MuiTooltip: {
      tooltip: {
        maxWidth: 500,
        padding: '12px 16px',
        backgroundColor: '#232121',
      },
      arrow: {
        color: '#232121',
      }
    },
  },
  typography: {
    fontFamily: defaultTheme.typography.fontFamily,
    h3: {
      fontWeight: 600,
    },
    h4: {
      letterSpacing: '0.25px',
      fontWeight: 600,

      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: '1.5rem',
        lineHeight: '32px',
      },
    },
    h5: {
      fontWeight: 600,
      letterSpacing: 0.1,

      [defaultTheme.breakpoints.down('xs')]: {
        fontSize: 14,
        lineHeight: '20px',
      },
    },
    h6: {
      letterSpacing: 0.15,
      color: '#4A5351',
    },
    button: {
      fontWeight: 600,
      letterSpacing: 1.5,
    }
  },
});

export default mui;
