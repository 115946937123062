import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

import { CurrentAccountProps } from '../../../../containers/CabinetContainer/context';
import { openCreateAccountModal } from '../../components/CreateAccountModal';
import useListState from '../../../../hooks/useListState';

import useAccountList from '../../hooks/accounts/useAccountList';
import AccountListItem from '../../components/AccountListItem';
import AccountListLayout from '../../../../components/AccountListLayout';
import { DashedLongButton } from '../../../../components/ui/Button';
import ErrorMessage from '../../../../components/ui/ErrorMessage';
import Grid from '../../../../components/ui/Grid';
import Pagination from '../../../../components/ui/Pagination';

import { AsideBgImg, ListRoot, ListWrap } from './styled';

const AccountListScreen = () => {
  const { page, pageSize, setPage, setPageSize } = useListState();
  const [data, isLoading, error, loadData] = useAccountList({ page, pageSize });
  const { t } = useTranslation();

  const createAccount = useCallback(() => {
    openCreateAccountModal({ callback: loadData });
  }, [loadData]);

  return (
    <AccountListLayout
      title={t('account.list.title')}
      loading={isLoading}
      empty={data !== null && data.results.length === 0}
      footer={
        <Pagination
          page={page}
          pageSize={pageSize}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          count={data && data.count}
        />
      }
    >
      <ListRoot>
        <Grid container>
          <Grid item md={8} xs={12}>
            <ListWrap>
              {error && (
                <ErrorMessage error={error} />
              )}
              {data && data.results && data.results.map((item: CurrentAccountProps ) => (
                <AccountListItem
                  key={item?.id}
                  item={item}
                  loadData={loadData}
                />
              ))}
              <DashedLongButton
                startIcon={<AddIcon />}
                onClick={createAccount}
              >
                {t('account.create.addCompany')}
              </DashedLongButton>
            </ListWrap>
          </Grid>
          <Grid item container md={4} xs={1} justifyContent="flex-end">
            <AsideBgImg />
          </Grid>
        </Grid>
      </ListRoot>
    </AccountListLayout>
  );
};

export default AccountListScreen;
