import { useTranslation } from 'react-i18next';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';

import Box from '../../../../components/ui/Box';
import StyledAccordion from '../../../../components/StyledAccordion';
import LoaderProgress from '../../../../components/LoaderProgress';

import useKYCShareholders from '../../hooks/tasks/useKYCShareholders';

import { Divider, ListWrapper, Name, Status, ShareholderIcon } from './styled';

export type ProcessKYCVerificationPanelProps = {
  processId: number;
  processName: string;
};

const ProcessKYCVerificationPanel = (props: ProcessKYCVerificationPanelProps) => {
  const { processId, processName } = props;
  const { t } = useTranslation();

  const title = t('account.processes.forms.companyIncorporation.shareholders.label');

  const [KYCShareholders, isKYCShareholdersLoading] = useKYCShareholders({
    processId,
    processName,
  });

  const getStatusText = (status: string): string => {
    if (status === 'new') {
      return t('account.processes.kyc.status.inProgress');
    }

    if (status === 'verified') {
      return t('account.processes.kyc.status.signed');
    }

    if (status === 'rejected') {
      return t('account.processes.kyc.status.rejected');
    }

    return status;
  };

  const mappedShareholders = KYCShareholders ? KYCShareholders.map(({ status, ...rest }) => ({
    status: getStatusText(status),
    ...rest,
  })) : [];

  return (
    <Box mb={4}>
      <StyledAccordion
        title={title}
        initialExpanded={mappedShareholders.length < 7}
      >
        <ListWrapper>
          {isKYCShareholdersLoading && <LoaderProgress size={30} />}

          {mappedShareholders.map(({ id, account_type, name, status }) => (
            <Box key={id} display="flex" justifyContent="space-between" width="100%" mt={1} mb={1}>
              <Name>
                <ShareholderIcon>
                  {account_type === 'company' ? <BusinessIcon /> : <PersonIcon />}
                </ShareholderIcon>
                {name}
              </Name>
              <Status status={status}>{status}</Status>
            </Box>
          ))}
        </ListWrapper>
      </StyledAccordion>
      <Divider />
    </Box>
  );
};

export default ProcessKYCVerificationPanel;
