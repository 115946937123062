import React from 'react';

import MenuItem from '../../../../../../../../components/ui/MenuItem';
import FormSelect from '../../../../../../../../components/ui/FormSelect';
import { COUNTRIES } from '../../../../../../utils/constants';
import { useTranslation } from 'react-i18next';

type CountryProps = {
  name: string;
  tPrefix: string;
  validate?: (value: any) => string | undefined;
};

export const Country = (props: CountryProps) => {
  const { name, tPrefix, validate } = props;

  const { t } = useTranslation();

  return (
    <FormSelect
      name={name}
      label={`${t(`${tPrefix}.peopleForm.registeredAddress.country`)} *`}
      variant="outlined"
      validate={validate}
      initialValue=""
    >
      {COUNTRIES.map((option) => {
        return (
          <MenuItem key={option.code} value={option.name}>
            {option.name}
          </MenuItem>
        );
      })}
    </FormSelect>
  );
};
