import { ChangeEvent, useCallback, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';

import IconButton from '../../../../../../components/ui/IconButton';
import InputAdornment from '../../../../../../components/ui/InputAdornment';
import FormControl from '../../../../../../components/ui/FormControl';

import { useCompanySecretaryContext } from '../../../../providers/CompanySecretaryProvider';
import { AllItemsFlattenedModel } from '../../../../utils/companySecretary';

import SearchResultsTable from '../SearchBarResults';

import { Wrapper, ClickContainer, StyledPaper, StyledInput, StyledButton, ResultsWrapper, NoResults } from './styled';

interface SearchBarProps {
  changeSearchQuery?: (search: string) => void;
  defaultSearch?: string;
  isSearchPage?: boolean;
  resetSearchQuery?: () => void;
}

const SearchBar = ({ defaultSearch, changeSearchQuery, isSearchPage, resetSearchQuery }: SearchBarProps) => {
  const { t } = useTranslation();

  const { allItemsFlattened, rootUrl } = useCompanySecretaryContext();

  const [search, setSearch] = useState(defaultSearch || '');
  const [focused, setFocused] = useState(false);
  const [foundFolders, setFoundFolders] = useState<AllItemsFlattenedModel[]>([]);
  const [foundFiles, setFoundFiles] = useState<AllItemsFlattenedModel[]>([]);

  const allFiles = (allItemsFlattened || []).filter(({ type }) => type === 'file');
  const allFolders = (allItemsFlattened || []).filter(({ type }) => type === 'folder');

  const containFound = useMemo(() =>
    Boolean(foundFolders.length || foundFiles.length),
    [foundFolders, foundFiles]
  );

  const totalFound = useMemo(() => foundFolders.length + foundFiles.length, [foundFolders, foundFiles]);

  const placeholder = t('account.companySecretary.search.placeholder');

  const handleFocus = useCallback(() => {
    setFocused(true);
  }, []);

  const handleBlur = useCallback(() => {
    setFocused(false);
  }, []);

  const handleSearch = useDebouncedCallback(() => {
    if (!search) {
      setFoundFolders([]);
      setFoundFiles([]);
      return;
    }

    const folders = allFolders.filter(folder => folder.title.toLowerCase().includes(search.toLowerCase()));
    const files = allFiles.filter(file => file.title.toLowerCase().includes(search.toLowerCase()));

    setFoundFolders(folders);
    setFoundFiles(files);
    changeSearchQuery && changeSearchQuery(search);
  }, 500, { trailing: true });

  const handleChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    handleSearch();
  }, [handleSearch]);

  const handleResetSearch = useCallback(() => {
    setSearch('');
    setFocused(false);
    setFoundFolders([]);
    setFoundFiles([]);
    resetSearchQuery && resetSearchQuery();
  }, [resetSearchQuery]);

  const commonResultsProps = useMemo(() => ({
    handleClose: handleBlur,
    search,
  }), [handleBlur, search]);

  return (
    <Wrapper fullWidth={isSearchPage || focused}>
      <ClickContainer onClick={handleBlur} focused={focused} />
      <StyledPaper focused={focused} isSearchPage={isSearchPage}>
        <FormControl variant="outlined" fullWidth>
          <StyledInput
            focused={focused}
            value={search}
            startAdornment={(
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            )}
            endAdornment={search && (
              <InputAdornment position="end" onClick={handleResetSearch}>
                <IconButton><CloseIcon /></IconButton>
              </InputAdornment>
            )}
            placeholder={placeholder}
            onChange={handleChange}
            inputProps={{
              onFocus: handleFocus,
            }}
          />
        </FormControl>

        {(!defaultSearch && search) ? (
          <>
            <ResultsWrapper focused={containFound && focused}>
              {foundFolders.length ? <SearchResultsTable rows={foundFolders} title="Folders" {...commonResultsProps} /> : null}
              {foundFiles.length ? <SearchResultsTable rows={foundFiles} title="Files" {...commonResultsProps} /> : null}
              {containFound && (
                <StyledButton
                  component={Link}
                  to={`/${rootUrl}/search?search=${search}`}
                  startIcon={<SearchIcon />}
                >{t('account.companySecretary.search.allResults')} ({totalFound})</StyledButton>
              )}
            </ResultsWrapper>

            {(search && !containFound) && (
              <NoResults>{t('account.companySecretary.search.notFound')}</NoResults>
            )}
          </>
        ) : null}
      </StyledPaper>
    </Wrapper>
  );
};

export default SearchBar;
