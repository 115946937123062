import styled from 'styled-components';

export const Root = styled.div<{ isServicesPage?: boolean }>`
  flex-grow: 1;

  display: flex;
  flex-direction: row;

  background-color: ${(p) => p.isServicesPage ? '#F5F5F5' : p.theme.palette.background.paper};
  border-radius: 24px 24px 0 0;
  overflow: hidden;

  height: 100%;
  margin-top: 88px;
  min-height: calc(100vh - 88px);
  padding: 40px;
  ${(p) => p.isServicesPage && `
    padding-bottom: 0;
  `}

  ${(p) => p.theme.breakpoints.down('xs')} {
    margin-top: 56px;
    padding: 32px 24px 24px;
  }
`;
