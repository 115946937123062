import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import { ProcessResultModel } from '../../network/models';
import { getProcess } from '../../network';

type ResultData = ProcessResultModel | null;

type UseProcessParams = {
  processId: number;
};

export type LoadProcessParams = {
  loader?: boolean;
};

const useProcess = (params: UseProcessParams): [ResultData, boolean, Error | null, (loader?: boolean) => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const { processId } = params;

  const loadData = useDebouncedCallback(async (loader = true) => {
    if (loader) {
      setIsLoading(true);
    }

    setError(null);

    try {
      const response = await getProcess(params);
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  useEffect(() => {
    loadData();
  }, [processId, loadData]);

  return [data, isLoading, error, loadData];
};

export default useProcess;
