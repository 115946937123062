import CircularProgress from '../ui/CircularProgress';

import { LoaderWrapper } from './styled';

type LoaderProgressProps = {
  size?: number;
};

const LoaderProgress = ({ size }: LoaderProgressProps) => {
  return (
    <LoaderWrapper>
      <CircularProgress size={size || 50} />
    </LoaderWrapper>
  );
};

export default LoaderProgress;
