export const getSavedAccountInLS = (): any => {
  const savedAccount = localStorage.getItem('account');

  if (savedAccount) {
    return JSON.parse(savedAccount);
  }

  return null;
};

export const saveAccountToLS = (account: any) => {
  localStorage.setItem('account', JSON.stringify(account));
};
