import styled, { css } from 'styled-components';

import Grid from '../../../../components/ui/Grid'

export const Wrapper = styled.div`
  margin-top: 32px;
`;

export const Documents = styled.div<{ disabled?: boolean }>`
  ${(p) => p.disabled && css`
    pointer-events: none;
    cursor: no-drop;
    opacity: 0.4;
    user-select: none;
  `}
`;

export const Document = styled(Grid)`
  position: relative;
  padding: 12px 0;
`;

export const NameWrapper = styled(Grid)<{ disabled?: boolean }>`
  ${(p) => p.disabled && css`
    pointer-events: none;
    cursor: no-drop;
    opacity: 0.4;
    user-select: none;
  `}

  ${(p) => p.theme.breakpoints.down('sm')} {
    display: flex;
    flex-direction: column;
  }
`;

export const IconWrapper = styled.div`
  display: inline-block;
  margin-right: 18px;
  position: relative;
  top: 2px;

  ${(p) => p.theme.breakpoints.down('sm')} {
    display: flex;
    align-items: center;
    margin-right: 20px;
    top: 0;

    transform: scale(1.2);
  }
`;

export const Name = styled.div`
  display: inline-block;

  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #232121;
  word-break: break-all;
  cursor: pointer;

  a {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.1px;
    color: #232121;

    text-decoration: none;
  }
`;

export const Status = styled(Grid)<{ status?: string }>`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  position: relative;
  display: flex;
  align-items: center;
  letter-spacing: 0.1px;
  text-transform: capitalize;

  text-align: right;
  justify-content: flex-end;
  margin-left: auto;

  ${p => p.status === 'completed' && css`
    color: #00C853;
  `}

  ${p => p.status === 'sent' && css`
    color: #FFB800;
  `}

  ${p => (p.status === 'rejected' || p.status === 'declined') && css`
    color: #D95050;
  `}

  ${p => p.status === 'external_review' && css`
    color: #FFB800;
  `}

  ${p => p.status === 'viewed' && css`
    color: rgba(35, 33, 33, 0.6);
  `}

  ${p => p.status === 'uploaded' && css`
    color: rgba(35, 33, 33, 0.6);
  `}

  ${(p) => p.theme.breakpoints.down('sm')} {
    margin-top: 4px;
  }
`;

export const Congratulations = styled.div`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: rgba(35, 33, 33, 0.6);
  text-align: center;
`;
