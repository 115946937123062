import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import mainClient from '../../../../network/mainClient';
import RequestError from '../../../../network/RequestError';
import ObjectType from '../../../../utils/ObjectType';

type ResultData = ObjectType| null;

const useAccountList = (
  params: { page?: number; pageSize?: number } = {}
): [ResultData, boolean, Error | null, () => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const { page, pageSize } = params;

  const loadData = useDebouncedCallback(async (page?: number, pageSize?: number) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await mainClient.account.getAccountList({
        page,
        pageSize,
      });
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  useEffect(() => {
    loadData(page, pageSize);
  }, [page, pageSize, loadData]);

  return [data, isLoading, error, loadData];
};

export default useAccountList;
