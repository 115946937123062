import styled, { css } from 'styled-components';

import SuccessTaskIcon from '../../../../assets/img/processes/success-task.svg';
import WaitIcon from '../../../../assets/img/processes/wait.svg';

import { TASK_STATUS_DONE, TASK_STATUS_STARTED } from '../../utils/constants';

export const StepWrapper = styled.div<{ status?: string }>`
  margin-bottom: 24px;
  padding: 18px 64px 18px 24px;
  border-radius: 12px;
  border: 1px solid #DEDEDE;
  width: 100%;
  min-width: 250px;
  max-width: 440px;
  position: relative;

  ${(p) => p.status === TASK_STATUS_STARTED && css`
    box-shadow: 0 7px 20px rgb(35 33 33 / 5%),
      0 4px 10px rgb(35 33 33 / 3%),
      0 1px 5px rgb(35 33 33 / 3%);
    border: none;
  `}

  ${(p) => p.status === TASK_STATUS_DONE && css`
    background-color: #FFFFFF;
    cursor: no-drop;
  `}

  ${(p) => p.status === undefined && css`
    background-color: #F5F5F5;
    cursor: no-drop;
  `}

  ${(p) => p.theme.breakpoints.down('sm')} {
    width: 100%;
    max-width: 100%;
  }
`;

export const StepNumber = styled.div`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

export const StepTitle = styled.div<{ status?: string }>`
  color: rgba(35, 33, 33, 0.6);
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.15px;
  margin-top: 4px;

  ${(p) => p.status === TASK_STATUS_STARTED && css`
    color: #232121;;
  `}

  ${(p) => p.theme.breakpoints.down('sm')} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const StepDescription = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 90%;
  opacity: 0.6;

  color: #232121;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 4px;
`;

export const StepIcon = styled.div<{ status?: string }>`
  position: absolute;
  right: 8px;
  width: 48px;
  height: 48px;
  top: 50%;
  transform: translateY(-50%);

  ${(p) => p.status === TASK_STATUS_DONE && css`
    background: url(${SuccessTaskIcon}) no-repeat center;
  `}

  ${(p) => p.status === TASK_STATUS_STARTED && css`
    background: url(${WaitIcon}) no-repeat center;
  `}
`;
