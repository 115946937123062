import qs from 'qs';

import RequestError from '../RequestError';
import { getCurrentCabinetContextValue } from '../../containers/CabinetContainer/context';

type RequestOptions = {
  url: string;
  urlParams?: { [key: string]: any };
  method?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  headers?: { [key: string]: string };
  body?: any;
  responseType?: string;
  contentType?: string;
  addAccountToBody?: boolean;
};

type Result = {
  data?: any;
  ok: boolean;
  status: number;
  errorMessage?: string;
};

const request = async (options: RequestOptions): Promise<Result> => {
  let {
    url,
    urlParams = null,
    method = 'GET',
    headers = {},
    body = null,
    responseType = 'json',
    contentType = null,
    addAccountToBody = false,
  } = options;

  const cabinetContext = await getCurrentCabinetContextValue();

  if (cabinetContext?.currentAccount?.id) {
    headers['X-Account-ID'] = headers['X-Account-ID'] ?? cabinetContext?.currentAccount?.id;

    if (addAccountToBody) {
      body.account = cabinetContext?.currentAccount?.id;
    }
  }

  let fullUrl = `https://api.ezstart.lu${url}`;
  if (urlParams !== null) {
    fullUrl = `${fullUrl}?${qs.stringify(urlParams, { arrayFormat: 'comma' })}`;
  }

  if (contentType !== null && body !== null) {
    if (contentType === 'json' && typeof body === 'object') {
      if (typeof body === 'object') {
        body = JSON.stringify(body);
      }
      headers['content-type'] = 'application/json';
    } else if (contentType === 'form' && typeof body === 'object') {
      const formData = new FormData();
      for (const key in body) {
        formData.append(key, body[key]);
      }

      body = formData;
    }
  } else if (contentType === 'pdf') {
    headers['content-type'] = 'application/pdf';
  }

  const requestConfig: RequestInit = {
    method,
    body,
    headers,
    credentials: 'include',
    // mode: 'cors',
  };

  const response = await fetch(fullUrl, requestConfig);

  if (!response.ok) {
    const error = await RequestError.fromFetchResponse(response);
    throw error;
  }


  const result: Result = {
    ok: response.ok,
    status: response.status,
    data: null,
  };
  if (response.ok) {
    try {
      if (responseType === 'json') {
        result.data = await response.json();
      }
      if (responseType === 'text') {
        result.data = await response.text();
      }
      if (responseType === 'blob') {
        result.data = await response.blob();
      }
    } catch (e) {}
  } else {
    result.errorMessage = response.statusText;
  }

  return result;
};

export default request;
