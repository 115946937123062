import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import printJS from 'print-js';

import { ReactComponent as DownloadIcon } from '../../../../../../assets/img/companySecretary/download-icon.svg';
import { ReactComponent as PrintIcon } from '../../../../../../assets/img/companySecretary/print-icon.svg';
import { AllItemsFlattenedModel } from '../../../../utils/companySecretary';

import CircularProgress from '../../../../../../components/ui/CircularProgress';
import Box from '../../../../../../components/ui/Box';

import { StyledButton, StyledToolbar, ButtonsWrapper } from './styled';

interface EnhancedTableToolbarProps {
  selected: AllItemsFlattenedModel[];
}

const TableToolbar = (props: EnhancedTableToolbarProps) => {
  const { selected } = props;
  const { t } = useTranslation();
  const [downloading, setDownloading] = useState(false);
  const [printing, setPrinting] = useState(false);

  const numSelected = selected.length;
  const selectedUrls = selected.map(({ file }) => file);

  const downloadText = t('account.companySecretary.actions.download');
  const printText = t('account.companySecretary.actions.print');

  const downloadMultipleZippedFiles = useCallback(async () => {
    const zip = new JSZip();

    const remoteFiles = selected.map(async (item) => {
      const fileExtension = item.file!.split('.').pop();

      const response = await fetch(item.file!, {
        headers: { 'Content-Type': 'application/pdf' },
      });
      const data = await response.blob();

      zip.file(`${item.title}.${fileExtension}`, data);

      return data;
    });

    await Promise.all(remoteFiles);
    const content = await zip.generateAsync({ type: 'blob' });

    saveAs(content, 'docs.zip');

    setDownloading(false);
  }, [selected]);

  const downloadOneFile = useCallback(async () => {
    const fileExtension = selected[0].file!.split('.').pop();
    const response = await fetch(selected[0].file!, {
      headers: { 'Content-Type': 'application/pdf' },
    });

    const data = await response.blob();

    saveAs(data, `${selected[0].title}.${fileExtension}`);
    setDownloading(false);
  }, [selected]);

  const handleDownload = useCallback(async () => {
    setDownloading(true);

    try {
      if (numSelected === 1) {
        await downloadOneFile();
      } else {
        await downloadMultipleZippedFiles();
      }
    } catch (error) {
      console.error(error);
      setDownloading(false);
    }
  }, [downloadOneFile, downloadMultipleZippedFiles, numSelected]);

  const printItems = useCallback((fileIndex: number) => {
    const file = selectedUrls[fileIndex];
    const nextFile = selectedUrls[fileIndex + 1];

    if (!file) {
      return;
    }

    printJS({
      printable: file,
      type: 'pdf',
      onPrintDialogClose: () => nextFile && printItems(fileIndex + 1),
      onLoadingStart: () => setPrinting(true),
      onLoadingEnd: () => setPrinting(false),
    });
  }, [selectedUrls]);

  const handlePrint = useCallback(() => {
    try {
      if (selectedUrls[0]) {
        printItems(0);
      }
    } catch (error) {
      console.error(error);
      setPrinting(false);
    }
  }, [printItems, selectedUrls]);

  return numSelected > 0 ? (
    <StyledToolbar>
      <ButtonsWrapper>
        <StyledButton aria-label={downloadText} onClick={handleDownload} disabled={downloading || printing}>
          {downloading ? (
            <Box mr={1} display="flex" alignItems="center">
              <CircularProgress size={15}/>
            </Box>
          ) : <DownloadIcon />}

          {downloadText}
        </StyledButton>
        <StyledButton aria-label={printText} onClick={handlePrint} disabled={downloading || printing}>
          {printing ? (
            <Box mr={1} display="flex" alignItems="center">
              <CircularProgress size={15}/>
            </Box>
          ) : <PrintIcon />}

          {printText}
        </StyledButton>
      </ButtonsWrapper>
    </StyledToolbar>
  ) : null;
};

export default TableToolbar;
