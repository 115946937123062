import React from 'react';
import { useTranslation } from 'react-i18next';

import useDateLib from '../../../../../../hooks/useDateLib';
import { FilteredMessageModel } from '../../../../network/models';
import { removeReplyTextRegExp } from '../../../../utils/messages';

import {
  HistoryTitle,
  EmptyHistory,
  HistoryWrapper,
  HistoryItem,
  HistoryItemTitle,
  HistoryItemDate,
  HistoryText,
  HistoryCheckingIcon,
} from './styled';

type HistoryProps = {
  history: FilteredMessageModel[];
  handleChangeCurrentMessage: (message: FilteredMessageModel) => () => void;
  currentMessage: FilteredMessageModel;
};

const History = ({ history, handleChangeCurrentMessage, currentMessage }: HistoryProps) => {
  const { t } = useTranslation();

  const { getCreatedDate } = useDateLib();

  return (
    <HistoryWrapper width={{ xs: '100%', md: '40%' }}>
      <HistoryTitle>{t('account.messages.message.history')}</HistoryTitle>
      {history.length > 1
        ? history.map((message) => {
          const isActive = message.date === currentMessage.date && message.body_text === currentMessage.body_text;
          const body = (message.body_text || '').replace(removeReplyTextRegExp, '');

          return (
            <HistoryItem key={`${message.date}_${message.body_text}`} onClick={handleChangeCurrentMessage(message)}>
              {isActive ? <HistoryCheckingIcon /> : null}
              <HistoryText>
                <HistoryItemTitle>{body}</HistoryItemTitle>
                <HistoryItemDate>{getCreatedDate(message.date)}</HistoryItemDate>
              </HistoryText>
            </HistoryItem>
          );
        })
        : <EmptyHistory>{t('account.messages.message.emptyHistory')}</EmptyHistory>
      }
    </HistoryWrapper>
  );
};

export default History;
