import request from '../../../network/mainClient/request'

export type GetServiceListParams = {
  page?: number;
  pageSize?: number;
}

export const getServiceList = ({ page = 1, pageSize = 30 }: GetServiceListParams) => request({
  url: '/api/v1/service/',
  urlParams: {
    page,
    page_size: pageSize,
  },
}).then((result) => result.data);
