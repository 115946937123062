import { useTranslation } from 'react-i18next';

import { useProcessesContext } from '../../../../providers/ProcessesProvider';
import TableContainer from '../../../../../../components/ui/TableContainer';
import TableHead from '../../../../../../components/ui/TableHead';
import TableRow from '../../../../../../components/ui/TableRow';
import TableBody from '../../../../../../components/ui/TableBody';
import Pagination from '../../../../../../components/ui/Pagination';

import { ProcessesListProps } from '../ProcessesList';
import ProcessesListItemDesktop from '../ProcessesListItemDesktop';

import { ColumnTitle, StyledPagination, StyledTable } from './styled';

const ProcessesDesktopView = (props: ProcessesListProps) => {
  const { processes } = props;

  const { page, pageSize, setPage, setPageSize } = useProcessesContext();

  const { t } = useTranslation();

  return (
    <TableContainer component="div">
      <StyledPagination>
        <Pagination
          page={page}
          pageSize={pageSize}
          showRowsPerPage={false}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          count={processes && processes.length}
        />
      </StyledPagination>

      <StyledTable size="medium" aria-label="processes table">
        <TableHead>
          <TableRow>
            <ColumnTitle>{t('account.requests.list.requests')}</ColumnTitle>
            <ColumnTitle>{t('account.requests.list.status')}</ColumnTitle>
            <ColumnTitle>{t('account.requests.list.date')}</ColumnTitle>
          </TableRow>
        </TableHead>
        <TableBody>
          {processes.map((process) => (
            <ProcessesListItemDesktop
              key={process.id}
              process={process}
            />
          ))}
        </TableBody>
      </StyledTable>
    </TableContainer>
  );
};

export default ProcessesDesktopView;
