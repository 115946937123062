import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/img/services/close-icon.svg';
import { NUMBERING } from '../../../../../../utils/constants';
import {
  composeValidators,
  useValidators,
  UseValidatorsReturnProps,
} from '../../../../../../../service/utils/validation';
import useDateLib from '../../../../../../../../hooks/useDateLib';
import { formatToDateInput } from '../../utils/format';

import Box from '../../../../../../../../components/ui/Box';
import Grid from '../../../../../../../../components/ui/Grid';
import FormTextInput from '../../../../../../../../components/ui/FormTextInput';

import { Country } from '../fields';

import {
  ChipWrapper,
  StyledButton,
  StyledChip,
  StyledTabs,
  TabPanels,
  TabsWrapper,
  FormSubheader,
} from './styled';

type FormTabState = {
  id: number;
  name: string;
};

type GenerateTabProps = {
  tabName: string;
  index: number;
  isDefault?: boolean;
};

function generateTab({ tabName, index, isDefault }: GenerateTabProps): FormTabState {
  const numberingString = NUMBERING.digits[index];
  const tabNameFormatted = `${tabName}${isDefault ? ` ${numberingString}` : ''}`;

  return { id: index, name: tabNameFormatted };
}

type ShareholdersProps = {
  idSeparatorNumber: number;
  maxTabs: number;
  parentId: string;
  tPrefix: string;
  showTabs?: boolean;
};

function Citizenship(props: ShareholdersProps) {
  const { idSeparatorNumber, maxTabs, parentId, showTabs = true, tPrefix } = props;
  const { t } = useTranslation();
  const validators: UseValidatorsReturnProps = useValidators();
  const formState = useFormState();
  const { dateLib } = useDateLib();


  const parentTabIdArray = parentId.split('_');
  const parentTabId = parentTabIdArray[parentTabIdArray.length - 1];

  const dateValidators = composeValidators(validators.date, validators.is_required);
  const tabName = t(`${tPrefix}.peopleForm.individual.citizenship.label`);

  const getPassportValidUntilMin = useCallback((tabId: number) => {
    const passportIssued = formState.values[`${parentId}_citizenship_${tabId}_passport_issued`];
    const passportIssuedDate = passportIssued ? new Date(passportIssued) : new Date();
    const oneDayMore = dateLib(passportIssuedDate).add(1, 'day').toDate();

    return formatToDateInput(oneDayMore);
  }, [dateLib, formState.values, parentId]);

  const existentTabsState = Object.keys(formState.values)
    .filter((key: string) => key.includes(`${parentId}_citizenship_`) && key.includes('_country'))
    .map((key: string) => {
      return generateTab({
        tabName: `${formState.values[key]}`,
        index: Number(key.split('_')[idSeparatorNumber]),
      })
    });

  const defaultTabs = existentTabsState.length
    ? existentTabsState
    : [generateTab({ tabName, index: 0, isDefault: true })];

  const [tabs, setTabs] = useState<FormTabState[]>(defaultTabs);
  const [activeTab, setActiveTab] = useState(defaultTabs[0]);

  const showAddNewButton = tabs.length < maxTabs;

  const addNewTab = useCallback(() => {
    let tabIndex = tabs.length;

    if (tabIndex >= maxTabs) {
      return;
    }

    const tabsIds = tabs.map(tab => tab.id);

    while (tabsIds.includes(tabIndex)) {
      tabIndex++;
    }

    const newTab = generateTab({ tabName, index: tabIndex, isDefault: false });

    setTabs([...tabs, newTab]);
    setActiveTab(newTab);
  }, [maxTabs, tabName, tabs]);

  const removeTab = useCallback((tabId: number) => () => {
    const removingTabIndex = tabs.findIndex(tab => tab.id === tabId);
    const nextTabIndex = removingTabIndex !== tabs.length - 1 ? removingTabIndex : removingTabIndex - 1;

    const newTabs = tabs
      .filter(({ id }) => id !== tabId)
      .map((tab, index) => {
        const newTab = { ...tab };

        if (tab.name.includes(t(`${tPrefix}.peopleForm.individual.citizenship.label`))) {
          newTab.name = `${t(`${tPrefix}.peopleForm.individual.citizenship.label`)} ${index + 1}`;
        }

        return newTab;
      });
    const newActiveTab = nextTabIndex !== -1  ? newTabs[nextTabIndex] : newTabs[0];

    delete formState.values[`${parentId}_citizenship_${tabId}_country`];
    delete formState.values[`${parentId}_citizenship_${tabId}_passport`];
    delete formState.values[`${parentId}_citizenship_${tabId}_passport_issued`];
    delete formState.values[`${parentId}_citizenship_${tabId}_passport_valid_until`];

    setTabs(newTabs);
    setActiveTab(newActiveTab);
  }, [formState.values, parentId, t, tPrefix, tabs]);

  const handleSetActiveTab = useCallback((tab: FormTabState) => () => {
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    tabs.forEach((tab) => {
      const tabNameInputId = `${parentId}_citizenship_${tab.id}_country`;

      const newTabName = `${formState.values[tabNameInputId] || ''}`;

      tab.name = newTabName || `${tabName} ${NUMBERING.digits[tabs.findIndex(t => t.id === tab.id)]}`;
    });
  },[activeTab, formState, parentId, t, tPrefix, tabName, tabs]);

  return (
    <TabsWrapper>
      <Box mt={4} mb={3}>
        <FormSubheader>{t(`${tPrefix}.peopleForm.individual.citizenship.label`)}</FormSubheader>
      </Box>

      {showTabs ? (
        <StyledTabs aria-label={`tabs of ${tabName}s`}>
          {tabs.map((tab) => {
            const tabId = tab.id;
            const isActive = tabId === activeTab.id;

            const removeProps = tabs.length !== 1 ? { onDelete: removeTab(tabId) } : {};

            return (
              <ChipWrapper isActive={isActive} key={tabId}>
                <StyledChip
                  id={`citizenship-${parentTabId}-${tabId}`}
                  onClick={handleSetActiveTab(tab)}
                  label={tab.name}
                  deleteIcon={<DeleteIcon />}
                  {...removeProps}
                />
              </ChipWrapper>
            );
          })}

          {showAddNewButton ? (
            <StyledButton
              onClick={addNewTab}
              startIcon={<AddIcon />}
              size="small"
            >{t(`${tPrefix}.peopleForm.addButton`)}</StyledButton>
          ) : null}
        </StyledTabs>
      ) : null}

      <TabPanels>
        {tabs.map((tab) => {
          const tabId = tab.id;
          const isActive = tabId === activeTab.id;

          if (!isActive) {
            return null;
          }

          return (
            <Box key={tabId} display="flex" flexDirection="column">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <Country
                    name={`${parentId}_citizenship_${tabId}_country`}
                    tPrefix={tPrefix}
                    validate={validators.is_required}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                  <FormTextInput
                    name={`${parentId}_citizenship_${tabId}_passport`}
                    label={`${t(`${tPrefix}.peopleForm.individual.citizenship.passportNumber`)} *`}
                    validate={validators.is_required}
                    initialValue=""
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <FormTextInput
                    name={`${parentId}_citizenship_${tabId}_passport_issued`}
                    label={`${t(`${tPrefix}.peopleForm.individual.citizenship.issuedOn`)} *`}
                    validate={dateValidators}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        max: formatToDateInput(new Date()),
                      },
                    }}
                    initialValue=""
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                  <FormTextInput
                    name={`${parentId}_citizenship_${tabId}_passport_valid_until`}
                    label={`${t(`${tPrefix}.peopleForm.individual.citizenship.validUntil`)} *`}
                    validate={dateValidators}
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      inputProps: {
                        min: getPassportValidUntilMin(tabId),
                      },
                    }}
                    initialValue=""
                  />
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </TabPanels>
    </TabsWrapper>
  );
}

export default Citizenship;
