export interface IShareholders {
  id: string;
  name: string;
  account_type: string;
  email: string;
}

export interface IResults {
  shareholders: IShareholders[];
}

export const formatValues = (values: any) => {
  const results: IResults = {
    shareholders: [],
  };

  Object.keys(values).forEach((key) => {
    const shareholderId = key.split('_')[1];
    const isShareholderExist = results.shareholders.find(({ id }) => id === shareholderId)

    if (isShareholderExist) {
      return;
    }

    const shareholder = {
      id: shareholderId,
      name: values[`shareholders_${shareholderId}_name`],
      account_type: values[`shareholders_${shareholderId}_account_type`].toLowerCase(),
      email: values[`shareholders_${shareholderId}_email`],
    };

    results.shareholders.push(shareholder);
  });

  return {
    shareholders: results.shareholders.map(({ id, ...rest }) => rest),
  };
};
