import styled, { keyframes } from 'styled-components';
import ButtonBase from '@material-ui/core/ButtonBase';

const showPopover = keyframes`
  from {
    background: transparent;
  }

  to {
    background: rgba(35, 33, 33, 0.12);
  }
`;

const showPaperRoot = keyframes`
  from {
    transform: translateX(-20%);
    opacity: 0;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const StyledPopover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 5000;

  animation: ${showPopover} .3s cubic-bezier(0.42, 0, 0.26, 1);
  background: rgba(35, 33, 33, 0.12);
`;

export const PaperRoot = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;
  top: 0;
  left: 0;
  right: 39%;
  bottom: 0;

  animation: ${showPaperRoot} .3s cubic-bezier(0.42, 0, 0.26, 1);
  background-color: #fff;
  box-shadow: 0 0.5px 5px rgba(35, 33, 33, 0.039), 0 3.75px 11px rgba(35, 33, 33, 0.19);
  border-radius: 0 24px 24px 0;

  overflow: auto;

  ${(p) => p.theme.breakpoints.down('xs')} {
    right: 0;
  }
`;

export const MenuItem = styled(ButtonBase)`
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 56px;
  padding: 16px 24px;

  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  transition: background-color .2s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

export const MenuItemIcon = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;


