import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import mainClient from '../../../../network/mainClient';
import RequestError from '../../../../network/RequestError';
import ObjectType from '../../../../utils/ObjectType';
import UrlParamsType from '../../../../utils/UrlParamsType';

type ResultData = ObjectType | null;

const useAccountItem = (): [ResultData, boolean, Error | null, (accountId: string) => Promise<void>] => {
  const { accountId } = useParams<UrlParamsType>();

  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await mainClient.account.getCurrentAccount({
        id: accountId!,
      });
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, [accountId]);

  useEffect(() => {
    if (accountId) {
      loadData();
    }
  }, [accountId, loadData]);

  return [data, isLoading, error, loadData];
};

export default useAccountItem;
