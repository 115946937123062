import { FormApi } from 'final-form';
import { getAllNonFilledFields } from './format';
import ObjectType from '../../../../../../../utils/ObjectType';

type THandleOnSubmitProps = {
  values: ObjectType;
  t: any;
  tPrefix: string;
  errors?: ObjectType;
  handleSubmit: (event: any) => void;
  form: FormApi;
};

const peopleIdentities = ['shareholders', 'directorship', 'management', 'client_authorized_person'];

export const handleOnSubmit = ({ values, t, tPrefix, errors, handleSubmit, form }: THandleOnSubmitProps) => (event: any) => {
  const nonFilledFields = getAllNonFilledFields({ values, t, tPrefix });
  const fieldsWithErrors = [...nonFilledFields, ...(errors ? Object.keys(errors) : [])];

  if (fieldsWithErrors.length === 0) {
    handleSubmit(event);
  } else {
    const firstFieldName = fieldsWithErrors[0];

    if (peopleIdentities.some(identity => peopleIdentities.includes(identity))) {
      let tabId = '';

      if (firstFieldName.includes('client_authorized_person')) {
        const authPersonId = firstFieldName.split('_')[3];
        const authPersonTab = document.getElementById(`person-${authPersonId}`);
        authPersonTab?.click();
      } else {
        const isShareholdersType = firstFieldName.includes('shareholders');

        if (isShareholdersType) {
          tabId = firstFieldName.split('_')[1];
        } else if (firstFieldName.includes('directorship') || firstFieldName.includes('management')) {
          tabId = firstFieldName.split('_')[2];
        }

        const tab = document.getElementById(tabId);
        tab?.click();

        if (firstFieldName.includes('citizenship')) {
          const citizenshipId = firstFieldName.split('_')[3];
          const citizenshipTab = document.getElementById(`citizenship-${tabId}-${citizenshipId}`);
          citizenshipTab?.click();
        }

        if (firstFieldName.includes('legal_representatives')) {
          const representativeId = firstFieldName.split('_')[isShareholdersType ? 4 : 5];
          const representativeIdTab = document.getElementById(`representative-${tabId}-${representativeId}`);
          representativeIdTab?.click();
        }
      }
    }

    setTimeout(() => {
      const firstField = document.getElementsByName(firstFieldName)[0];
      form.focus(firstFieldName);
      firstField?.focus();
    }, 100);
  }
};
