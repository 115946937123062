import styled from 'styled-components';

export const EmptyText = styled.div`
  opacity: 0.6;
`;

export const DateTimeWrapper = styled.div`
  position: absolute;
  right: 0;
  top: 0;

  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: right;
  letter-spacing: 0.25px;
  color: rgba(35, 33, 33, 0.6);
`;

export const Time = styled.div`

`;

export const Date = styled.div`

`;
