import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { getUrlWithoutSlash } from '../../../../utils/helpers';
import ServicesLayout from '../../components/ServicesLayout';
import Box from '../../../../components/ui/Box';
import ActiveServicesScreen from '../ActiveServicesScreen';
import { ServiceResultModel } from '../../network/models';
import type { MenuItemProps } from '../../components/AsideServicesMenu';
import { useServicesContext } from '../../providers/ServicesProvider';
import { useProcessesContext } from '../../../process/providers/ProcessesProvider';

const ServicesCatalogScreen = () => {
  const { url, path } = useRouteMatch();
  const { t } = useTranslation();
  const { data, loading } = useServicesContext();
  const { loading: processesLoading } = useProcessesContext();

  const [servicesList, setServicesList] = useState<ServiceResultModel[]>([]);
  const [menuItems, setMenuItems] = useState<MenuItemProps[]>([]);

  const rightUrl = getUrlWithoutSlash(url);

  useEffect(() => {
      if (data) {
        const { results } = data;

        setServicesList(results);

        const items = results
          .map((service: ServiceResultModel): MenuItemProps => {
            const { slug } = service;

            return {
              to: `${rightUrl}/${slug}`,
              ...service,
            };
          });

        setMenuItems(items);
      }
    },
    [data, rightUrl, setServicesList, setMenuItems]
  );

  const firstServiceSlug = (servicesList[0]?.slug) || null;

  const redirectUrl = firstServiceSlug
    ? `${rightUrl}/${firstServiceSlug}`
    : '/404';

  return (
    <ServicesLayout
      title={t('account.services.title')}
      loading={loading && processesLoading}
      menuItems={menuItems}
    >
      <>
        {servicesList.length ? (
          <Box display="flex" flex={1} flexDirection={{ xs: 'column', md: 'row' }} height="100%">
            <Switch>
              <Redirect from={path} exact to={redirectUrl} />

              {servicesList.map((service) => (
                <Route path={`${path}/${service.slug}`} key={service.id}>
                  <ActiveServicesScreen service={service} />
                </Route>
              ))}
            </Switch>
          </Box>
        ) : null}
      </>
    </ServicesLayout>
  )
};

export default ServicesCatalogScreen;
