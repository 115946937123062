import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form } from 'react-final-form';

import { composeValidators, useValidators, UseValidatorsReturnProps } from '../../../../../service/utils/validation';

import { OutlinedButton, PrimaryButton } from '../../../../../../components/ui/Button';
import CircularProgress from '../../../../../../components/ui/CircularProgress';
import FormControlLabel from '../../../../../../components/ui/FormControlLabel';
import FormHelperText from '../../../../../../components/ui/FormHelperText';
import StyledAccordion from '../../../../../../components/StyledAccordion';
import FormTextInput from '../../../../../../components/ui/FormTextInput';
import ErrorMessage from '../../../../../../components/ui/ErrorMessage';
import FormCheckbox from '../../../../../../components/ui/FormCheckbox';
import FormSelect from '../../../../../../components/ui/FormSelect';
import RadioGroup from '../../../../../../components/ui/RadioGroup';
import MenuItem from '../../../../../../components/ui/MenuItem';
import Radio from '../../../../../../components/ui/Radio';
import Grid from '../../../../../../components/ui/Grid';
import Box from '../../../../../../components/ui/Box';

import { useProcessesContext } from '../../../../providers/ProcessesProvider';
import { SubmitProcessParams } from '../../../../network';
import { EU_LUXEMBOURG } from '../../../../utils/constants';
import { ProcessFormProps } from '../../index';
import {
  formatValues,
  getFlattenedValues,
  getShareholdersIds,
} from './utils/format';

import ProcessKYCVerificationPanel from '../../../ProcessKYCVerificationPanel';
import PeopleForm from './components/PeopleForm';
import ClientForm from './components/ClientForm';
import StatutoryAuditorForm from './components/StatutoryAuditorForm';

import {
  FormWrapper,
  FormInputLabel,
  FormSubheader,
  StyledFormControlLabel,
} from './styled';
import { handleOnSubmit } from './utils/handlers';

type CompanyIncorporationProps = ProcessFormProps;

const CompanyIncorporation = (props: CompanyIncorporationProps) => {
  const {
    closeModal,
    loadProcess,
    task,
    process,
  } = props;

  const { t } = useTranslation();
  const validators: UseValidatorsReturnProps = useValidators();

  const { id: taskId } = task;
  const { id: processId, service: { process_name: processName }, value } = process;

  const {
    isSaveProcessLoading,
    saveProcessError,
    saveProcess,
    isSubmitProcessLoading,
    submitProcessError,
    submitProcess,
  } = useProcessesContext();

  const tPrefix = useMemo(() => 'account.processes.forms.companyIncorporation', []) as string;

  const getDataToSubmit = useCallback((values: any, isSaveRequest: boolean): SubmitProcessParams => {
    const formattedValues = formatValues({ t, tPrefix, values });

    return {
      value: formattedValues,
      form_submitted: !isSaveRequest,
      processName,
      taskName: task.flow_task,
      processId,
      taskId,
    };
  }, [t, tPrefix, processName, task.flow_task, processId, taskId]);

  const handleSave = useCallback((values) => () => {
    try {
      const data = getDataToSubmit(values, true);
      saveProcess(data);
    } catch (e) {
      console.error(e)
    }
  }, [getDataToSubmit, saveProcess]);

  const handleSubmitProcess = useCallback(
    async (values) => {
      try {
        const data = getDataToSubmit(values, false);

        await submitProcess(data);

        if (!submitProcessError && !isSubmitProcessLoading) {
          await loadProcess();
        }
      } catch (e) {
        console.error('e', e);
      }
    },
    [getDataToSubmit, submitProcess, submitProcessError, isSubmitProcessLoading, loadProcess],
  );

  const initialValues = useMemo(() =>
      getFlattenedValues({ t, tPrefix, values: value }),
    [t, tPrefix, value],
  );

  return (
    <Form
      onSubmit={handleSubmitProcess}
      initialValues={initialValues}
      render={(props) => {
        const { handleSubmit, form, errors, values } = props;

        const submit = handleOnSubmit({ values, t, tPrefix, form, errors, handleSubmit });

        const legalForm = values.legal_form;
        const isSAChecked = legalForm === t(`${tPrefix}.legalForm.radio1`);

        const managementOrDirectorship = isSAChecked
          ? t(`${tPrefix}.directorship.label`).toLowerCase()
          : t(`${tPrefix}.management.label`).toLowerCase();

        const managementOrDirectorshipSelected = values[`${managementOrDirectorship}_type`];

        const shareholdersIds: string[] = getShareholdersIds(values);
        const isOnlyOneShareholder = shareholdersIds.length === 1;

        const isSubmitDisabled = isSubmitProcessLoading || !managementOrDirectorshipSelected;

        return (
          <>
            {(submitProcessError || saveProcessError) && (
              <Box mb={2} mt={4}>
                <ErrorMessage error={submitProcessError || saveProcessError} />
              </Box>
            )}

            <ProcessKYCVerificationPanel processId={processId} processName={processName} />

            <FormWrapper>
              <form onSubmit={submit}>
                <Box mb={5}>
                  <Box mb={3} display="flex" flexDirection="column">
                    <Box>
                      <StyledAccordion
                        title={`1. ${t(`${tPrefix}.companyName.label`)}`}
                        description={t(`${tPrefix}.companyName.description`)}
                      >
                        <FormTextInput
                          name="company_name"
                          label={`${t(`${tPrefix}.companyName.label`)} *`}
                          validate={validators.is_required}
                        />
                      </StyledAccordion>
                    </Box>

                    <Box mt={3}>
                      <StyledAccordion
                        title={`2. ${t(`${tPrefix}.legalForm.label`)}`}
                        hint={t(`${tPrefix}.legalForm.hint`)}
                      >
                        <RadioGroup row>
                          <Field
                            name="legal_form"
                            value={t(`${tPrefix}.legalForm.radio1`)}
                            type="radio"
                            initialValue={t(`${tPrefix}.legalForm.radio1`)}
                          >
                            {({ input }) => (
                              <StyledFormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={<FormInputLabel>{t(`${tPrefix}.legalForm.radio1`)}</FormInputLabel>}
                              />
                            )}
                          </Field>
                          <Field
                            name="legal_form"
                            value={t(`${tPrefix}.legalForm.radio2`)}
                            type="radio"
                          >
                            {({ input }) => (
                              <StyledFormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={<FormInputLabel>{t(`${tPrefix}.legalForm.radio2`)}</FormInputLabel>}
                              />
                            )}
                          </Field>
                        </RadioGroup>

                        <Box mt={2}>
                          <FormCheckbox
                            name="spf"
                            label={t(`${tPrefix}.legalForm.spf`)}
                            hint={t(`${tPrefix}.legalForm.spfHint`)}
                            disabled={Boolean(values['company_purpose'] && values['has_purpose'] === t(`${tPrefix}.purpose.radio1`))}
                            afterChangeCallback={(value: boolean) => {
                              if (value) {
                                form.change('has_purpose', t(`${tPrefix}.purpose.radio2`));
                                form.change('company_purpose', null);
                              }
                            }}
                          />
                        </Box>
                      </StyledAccordion>
                    </Box>

                    <Box mt={3}>
                      <StyledAccordion
                        title={`3. ${t(`${tPrefix}.registeredOffice.label`)}`}
                      >
                        <RadioGroup>
                          <Field
                            name="registered_office"
                            value={t(`${tPrefix}.registeredOffice.radio1`)}
                            type="radio"
                            initialValue={t(`${tPrefix}.registeredOffice.radio1`)}
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={<FormInputLabel>{t(`${tPrefix}.registeredOffice.radio1`)}</FormInputLabel>}
                              />
                            )}
                          </Field>
                          {values['registered_office'] === t(`${tPrefix}.registeredOffice.radio1`)
                            ? (
                              <Box ml={4} mb={1}>
                                <FormHelperText>
                                  {t(`${tPrefix}.registeredOffice.radio1HelperText`)}
                                </FormHelperText>
                              </Box>
                            ) : null}
                          <Field
                            name="registered_office"
                            value={t(`${tPrefix}.registeredOffice.radio2`)}
                            type="radio"
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={<FormInputLabel>{t(`${tPrefix}.registeredOffice.radio2`)}</FormInputLabel>}
                              />
                            )}
                          </Field>
                          {values['registered_office'] === t(`${tPrefix}.registeredOffice.radio2`)
                            ? (
                              <Box mt={2}>
                                <Grid container spacing={3}>
                                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <FormTextInput
                                      name={'registered_office_city'}
                                      label={`${t(`${tPrefix}.peopleForm.registeredAddress.city`)} *`}
                                      validate={validators.is_required}
                                      initialValue=""
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                                    <FormTextInput
                                      name={'registered_office_postal_code'}
                                      label={`${t(`${tPrefix}.peopleForm.registeredAddress.postalCode`)} *`}
                                      validate={validators.is_required}
                                      initialValue=""
                                    />
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                                    <FormSelect
                                      name={'registered_office_country'}
                                      label={`${t(`${tPrefix}.peopleForm.registeredAddress.country`)} *`}
                                      variant="outlined"
                                      initialValue={EU_LUXEMBOURG[0].name}
                                      disabled
                                    >
                                      {EU_LUXEMBOURG.map((option) => {
                                        return (
                                          <MenuItem key={option.code} value={option.name}>
                                            {option.name}
                                          </MenuItem>
                                        );
                                      })}
                                    </FormSelect>
                                  </Grid>

                                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <FormTextInput
                                      name={'registered_office_address'}
                                      label={`${t(`${tPrefix}.peopleForm.registeredAddress.address`)} *`}
                                      validate={validators.is_required}
                                      initialValue=""
                                    />
                                  </Grid>
                                </Grid>
                              </Box>
                            ) : null}
                        </RadioGroup>
                      </StyledAccordion>
                    </Box>

                    <Box mt={3}>
                      <StyledAccordion
                        title={`4. ${t(`${tPrefix}.shareCapital.label`)}`}
                        hint={t(`${tPrefix}.shareCapital.hint`)}
                      >
                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <FormTextInput
                              name="share_capital_amount"
                              label={`${t(`${tPrefix}.shareCapital.text1`)} *`}
                              validate={composeValidators(
                                validators.is_required,
                                validators.numbers_only,
                                validators.minimumNumber(isSAChecked ? 31000 : 12000, t(`${tPrefix}.shareCapital.text1`)),
                              )}
                              initialValue=""
                              onlyNumbers
                            />
                          </Grid>
                          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                            <FormSelect
                              name="share_capital_currency"
                              label={`${t(`${tPrefix}.shareCapital.text2`)} *`}
                              variant="outlined"
                              validate={validators.is_required}
                            >
                              {['$', '€'].map((option, index) => {
                                return (
                                  <MenuItem key={index} value={option}>
                                    {option}
                                  </MenuItem>
                                );
                              })}
                            </FormSelect>
                          </Grid>
                          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                            <FormTextInput
                              name="share_capital_value_per_share"
                              label={`${t(`${tPrefix}.shareCapital.text3`)} *`}
                              validate={composeValidators(validators.is_required, validators.numbers_only)}
                              initialValue=""
                              onlyNumbers
                            />
                          </Grid>
                        </Grid>
                      </StyledAccordion>
                    </Box>

                    <Box mt={3}>
                      <StyledAccordion
                        title={`5. ${t(`${tPrefix}.shareholders.label`)}`}
                        description={t(`${tPrefix}.shareholders.description`)}
                      >
                        <PeopleForm
                          emailRequired
                          idSeparatorNumber={1}
                          formPrefix="shareholders"
                          numbering="digits"
                          tPrefix={tPrefix}
                        />
                      </StyledAccordion>
                    </Box>

                    <Box mt={3}>
                      <StyledAccordion
                        title={`6. ${t(`${tPrefix}.closureFinancialYear.label`)}`}
                        hint={t(`${tPrefix}.closureFinancialYear.hint`)}
                      >
                        <RadioGroup>
                          <Field
                            name="end_of_financial_year"
                            value={t(`${tPrefix}.closureFinancialYear.radio1`)}
                            type="radio"
                            initialValue={t(`${tPrefix}.closureFinancialYear.radio1`)}
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={<FormInputLabel>{t(`${tPrefix}.closureFinancialYear.radio1`)}</FormInputLabel>}
                              />
                            )}
                          </Field>

                          <Field
                            name="end_of_financial_year"
                            value={t(`${tPrefix}.closureFinancialYear.radio2`)}
                            type="radio"
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={<FormInputLabel>{t(`${tPrefix}.closureFinancialYear.radio2`)}</FormInputLabel>}
                              />
                            )}
                          </Field>
                          {values['end_of_financial_year'] === t(`${tPrefix}.closureFinancialYear.radio2`)
                            ? (
                              <Box ml={2}>
                                <FormHelperText>
                                  {t(`${tPrefix}.closureFinancialYear.helperText`)}
                                </FormHelperText>
                              </Box>
                            ) : null}

                          <Field
                            name="end_of_financial_year"
                            value={t(`${tPrefix}.closureFinancialYear.radio3`)}
                            type="radio"
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={<FormInputLabel>{t(`${tPrefix}.closureFinancialYear.radio3`)}</FormInputLabel>}
                              />
                            )}
                          </Field>
                          {values['end_of_financial_year'] === t(`${tPrefix}.closureFinancialYear.radio3`)
                            ? (
                              <Box ml={2}>
                                <FormHelperText>
                                  {t(`${tPrefix}.closureFinancialYear.helperText`)}
                                </FormHelperText>
                              </Box>
                            ) : null}
                        </RadioGroup>

                        <Grid container spacing={3}>
                          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <FormSubheader>{t(`${tPrefix}.closureFinancialYear.annualTitle`)}</FormSubheader>
                          </Grid>

                          <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <Box mt={2}>
                              <FormTextInput
                                name="annual_general_meeting_date"
                                label={`${t(`${tPrefix}.closureFinancialYear.annualField`)} *`}
                                validate={validators.is_required}
                                type="date"
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                initialValue=""
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </StyledAccordion>
                    </Box>

                    <Box mt={3}>
                      <StyledAccordion
                        title={`7. ${t(`${tPrefix}.${managementOrDirectorship}.label`)}`}
                        hint={t(`${tPrefix}.${managementOrDirectorship}.hint`)}
                      >
                        <RadioGroup>
                          {isSAChecked && !isOnlyOneShareholder ? null : (
                            <>
                              <Field
                                name={`${managementOrDirectorship}_type`}
                                value={t(`${tPrefix}.${managementOrDirectorship}.radio1`)}
                                type="radio"
                              >
                                {({ input }) => (
                                  <FormControlLabel
                                    control={<Radio color="default" {...input}  />}
                                    label={
                                      <FormInputLabel>{t(`${tPrefix}.${managementOrDirectorship}.radio1`)}</FormInputLabel>}
                                  />
                                )}
                              </Field>
                              {managementOrDirectorshipSelected === t(`${tPrefix}.${managementOrDirectorship}.radio1`)
                                ? (
                                  <Box mt={3} mb={3}>
                                    <PeopleForm
                                      idSeparatorNumber={2}
                                      formPrefix={`${managementOrDirectorship}_sole`}
                                      maxTabs={1}
                                      numbering="digits"
                                      tFormPrefix={managementOrDirectorship}
                                      tPrefix={tPrefix}
                                    />
                                  </Box>
                                ) : null}
                            </>
                          )}

                          {!isSAChecked ? (
                            <>
                              <Field
                                name={`${managementOrDirectorship}_type`}
                                value={t(`${tPrefix}.${managementOrDirectorship}.radio2`)}
                                type="radio"
                              >
                                {({ input }) => (
                                  <FormControlLabel
                                    control={<Radio color="default" {...input}  />}
                                    label={
                                      <FormInputLabel>{t(`${tPrefix}.${managementOrDirectorship}.radio2`)}</FormInputLabel>}
                                  />
                                )}
                              </Field>
                              {managementOrDirectorshipSelected === t(`${tPrefix}.${managementOrDirectorship}.radio2`)
                                ? (
                                  <Box mt={3} mb={3}>
                                    <PeopleForm
                                      idSeparatorNumber={2}
                                      formPrefix={`${managementOrDirectorship}_class`}
                                      numbering="alphabet"
                                      tFormPrefix={managementOrDirectorship}
                                      tPrefix={tPrefix}
                                    />
                                  </Box>
                                ) : null}
                            </>
                            ) : null}
                          <Field
                            name={`${managementOrDirectorship}_type`}
                            value={
                              isSAChecked
                                ? t(`${tPrefix}.${managementOrDirectorship}.radio3MinimumThreeDirectors`)
                                : t(`${tPrefix}.${managementOrDirectorship}.radio3`)
                            }
                            type="radio"
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={
                                  <FormInputLabel>{isSAChecked
                                    ? t(`${tPrefix}.${managementOrDirectorship}.radio3MinimumThreeDirectors`)
                                    : t(`${tPrefix}.${managementOrDirectorship}.radio3`)}</FormInputLabel>}
                              />
                            )}
                          </Field>
                          {managementOrDirectorshipSelected === t(`${tPrefix}.${managementOrDirectorship}.radio3`)
                          || managementOrDirectorshipSelected === t(`${tPrefix}.${managementOrDirectorship}.radio3MinimumThreeDirectors`)
                            ? (
                              <Box mt={3}>
                                <PeopleForm
                                  idSeparatorNumber={2}
                                  formPrefix={`${managementOrDirectorship}_noclass`}
                                  numbering={isSAChecked ? 'alphabet' : 'digits'}
                                  minTabs={isSAChecked ? 3 : 1}
                                  tFormPrefix={managementOrDirectorship}
                                  tPrefix={tPrefix}
                                />
                              </Box>
                            ) : null}
                        </RadioGroup>
                      </StyledAccordion>
                    </Box>

                    <Box mt={3}>
                      <StyledAccordion
                        title={`8. ${t(`${tPrefix}.purpose.label`)}`}
                        hint={t(`${tPrefix}.purpose.hint`)}
                        disabled={Boolean(values.spf)}
                      >
                        <>
                          <RadioGroup row>
                            <Field
                              name="has_purpose"
                              value={t(`${tPrefix}.purpose.radio1`)}
                              type="radio"
                              initialValue={t(`${tPrefix}.purpose.radio1`)}
                            >
                              {({ input }) => (
                                <FormControlLabel
                                  control={<Radio color="default" {...input}  />}
                                  label={<FormInputLabel>{t(`${tPrefix}.purpose.radio1`)}</FormInputLabel>}
                                />
                              )}
                            </Field>
                            <Field
                              name="has_purpose"
                              value={t(`${tPrefix}.purpose.radio2`)}
                              type="radio"
                            >
                              {({ input }) => (
                                <FormControlLabel
                                  control={<Radio color="default" {...input}  />}
                                  label={<FormInputLabel>{t(`${tPrefix}.purpose.radio2`)}</FormInputLabel>}
                                />
                              )}
                            </Field>
                          </RadioGroup>

                          {values.has_purpose === t(`${tPrefix}.purpose.radio1`) ? (
                            <Box mt={2}>
                              <FormTextInput
                                name="company_purpose"
                                label={`${t(`${tPrefix}.purpose.text1`)}`}
                                disabled={Boolean(values.spf)}
                              />
                            </Box>
                          ) : null}
                        </>
                      </StyledAccordion>
                    </Box>

                    <Box mt={3}>
                      <StyledAccordion
                        title={`9. ${t(`${tPrefix}.methodOfIncorporation.label`)}`}
                        hint={t(`${tPrefix}.methodOfIncorporation.hint`)}
                      >
                        <RadioGroup>
                          <Field
                            name="incorporation_method"
                            value={t(`${tPrefix}.methodOfIncorporation.radio1`)}
                            type="radio"
                            initialValue={t(`${tPrefix}.methodOfIncorporation.radio1`)}
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={<FormInputLabel>{t(`${tPrefix}.methodOfIncorporation.radio1`)}</FormInputLabel>}
                              />
                            )}
                          </Field>
                          <Field
                            name="incorporation_method"
                            value={t(`${tPrefix}.methodOfIncorporation.radio2`)}
                            type="radio"
                          >
                            {({ input }) => (
                              <FormControlLabel
                                control={<Radio color="default" {...input}  />}
                                label={<FormInputLabel>{t(`${tPrefix}.methodOfIncorporation.radio2`)}</FormInputLabel>}
                              />
                            )}
                          </Field>
                        </RadioGroup>
                      </StyledAccordion>
                    </Box>

                    <Box mt={3}>
                      <StyledAccordion
                        title={`10. ${t(`${tPrefix}.clientAndAuthorizedPerson.label`)}`}
                      >
                        <ClientForm tPrefix={tPrefix} />
                      </StyledAccordion>
                    </Box>

                    {isSAChecked ? (
                      <Box mt={3}>
                        <StyledAccordion
                          title={`11. ${t(`${tPrefix}.statutoryAuditor.label`)}`}
                        >
                          <>
                            <RadioGroup row>
                              <Field
                                name="statutory_auditor_type"
                                value={t(`${tPrefix}.statutoryAuditor.radio1`)}
                                type="radio"
                                initialValue={t(`${tPrefix}.statutoryAuditor.radio1`)}
                              >
                                {({ input }) => (
                                  <StyledFormControlLabel
                                    control={<Radio color="default" {...input}  />}
                                    label={<FormInputLabel>{t(`${tPrefix}.statutoryAuditor.radio1`)}</FormInputLabel>}
                                  />
                                )}
                              </Field>
                              <Field
                                name="statutory_auditor_type"
                                value={t(`${tPrefix}.statutoryAuditor.radio2`)}
                                type="radio"
                              >
                                {({ input }) => (
                                  <StyledFormControlLabel
                                    control={<Radio color="default" {...input}  />}
                                    label={<FormInputLabel>{t(`${tPrefix}.statutoryAuditor.radio2`)}</FormInputLabel>}
                                  />
                                )}
                              </Field>
                            </RadioGroup>
                            {values['statutory_auditor_type'] === t(`${tPrefix}.statutoryAuditor.radio1`)
                              ? (
                                <Box mt={2} ml={1} mb={1}>
                                  <FormHelperText>
                                    {t(`${tPrefix}.statutoryAuditor.radio1HelperText`)}
                                  </FormHelperText>
                                </Box>
                              ) : (
                                <Box mt={3} mb={3}>
                                  <StatutoryAuditorForm tPrefix={tPrefix} />
                                </Box>
                              )}
                          </>
                        </StyledAccordion>
                      </Box>
                    ) : null}
                  </Box>

                  <Box display="flex" justifyContent="space-between" pt={5}>
                    <Box display="inline-block" mr={1}>
                      <OutlinedButton onClick={closeModal}>
                        {t('account.services.checkout.form.buttons.cancel')}
                      </OutlinedButton>
                    </Box>

                    <Box display="flex">
                      <Box display="inline-block" mr={1}>
                        <OutlinedButton
                          disabled={isSaveProcessLoading}
                          onClick={handleSave(values)}
                        >
                          {isSaveProcessLoading ? (
                            <Box mr={1} display="flex" alignItems="center">
                              <CircularProgress size={20}/>
                            </Box>
                          ) : null}
                          {t('account.services.checkout.form.buttons.save')}
                        </OutlinedButton>
                      </Box>

                      <PrimaryButton
                        disabled={isSubmitDisabled}
                        onClick={submit}
                      >
                        <Box display="flex" alignItems="center">
                          {isSubmitProcessLoading ? (
                            <Box mr={1} display="flex" alignItems="center">
                              <CircularProgress size={20}/>
                            </Box>
                          ) : null}
                          {t('account.services.checkout.form.buttons.submit')}
                        </Box>
                      </PrimaryButton>
                    </Box>
                  </Box>
                </Box>
              </form>
            </FormWrapper>
          </>
        )
      }}
    />
  );
};

export default CompanyIncorporation;
