import styled from 'styled-components';

import Box from '../../../../../../components/ui/Box';
import Table from '../../../../../../components/ui/Table';
import TableCell from '../../../../../../components/ui/TableCell';

export const ColumnTitle = styled(TableCell)`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: 1.5px;
  text-transform: uppercase;

  padding-left: 0;
`;

export const StyledTable = styled(Table)`
  margin-bottom: 84px;
  z-index: 1;
`;

export const StyledPagination = styled(Box)`
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 5;

  & .MuiTablePagination-root {
    background: #FFFFFF;
    box-shadow: 0 0.25px 3px rgba(35, 33, 33, 0.039), 0 2.75px 9px rgba(35, 33, 33, 0.19);
    border-radius: 12px;
    padding: 2px 8px 2px 20px;
  }

  & .MuiTablePagination-toolbar {
    min-height: 24px;
  }

  & .MuiTablePagination-caption {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.444444px;
    color: #232121;
  }

  & .MuiTablePagination-actions {
    margin-left: 12px;
    button {
      padding: 8px;
    }
  }

  & .MuiToolbar-gutters {
    padding: 0;
  }
`;
