import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import mainClient from '../../../../../network/mainClient';
import { AccountItemModel, CreateAccountModel } from '../../../../../network/mainClient/models';
import RequestError from '../../../../../network/RequestError';

type RequestData = CreateAccountModel;
type ResultData = AccountItemModel | null;

const useAccountCreate = (): [
  ResultData,
  boolean,
  Error | null,
  (params: RequestData) => void,
] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useDebouncedCallback(async (params: RequestData) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await mainClient.account.createAccount(params);
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  return [data, isLoading, error, loadData];
};

export default useAccountCreate;
