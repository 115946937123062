import { createContext, ReactElement, useEffect } from 'react';

import {
  ListContextInitialValues,
  ListContextValues,
  ListProvider,
  useListContext,
} from '../../../providers/ListProvider';
import RequestError from '../../../network/RequestError';
import ObjectType from '../../../utils/ObjectType';
import useListState from '../../../hooks/useListState';
import { NotificationsListModel } from '../network/models';
import useNotificationsList from '../hooks/notifications/useNotificationsList';
import useNotificationRead from '../hooks/notifications/actions/useNotificationRead';
import useNotificationsReadAll from '../hooks/notifications/actions/useNotificationsReadAll';
import { useCabinetContext } from '../../../containers/CabinetContainer/context';

export type NotificationsListProviderProps = {
  children: ReactElement;
};

export interface NotificationsListContextValues extends ListContextValues {
  data: NotificationsListModel | null;

  readData: null | ObjectType;
  readAllData: null | ObjectType;

  isReadDataLoading: boolean;
  isReadAllDataLoading: boolean;

  readDataError: null | RequestError;
  readAllDataError: null | RequestError;

  loadData: (page?: number, pageSize?: number) => void;
  readNotification: (id: number) => void;
  readAllNotifications: () => void;
}

const NotificationsListContextInitialValues = {
  ...ListContextInitialValues,

  readData: null,
  readAllData: null,

  isReadDataLoading: false,
  isReadAllDataLoading: false,

  readDataError: null,
  readAllDataError: null,

  loadData: () => {},
  readNotification: () => {},
  readAllNotifications: () => {},
};

export const NotificationsListContext = createContext<NotificationsListContextValues>(NotificationsListContextInitialValues)

export const DEFAULT_PAGE_SIZE = 20;

export const NotificationsListProvider = (props: NotificationsListProviderProps) => {
  const { currentAccount } = useCabinetContext();
  const { page, pageSize, setPage, setPageSize } = useListState({ defaultPageSize: DEFAULT_PAGE_SIZE });

  const [data, isLoading, error, loadData] = useNotificationsList({ page, pageSize });

  const [readData, isReadDataLoading, readDataError, readNotification] = useNotificationRead();
  const [readAllData, isReadAllDataLoading, readAllDataError, readAllNotifications] = useNotificationsReadAll();

  useEffect(() => {
    if (!isLoading) {
      setPageSize(DEFAULT_PAGE_SIZE);
      loadData(page, pageSize);
    }
  }, [currentAccount, isLoading, loadData, page, pageSize, setPageSize]);

  return (
    <ListProvider
      ListContext={NotificationsListContext}
      data={data}
      loading={isLoading}
      error={error}
      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
      loadData={loadData}

      readData={readData}
      readAllData={readAllData}

      isReadDataLoading={isReadDataLoading}
      isReadAllDataLoading={isReadAllDataLoading}

      readDataError={readDataError}
      readAllDataError={readAllDataError}

      readNotification={readNotification}
      readAllNotifications={readAllNotifications}
    >
      {props.children}
    </ListProvider>
  );
};

export const useNotificationsListContext = () => useListContext(NotificationsListContext) as NotificationsListContextValues;
