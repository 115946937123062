import styled from 'styled-components';

import Button, { Props as ButtonProps } from '../../../../../../components/ui/Button';
import Toolbar from '../../../../../../components/ui/Toolbar';

export const StyledToolbar = styled(Toolbar)`
  min-width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0;

  position: absolute;
  right: 0;
  width: 100%;
`;

export const ButtonsWrapper = styled.div`
  background-color: #FFFFFF;
  display: flex;
  z-index: 99;
`;

export const StyledButton = styled(Button)<ButtonProps>`
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #232121;
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    width: 16px;
    margin-right: 8px;
  }
`;
