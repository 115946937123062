import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import SleepBgImg from '../../../../assets/img/backgrounds/sleep-bg-cut.png';
import Box from '../../../../components/ui/Box';
import Hidden from '../../../../components/ui/Hidden';
import Typography from '../../../../components/ui/Typography';
import LoaderProgress from '../../../../components/LoaderProgress';
import { OutlinedButton } from '../../../../components/ui/Button';
import { useCabinetContext } from '../../../../containers/CabinetContainer/context';

import { EmptyBg, EmptyContent, EmptyScreenText } from '../../screens/ProcessesScreen/styled';

import { ContentWrapper, StyledSubTitle } from './styled';

interface ProcessesLayoutProps {
  children: React.ReactChild;
  title: string | React.ReactNode;
  subTitle?: string | null;
  loading?: boolean;
  footer?: React.ReactNode;
  pagination?: React.ReactNode;
  empty?: boolean;
}

const ProcessesLayout = (props: ProcessesLayoutProps) => {
  const {
    title,
    loading,
    empty = false,
    footer = null,
    pagination = null,
    subTitle = '',
    children,
  } = props;

  const { t } = useTranslation();
  const { currentAccount } = useCabinetContext();

  return (
    <ContentWrapper>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb={{ xs: 3, sm: 4.5 }}
      >
        {title && (
          <Box>
            <Typography variant="h4" component="h1">
              {title}
            </Typography>
            {subTitle && (
              <Box mt={1}>
                <StyledSubTitle>
                  {subTitle}
                </StyledSubTitle>
              </Box>
            )}
          </Box>
        )}
        {!empty && (
          <Hidden smDown>
            {pagination}
          </Hidden>
        )}
      </Box>
      {(empty && !loading) ? (
        <EmptyContent mt={6}>
          <Box mb={4}>
            <EmptyScreenText>{t('account.requests.createNewRequest')}</EmptyScreenText>
          </Box>
          <OutlinedButton
            component={Link}
            to={`/cabinet/account/${currentAccount?.id}/services`}
          >{t('account.services.toServicesList')}</OutlinedButton>
          <EmptyBg src={SleepBgImg}/>
        </EmptyContent>
      ) : children}
      {loading ? <LoaderProgress /> : null}
      <Hidden mdUp>
        <Box display="flex" justifyContent="center">
          {pagination}
        </Box>
      </Hidden>
      {footer}
    </ContentWrapper>
  );
};

export default ProcessesLayout;
