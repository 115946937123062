import styled from 'styled-components';

import TextField from '../TextField';

export const StyledTextField = styled(TextField)`
  width: 100%;
  & .MuiInputBase-root {
    border-radius: 10px;
    background: #FFFFFF;
  }
  & .MuiFormLabel-root {
    z-index: 1;
  }
`;
