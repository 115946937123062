import { makeStyles } from '@material-ui/core/styles';
import TextField, { TextFieldProps } from '@material-ui/core/TextField';

const useStyles = makeStyles(() => ({
  root: {
    '& .Mui-focused': {
      color: '#232121',
    },
    '& .MuiInputLabel-outlined.Mui-disabled': {
      color: '#4A5351',
      opacity: 0.5,
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(16px, 20px) scale(1)',

      '&.MuiInputLabel-shrink': {
        transform: 'translate(16px, -6px) scale(0.75)',
      }
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: 10,

      '&.Mui-disabled': {
        opacity: 0.7,

        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#4A5351',
        },
      },
      '&.Mui-focused': {
        borderColor: '#232121',
      },
      '& fieldset': {
        borderRadius: 10,
      },
      '& input': {
        padding: '18.5px 16px',
      },
    },
  },
}));

export default (props: TextFieldProps) => {
  const classes = useStyles();

  return (
    <TextField
      className={classes.root}
      {...props}
    />
  );
}

export type { TextFieldProps as Props } from '@material-ui/core';
