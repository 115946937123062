import { useCallback, useState } from 'react';

type ListStateProps = {
  defaultPageSize?: number;
};

export default (props: ListStateProps = {}) => {
  const { defaultPageSize = 10 } = props;
  const [filters, setFilters] = useState({});
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [sort, setSort] = useState('ASC');

  const changeFilters = useCallback((filters) => {
    setFilters(filters);
    setPage(0);
  }, [setFilters, setPage]);

  const changeSort = useCallback((sort) => {
    setSort(sort);
    setPage(0);
  }, [setSort, setPage]);

  return {
    filters,
    page,
    pageSize,
    sort,
    setFilters: changeFilters,
    setSort: changeSort,
    setPage,
    setPageSize,
  };
};
