import { createGlobalStyle } from 'styled-components'

export const GlobalStyle = createGlobalStyle`
  #root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: ${(p) => p.theme.palette.background.default};
  }
  body._no-scroll {
    overflow: hidden;
  },
`;
