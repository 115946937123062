import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MenuIcon from '@material-ui/icons/Menu';

import Toolbar from '../../../ui/Toolbar';
import UserMenu from '../UserMenu';
import Grid from '../../../ui/Grid';
import Button, { PrimaryButton } from '../../../ui/Button';
import IconButton from '../../../ui/IconButton';
import Box from '../../../ui/Box';
import Hidden from '../../../ui/Hidden';
import { useCabinetContext } from '../../../../containers/CabinetContainer/context';
import { useModalsContext } from '../../../../containers/ModalsContainer';
import { openMobileMainMenuModal } from '../../../Modal/MobileMainMenuModal';
import Logo from '../../../Logo';

import useStyles, {
  NavLink,
  StyledAppBar,
  StyledButton,
} from './styled';

const headerNavList = [
  {
    to: '#',
    title: 'For startups',
  },
  {
    to: '#',
    title: 'For investors',
  },
];

export default memo(({ isServicesPage }: { isServicesPage?: boolean }) => {
  const classes = useStyles();
  const { currentUser, currentAccount } = useCabinetContext();
  const { items } = useModalsContext();
  const { t } = useTranslation();

  return (
    <StyledAppBar style={{ pointerEvents: items?.length ? 'none' : 'all' }} color={isServicesPage ? 'inherit' : 'default'}>
        <Toolbar>
          <Box display={{ xs: 'block', md: 'none' }}>
            <IconButton
              edge="start"
              className={classes.menuButton}
              color="inherit"
              aria-label="menu"
              onClick={openMobileMainMenuModal}
            >
              <MenuIcon />
            </IconButton>
          </Box>
          <Box display="flex" flexGrow="1" alignItems="center">
            <Logo />
            <Hidden xsDown>
              <StyledButton
                component={Link}
                edge="start"
                color="inherit"
                aria-label="Services list"
                to={currentAccount?.id ? `/cabinet/account/${currentAccount?.id}/services` : '/cabinet/services'}
              >
                {t('user.menu.services')}
              </StyledButton>
              {currentAccount?.id ? (
                <StyledButton
                  component={Link}
                  edge="start"
                  color="inherit"
                  aria-label="Services list"
                  to={`/cabinet/account/${currentAccount?.id}/workspace`}
                >
                  {t('user.menu.workspace')}
                </StyledButton>
              ) : null}
            </Hidden>
            {!currentUser && (
              <Box display={{ xs: 'none', md: 'flex' }}>
                {headerNavList.map((link) => (
                  <NavLink key={link.title} to={link.to}>{link.title}</NavLink>
                ))}
              </Box>
            )}
          </Box>
          <Box display="flex" alignItems="center">
            {currentUser ? (
              <>
                {/*<Box display={{ xs: 'none', sm: 'block' }}>*/}
                {/*  {currentAccount?.id && (*/}
                {/*    <NotificationsListProvider>*/}
                {/*      <NotificationCenter />*/}
                {/*    </NotificationsListProvider>*/}
                {/*  )}*/}
                {/*</Box>*/}
                <UserMenu />
              </>
            ) : (
              <Grid container spacing={2}>
                <Grid item>
                  <Button>Log in</Button>
                </Grid>
                <Grid item>
                  <PrimaryButton>Sign up</PrimaryButton>
                </Grid>
              </Grid>
            )}
          </Box>
        </Toolbar>
      </StyledAppBar>
  );
});
