import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import ObjectType from '../../../../utils/ObjectType';
import { TaskParams } from '../../network';
import { submitKYCOnboarding } from '../../network';

type ResultData = ObjectType | null;

const useSubmitKYCOnboarding = (params: TaskParams): [ResultData, boolean, Error | null, (data: any) => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useDebouncedCallback(async (data: any) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await submitKYCOnboarding({ ...params, data });
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  return [data, isLoading, error, loadData];
};

export default useSubmitKYCOnboarding;
