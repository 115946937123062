import styled from 'styled-components';

import { ReactComponent as QuestionCircle } from '../../../assets/img/icons/question-circle.svg';
import { Theme, withStyles } from '@material-ui/core/styles';
import Tooltip from '../Tooltip';

export const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: '#232121',
    boxShadow: theme.shadows[3],
    fontSize: 14,
  },
  arrow: {
    '&:before': {
      border: '1px solid #E6E8ED'
    },
    color: theme.palette.common.white
  },
}))(Tooltip);

export const Icon = styled(QuestionCircle)`
  opacity: 0.6;

  &:hover {
    opacity: 1;
  }
`;

export const InfoBubble = (props: { text: string }) => {
  const { text } = props;

  return (
    <LightTooltip
      PopperProps={{
        disablePortal: true,
        style: {
          whiteSpace: 'pre-line',
        }
      }}
      disableFocusListener
      title={<div dangerouslySetInnerHTML={{ __html: text }} />}
      arrow
    >
      <Icon />
    </LightTooltip>
  );
}
