import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core';

import AppBar from '../../../ui/AppBar';
import Button from '../../../ui/Button';

export const NavLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 16px;

  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  text-decoration: none;

  &:hover {
    color: #444;
  }
`;

export const StyledButton = styled(Button)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-transform: capitalize;
  letter-spacing: 0.1px;
  color: rgba(35, 33, 33, 0.6);

  margin-right: 16px;
`;

export const StyledAppBar = styled(AppBar)`
`;

export default makeStyles((theme) => ({
  menuButton: {
    marginRight: theme.spacing(2),
  },
}));
