import { CreateAccountModel, PagingModel } from './models';
import request from './request';

export const getAccountList = ({ page = 1, pageSize = 10 }: PagingModel) => request({
  url: '/api/v1/account/account/',
  urlParams: {
    page,
    page_size: pageSize,
  }
}).then((result) => result.data);

export const getCurrentAccount = ({ id }: { id: string }) => request({
  url: '/api/v1/account/current/',
  headers: {
    'X-Account-ID': id,
  },
}).then((result) => result.data);

export const generateVerificationData = () => request({
  url: '/api/v1/account/generate-verification-data/',
  method: 'POST',
}).then((result) => result.data);

export const createAccount = (data: CreateAccountModel) => request({
  url: '/api/v1/account/account/',
  method: 'POST',
  body: data,
  contentType: 'json',
}).then((result) => result.data);

export const removeAccount = (id: string) => request({
  url: `/api/v1/account/account/${id}/`,
  method: 'DELETE',
}).then((result) => result.data);
