import { useMemo } from 'react';

type AvatarLettersProps = [ string, string? ];

export default (words?: AvatarLettersProps) =>
  useMemo(() => {
    if (!words) return '';

    const [ first, second ] = words;
    let avatarLetter = '';

    if (first && second) {
      avatarLetter = `${first[0].toUpperCase()}${second[0].toUpperCase()}`;
    } else if (first) {
      avatarLetter = first[0].toUpperCase();
    }

    return avatarLetter;
  },
  [words]
);
