export const getLoginUrl = () => {
  return `https://api.ezstart.lu/oidc/authenticate/?next=${window.location.href}`;
};

export const getLogoutUrl = () => {
  return `https://api.ezstart.lu/user/logout/?next=${window.location.origin}`;
};

export const getProfileUrl = () => {
  return 'https://dashboard.ezstart.lu/';
};

export const getLandingUrl = () => {
  return 'https://ezstart.lu/';
};
