import { createContext, ReactElement } from 'react';

import {
  ListContextInitialValues,
  ListContextValues,
  ListProvider,
  useListContext,
} from '../../../providers/ListProvider';

import useListState from '../../../hooks/useListState';
import useServicesList from '../hooks/useServicesList';

export type ServicesProviderProps = {
  children: ReactElement;
};

export const ServicesContext = createContext<ListContextValues>(ListContextInitialValues);

export const ServicesProvider = (props: ServicesProviderProps) => {
  const { page, pageSize, setPage, setPageSize } = useListState();

  const [servicesList, isServicesListLoading, servicesListError, loadServicesList] = useServicesList();

  return (
    <ListProvider
      ListContext={ServicesContext}

      data={servicesList}
      loading={isServicesListLoading}
      error={servicesListError}
      loadData={loadServicesList}

      page={page}
      pageSize={pageSize}
      setPage={setPage}
      setPageSize={setPageSize}
    >
      {props.children}
    </ListProvider>
  );
};

export const useServicesContext = () => useListContext(ServicesContext) as ListContextValues;
