import { useCallback, useState } from 'react';

import RequestError from '../../../../../network/RequestError';
import ObjectType from '../../../../../utils/ObjectType';
import { BaseProcessParams, updateProcess } from '../../../network';

type ResultData = ObjectType | null;

const useProcessRestore = (): [ResultData, boolean, Error | null, (params: BaseProcessParams) => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useCallback(async ({ processId }: BaseProcessParams) => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await updateProcess({
        processId,
        data: {
          is_archived: false,
        },
      });

      setData(response);
    } catch (e) {
      setError(e);
      throw new Error(e);
    }
    setIsLoading(false);
  }, [setData, setError, setIsLoading]);

  return [data, isLoading, error, loadData];
};

export default useProcessRestore;
