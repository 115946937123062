import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { useCabinetContext } from '../../../../containers/CabinetContainer/context';
import useDateLib from '../../../../hooks/useDateLib';

import VerificationInfoPanel from '../../../verification/components/VerificationInfoPanel';
import Grid from '../../../../components/ui/Grid';
import AccountLayout from '../../components/AccountLayout';
import OverviewCard from './components/OverviewCard';
import Transactions from './components/Transactions';

import {
  EmptyText,
  DateTimeWrapper,
  Time,
  Date,
} from './styled';

const OverviewScreen = () => {
  const { t } = useTranslation();
  const { nowTime, nowDate } = useDateLib();
  const { currentAccount } = useCabinetContext();

  const cards = useMemo(() => ([
    {
      title: t('account.overview.cards.company'),
      emptyText: t('account.overview.empty.company'),
    },
    // {
    //   title: t('account.overview.cards.companySecretary'),
    //   emptyText: t('account.overview.empty.companySecretary'),
    // },
    {
      Component: Transactions,
    },
    // {
    //   title: t('account.overview.cards.subscriptions'),
    //   emptyText: t('account.overview.empty.subscriptions'),
    // },
  ]), [t]);

  const date = useMemo(nowDate, [nowDate]);

  const showVerificationPanel = currentAccount?.status === 'new';

  return (
    <AccountLayout title={t('account.overview.title')}>
      <>
        <DateTimeWrapper>
          <Time>{nowTime()}</Time>
          <Date>{date}</Date>
        </DateTimeWrapper>

        {showVerificationPanel && <VerificationInfoPanel type="standard" />}

        <Grid container spacing={4}>
          {cards.map(({ title, emptyText, Component}, index) => Component ? (
            <Grid item key={index} xs={12} sm={6} xl={6}>
              <Component />
            </Grid>
          ) : (
            <Grid item key={index} xs={12} sm={6} xl={6}>
              <OverviewCard key={index} title={title} color={emptyText ? 'empty' : ''}>
                <EmptyText>{emptyText}</EmptyText>
              </OverviewCard>
            </Grid>
          ))}
        </Grid>
      </>
    </AccountLayout>
  );
};

export default OverviewScreen;
