import styled from 'styled-components';

export const PageTitle = styled.div`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
`;

export const AsideWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${(p) => p.theme.breakpoints.down('md')} {
    left: 0;
    padding: 24px 24px 24px 0;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    left: 0;
    padding: 0;
    margin-bottom: 0;
  }

  ${(p) => p.theme.breakpoints.down('xs')} {
    margin-bottom: 0;
  }
`;

export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: calc(100vh - 128px);
  min-height: calc(100vh - 128px);
  overflow-y: scroll;
  position: relative;
  box-shadow: 0 100px 92px rgba(0, 0, 0, 0.07), 0 20.7315px 46.3333px rgba(0, 0, 0, 0.0359341), 0 7.4877px 27.864px rgba(0, 0, 0, 0.0271136), 0 4.14464px 16.7352px rgba(0, 0, 0, 0.0286778), 0 3.00347px 9.2904px rgba(0, 0, 0, 0.0260528), 0 1.88136px 3.99726px rgba(0, 0, 0, 0.0162008);
  border-radius: 24px 24px 0 0;
  padding: 40px 64px 40px 72px;
  background-color: #FFFFFF;
  z-index: 1;
  width: calc(100% + 40px);
  margin-left: -40px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${(p) => p.theme.breakpoints.down('md')} {
    left: 0;
    padding: 24px;
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    left: 0;
    padding: 24px;
  }
`;
