import styled, { css } from 'styled-components';

import Typography from '../../../../../../components/ui/Typography';
import FormControlLabel from '../../../../../../components/ui/FormControlLabel';

export const FormWrapper = styled.div``;

export const FormInputLabel = styled(Typography)`
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: #232121;
`;

export const StyledFormControlLabel = styled(FormControlLabel)`
  flex-grow: 0;
  width: 49%;
  max-width: 49%;
  height: 80px;
  justify-content: center;
  padding: 0 24px;

  background: #F5F5F5;
  border: 1px solid rgba(35, 33, 33, 0.12);
  border-radius: 12px;
  text-transform: none;

  margin-right: 24px;

  & .MuiButtonBase-root {
    display: none;
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  ${(p) => p.theme.breakpoints.down('md')} {
    width: 100%;
    max-width: 100%;
    margin-right: 0;
    margin-top: 16px;

    &:last-child {
      margin-left: 0;
    }

    &:first-child {
      margin-top: 0;
    }
  }

  ${({ control, theme }) => control.props.checked && css`
    background: #FFFFFF;
    border: 1px solid #232121;
    box-shadow: 0 27px 138px rgba(67, 67, 67, 0.07),
    0 5.45819px 30.8241px rgba(67, 67, 67, 0.0417275),
    0 1.65985px 9.17714px rgba(67, 67, 67, 0.0282725);

    ${theme.breakpoints.down('md')} {
      box-shadow: 0 7px 40px rgba(67, 67, 67, 0.04),
      0 2px 10px rgba(67, 67, 67, 0.02),
      0 1px 5px rgba(67, 67, 67, 0.02);
    }
  `}
`;

export const FormSubheader = styled.div`
  color: #232121;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  margin-top: 24px;
  margin-bottom: -8px;
`;
