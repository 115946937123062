import getBodyFromFetch from './utils/getBodyFromFetch';

class RequestError extends Error {
  statusCode?: number;
  statusText?: string;
  body?: any;
  response?: Response;
  isUnauthorized?: boolean;

  static fromFetchResponse = async (response: Response) => {
    const messageStatus = response.statusText || response.status || 'no status';
    const requestError = new RequestError(`Request failed with ${messageStatus}`);

    requestError.response = response;
    requestError.statusCode = response.status;
    requestError.statusText = response.statusText;
    requestError.body = await getBodyFromFetch(response);

    if (response.status === 401 || response.status === 403) {
      requestError.isUnauthorized = true;
    }

    return requestError;
  }

  constructor(error?: string | Error) {
    if (typeof error === 'string') {
      super(error);
    } else if (error instanceof Error) {
      super();
      this.name = error.name;
      this.message = error.message;
      this.stack = error.stack;
    }
  }
}

export default RequestError;
