import React, { ChangeEvent, MouseEvent, useCallback } from 'react';

import TableHead from '../../../../../../components/ui/TableHead';
import TableRow from '../../../../../../components/ui/TableRow';
import TableCell from '../../../../../../components/ui/TableCell';
import Checkbox from '../../../../../../components/ui/Checkbox';
import TableSortLabel from '../../../../../../components/ui/TableSortLabel';

import { Order } from '../../../../utils/companySecretary';

import { VisuallyHidden } from './styled';

interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  numeric: boolean;
}

const headCells: HeadCell[] = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Name' },
  { id: 'last_date', numeric: true, disablePadding: false, label: 'Date' },
];

interface TableHeaderProps {
  numSelected: number;
  onRequestSort: (event: MouseEvent<unknown>, property: string) => void;
  onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  showSelectAll: boolean;
}

function TableHeader(props: TableHeaderProps) {
  const { showSelectAll, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = useCallback((property: string) => (event: MouseEvent<unknown>) => {
    onRequestSort(event, property);
  }, [onRequestSort]);

  return (
    <TableHead>
      <TableRow>
        {showSelectAll && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all items' }}
            />
          </TableCell>
        )}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <VisuallyHidden>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </VisuallyHidden>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default TableHeader;
