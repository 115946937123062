import styled from 'styled-components';
import Typography, { Props as TypographyProps } from '../ui/Typography';


export const ContentWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 250px;
  position: relative;
`;

export const StyledSubTitle = styled(Typography)<TypographyProps>`
  color: rgba(35, 33, 33, 0.6);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.444444px;
`;

export const EmptyWrapper = styled.div`
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
