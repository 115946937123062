import styled from 'styled-components';

export const BillingsListItem = styled.div`
  display: flex;
  padding: 16px 0;
  margin: 0 -24px;
  justify-content: space-between;
  align-items: center;

  &:not(:last-child) {
    border-bottom: 1px solid rgba(35, 33, 33, 0.12);
  }
`;

export const BillingMobile = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 0 24px;
`;

export const BillingLineMobile = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const BillingTimeMobile = styled.div`
  color: rgba(35, 33, 33, 0.6);
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
`;

export const BillingNumberMobile = styled.div`
  color: #232121;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.15px;
  line-height: 24px;

  margin-top: 4px;
`;

export const BillingTitleMobile = styled.div`
  color: #232121;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
`;

export const BillingAmountMobile = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 0.444444px;
  color: #232121;

  margin-top: 4px;
  margin-left: 8px;
`;
