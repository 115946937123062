import request from '../../../network/mainClient/request';
import { getCheckPaymentBody } from '../utils/payments';
import { InvoiceData } from '../hooks/payments/useProcessPaymentInvoice';

export type PaymentParams = {
  taskId: number;
  processId: number;
  processName: string;
};

export const getPaymentClientSecret = ({ processName, processId, taskId }: PaymentParams) => request({
  url: `/api/v1/processes/${processName}/${processId}/payment/${taskId}/client_secret`,
  method: 'GET',
  contentType: 'json',
}).then((result) => result.data);

export type CheckPaymentParams = {
  paymentType: string;
  paymentId?: string;
  vat?: VatNumber;
  invoiceType?: string;
};

export type VatNumber = {
  stateCode: string;
  number: string;
}

export type CheckPaymentProps = PaymentParams & CheckPaymentParams;

export const checkPayment = ({ processName, processId, taskId, paymentId, paymentType, vat, invoiceType }: CheckPaymentProps) => request({
  url: `/api/v1/processes/${processName}/${processId}/payment/${taskId}/`,
  method: 'POST',
  contentType: 'json',
  body: getCheckPaymentBody({ paymentId, paymentType, vat, invoiceType }),
}).then((result) => result.data);

export type VerifyVATProps = {
  stateCode: string;
  vatNumber: string;
};

export const verifyVAT = ({ stateCode, vatNumber }: VerifyVATProps) => request({
  url: '/api/v1/utils/check-vat-number',
  method: 'POST',
  contentType: 'json',
  body: {
    state_code: stateCode,
    number: vatNumber,
  },
}).then((result) => result.data);

export type GetInvoiceProps = PaymentParams & {
  data: InvoiceData;
};

export const getInvoice = ({ processId, processName, taskId, data }: GetInvoiceProps) => request({
  url: `/api/v1/processes/${processName}/${processId}/payment/${taskId}/invoice/`,
  method: 'GET',
  contentType: 'pdf',
  responseType: 'blob',
  urlParams: {
    ...data,
  },
}).then((result) => result.data);

export const paymentFinish = ({ processName, processId, taskId }: PaymentParams) => request({
  url: `/api/v1/processes/${processName}/${processId}/payment_pending/${taskId}/`,
  method: 'POST',
  contentType: 'json',
}).then((result) => result.data);
