import React, { useContext } from 'react';
import { useRouteMatch } from 'react-router-dom';

interface AccountLayoutProviderProps {
  children: React.ReactChild;
}

interface AccountLayoutContextValues {
  url: string;
}

export const AccountLayoutContext = React.createContext<AccountLayoutContextValues>({
  url: '',
});

export const AccountLayoutConsumer = AccountLayoutContext.Consumer;

export const useAccountLayoutContext =
  () => useContext(AccountLayoutContext) as AccountLayoutContextValues;

function AccountLayoutProvider(props: AccountLayoutProviderProps) {
  const { children } = props;
  const { url } = useRouteMatch();

  return (
    <AccountLayoutContext.Provider value={{ url }}>
      {children}
    </AccountLayoutContext.Provider>
  );
}

export default AccountLayoutProvider;
