import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import mainTheme from '../../themes/mainTheme';
import RootLayout from '../../components/RootLayout';
import CabinetContainer from '../CabinetContainer';
import PublicContainer from '../PublicContainer';
import ModalsProvider, { ModalsContainer } from '../ModalsContainer';

const CustomThemeProvider = ({ children }: { children: React.ReactNode }) => {
  return(
    <MuiThemeProvider theme={mainTheme.mui}>
      <ThemeProvider theme={mainTheme.mui}>
        {children}
      </ThemeProvider>
    </MuiThemeProvider>
  );
}

const RootContainer = () => (
  <CustomThemeProvider>
    <CssBaseline />
    <RootLayout>
      <Router>
        <ModalsProvider>
          <Switch>
            <Route path="/cabinet" component={CabinetContainer} />
            <Route path="*" component={PublicContainer} />
          </Switch>
          <ModalsContainer name="root" />
        </ModalsProvider>
      </Router>
    </RootLayout>
  </CustomThemeProvider>
);

export default RootContainer;
