import { Switch, Route } from 'react-router-dom';
import PublicLayout from '../../components/PublicLayout';
import HomeScreen from '../../screens/HomeScreen';
import NotFoundScreen from '../../screens/NotFoundScreen';
import VerificationLanding from '../../modules/verification/screens/VerificationLanding';

const PublicContainer = () => {
  return (
    <PublicLayout>
      <Switch>
        <Route path="/" exact component={HomeScreen} />
        <Route path="/verification" exact component={VerificationLanding} />
        <Route path="*" component={NotFoundScreen} />
      </Switch>
    </PublicLayout>
  )
};

export default PublicContainer;
