import React, { useCallback, useEffect, useState } from 'react';
import { useFormState } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/img/services/close-icon.svg';
import { NUMBERING } from '../../../../../../utils/constants';
import {
  useValidators,
  UseValidatorsReturnProps,
} from '../../../../../../../service/utils/validation';

import Box from '../../../../../../../../components/ui/Box';
import Grid from '../../../../../../../../components/ui/Grid';
import FormTextInput from '../../../../../../../../components/ui/FormTextInput';

import {
  ChipWrapper,
  StyledButton,
  StyledChip,
  StyledTabs,
  TabPanels,
  TabsWrapper,
  FormSubheader,
} from './styled';

type FormTabState = {
  id: number;
  name: string;
};

type GenerateTabProps = {
  tabName: string;
  index: number;
  isDefault?: boolean;
};

function generateTab({ tabName, index, isDefault }: GenerateTabProps): FormTabState {
  const numberingString = NUMBERING.digits[index];
  const tabNameFormatted = `${tabName}${isDefault ? ` ${numberingString}` : ''}`;

  return { id: index, name: tabNameFormatted };
}

type ShareholdersProps = {
  idSeparatorNumber: number;
  parentId: string;
  tPrefix: string;
};

function LegalRepresentatives({ idSeparatorNumber, parentId, tPrefix }: ShareholdersProps) {
  const { t } = useTranslation();
  const validators: UseValidatorsReturnProps = useValidators();
  const parentTabIdArray = parentId.split('_');
  const parentTabId = parentTabIdArray[parentTabIdArray.length - 1];

  const formState = useFormState();
  const tabName = t(`${tPrefix}.peopleForm.entity.representatives.one`);

  const existentTabsState = Object.keys(formState.values)
    .filter((key: string) => key.includes(`${parentId}_legal_representatives_`) && key.includes('_name'))
    .map((key: string) => generateTab({
      tabName: `${formState.values[key]} ${formState.values[`${key.slice(0, -5)}_surname`]}`,
      index: Number(key.split('_')[idSeparatorNumber]),
    }));

  const defaultTabs = existentTabsState.length
    ? existentTabsState
    : [generateTab({ tabName, index: 0, isDefault: true })];

  const [tabs, setTabs] = useState<FormTabState[]>(defaultTabs);
  const [activeTab, setActiveTab] = useState(defaultTabs[0]);

  const showAddNewButton = tabs.length;

  const addNewTab = useCallback(() => {
    let tabIndex = tabs.length;

    if (tabIndex >= 5) {
      return;
    }

    const tabsIds = tabs.map(tab => tab.id);

    while (tabsIds.includes(tabIndex)) {
      tabIndex++;
    }

    const newTab = generateTab({ tabName, index: tabIndex, isDefault: false });

    setTabs([...tabs, newTab]);
    setActiveTab(newTab);
  }, [tabName, tabs]);

  const removeTab = useCallback((tabId: number) => () => {
    const removingTabIndex = tabs.findIndex(tab => tab.id === tabId);
    const nextTabIndex = removingTabIndex !== tabs.length - 1 ? removingTabIndex : removingTabIndex - 1;

    const newTabs = tabs
      .filter(({ id }) => id !== tabId)
      .map((tab, index) => {
        const newTab = { ...tab };

        if (tab.name.includes(t(`${tPrefix}.peopleForm.entity.representatives.one`))) {
          newTab.name = `${t(`${tPrefix}.peopleForm.entity.representatives.one`)} ${index + 1}`;
        }

        return newTab;
      });
    const newActiveTab = nextTabIndex !== -1 ? newTabs[nextTabIndex] : newTabs[0];

    delete formState.values[`${parentId}_legal_representatives_${tabId}_name`];
    delete formState.values[`${parentId}_legal_representatives_${tabId}_surname`];

    setTabs(newTabs);
    setActiveTab(newActiveTab);
  }, [formState.values, parentId, t, tPrefix, tabs]);

  const handleSetActiveTab = useCallback((tab: FormTabState) => () => {
    setActiveTab(tab);
  }, []);

  useEffect(() => {
    tabs.forEach((tab) => {
      const tabIndividualNameInputId = `${parentId}_legal_representatives_${tab.id}_name`;
      const tabIndividualSurnameInputId = `${parentId}_legal_representatives_${tab.id}_surname`;

      const newTabName = `${formState.values[tabIndividualNameInputId] || ''} ${formState.values[tabIndividualSurnameInputId] || ''}`

      tab.name = (newTabName !== ' ' ? newTabName : '')
        || `${tabName} ${NUMBERING.digits[tabs.findIndex(t => t.id === tab.id)]}`;
    });
  },[activeTab, formState, parentId, t, tPrefix, tabName, tabs]);

  return (
    <TabsWrapper>
      <Box mt={4} mb={3}>
        <FormSubheader>{t(`${tPrefix}.peopleForm.entity.representatives.label`)}</FormSubheader>
      </Box>

      <StyledTabs aria-label={`tabs of ${tabName}s`}>
        {tabs.map((tab) => {
          const tabId = tab.id;
          const isActive = tabId === activeTab.id;

          const removeProps = tabs.length !== 1 ? { onDelete: removeTab(tabId) } : {};

          return (
            <ChipWrapper isActive={isActive} key={tabId}>
              <StyledChip
                id={`representative-${parentTabId}-${tabId}`}
                onClick={handleSetActiveTab(tab)}
                label={tab.name}
                deleteIcon={<DeleteIcon />}
                {...removeProps}
              />
            </ChipWrapper>
          );
        })}

        {showAddNewButton && (tabs.length < 5) ? (
          <StyledButton
            onClick={addNewTab}
            startIcon={<AddIcon />}
            size="small"
          >{t(`${tPrefix}.peopleForm.addButton`)}</StyledButton>
        ) : null}
      </StyledTabs>

      <TabPanels>
        {tabs.map((tab) => {
          const tabId = tab.id;
          const isActive = tabId === activeTab.id;

          if (!isActive) {
            return null;
          }

          return (
            <Box key={tabId} display="flex" flexDirection="column">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormTextInput
                    name={`${parentId}_legal_representatives_${tabId}_name`}
                    label={`${t(`${tPrefix}.peopleForm.entity.representatives.name`)} *`}
                    validate={validators.is_required}
                    initialValue=""
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormTextInput
                    name={`${parentId}_legal_representatives_${tabId}_surname`}
                    label={`${t(`${tPrefix}.peopleForm.entity.representatives.surname`)} *`}
                    validate={validators.is_required}
                    initialValue=""
                  />
                </Grid>
              </Grid>
            </Box>
          );
        })}
      </TabPanels>
    </TabsWrapper>
  );
}

export default LegalRepresentatives;
