import { useTranslation } from 'react-i18next';

import BillingsBgImg from '../../../../../../assets/img/backgrounds/billings-bg.png';
import { BillingModel, BillingsModel } from '../../../../network/models';

import Hidden from '../../../../../../components/ui/Hidden';
import BillingsDesktopView from '../BillingsListDesktopView';
import BillingsMobileView from '../BillingsListMobileView';

import { EmptyBg, EmptyText, EmptyWrapper } from './styled';
import {
  StyledPagination
} from '../../../../../process/screens/ProcessesScreen/components/ProcessesDesktopView/styled';
import Pagination from '../../../../../../components/ui/Pagination';
import { useBillingsContext } from '../../../../providers/BillingsProvider';

export type BillingsListItemProps = {
  billing: BillingModel;
};

export type BillingsDataProps = {
  data: BillingsModel;
};

export type BillingsListProps = {
  billings: BillingModel[];
};

const BillingsList = (props: BillingsDataProps) => {
  const { data } = props;

  const { results, count } = data;
  const billings = results || [];
  const { t } = useTranslation();
  const { page, pageSize, setPage, setPageSize } = useBillingsContext();

  const hasBillings = !!billings?.length;

  return hasBillings ? (
    <>
      <StyledPagination>
        <Pagination
          page={page}
          pageSize={pageSize}
          showRowsPerPage={false}
          onChangePage={setPage}
          onChangePageSize={setPageSize}
          count={count}
        />
      </StyledPagination>

      <Hidden smDown>
        <BillingsDesktopView billings={billings} />
      </Hidden>

      <Hidden mdUp>
        <BillingsMobileView billings={billings} />
      </Hidden>
    </>
  ) : (
    <EmptyWrapper>
      <EmptyText>{t('account.invoices.emptyText')}</EmptyText>
      <EmptyBg src={BillingsBgImg} />
    </EmptyWrapper>
  );
};

export default BillingsList;
