import request from '../../../network/mainClient/request';

export type TaskParams = {
  taskId: number;
  processId: number;
  processName: string;
};

export const taskQuestionnaireEdit = ({ processName, processId, taskId }: TaskParams) => request({
  url: `/api/v1/processes/${processName}/${processId}/documents_submit/${taskId}/`,
  method: 'POST',
  contentType: 'json',
  body: {
    documents_submitted: false,
  },
}).then((result) => result.data);

export type SubmitKYCOnboardingParams = TaskParams & {
  data: any
};

export const submitKYCOnboarding = ({ processName, processId, taskId, data }: SubmitKYCOnboardingParams) => request({
  url: `/api/v1/processes/${processName}/${processId}/kyc_onboarding/${taskId}/`,
  method: 'POST',
  contentType: 'json',
  body: {
    ...data,
  },
}).then((result) => result.data);

export type GetKYCShareholdersParams = {
  processId: number;
  processName: string;
};

export const getKYCShareholders = ({ processName, processId }: GetKYCShareholdersParams) => request({
  url: `/api/v1/processes/${processName}/${processId}/shareholders/`,
  method: 'GET',
}).then((result) => result.data);
