import React from 'react';

import { Root } from './styled';

type CabinetBodyProps = {
  children: NonNullable<React.ReactNode>;
  isServicesPage: boolean;
}

export default ({ children, isServicesPage }: CabinetBodyProps) => (
  <Root
    isServicesPage={isServicesPage}
  >
    {children}
  </Root>
);
