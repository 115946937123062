import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import RefreshIcon from '@material-ui/icons/Refresh';

import Img from '../../../../assets/img/backgrounds/pending-payment.png';
import SuccessImg from '../../../../assets/img/backgrounds/success-bg.png';

import { useCabinetContext } from '../../../../containers/CabinetContainer/context';
import VerificationInfoPanel from '../../../verification/components/VerificationInfoPanel';
import usePaymentFinish from '../../hooks/payments/usePaymentFinish';
import { PaymentParams } from '../../network';

import CircularProgress from '../../../../components/ui/CircularProgress';
import { OutlinedButton } from '../../../../components/ui/Button';
import Box from '../../../../components/ui/Box';

import {
  Wrapper,
  Title,
  Text,
  ImageWrapper,
  Image,
} from './styled';

type ProcessPaymentProps = PaymentParams & {
  loadProcess: (loader?: boolean) => void;
  isSuccess: boolean;
};

const ProcessPaymentPending = (props: ProcessPaymentProps) => {
  const { isSuccess, processName, processId, taskId, loadProcess } = props;
  const { t } = useTranslation();
  const { currentAccount } = useCabinetContext();

  const [, isFinishPaymentLoading,, finishPayment] = usePaymentFinish({
    taskId,
    processId,
    processName,
  });

  const handleContinue = useCallback(async () => {
    await finishPayment();
    await loadProcess();
  }, [finishPayment, loadProcess]);

  const showVerificationPanel = currentAccount?.status === 'new';

  return (
    <Wrapper>
      {showVerificationPanel && <VerificationInfoPanel type="standard" />}

      {isSuccess ? (
        <>
          <Title>{t('account.processes.payment.success.bankTransfer.title')}</Title>
          <Text>{t('account.processes.payment.success.text')}</Text>

          <OutlinedButton onClick={handleContinue} disabled={isFinishPaymentLoading}>
            {isFinishPaymentLoading ? (
              <Box mr={1} display="flex" alignItems="center">
                <CircularProgress size={20}/>
              </Box>
            ) : null}
            {t('account.processes.payment.success.button')}
          </OutlinedButton>
        </>
      ) : (
        <>
          <Title>{t('account.processes.payment.pendingTitle')}</Title>
          <Text>{t('account.processes.payment.pendingText')}</Text>

          <OutlinedButton onClick={loadProcess} startIcon={<RefreshIcon />}>
            {t('account.processes.payment.refreshButton')}
          </OutlinedButton>
        </>
      )}

      <ImageWrapper>
        <Image src={isSuccess ? SuccessImg : Img} />
      </ImageWrapper>
    </Wrapper>
  )
};

export default ProcessPaymentPending;
