import React from 'react';

import LoaderProgress from '../../../../components/LoaderProgress';
import Box from '../../../../components/ui/Box';
import Grid from '../../../../components/ui/Grid';
import Hidden from '../../../../components/ui/Hidden';
import AsideServicesMenu  from '../AsideServicesMenu';
import type { MenuItemProps } from '../AsideServicesMenu';
import { AsideMenuWrapper } from '../../screens/ServicesCatalogScreen/styled';

import {
  AsideWrapper,
  ContentWrapper,
  PageTitle,
} from './styled';

interface AccountLayoutProps {
  children: React.ReactChild;
  title: string;
  menuItems: Array<MenuItemProps>;
  loading?: boolean;
}

const ServicesLayout = (props: AccountLayoutProps) => {
  const { title, loading, menuItems, children } = props;

  if (loading || !menuItems.length) {
    return <LoaderProgress />;
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4} lg={3} xl={2}>
        <AsideWrapper>
          {title && (
            <Box mb={{ xs: 2, sm: 3 }}>
              <PageTitle>{title}</PageTitle>
            </Box>
          )}
          {menuItems.length ? (
            <AsideMenuWrapper>
              <AsideServicesMenu items={menuItems} />
            </AsideMenuWrapper>
          ) : null}
        </AsideWrapper>
      </Grid>

      <Hidden smDown>
        <Grid item xs={12} md={8} lg={9} xl={10}>
          <ContentWrapper>
            {children}
          </ContentWrapper>
        </Grid>
      </Hidden>
    </Grid>
  );
}

export default ServicesLayout;
