import { useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import RequestError from '../../../../network/RequestError';
import ObjectType from '../../../../utils/ObjectType';
import { paymentFinish, PaymentParams } from '../../network';

type ResultData = ObjectType | null;

const usePaymentFinish = (params: PaymentParams): [ResultData, boolean, Error | null, () => void] => {
  const [data, setData] = useState<ResultData>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<RequestError | null>(null);

  const loadData = useDebouncedCallback(async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await paymentFinish({ ...params });
      setData(response);
    } catch (e) {
      setError(e);
    }
    setIsLoading(false);
  }, 500, { leading: true });

  return [data, isLoading, error, loadData];
};

export default usePaymentFinish;
