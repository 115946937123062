import styled from 'styled-components';

import ButtonGroup from '../ui/ButtonGroup';
import Button from '../ui/Button';

export const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: 0;
`;

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    background: #F5F5F5;
    color: rgba(35, 33, 33, 0.6);

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 24px;

    &.active {
      background: #FFFFFF;
      box-shadow: 0 0 5px rgb(35 33 33 / 2%), 0 0 5px rgb(35 33 33 / 19%);
      color: #232121;
    }
  }
`;
