import styled from 'styled-components';

import { OutlinedButton } from '../../../../components/ui/Button';

export const StyledOutlinedButton = styled(OutlinedButton)`
  font-family: 'Poppins', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #232121;

  &.MuiButton-root {
    padding: 8px 16px;
    min-width: 36px;
    border: 1px solid rgba(35, 33, 33, 0.12);

    .MuiButton-startIcon {
      position: relative;
      left: 2px;
    }
  }

  ${(p) => p.theme.breakpoints.down('sm')} {
    .MuiButton-startIcon {
      margin-right: 0;
    }
  }
`;
