import React from 'react';
import { FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';

import {
  composeValidators,
  useValidators,
  UseValidatorsReturnProps,
} from '../../../../../../../service/utils/validation';

import Box from '../../../../../../../../components/ui/Box';
import Grid from '../../../../../../../../components/ui/Grid';
import FormTextInput from '../../../../../../../../components/ui/FormTextInput';

import { Country, LegalType } from '../fields';
import AuthorizedPerson from '../AuthorizedPerson';

import {
  ChipWrapper,
  StyledChip,
  StyledTabs,
  TabPanels,
  TabsWrapper,
  FormSubheader,
} from './styled';

type ClientFormProps = {
  tPrefix: string;
};

function ClientForm(props: ClientFormProps) {
  const { tPrefix } = props;

  const { t } = useTranslation();
  const validators: UseValidatorsReturnProps = useValidators();

  const formPrefix = 'client';

  return (
    <TabsWrapper>
      <StyledTabs aria-label={'Client tab'}>
        <ChipWrapper isActive>
          <StyledChip label={t(`${tPrefix}.clientAndAuthorizedPerson.chipLabel`)} />
        </ChipWrapper>
      </StyledTabs>

      <TabPanels>
        <Box display="flex" flexDirection="column">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box ml={1}>
                <LegalType
                  formPrefix={formPrefix}
                  tPrefix={tPrefix}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <FormSpy>
                {props => {
                  const isEntityLegalType = props.values[`${formPrefix}_legal_type`] === t(`${tPrefix}.peopleForm.entity.label`);
                  const isIndividualLegalType = props.values[`${formPrefix}_legal_type`] === t(`${tPrefix}.peopleForm.individual.label`);

                  return (
                    <>
                      <Grid container spacing={3}>
                        {isIndividualLegalType ? (
                          <>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <FormTextInput
                                name={`${formPrefix}_name`}
                                label={`${t(`${tPrefix}.peopleForm.individual.name`)} *`}
                                validate={validators.is_required}
                                initialValue=""
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <FormTextInput
                                name={`${formPrefix}_surname`}
                                label={`${t(`${tPrefix}.peopleForm.individual.surname`)} *`}
                                validate={validators.is_required}
                                initialValue=""
                              />
                            </Grid>
                          </>
                        ) : (
                          <>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <FormTextInput
                                name={`${formPrefix}_entity_name`}
                                label={`${t(`${tPrefix}.peopleForm.entity.name`)} *`}
                                validate={validators.is_required}
                                initialValue=""
                              />
                            </Grid>

                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                              <FormTextInput
                                name={`${formPrefix}_organization_number`}
                                label={`${t(`${tPrefix}.peopleForm.entity.organizationNumber`)} *`}
                                validate={validators.is_required}
                                initialValue=""
                              />
                            </Grid>
                          </>
                        )}

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormTextInput
                            name={`${formPrefix}_phone`}
                            label={`${t(`${tPrefix}.peopleForm.individual.phone`)} *`}
                            validate={validators.is_required}
                            initialValue=""
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                          <FormTextInput
                            name={`${formPrefix}_email`}
                            label={`${t(`${tPrefix}.peopleForm.individual.email`)} *`}
                            validate={composeValidators(validators.email, validators.is_required)}
                            type="email"
                            initialValue=""
                          />
                        </Grid>
                      </Grid>

                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormSubheader>{t(`${tPrefix}.peopleForm.registeredAddress.label`)}</FormSubheader>
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                          <FormTextInput
                            name={`${formPrefix}_city`}
                            label={`${t(`${tPrefix}.peopleForm.registeredAddress.city`)} *`}
                            validate={validators.is_required}
                            initialValue=""
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
                          <FormTextInput
                            name={`${formPrefix}_postal_code`}
                            label={`${t(`${tPrefix}.peopleForm.registeredAddress.postalCode`)} *`}
                            validate={validators.is_required}
                            initialValue=""
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                          <Country
                            name={`${formPrefix}_country`}
                            tPrefix={tPrefix}
                            validate={validators.is_required}
                          />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                          <FormTextInput
                            name={`${formPrefix}_address`}
                            label={`${t(`${tPrefix}.peopleForm.registeredAddress.address`)} *`}
                            validate={validators.is_required}
                            initialValue=""
                          />
                        </Grid>
                      </Grid>

                      <AuthorizedPerson
                        tPrefix={tPrefix}
                        parentId={formPrefix}
                        idSeparatorNumber={3}
                        isEntityLegalType={isEntityLegalType}
                      />
                    </>
                  );
                }}
              </FormSpy>
            </Grid>
          </Grid>
        </Box>
      </TabPanels>
    </TabsWrapper>
  );
}

export default ClientForm;
