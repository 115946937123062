import styled from 'styled-components';
import { Link } from 'react-router-dom';

import Breadcrumbs from '../../../../../../components/ui/Breadcrumbs';
import Typography from '../../../../../../components/ui/Typography';

export const StyledBreadcrumbs = styled(Breadcrumbs)`
  position: relative;
  top: -16px;
  margin-bottom: 8px;
`;

export const BreadcrumbsLink = styled(Link)`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.1px;
  color: #232121;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

export const BreadcrumbsText = styled(Typography)`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(35, 33, 33, 0.6);
`;
