import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ type?: string }>`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 32px;

  ${(p) => p.type === 'next' && css`
    background: rgba(217, 81, 80, 0.1);
    & ${VerifyButton} {
      color: #232121;
    }
  `}
  ${(p) => p.type === 'current' && css`
    background: rgba(217, 81, 80, 0.1);
    flex-direction: row;
    justify-content: center;

    & ${VerifyButton} {
      color: #D95150;
    }
  `}
  ${(p) => p.type === 'standard' && css`
    background: rgba(217, 80, 80, 0.1);
  `}
  border-radius: 12px;
`;

export const Text = styled.div`
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: 0.15px;
  color: #232121;
`;

export const VerifyButton = styled.span`
  margin-top: 4px;

  font-weight: 600;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
