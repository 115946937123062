import snsWebSdk from '@sumsub/websdk';
import ObjectType from '../../../utils/ObjectType';

export type LaunchWebSdkProps = {
  accessToken: string,
  applicantEmail?: string,
  applicantPhone?: string,
  customI18nMessages?: ObjectType,
  getNewAccessToken: () => ObjectType,
};

/**
 * accessToken - access token that you generated on the backend in Step 2
 * applicantEmail - applicant email (not required)
 * applicantPhone - applicant phone, if available (not required)
 * customI18nMessages - customized locale messages for current session (not required)
 */
export default function launchWebSdk(options: LaunchWebSdkProps) {
  const {
    accessToken,
    applicantEmail,
    applicantPhone,
    customI18nMessages,
    getNewAccessToken,
  } = options;

  const phone = applicantPhone ? { phone: applicantPhone } : {};

  let snsWebSdkInstance = snsWebSdk.init(
      accessToken,
      async (newAccessTokenCallback: (newAccessToken: string) => void) => {
        // Access token expired
        // get a new one and pass it to the callback to re-initiate the WebSDK
        const { token: newAccessToken } = await getNewAccessToken(); // get a new token from your backend
        newAccessTokenCallback(newAccessToken)
      },
    )
    .withConf({
      lang: 'en', //language of WebSDK texts and comments (ISO 639-1 format)
      email: applicantEmail,
      ...phone,
      i18n: customI18nMessages,
      uiConf: {
        // customCss: "https://url.com/styles.css"
        // URL to css file in case you need change it dynamically from the code
        // the similar setting at Applicant flow will rewrite customCss
        // you may also use to pass string with plain styles `customCssStr:`
      },
    })
    .withOptions({ addViewportTag: false, adaptIframeHeight: true })
    .on('stepCompleted', (payload: any) => {
      console.log('stepCompleted', payload);
    })
    .on('onError', (error: any) => {
      console.log('onError', error);
    })
    .onMessage((type: any, payload: any) => {
      // see below what kind of messages the WebSDK generates
      console.log('onMessage', type, payload);
    })
    .build();

  // you are ready to go:
  // just launch the WebSDK by providing the container element for it
  snsWebSdkInstance.launch('#sumsub-websdk-container')
}
