import styled from 'styled-components';

export const Wrapper = styled.div<{ isServicesPage?: boolean }>`
  display: flex;
  width: 100%;

  ${(p) => p.theme.breakpoints.up('md')} {
    margin-left: ${(p) => p.isServicesPage ? 0 : 348}px;
  }
`;
