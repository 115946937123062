import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Form } from 'react-final-form';

import { OutlinedButton, PrimaryButton } from '../../../../components/ui/Button';
import CircularProgress from '../../../../components/ui/CircularProgress';
import StyledAccordion from '../../../../components/StyledAccordion';
import ErrorMessage from '../../../../components/ui/ErrorMessage';
import Box from '../../../../components/ui/Box';

import useSubmitKYCOnboarding from '../../hooks/tasks/useSubmitKYCOnboarding';
import { ProcessFormProps } from '../ProcessForm';

import ShareholdersForm from './components/ShareholdersForm';
import { formatValues } from './utils';

import { FormWrapper } from './styled';

type CompanyIncorporationProps = ProcessFormProps;

const ProcessKYCOnboarding = (props: CompanyIncorporationProps) => {
  const {
    closeModal,
    loadProcess,
    task,
    process,
  } = props;

  const { t } = useTranslation();

  const { id: taskId } = task;
  const { id: processId, service: { process_name: processName } } = process;

  const [, isSubmitKYCOnboardingLoading, submitKYCOnboardingError, submitKYCOnboarding] = useSubmitKYCOnboarding({
    taskId,
    processId,
    processName,
  });

  const tPrefix = useMemo(() => 'account.processes.forms.companyIncorporation', []) as string;

  const handleOnSubmit = useCallback(
    async (values) => {
      try {
        const data = formatValues(values);

        await submitKYCOnboarding(data);

        if (!submitKYCOnboardingError && !isSubmitKYCOnboardingLoading) {
          await loadProcess();
        }
      } catch (e) {
        console.error('e', e);
      }
    },
    [submitKYCOnboarding, submitKYCOnboardingError, isSubmitKYCOnboardingLoading, loadProcess],
  );

  return (
    <Form
      onSubmit={handleOnSubmit}
      render={(props) => {
        const { handleSubmit, valid, hasValidationErrors, values } = props;

        const isAllFieldsFilled = Object.values(values).every((value: any) => !!value);

        const isSubmitDisabled = !valid
          || hasValidationErrors
          || isSubmitKYCOnboardingLoading
          || !isAllFieldsFilled;

        return (
          <>
            {(submitKYCOnboardingError) && (
              <Box mb={2} mt={4}>
                <ErrorMessage error={submitKYCOnboardingError} />
              </Box>
            )}

            <FormWrapper>
              <form onSubmit={handleSubmit}>
                <Box mb={5}>
                  <Box mb={3} display="flex" flexDirection="column">
                    <Box>
                      <StyledAccordion
                        title={`${t(`${tPrefix}.shareholders.label`)}`}
                        description={t('account.processes.kyc.description')}
                      >
                        <ShareholdersForm tPrefix={tPrefix} />
                      </StyledAccordion>
                    </Box>
                  </Box>

                  <Box display="flex" justifyContent="space-between" pt={5}>
                    <Box display="inline-block" mr={1}>
                      <OutlinedButton onClick={closeModal}>
                        {t('account.services.checkout.form.buttons.cancel')}
                      </OutlinedButton>
                    </Box>

                    <Box display="flex">
                      <PrimaryButton
                        disabled={isSubmitDisabled}
                        onClick={handleSubmit}
                      >
                        <Box display="flex" alignItems="center">
                          {isSubmitKYCOnboardingLoading ? (
                            <Box mr={1} display="flex" alignItems="center">
                              <CircularProgress size={20}/>
                            </Box>
                          ) : null}
                          {t('account.services.checkout.form.buttons.submit')}
                        </Box>
                      </PrimaryButton>
                    </Box>
                  </Box>
                </Box>
              </form>
            </FormWrapper>
          </>
        )
      }}
    />
  );
};

export default ProcessKYCOnboarding;
