import styled from 'styled-components';

import checkoutSuccessImg from '../../../../assets/img/backgrounds/success-bg.png';

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SuccessTitle = styled.div`
  align-items: center;
  display: flex;

  color: #232121;
  font-weight: 600;
  font-size: 34px;
  letter-spacing: 0.25px;
  line-height: 40px;

  margin-bottom: 24px;
`;

export const SuccessDescription = styled.div`
  display: flex;
  align-items: center;

  color: rgba(35, 33, 33, 0.6);
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.15px;
  text-align: center;

  margin-bottom: 30px;
  max-width: 390px;
`;

export const SuccessActions = styled.div`
  display: flex;
  justify-content: center;

  button, a {
    margin: 0 8px;
  }
`;

export const SuccessImage = styled.div`
  background: url(${checkoutSuccessImg}) no-repeat center center;
  background-size: contain;

  height: 490px;
  margin: 24px;
  max-width: 490px;
  width: 100%;

  ${(p) => p.theme.breakpoints.down('sm')} {
    height: 312px;
  }
`;
