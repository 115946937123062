import React from 'react';

import { GlobalStyle } from './styled';

const RootLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <GlobalStyle />
    {children}
  </>
);

export default RootLayout;
