import React, { Fragment, useContext } from 'react';

import { ModalsContext } from './index';

const ModalsContainer = (props: { name: string }) => {
  const { name } = props;
  const { items, closeModal } = useContext(ModalsContext);

  return (
    <>
      {items.filter(x => x.containerName === name).map((modal) => (
        <Fragment key={modal.id}>
          {modal.render({
            ...modal.props,
            modalId: modal.id,
            modalLevel: modal.level,
            modalIsOpen: true,
            modalRequestClose: () => closeModal(modal.id),
          })}
        </Fragment>
      ))}
    </>
  );
};

export default ModalsContainer;
