import styled from 'styled-components';

export const BillingStatusBase = styled.div`
  border-radius: 8px;
  font-weight: 600;
  font-size: 10px;
  line-height: 16px;
  text-transform: uppercase;

  align-items: center;
  display: flex;
  justify-content: center;
  height: 24px;
  padding: 8px;
  width: fit-content;
  position: relative;
  white-space: nowrap;
`;

export const BillingStatusSucceeded = styled(BillingStatusBase)`
  background: #E7FFF8;
  color: #27C297;
`;

export const BillingStatusInProgress = styled(BillingStatusBase)`
  background: #DEDEDE;
  color: #232121;
`;

export const BillingStatusCanceled = styled(BillingStatusBase)`
  background: #F7DCDC;
  color: #D95050;
`;
